<template>
  <div>
    <div
      class="showTimeWindow-container"
      :class="{ 'showTimeWindow-container__clickable': allowAddTimeWindow }"
      v-if="hasTimeWindow"
      @click="onAddTimeWindowClicked"
    >
      <div class="showTimeWindow">
        <label
          class="timeWindow__label noselect"
          :class="{ 'timeWindow__label__clickable': allowAddTimeWindow }"
        >
          {{ this.merchandise.timeWindow.start }} - {{ this.merchandise.timeWindow.end }}
        </label>
      </div>
    </div>
    <div
      class="addTimeWindow-container"
      v-if="showAddTimeWindow"
      @click="onAddTimeWindowClicked"
    >
      <div
        class="addTimeWindow"
      >
        <label class="addTimeWindow__label noselect">
          + {{ timeWindowLabelByServiceType }}
        </label>
      </div>
    </div>

    <time-window-picker
      :title="timeWindowPickerTitleByServiceType"
      v-if="showTimeWindowPicker"
      :start="merchandise.timeWindow.start"
      :end="merchandise.timeWindow.end"
      @close="onTimeWindowPickerClosed"
      @confirm="onTimeWindowPickerConfirm"
    />
  </div>
</template>

<script>
import '../../common/style.css';
import DateTimePicker from '../../common/DateTimePicker.vue';
import TimeWindowPicker from './TimeWindowPicker.vue';
import { serviceTypes } from '../common/serviceTypes';

export default {
  name: 'MerchandiseListTimeWindow',

  components: { DateTimePicker, TimeWindowPicker },

  props: {
    merchandise: Object,
    allowAddTimeWindow: Boolean,
    serviceType: String,
  },

  data() {
    return {
      showTimeWindowPicker: false,
    }
  },

  computed: {
    hasTimeWindow() {
      return this.merchandise.timeWindow.start != '' && this.merchandise.timeWindow.end != '';
    },

    showAddTimeWindow() {
      return this.allowAddTimeWindow && !this.hasTimeWindow;
    },

    timeWindowLabelByServiceType() {
      return {
        [serviceTypes.PICKUP]: "horário de coleta",
        [serviceTypes.DELIVERY]: "horário de entrega",
      }[this.serviceType]
    },

    timeWindowPickerTitleByServiceType() {
      return {
        [serviceTypes.PICKUP]: "Defina os horários de coleta",
        [serviceTypes.DELIVERY]: "Defina os horários de entrega",
      }[this.serviceType]
    },
  },

  methods: {
    onAddTimeWindowClicked() {
      if (this.allowAddTimeWindow) {
        this.showTimeWindowPicker = true;
      }
    },

    onTimeWindowPickerClosed() {
      this.showTimeWindowPicker = false;
    },

    onTimeWindowPickerConfirm(start, end) {
      this.showTimeWindowPicker = false;
      this.$emit('on-add-time-window', this.merchandise, start, end)
    },
  },
}
</script>

<style scoped>
  .showTimeWindow-container {
    margin: 0 auto;
    width: 120px;
    height: 36px;
    display: table-cell;
    vertical-align: middle;
  }

  .showTimeWindow-container__clickable {
    cursor: pointer;
  }

  .showTimeWindow {
    border-radius: 18px;
    border: solid 1px #6a6a6a;
    padding: 8px 12px 8px 12px;
    display: inline-block
  }

  .timeWindow__label {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #1f1f1f;
  }

  @media only screen and (min-width: 1366px) {
    .timeWindow__label {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .timeWindow__label {
      font-size: 14px;
    }
  }

  .timeWindow__label__clickable {
    cursor:pointer;
  }

  .addTimeWindow-container {
    height: 36px;
    border-radius: 4px;
    background-color: #198bfd;
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
  }

  .addTimeWindow {
    display: inline-block;
    padding: 0 16px;
  }

  .addTimeWindow__label {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 0.3px;
    text-align:center;
    color: #ffffff;
    cursor:pointer;
  }

  @media only screen and (min-width: 1366px) {
    .addTimeWindow__label {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .addTimeWindow__label {
      font-size: 14px;
    }
  }

</style>
