import Vue from 'vue';

const intercomState = Vue.observable({ enabled: false });

function callIntercom(...args) {
  if (typeof window.Intercom !== 'function') {
    return;
  }

  window.Intercom(...args);
}

function isIntercomAppIdEmpty(appId) {
  return appId == null || appId === '';
}

/**
 * Thin wrapper around the global `window.Intercom` object.
 *
 * @see https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */
export default class Intercom {
  static boot(params = {}) {
    if (isIntercomAppIdEmpty(params.app_id)) {
      return;
    }

    callIntercom('boot', params);

    // Set after success!
    intercomState.enabled = true;
  }

  static update(params) {
    callIntercom('update', params);
  }
}

export function isIntercomEnabled() {
  return intercomState.enabled;
}
