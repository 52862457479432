<template>
  <div :class="[style.lineAndElement, styleIfLastElement]">
    <div :class="style.element">
      <div :class="style.markerContainer">
        <div v-if="isFirst" :class="style.spacer"/>
        <div v-else :class="[style.line, highlightIfCompleteOrCurrentStep]"/>
        <div
          :class="[style.marker, markerStatusStyle]"
        >
          <img :src="markerIcon" />
        </div>
        <div v-if="isLast" :class="style.spacer"/>
        <div v-else :class="[style.line, highlightIfCompleteStep]"/>
      </div>
      <div :class="style.details">
        <div
          :class="[style.title, highlightIfCurrentStep]"
        >
          {{ title }}
        </div>
        <div
          :class="[style.description, highlightIfCurrentStep]"
        >
          {{ description }}
        </div>
      </div>
    </div>
    <div v-if="!isLast" :class="[style.line, highlightIfCompleteStep]"/>
  </div>
</template>

<script>
import timelineStatus from "./timelineStatus";
import style from "../../styles/timeline/timelineElement.module.scss";

// TODO: get these icons from HounDS
import checkMarkIcon from '@publicAssets/checkbox-checkmark.svg'
import iconProgress from '@publicAssets/icon-progress.svg'
import iconLock from '@publicAssets/icon-lock.svg'

export default {
  name: 'TimelineElement',

  props: {
    status: String,
    title: String,
    description: String,
    isFirst: Boolean,
    isLast: Boolean,
  },

  computed: {
    isCompleteStep() {
      return this.status === timelineStatus.DONE;
    },
    isCurrentStep() {
      return this.status === timelineStatus.DOING;
    },

    styleIfLastElement() {
      return this.isLast ? this.style.last : null;
    },
    highlightIfCurrentStep() {
      return this.isCurrentStep ? this.style.highlighted : null;
    },
    highlightIfCompleteStep() {
      return this.isCompleteStep ? this.style.highlighted : null;
    },
    highlightIfCompleteOrCurrentStep() {
      return this.isCompleteStep || this.isCurrentStep ? this.style.highlighted : null;
    },
    markerStatusStyle() {
      if (this.isCompleteStep) {
        return this.style.complete;
      } else if (this.isCurrentStep) {
        return this.style.current;
      } else {
        return this.style.idle;
      }
    },
    markerIcon() {
      if (this.isCompleteStep) {
        return checkMarkIcon;
      } else if (this.isCurrentStep) {
        return iconProgress;
      } else {
        return iconLock;
      }
    },
    style: () => Object.freeze(style),
  }
}
</script>
