
<template>
  <div class="content__pod--mapcontainer">
    <GmapMap
      :center="mapCenter"
      :zoom="15"
      map-type-id="roadmap"
      class="content__pod--map"
    >
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false">
      </gmap-info-window>

      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :icon="m.icon"
        @click="toggleInfoWindow(m,index)"
      />
    </GmapMap>
  </div>
</template>

<script>
  export default {
    name: "PodMap",

    props: {
      podLocation: Object,
      stopLocation: Object,
    },

    data: function() {
      return {

        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,

        infoOptions: {
          content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
            width: 0,
            height: -35,
          }
        }
      }
    },

    computed: {
      markers(){
        return [
          {
            position: {
              lat: this.stopLocation.lat,
              lng: this.stopLocation.lng,
            },
            infoText: '<strong>Parada programada para este local</strong>',
            icon: { url: ('/jade/public/map-pin-stop.svg')},
          },

          {
            position: {
              lat: this.podLocation.lat,
              lng: this.podLocation.lng,
            },
            infoText: '<strong>Comprovante gerado neste local</strong>',
            icon: { url: ('/jade/public/map-pin-pod.svg')},
           },
        ]
      },

      mapCenter(){
        return {
          lat: this.stopLocation.lat,
          lng: this.stopLocation.lng,
        }
      }
    },

    methods: {
      toggleInfoWindow: function(marker, index) {
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx === index) {
          this.infoWinOpen = !this.infoWinOpen;
        }

        else {
          this.infoWinOpen = true;
          this.currentMidx = index;
        }
      }
    }
  }
</script>

<style scoped>
  .content__pod--mapcontainer {
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    height: 100%;
    width: 100%;
    display: block;
  }

  .content__pod--map {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
