<template>
  <fieldset class="nps-rating">
    <label
      v-for="score of possibleScores"
      :key="score"
      class="nps-rating-item"
    >
      <input
        v-model="currentValue"
        type="radio"
        class="nps-rating-item__button"
        :name="`nps-${uniqueId}`"
        :value="score"
      />
      <span class="nps-rating-item__text">
        {{ score }}
      </span>
    </label>
  </fieldset>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'NpsRating',

  props: {
    value: Number,
  },

  data() {
    return {
      currentValue: this.value,
      uniqueId: uuidv4(),
    };
  },

  computed: {
    possibleScores() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    },
  },

  watch: {
    currentValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss">
@use '@vuxx/hounds/scss/abstracts/variables' as *;

.nps-rating {
  display: flex;
  border: none;
  padding: 0.5em 0;
}

.nps-rating-item {
  flex: 1 0;
}

.nps-rating-item__button {
  position: absolute;
  width: 0.062rem;
  height: 0.062rem;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.nps-rating-item__text {
  display: block;
  text-align: center;
  align-items: center;
  padding: 0.625em;
  border: 1px solid $color-gray--500;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  font-size: 1.266em;
}

.nps-rating-item:first-child .nps-rating-item__text {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.nps-rating-item:last-child .nps-rating-item__text {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.nps-rating-item__button:checked + .nps-rating-item__text {
  background-color: $color-primary;
  color: $color-gray--50;
  border-color: $color-primary;
}
</style>
