export const vehicleNames = {
  'MOTO': 'Moto',
  'PASSEIO': 'Veículo de Passeio',
  'FIORINO': 'Utilitário Pequeno',
  'VAN': 'Van',
  'HR': 'VUC Pequeno',
  'IVECO': 'VUC Médio',
  '3/4': 'VUC Grande',
  'TOCO': 'Toco',
  'TRUCK': 'Truck',
  'BITRUCK': 'Bi-Truck',
  'CARRETA': 'Carreta',
};

export function vehicleTypeLabel(vehicleType) {
  return vehicleNames[vehicleType] ?? 'Desconhecido';
}
