<template>
  <base-layout
    :add-container="false"
  >
    <div class="container container--small">
      <div v-if="!podeSolicitarRota" class="alert alert--warning">
        <svg class="icon">
          <use href="@vuxx/hounds/icons/general-icons-defs.svg#icon-info"></use>
        </svg>
        <span v-if="inativo">
          Sua conta está inativa há mais de 90 dias. Por favor, entre em contato com o e-mail comercial para reativar:
        </span>
        <span v-else>
          Sua conta está bloqueada para solicitações. Por favor, entre em contato com o e-mail comercial:
        </span>
        <a href="mailto:comercial4rodas@rappi.com" class="simple-link">
          comercial4rodas@rappi.com
        </a>
      </div>

      <div v-if="!serviceAvailableCheck" class="alert alert--warning">
        <svg class="icon">
          <use href="@vuxx/hounds/icons/general-icons-defs.svg#icon-info"></use>
        </svg>
        <span>
          {{serviceAvailableCheckMessage}}
        </span>
      </div>

      <div v-if="newVehiclePolicy" class="alert alert--info">
        <svg class="icon">
          <use href="@vuxx/hounds/icons/general-icons-defs.svg#icon-info"></use>
        </svg>
        <span>
          <p>Em decorrência de mudanças organizacionais entre as fusões das empresas Vuxx, Box Delivery e Rappi, no momento estamos realizando atendimento para a demanda de três categorias de veículos: <strong>Moto, Veículo de Passeio e Fiorino (Utilitário Pequeno)</strong>.</p>
          <p>Caso deseje realizar solicitações de veículos maiores, é necessário entrar em contato conosco para alinharmos a operação e assim evitarmos falhas e/ou quaisquer transtornos.</p>
          <p>Agradecemos a atenção e ficamos à disposição para qualquer dúvida!</p>
        </span>
      </div>
    </div>

    <stepper
      class="content__stepper"
      :current-step="currentStep"
      :merchandise-count="merchandiseCount"
      :quote-value="quoteLabel"
      :quote-value-loading="estimatingPriceAndDistance"
    />
    <component
      :is="currentStepComponent"
      :merchandises="merchandises"
      :service-type="serviceType"
      :price-estimation="priceEstimation"
      :request-submitted="requestSubmitted"
      :can-finish-request="canFinishRequest"
      :estimating-price-and-distance="estimatingPriceAndDistance"
      :warehouse="warehouse"
      :round-trip.sync="roundTrip"
      :is-round-trip.sync="isRoundTrip"
      :shipment-tracking-sharing-enabled="shipmentTrackingSharingEnabled"
      @on-next="onNext"
      @on-previous="onPrevious"
      @merchandise-added-or-updated="onMerchandiseAddedOrUpdated"
      @merchandise-updated="onMerchandiseUpdated"
      @merchandise-removed="onMerchandiseRemoved"
      @update:service-type="onServiceTypeChanged"
      @finish-request="onFinishRequest"
      @show-message="openMessageModal"
      @on-warehouse-selected="onWarehouseSelected"
      @on-merchandise-add-time-window="onMerchandiseAddTimeWindow"
    />
    <estimate-error-modal
      v-if="estimateErrorModal.visible"
      :title="estimateErrorModal.title"
      :message="estimateErrorModal.message"
      @recalculate="recalculatePrice"
      @close="closeEstimateErrorModal"
    />
    <finance-info-modal
      :visible="financeInfoModal.visible"
      :close-handler="onFinanceInfoModalResult"
      :show-message-handler="openMessageModal"
    />
    <message-modal
      v-if="messageModal.visible"
      :title="messageModal.title"
      :message="messageModal.message"
      :errors="messageModal.errors"
      @modal-close-requested="closeMessageModal"
    />
    <loading-modal
      :title="loadingModal.title"
      :message="loadingModal.message"
      :visible="loadingModal.visible"
    />
  </base-layout>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { mapGetters, mapState } from 'vuex';
import { buildTransportationsFromShipmentsAndPlaces } from '@/spot/spotRequest/transportation/TransportationFactory';
import {
  convertRoundTripToPlace,
  convertWarehouseToPlace,
  findUniquePlacesFromMerchandises,
} from '@/spot/spotRequest/transportation/PlaceFactory';
import { convertMerchandisesToShipments } from '@/spot/spotRequest/transportation/ShipmentFactory';
import BaseLayout from '../../common/BaseLayout.vue';
import Stepper from './Stepper.vue';

import Merchandises from './Merchandises.vue';
import VehicleSelectionStep from './VehicleSelectionStep.vue';
import Summary from './Summary.vue';
import MessageModal from '../../common/MessageModal.vue';
import LoadingModal from '../../common/LoadingModal.vue';
import EstimateErrorModal from '../../common/EstimateErrorModal.vue';
import FinanceInfoModal from '../common/FinanceInfoModal.vue';

import finishRequest, { formatScheduledFor, merchandiseToSpotDocument } from './finishRequest';
import { moneyFormat } from '../../common/filters/numberFormatters';
import { serviceTypes } from '../common/serviceTypes';
import { experiments } from '../../common/activeExperiments';

import spotRequestErrors from './spotRequestErrors';
import { translateErrorMessage } from "@/spot/spotRequest/spotRequestErrorTranslator";

const merchandise = 'merchandise';
const vehicle = 'vehicle';
const summary = 'summary';

const components = {
  merchandise: Merchandises,
  vehicle: VehicleSelectionStep,
  summary: Summary,
};

function calculateDistanceAndPrice(estimateCommand) {
  return axios.post('/jade/api/spot/estimate', estimateCommand).then((response) => {
    const { data } = response;

    return {
      freightPrice: data.freightPrice,
      distanceInKm: data.distanceInKm,
      driverAssistantsPrice: data.driverAssistantsPrice,
      tollPrice: data.tollPrice,
      driverTollPrice: data.driverTollPrice,
      adValoremPrice: data.adValoremPrice,
      priceComponents: data.priceComponents,
      netPrice: data.netPrice,
      grossPrice: data.grossPrice,
      taxes: data.taxes,
      totalTaxes: data.totalTaxes,
      discounts: data.discounts,
      priceEstimatedAt: data.priceEstimatedAt,
      quotationId: data.quotationId
    };
  });
}

export default {
  name: 'SpotRequestPage',

  components: {
    BaseLayout,
    FinanceInfoModal,
    LoadingModal,
    MessageModal,
    EstimateErrorModal,
    Stepper,
  },

  data() {
    return {
      currentStep: merchandise,
      currentStepComponent: components[merchandise],
      priceEstimation: null,
      serviceType: serviceTypes.DELIVERY,
      merchandiseIds: [],
      merchandisesById: {},
      requestSubmitted: false,
      estimatingPriceAndDistance: false,
      estimateError: false,
      loadingModal: { title: '', visible: false },
      messageModal: { title: '', message: '', visible: false },
      estimateErrorModal: { title: '', message: '', visible: false },
      financeInfoModal: { visible: false },
      serviceAvailableCheck : true,
      serviceAvailableCheckMessage : '',
      warehouse: null,
      isRoundTrip: false,
      roundTrip: null,
    };
  },

  computed: {
    ...mapState('spotRequest', [
      'config',
      'scheduling',
      'totalWeightInKilograms',
      'driverAssistants',
      'selectedVehicleBodyTypes',
    ]),

    ...mapGetters('spotRequest', [
      'vehicleType',
      'driverAssistants',
      'merchandisesTotalPrice',
    ]),

    podeSolicitarRota() {
      // Padrão é true caso não esteja definido — para evitar que o alerta apareça rapidamente enquanto a configuração é carregada.
      return this.$store.state.spotRequest.config?.pode_solicitar_rotas ?? true;
    },

    newVehiclePolicy() {
      // Padrão é false caso não esteja definido — para evitar que o alerta apareça rapidamente enquanto a configuração é carregada.
      return this.$store.getters['spotRequest/newVehiclePolicy'] ?? false;
    },

    selectedVehicleBodyTypesIds() {
      return this.selectedVehicleBodyTypes.map((bodyType) => bodyType.id);
    },

    merchandiseCount() {
      return this.merchandiseIds.length;
    },

    merchandises() {
      return this.merchandiseIds.map((id) => this.merchandisesById[id]);
    },

    canFinishRequest() {
      return !this.requestSubmitted && this.priceAndDistanceEstimated;
    },

    priceAndDistanceEstimated() {
      return !this.estimatingPriceAndDistance && !this.estimateError;
    },

    quoteLabel() {
      if (this.estimatingPriceAndDistance) {
        return 'Calculando...';
      } if (!this.priceEstimation) {
        return '';
      }
      return `R$ ${moneyFormat(this.priceEstimation.grossPrice)}`;
    },

    shipmentTrackingSharingEnabled() {
      return this.$store.getters['core/isExperimentActive'](experiments.ShipmentTrackingSharing);
    },

    roundTripPlace() {
      return convertRoundTripToPlace(this.roundTrip);
    },
    warehousePlace() {
      return convertWarehouseToPlace(this.warehouse, this.serviceType);
    },
    merchandisesPlaces() {
      return findUniquePlacesFromMerchandises(this.merchandises);
    },
    placesById() {
      let allPlaces = [this.warehousePlace, this.roundTripPlace, ...this.merchandisesPlaces];
      allPlaces = allPlaces.filter((place) => place); // remove null values (round trip is nullable)

      const placesById = {};
      for (const place of allPlaces) {
        placesById[place.id] = place;
      }
      return placesById;
    },
    shipments() {
      return convertMerchandisesToShipments(this.merchandises, this.serviceType);
    },
    transportations() {
      return buildTransportationsFromShipmentsAndPlaces(this.shipments, this.placesById);
    },
  },

  async mounted() {
    const all = [
      this.$store.dispatch('core/fetchActiveExperiments'),
      this.$store.dispatch('spotRequest/fetchConfig').then(async () => {
        await this.checkServiceAvailable();
      }),
      this.$store.dispatch('spotRequest/fetchVehicles'),
    ];

    return Promise.all(all);
  },

  methods: {
    async onFinishRequest() {
      this.requestSubmitted = true;

      this.openLoadingModal({ title: 'Finalizando solicitação' });

      const newSpotCommand = this.getNewSpotCommand();

      try {
        const { spotId } = await finishRequest(newSpotCommand);
        this.onSpotRequestCreated(spotId);
      } catch (error) {
        this.closeLoadingModal();
        this.requestSubmitted = false;

        if (error.type && error.type === spotRequestErrors.MissingFinancialInfo) {
          this.openFinanceInfoModal();
          return;
        }

        const translatedMessage = translateErrorMessage(error);
        if (translatedMessage.shouldBeReported === true) {
          this.$logger.error(error, { req: error.req });
        }

        this.openMessageModal(translatedMessage);
      }
    },

    async onSpotRequestCreated() {
      const response = await Swal.fire({
        icon: 'success',
        title: 'Solicitação concluída com sucesso!',
      });
      if (response) {
        this.$router.push({ name: 'monitoring' })
      }
    },

    async onNext(componentName, args) {
      switch (componentName) {
        case merchandise:
          this.serviceType = args.serviceType;
          this.currentStep = vehicle;
          this.currentStepComponent = components[vehicle];
          this.updateMerchandisesTotalPriceFromCurrentMerchandises();
          break;
        case vehicle:
          // Moving to next step
          this.currentStep = summary;
          this.currentStepComponent = components[summary];

          this.calculatePrice();

          break;
      }
    },

    async calculatePrice() {
      this.closeEstimateErrorModal();

      this.estimatingPriceAndDistance = true;

      try {
        const places = this.getLoadingAndUnloadingPlaces();
        const estimative = await calculateDistanceAndPrice(this.getEstimateCommand());

        this.estimateError = false;

        // Updating new prices
        this.priceEstimation = estimative;
      } catch (err) {
        this.estimateError = true;
        this.priceEstimation = null;
        this.openEstimateErrorModal({
          title: 'Erro ao calcular preço',
          message: 'Não conseguimos calcular o preço neste momento. Por favor, tente novamente em alguns instantes.\n',
        });
      } finally {
        // Finishing visual loading
        this.estimatingPriceAndDistance = false;
      }
    },

    recalculatePrice() {
      this.calculatePrice();
    },

    onPrevious(componentName) {
      switch (componentName) {
        case summary:
          this.currentStep = vehicle;
          this.currentStepComponent = components[vehicle];
          break;
        case vehicle:
          this.currentStep = merchandise;
          this.currentStepComponent = components[merchandise];
          break;
      }
    },

    onMerchandiseUpdated(merchandise) {
      if (this.merchandisesById[merchandise.id]) {
        // Vue cannot detect property addition or deletion
        // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
        this.$set(this.merchandisesById, merchandise.id, merchandise);
      }
    },

    onMerchandiseAddedOrUpdated(merchandise) {
      const isNew = !this.merchandisesById[merchandise.id];

      // Vue cannot detect property addition or deletion
      // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      this.$set(this.merchandisesById, merchandise.id, merchandise);

      if (isNew) {
        this.merchandiseIds.push(merchandise.id);
      }
    },

    onMerchandiseAddTimeWindow(merchandise, start, end) {
      merchandise.timeWindow.start = start;
      merchandise.timeWindow.end = end;
    },

    onMerchandiseRemoved(merchandise) {
      this.merchandiseIds = this.merchandiseIds.filter((id) => id !== merchandise.id);
      this.$delete(this.merchandisesById, merchandise.id);
    },

    updateMerchandisesTotalPriceFromCurrentMerchandises() {
      const totalPrice = this.merchandises.reduce(
        (total, merchandise) => total + merchandise.fiscalDocument?.worth?.goods || 0,
        0,
      );

      if (totalPrice === 0) {
        return;
      }

      this.$store.commit(
        'spotRequest/updateMerchandisesTotalPrice',
        totalPrice,
      );
    },

    onServiceTypeChanged(serviceType) {
      this.serviceType = serviceType;
      Object.values(this.merchandisesById).forEach((merchandise) => {
        merchandise.serviceType = serviceType;
      });
    },

    onWarehouseSelected(selectedWarehouse) {
      // Make a copy to roundTrip standart opition.
      if (!this.roundTrip) {
        this.roundTrip = selectedWarehouse;
      }

      this.warehouse = selectedWarehouse;
    },

    openMessageModal({ title, message, errors }) {
      this.messageModal = {
        title, message, errors, visible: true,
      };
    },

    closeMessageModal() {
      this.messageModal = {
        title: '', message: '', errors: '', visible: false,
      };
    },

    openLoadingModal({ title }) {
      this.loadingModal = { title, visible: true };
    },

    closeLoadingModal() {
      this.loadingModal = { title: '', visible: false };
    },

    openEstimateErrorModal({ title, message }) {
      this.estimateErrorModal = { title, message, visible: true };
    },

    closeEstimateErrorModal() {
      this.estimateErrorModal = { title: '', message: '', visible: false };
    },

    openFinanceInfoModal() {
      this.financeInfoModal.visible = true;
    },

    closeFinanceInfoModal() {
      this.financeInfoModal.visible = false;
    },

    onFinanceInfoModalResult({ completed = false } = {}) {
      this.closeFinanceInfoModal();

      if (completed) {
        this.onFinishRequest();
      }
    },

    getLoadingAndUnloadingPlaces() {
      const warehouse = this.warehousePlace;
      let roundTrip = this.roundTripPlace;

      if (!this.isRoundTrip) {
        roundTrip = null;
      }

      switch (this.serviceType) {
        case serviceTypes.DELIVERY: {
          return {
            loadingPlace: warehouse,
            unloadingPlace: roundTrip,
          };
        }
        case serviceTypes.PICKUP:
          return {
            loadingPlace: null, // round trip is disabled for pickup
            unloadingPlace: warehouse,
          };
      }
    },

    getNewSpotCommand() {
      function formatMoneyIfPresent(number) {
        return (number == null) ? number : Number(number).toFixed(2)
      }

      return {
        vehicle: {
          type: this.vehicleType,
          bodyTypes: this.selectedVehicleBodyTypesIds,
          extras: this.$store.getters['spotRequest/selectedVehicleExtraIds'],
        },
        totalWeightInKilograms: Number(this.totalWeightInKilograms),
        notes: this.notes,
        isRoundTrip: this.isRoundTrip,
        externalId: this.$store.state.spotRequest.externalId,

        ...this.getLoadingAndUnloadingPlaces(),

        documents: this.merchandises.map(merchandiseToSpotDocument),
        quote: {
          price: Number(this.priceEstimation.freightPrice).toFixed(2),
          tollPrice: Number(this.priceEstimation.tollPrice).toFixed(2),
          driverTollPrice: formatMoneyIfPresent(this.priceEstimation.driverTollPrice),
          distance: Number(this.priceEstimation.distanceInKm).toFixed(2),
          quotationId: this.priceEstimation.quotationId
        },
        serviceType: this.serviceType,
        scheduledFor: formatScheduledFor(this.scheduling),
        driverAssistantsCount: this.driverAssistants,
        priceEstimatedAt: this.priceEstimation.priceEstimatedAt,

        transportations: this.transportations,

        merchandisesTotalPrice: this.merchandisesTotalPrice?.toString(),
      };
    },

    getEstimateCommand() {
      return {
        vehicle: this.vehicleType,
        vehicleConfig: {
          type: this.vehicleType,
          extras: this.$store.getters['spotRequest/selectedVehicleExtraIds'],
        },
        notes: this.notes,
        isRoundTrip: this.isRoundTrip,

        ...this.getLoadingAndUnloadingPlaces(),
        places: this.merchandisesPlaces,

        serviceType: this.serviceType,
        scheduledFor: formatScheduledFor(this.scheduling),
        driverAssistantsCount: this.driverAssistants,
        calculateToll: true,
        merchandisesTotalPrice: this.merchandisesTotalPrice?.toString(),
      };
    },

    async checkServiceAvailable() {
      try {
        const serviceAvailableCheckResult = this.config.service_available;
        if(serviceAvailableCheckResult === 'available') {
          this.serviceAvailableCheck = true;
        } else if(serviceAvailableCheckResult === 'only_available_tomorrow'){
          this.serviceAvailableCheck = false;
          this.serviceAvailableCheckMessage = 'Olá cliente, no momento nosso time está ausente, sua solicitação será atendida amanhã, a partir das 7h.';
        } else if(serviceAvailableCheckResult === 'only_available_next_work_day'){
          this.serviceAvailableCheck = false;
          this.serviceAvailableCheckMessage = 'Olá cliente, no momento nosso time está ausente, sua solicitação será atendida na próxima segunda-feira, a partir das 7h.';
        } else{
          this.serviceAvailableCheck = false;
          this.serviceAvailableCheckMessage = 'Olá cliente, no momento nosso time está ausente, sua solicitação será atendida hoje, a partir das 7h.';
        }

      } catch (error) {
        console.error('Erro ao buscar configurações do spot:', error);
      }
    },
  },
};
</script>

<style scoped>
  .content__stepper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    border-top: 1px solid #e7e7e7;
    z-index: 2;
  }
</style>
