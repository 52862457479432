import axios from 'axios';

export default async function uploadFiscalDocument(file) {
  const formData = new FormData();
  formData.append('document', file);

  const response = await axios.post('/jade/api/fiscal-documents/upload-file',
    formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

  return response.data;
}
