<template>
  <div class="warehouseList" @keyup.esc="onEscPressed" ref="warehouseList">
    <div
      v-if="hasWarehouses"
      class="warehouseList-selectedWarehouse"
      :class="{'warehouseList-selectedWarehouse--max': listMaximized}"
      @click="onListOpenToggleClicked">
      <div class="warehouseList-selectedWarehouse-info">
        <h1 class="warehouseList-selectedWarehouse__title"> {{ selectedWarehouse.name }} </h1>
        <label class="warehouseList-selectedWarehouse__address"> {{ selectedWarehouseFormattedAddress }} </label>
      </div>
      <div class="warehouseList-selectedWarehouse-actions">
        <img class="warehouseList-selectedWarehouse-actions__icon" :src="listIconPath" />
      </div>
    </div>
    <div
      v-else
      class="warehouseList-warehousesNotFound"
      @click="requestAddWarehouse"
    >
      <div
        class="warehouseList-warehousesNotFound__info"
      >
        Adicionar um armazém
      </div>
    </div>

    <div
      v-if="listMaximized"
      class="warehouseList-overlay"
      @click="onOverlayClicked"
    />

    <div class="warehouseList-collapsible">
      <div
        class="warehouseList-list"
        :class="{'warehouseList-list--min': listMinimized,
                 'warehouseList-list--max': listMaximized}"
      >
        <header class="warehouseList__header">
          <div class="warehouseList-listActions">
            <input
              class="warehouseList-searchInput"
              type="text"
              v-model="searchText"
              placeholder="Busque pelo nome ou endereço"
            >
          </div>
          <div class="horizontalSeparator" />
        </header>
        <main class="warehouseList__listItems">
          <warehouse-item
            class="warehouseList-list__item"
            v-for="(warehouse, index) in warehouses"
            :key="index"
            :warehouse="warehouse"
            v-if="shouldShowWarehouse(warehouse, index)"
            @on-warehouse-clicked="onWarehouseItemClicked"
          />
        </main>
        <footer class="warehouseList__footer">
          <div class="horizontalSeparator warehouseList__horizontalSeparator" />
          <div class="warehouseList-addWarehouseButton__wrapper">
            <button
              type="button"
              class="baseButton secondaryActionButton warehouseList-addWarehouseButton"
              @click="onAddWarehouseClicked"
            >
              Adicionar novo armazém
            </button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import '../../common/style.css'
import WarehouseItem from './WarehouseItem.vue'
import removeAccents from 'remove-accents'
import { formattedAddress } from './warehouse';
import DisposableFocusTrap from '../../common/DisposableFocusTrap'

function applyTextFilter(text) {
  return removeAccents(text).toLowerCase();
}

export default {
  name: 'WarehouseList',

  components: { WarehouseItem },

  props: {
    warehouses: {
      type: Array,
      default: [],
    },

    selectedWarehouseId: {
      type: String,
      default: null
    }
  },

  computed: {
    hasWarehouses() {
      return this.warehouses != null && this.warehouses.length > 0;
    },

    selectedWarehouse() {
      return this.warehouses[this.selectedWarehouseIndex];
    },

    selectedWarehouseFormattedAddress() {
      return formattedAddress(this.selectedWarehouse);
    },

    listMaximized() {
      return !this.listMinimized;
    },

    listIconPath() {
      return this.listMinimized ?
        '/jade/public/icon-arrow-down-booking-info.svg' :
        '/jade/public/icon-arrow-up-booking-info.svg';
    },
  },

  data() {
    return {
      selectedWarehouseIndex: this.findIndexById(this.selectedWarehouseId),
      listMinimized: true,
      searchText: '',
      _focusTrap: new DisposableFocusTrap(() => this.$refs.warehouseList),
    };
  },

  methods: {
    findIndexById(id) {
      if (id === null) {
        return 0;
      }
      return this.warehouses.findIndex((element) => element.id === id);
    },

    onListOpenToggleClicked() {
      this.listMinimized = !this.listMinimized;
    },

    onWarehouseItemClicked(warehouse) {
      this.selectedWarehouseIndex = this.findIndexById(warehouse.id);
      this.listMinimized = true;
      this.$emit('on-warehouse-selected', this.selectedWarehouseIndex);
    },

    requestAddWarehouse() {
      this.$emit('add-warehouse-requested');
    },

    onAddWarehouseClicked() {
      this.listMinimized = true;
      this.requestAddWarehouse();
    },

    onOverlayClicked() {
      this.listMinimized = true;
    },

    onEscPressed() {
      this.listMinimized = true;
    },

    shouldShowWarehouse(warehouse, index) {
      const isNotSelectedIndex = index !== this.selectedWarehouseIndex;

      const filteredSearchText = applyTextFilter(this.searchText);

      const nameIncludesSearchText = applyTextFilter(warehouse.name).includes(filteredSearchText);
      const addressIncludesSearchText = applyTextFilter(warehouse.address.address).includes(filteredSearchText);

      const anyIncludesSearchText = nameIncludesSearchText || addressIncludesSearchText;

      return isNotSelectedIndex && anyIncludesSearchText;
    },
  },

  watch: {
    listMinimized(newListMinimized) {
      if (newListMinimized) {
        this.$data._focusTrap.deactivate();
      } else {
        this.$data._focusTrap.activate();
      }
    },

    selectedWarehouseId(newSelectedWarehouseId) {
      this.selectedWarehouseIndex = this.findIndexById(newSelectedWarehouseId);
    }
  },

}
</script>

<style scoped>
  .warehouseList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
  }

  .warehouseList-overlay {
    z-index: -2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .warehouseList-collapsible {
    transform: translateY(-5px);
    z-index: -1;
  }

  .warehouseList-list {
    padding-top: 4px;
    display: flex;
    flex-direction: column;
  }

  .warehouseList-list--min {
    max-height: 0;
    overflow: hidden;
  }

  .warehouseList-list--max {
    max-height: 431px;
    overflow: auto;
    border-radius: 4px;
    box-shadow: 0 10px 16px 0 rgba(10, 31, 68, 0.06), 0 2px 4px 0 rgba(0, 12, 32, 0.04);
    background-color: #ffffff;
    border: solid 1px #eaeaea;
  }

  .warehouseList__listItems {
    flex-grow: 1;
    overflow: auto;
  }

  .warehouseList-listActions {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }

  .warehouseList-searchInput {
    margin: 16px 12px;
    padding: 8px 12px 8px 36px;
    border: none;
    border-radius: 4px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #656565;
    background: #eeeeee url("/jade/public/icon-search.svg") no-repeat left 12px center;
    background-size: 16px;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .warehouseList-searchInput::-webkit-input-placeholder {
    color: #939393;
  }

  .warehouseList-searchInput::-moz-placeholder {
    color: #939393;
  }

  .warehouseList-searchInput:-ms-input-placeholder {
    color: #939393;
  }

  .warehouseList__horizontalSeparator {
    margin: 0 12px;
    background-color: #eaeaea;
  }

  .warehouseList-addWarehouseButton {
    width: 210px;
  }

  .warehouseList-addWarehouseButton__wrapper {
    display: flex;
    flex-direction: row;
    margin: 16px 12px;
    justify-content: flex-end;
  }

  .warehouseList-list__item:hover {
    background-color: #f8f8f8;
  }


  .warehouseList-selectedWarehouse,
  .warehouseList-warehousesNotFound {
    border: solid 1px #d7d7d7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    height: 48px;
    cursor: pointer;
    background: white;
    border-radius: 4px;
  }

  .warehouseList-warehousesNotFound__info {
    align-self: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #2f2f2f;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .warehouseList-selectedWarehouse--max {
    border: solid 1px #28c0f1;
  }

  .warehouseList-selectedWarehouse-info {
    flex-grow: 1;
    overflow: hidden;
  }

  .warehouseList-selectedWarehouse__title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #000000;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .warehouseList-selectedWarehouse__address {
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #858585;
    max-lines: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .warehouseList-selectedWarehouse-actions {
    margin-left: 8px;
    max-width: 20px;
  }

  .warehouseList-selectedWarehouse-actions__icon {
    height: 20px;
    width: 20px;
  }
</style>
