<template>
  <div>
    <div class="spotRequest-roundTripOption__header">
      <h2 class="spotRequest-sectionTitle spotRequest-merchandises__listSectionTitle">Rota circular</h2>
      <div class="spotRequest-roundTripOption__text">
        <p>Caso precise retornar com mercadorias para o seu armazém, adicione o retorno do motorista.<br/>
          <b>Importante:</b> esse retorno será adicionado como um ponto final na sua rota.</p>
      </div>
    </div>
    <div class="spotRequest-roundTripOption__horizontalSeparator"></div>
    <div class="spotRequest-roundTripOption__body">

      <div
        v-if="!readOnly"
        class="spotRequest-roundTripOption__option"
      >
        <input
          type="checkbox"
          class="spotRequest-roundTrip-checkbox"
          id="roundTrip-checkbox"
          :checked="isRoundTrip"
          @change="onIsRoundTripChanged"
        />
        <label class="defaultCheckbox__text" for="roundTrip-checkbox">
          Adicionar retorno
        </label>
      </div>

      <div class="spotRequest-roundTripOption__content"
           :class="{
             'spotRequest-roundTripOption__content--disabled': !isRoundTrip,
             'spotRequest-roundTripOption__content--readonly': readOnly
           }">

        <div class="spotRequest-roundTripOption__warehouse" v-if="roundTrip">
          <div class="spotRequest-roundTripOption__address" >
            {{ roundTrip.address.address }},
            {{ roundTrip.address.number }}<br>
            {{ roundTrip.address.neighborhood }},
            {{ roundTrip.address.city }},
            {{ roundTrip.address.state }}
          </div>
        </div>
        <button
          v-if="!readOnly"
          class="spotRequest-roundTripOption__edit"
          title="Editar rota cirular"
          @click="openRoundTripEditModal(roundTrip)"
        >
          Editar
        </button>
      </div>
    </div>

    <template v-if="manualRoundTripPlaceInputVisible">
      <round-trip-place-input
        ref="manualRoundTripInputModal"
        :round-trip-to-edit="roundTripToEdit"
        @cancel="closeRoundTripEditModal"
        @submit="onRoundTripDetailsModalSubmit"
      ></round-trip-place-input>
    </template>
  </div>
</template>

<script>
import RoundTripPlaceInput from "./RoundTripPlaceInput.vue";
import deepClone from "../../common/deepClone";
import { loadedState } from './addMerchandises';

  export default {
    name: 'roundTrip',

    components: {
      RoundTripPlaceInput,
    },

    props: {
      roundTrip: Object,
      isRoundTrip: Boolean,
      readOnly: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        manualRoundTripPlaceInputVisible: false,
        roundTripToEdit: null,
      };
    },

    methods: {
      onIsRoundTripChanged(e){
        this.$emit('update:isRoundTrip', e.target.checked);
      },

      openRoundTripEditModal(roundTrip) {
        this.roundTripToEdit = deepClone(roundTrip);
        this.manualRoundTripPlaceInputVisible = true;
      },

      closeRoundTripEditModal(){
        this.manualRoundTripPlaceInputVisible = false;
      },

      addOrUpdateRoundTrip(roundTrip) {
        const warehouseRoundTrip = this.mapRoundTripToWarehouseFormat(roundTrip);

        this.$emit('update:roundTrip', warehouseRoundTrip);
      },

      onRoundTripDetailsModalSubmit(editedRoundTip) {
        this.addOrUpdateRoundTrip({
          ...editedRoundTip,
          status: loadedState(),
        });
        this.closeRoundTripEditModal();
      },

      mapRoundTripToWarehouseFormat(roundTrip) {
        return {
          isRoundTrip: this.isRoundTrip,
          trackingCode: roundTrip.trackingCode,
          address: {
            cep: roundTrip.address.postalCode,
            address: roundTrip.address.thoroughfare,
            city: roundTrip.address.locality,
            complement: roundTrip.address.complement,
            neighborhood: roundTrip.address.neighborhood,
            number: roundTrip.address.number,
            state: roundTrip.address.state,
          },
          latitude: roundTrip.address.geolocation.latitude,
          longitude: roundTrip.address.geolocation.longitude,
          opening_time: roundTrip.timeWindow.start,
          closing_time: roundTrip.timeWindow.end,
          delivery_instructions: roundTrip.instructions,

        }
      },
    }
  }
</script>

<style scoped>

  .spotRequest-roundTripOption__header {
    padding: 24px 20px 16px 20px;
  }

  .spotRequest-roundTripOption__horizontalSeparator {
    height: 0;
    border-bottom: 1px solid #e7e7e7;
  }

  .spotRequest-roundTripOption__body {
    padding: 24px 20px 40px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .spotRequest-roundTripOption__option {
    display: flex;
    transform: translateY(-1px);
  }

  .spotRequest-roundTripOption__content {
    display: flex;
    flex-grow: 1;
    opacity:1;
    align-items: center;
  }

  .spotRequest-roundTripOption__content--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }

  .spotRequest-roundTripOption__content--readonly {
    pointer-events: none;
    cursor: default;
  }

  .spotRequest-roundTripOption__text {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .spotRequest-roundTrip-checkbox {
    all: unset;
    cursor: pointer;
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    border: solid 1px #6a6a6a;
    display: inline-block;
    max-height: 0;
  }

  .spotRequest-roundTrip-checkbox:checked {
    background: #1a8bfd url(/jade/public/checkbox-checkmark.svg) no-repeat 3px 4px;
    background-size: 70% auto;
  }

  .defaultCheckbox__text {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    margin-right: 40px;
    padding-left: 18px;
  }

  .spotRequest-roundTripOption__warehouse {
    display: flex;
    flex-direction: row;
  }

  .spotRequest-roundTripOption__address {
    width: 280px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    margin-right: 50px;
  }

  .spotRequest-roundTripOption__number {
    width: 50px;
    font-size: 16px;
    text-align: right;
    color: #2f2f2f;
  }

  .spotRequest-roundTripOption__edit {
    width: 34px;
    height: 34px;
    border: none;
    padding: 0;
    background: transparent no-repeat center center;
    text-indent: -9999em;
    overflow: hidden;
    cursor: pointer;
    background-image: url(/jade/public/icon-edit.svg);
    margin-left: auto;
  }

</style>
