<template>
  <div class="page">
    <img
      @click="$router.go(-1)"
      src="/jade/public/ic-inputhelp-close.svg"
      class="closeButton"
    />
    <div class="header">
      <div class="title">{{ title }}</div>
      <img src="/assets/images/logo-cargo-light.svg" class="headerLogo"/>
    </div>
    <center-screen-notification
      ref="exitNotification"
      message="Para sair, pressione ESC"
      :delay-to-hide="2000"
    />
    <unavailable-page v-if="isUnavailable"></unavailable-page>
    <div v-else class="content">
      <div class="map-background">
        <button v-show="!showOverlay" class="toggle-details" @click="toggleDetails">&lt; Voltar</button>
        <!-- TODO: Show the active status on the panel -->
        <div v-show="false" class="bottom-status-panel">
          <div class="bottom-status-component"></div>
        </div>
        <tracking-map
          v-if="hasMapMarkers"
          :markers="mapMarkers"
        />
      </div>
      <div v-show="showOverlay" class="overlay" ref="overlay">
        <div class="left-panel">
          <panel class="status-panel">
            <slot name="top-panel"></slot>
            <button class="toggle-map" @click="toggleDetails">Ver no mapa</button>
          </panel>
          <panel class="details-panel">
            <slot name="bottom-panel"></slot>
          </panel>
        </div>
      </div>
    </div>
    <button v-show="showOverlay && hasIntercom" class="intercomLauncher">
      <img src="/jade/public/icon-intercom.svg"> Preciso de ajuda
    </button>
  </div>
</template>

<script>
  import Panel from '../hounDS/components/Panel.vue'
  import CenterScreenNotification from "../hounDS/components/CenterScreenNotification";
  import TrackingMap from "./map/TrackingMap.vue";
  import UnavailablePage from "./UnavailablePage.vue";
  import Intercom, { isIntercomEnabled } from '../client/intercom'

  import Page from '../pods/Page.vue';

  export default {
    name: "TrackingPageLayout",
    components: {
      Panel,
      CenterScreenNotification,
      TrackingMap,
      UnavailablePage,
      Page
    },

    props: {
      title: String,
      isUnavailable: Boolean,
      mapMarkers: Array,
    },

    data: function () {
      return {
        showOverlay: true,
      }
    },

    methods: {
      toggleDetails() {
        this.showOverlay = !this.showOverlay;
      },

      handleKeyPressToCloseModal(event) {
        if (event.key === 'Escape') {
          this.$router.go(-1);
        }
      },

      showDetailsWhenDesktopMode() {
        const isDesktop = window.innerWidth > 768;
        if (isDesktop) {
          this.showOverlay = true
        }
      },
    },

    computed: {
      hasMapMarkers() {
        return this.mapMarkers.length > 0;
      },

      hasIntercom() {
        return isIntercomEnabled() && !this.$route.meta.public;
      },
    },

    async mounted() {
      window.addEventListener('resize', this.showDetailsWhenDesktopMode)
      window.addEventListener('keydown', this.handleKeyPressToCloseModal)
       
      Intercom.update({
        alignment: 'right',
        custom_launcher_selector: '.intercomLauncher',
        hide_default_launcher: true,
      });
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.showDetailsWhenDesktopMode);
      window.removeEventListener('keydown', this.handleKeyPressToCloseModal);
    }
  }
</script>

<style scoped lang="scss">
  @import '../hounDS/styles/global/colors';

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300');

  .page {
    font-family: "Open Sans";
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .content {
    flex: 1 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .closeButton {
    position: fixed;
    left: 20px;
    top: 12px;
    z-index: 1;
    padding: 4px;
    cursor: pointer;
    background-color: white;
    border-radius: 4px;
  }

  .header {
    font-family: Montserrat;
    flex: 0 0 auto;
    width: 100%;
    height: 48px;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  .title {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
    color: white;
    margin-left: 5.7vw;
    margin-bottom: 0;
  }

  .headerLogo {
    margin-right: 5.7vw;
    width: 78px;
    height: auto;
  }

  .map-background {
    flex: 1 0 auto;
    display: flex;
    background-color: $gray200;
  }

  .overlay {
    display: block;
    position: absolute;
    top: 48px;
    left: 0;
    bottom: 0;
    width: 53.17vw;
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 5.7vw;
  }

  .status-panel {
    margin-bottom: 32px;
    margin-top: 32px;
    padding: 20px 24px;
  }

  .details-panel {
    flex: 1 0 0;
    margin-bottom: 32px;
    overflow: hidden;
  }

  .toggle-map {
    display: none;
  }

  .toggle-details {
    display: none;
  }

  .bottom-status-panel {
    display: none;
  }

  .intercomLauncher {
    position: absolute;
    display: flex;
    align-items: center;
    right: 5.7vw;
    bottom: 32px;
    padding: 12px 16px;

    background: white;
    border: 1px solid $gray200;
    box-sizing: border-box;
    border-radius: 4px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    vertical-align: center;
  }

  .intercomLauncher:hover {
    cursor: pointer;
    background: $gray100;
  }

  .intercomLauncher > img {
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    .header {
      justify-content: center;
    }

    .title {
      display: none;
    }

    .headerLogo {
      margin-right: 0;
    }

    .left-panel {
      width: 100%;
      height: 100%;
      margin: 0;

      /* On mobile version, this element should be overflowed and children should not be overflowed. */
      /* It is worth noting that children sticky elements work as intended when only ONE parent is overflowed (both */
      /* vertically and horizontally), that is, sticky elements will be bugged when a parent is overflowed */
      /* horizontally and another parent vertically. */
      overflow: auto;
    }

    .overlay {
      width: 100%;
    }

    .status-panel {
      flex: 0 0 auto;
      margin: 0;
      display: flex;
      flex-direction: column;
      /* Prevents scrolling horizontally */
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .details-panel {
      width: 100%;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .toggle-details {
      position: fixed;
      display: block;
      left: 16px;
      top: 64px;
      z-index: 1;
      padding: 12px 24px;
      font-weight: bold;
      font-size: 14px;
      background: white;
      border: 1px solid $gray200;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .toggle-map {
      display: block;
      background: white;
      border: 1px solid $yellow500;
      box-sizing: border-box;
      border-radius: 4px;
      color: black;
      padding: 16px 20px;
      width: 256px;
      margin: 0 auto;
      font-size: 14px;
      font-weight: bold;
    }

    .bottom-status-panel {
      display: block;
      position: fixed;
      bottom: 20px;
      left: 0;
      z-index: 1;
      width: 100%;
    }

    .bottom-status-component {
      background: white;
      border: 1px solid $gray200;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 40px 40px;
      margin: 0 auto;
      width: 70%;
    }

    .intercomLauncher {
      right: 20px;
      bottom: 20px;
    }
  }
</style>
