<template>
  <div class="fiscalDocumentsUploadModal-backdrop">
    <div class="merchandiseInputHelp-content">
      <div class="merchandiseInputHelp-closeButton">
        <img 
          src="/jade/public/ic-inputhelp-close.svg"
          @click="cancel"
        />
      </div>
      <label class="merchandiseInputHelp-title__label">
        Como adicionar mercadorias
      </label>
      <label class="merchandiseInputHelp-subtitle__label">
        Veja as opções para você adicionar suas mercadorias
      </label>
      <div 
        v-for="(option, index) in inputOptions"
        :key="index"
        class="merchandiseInputHelp-option"
      >
        <img widht="100px" height="84px" :src="option.imgSrc" />
        <div class="merchandiseInputHelp-option-textContainer">
          <label class="merchandiseInputHelp-option-title__label">
            {{option.title}}
          </label>
          <br>
          <label class="merchandiseInputHelp-option-description__label">
            {{option.description}}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MerchandiseInputHelp',

  props: {
    visible: false
  },

  data() {
    return {
      inputOptions: [
        {
          imgSrc: '/jade/public/ic-inputhelp-ctrl.svg',
          title: 'CTRL/C + CTRL/V',
          description: 'Com uma lista dos códigos das notas de mercadoria, você consegue copia-los e colar no campo de notas.'
        },
        {
          imgSrc: '/jade/public/ic-inputhelp-excel.svg',
          title: 'Copiar tabela do Excel',
          description: 'Insira mercadorias copiando tabela do Excel, basta deixar nessa ordem código buscável, CEP, número e observação. Copie e cole no campo de notas.'
        },
        {
          imgSrc: '/jade/public/ic-inputhelp-xml.svg',
          title: 'Enviar arquivo XML',
          description: 'Adicione mercadorias enviando os arquivos para o TMS.'
        },
        {
          imgSrc: '/jade/public/ic-inputhelp-manual.svg',
          title: 'Adicionar manualmente',
          description: 'Caso não tenha as Nf-e’s ou Ct-e’s, crie manualmente fornecendo endereço, janela de entrega e observação.'
        },
      ]
    };
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },

  mounted() {
  }
}
</script>

<style scoped>
  .fiscalDocumentsUploadModal-backdrop {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .merchandiseInputHelp-content {
    width: 800px;
    min-height: 590px;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .merchandiseInputHelp-closeButton {
    display: flex;
    flex-direction: row-reverse;
    margin: 20px 20px 0 0;
    cursor: pointer;
  }

  .merchandiseInputHelp-title__label {
    margin: 0 32px 0 32px;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #0e0e0e;
  }

  .merchandiseInputHelp-subtitle__label {
    margin: 0 32px 0 32px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #636363;
  }

  .merchandiseInputHelp-option {
    display: flex;
    flex-direction: row;
    margin: 40px 32px 0 32px
  }

  .merchandiseInputHelp-option:last-child {
    margin-bottom: 32px;
  }

  .merchandiseInputHelp-option-textContainer {
    margin: 0 0 0 24px;
  }

  .merchandiseInputHelp-option-title__label {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #0e0e0e;
  }

  .merchandiseInputHelp-option-description__label {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #636363;
  }
</style>

