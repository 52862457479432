import spotRequestErrors from './spotRequestErrors';

const translators = {
  [spotRequestErrors.EstimateNoLongerValid]: (error) => {
    const maxTimespan = error.maxTimespanBetweenSpotEstimateAndRequestInMinutes;
    const message = `Nossa cotação é válida por apenas ${maxTimespan} minuto(s). Por favor refaça sua cotação para atualizar.`
    return {
      title: 'Cotação desatualizada',
      message,
    };
  },

  [spotRequestErrors.BlockedCustomer]: (error) => {
    const message = 'Tivemos um problema com seu pagamento e não é possível realizar sua solicitação no momento. <br>'
      + "Por favor entre em contato com: <a href='mailto:financeiro@vuxx.com.br'>financeiro@vuxx.com.br</a>.";
    return {
      title: 'Solicitação bloqueada',
      message,
    };
  },

  [spotRequestErrors.customerDoesNotHavePricingRule]: (error) => {
    const message = `Não há precificação cadastrada para o veículo ${error.vehicleType}`;
    return {
      title: 'Precificação não encontrada',
      message,
      shouldBeReported: true,
    };
  },

  [spotRequestErrors.usersNotFound]: (error) => {
    return {
      title: 'Usuários não encontrados',
      message: error.userEmails.join('\n'),
    };
  },

  [spotRequestErrors.warehousesNotFound]: (error) => {
    return {
      title: 'Armazéns não encontrados',
      message: error.warehouses
        .map(warehouse => `${warehouse.name} (cliente ${warehouse.customerId})`)
        .join('\n'),
    };
  },

  [spotRequestErrors.validationFailed]: (error) => {
    let message = '';
    for (const [key, value] of Object.entries(error.reasons)) {
      message += `${key}: ${value}\n`;
    }
    return {
      title: 'Erro de validação',
      message,
      shouldBeReported: true,
    };
  },
};

function translateErrorMessage(error) {
  if (error.type in translators) {
    return translators[error.type](error);
  }

  return {
    title: 'Erro no servidor',
    message: 'Por favor, entre em contato com a nossa equipe de suporte.',
    errors: error.messages,
    shouldBeReported: true,
  };
}

export { translateErrorMessage }
