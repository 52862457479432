<template>
  <div
    v-if="availableVehicleExtras.length"
    class="main-form bulma-mb-5"
  >
    <h3 class="main-form__label">Extras</h3>
    <div class="main-form__inline-group">
      <label
        v-for="extraItem in availableVehicleExtras"
        :key="extraItem.id"
        :for="extraItem.id"
        class="main-form__checkbox-container"
      >
        <input
          :id="extraItem.id"
          v-model="selectedExtrasArray"
          :name="extraItem.id"
          :value="extraItem"
          type="checkbox"
          class="main-form__input main-form__input--checkbox"
          @change="updateSelectedExtras"
        >
        <span class="main-form__checkbox-button">
            {{ extraItem.description }}
          </span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'VehicleExtras',

  data() {
    return {
      selectedExtrasArray: this.$store.state.spotRequest.selectedVehicleExtras,
    };
  },

  watch: {
    selectedVehicleExtras() {
      this.selectedExtrasArray = this.$store.state.spotRequest.selectedVehicleExtras;
    },
  },

  computed: {
    ...mapState('spotRequest', [
      'selectedVehicleExtras',
    ]),
    ...mapGetters('spotRequest', [
      'availableVehicleExtras',
    ]),
  },

  methods: {
    updateSelectedExtras() {
      this.$store.commit('spotRequest/updateSelectedVehicleExtras', this.selectedExtrasArray);
    },
    deselectAllExtras() {
      this.selectedExtrasArray = [];
    },
  },
};
</script>

<style lang="scss">
.main-form__label {
  display: block;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.main-form__checkbox-container {
  position: relative;
}

.main-form__checkbox-button {
  display: flex;
  align-items: center;
  padding-top: 0.625em;
  padding-right: 0.625em;
  padding-bottom: 0.625em;
  padding-left: 2.2em;
  border: 1px solid #dee2e6;
  transition: border-color 0.3s;
  border-radius: 8px;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 0.5em);
    left: 0.625em;
    width: 1em;
    height: 1em;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    background-image: url('/jade/public/icon-check-alt.svg');
    background-size: 0;
    transition: background-color 0.3s;
  }
}

.main-form__input--checkbox:focus + .main-form__checkbox-button {
  border-color: #f6be01;
  box-shadow: 0px 0px 0px 2px rgba(133, 32, 187, 0.16);
}

.main-form__input--checkbox:checked + .main-form__checkbox-button {
  border-color: #f6be01;

  &::before {
    color: white;
    background-color: #f6be01;
    background-size: cover;
  }
}

.main-form__input--checkbox {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.main-form__inline-group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.main-form__info {
  font-size: 14px;
}

.main-form__info-error {
  color: #e53935;
}

</style>
