<template>
  <div
    class="common-dateTimePickerModal"
    @keyup.esc="close"
    @keyup.enter="confirm"
    tabindex="0"
  >
    <div class="common-dateTimePickerModal__backdrop">
      <div class="common-dateTimePickerModal__content" role="dialog">
        <div class="common-dateTimePickerModal__title">{{ title }}</div>
        <div class="common-dateTimePickerModal__message">
          <date-time-picker
            v-model="selectedDateTime"
            :date-disabled="dateDisabled"
            :time-disabled="timeDisabled"
            :min-feasible-time-minutes-into-future="minFeasibleTimeMinutesIntoFuture"
          ></date-time-picker>
        </div>
        <div class="horizontalSeparator"></div>
        <div class="common-dateTimePickerModal__buttons">
          <button
            class="baseButton cancelButton"
            @click="close"
          >Cancelar</button>
          <button
            class="baseButton submitButton"
            @click="confirm"
            :disabled="cannotConfirm"
          >Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimePicker from './DateTimePicker.vue';
import moment from 'moment';

  export default {
    name: 'DateTimePickerModal',

    components: { DateTimePicker },

    data() {
      return {
        selectedDateTime: this.value
      };
    },

    props: {
      value: '',
      title: '',
      dateDisabled: false,
      timeDisabled: false,
      minFeasibleTimeMinutesIntoFuture: Number,
    },

    computed: {
      cannotConfirm() {
        return !this.selectedDateTime || this.selectedDateTime < moment.now();
      }
    },

    methods: {
      close() {
        this.$emit('close');
      },

      confirm() {
        this.$emit('confirm', this.selectedDateTime);
      },
    },

    watch: {
      value() {
        this.selectedDateTime = value;
      }
    },
  }
</script>

<style scoped>
  .common-dateTimePickerModal {
    position: fixed;
    z-index: 1000;
  }

  .common-dateTimePickerModal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .common-dateTimePickerModal__content {
    width: 510px;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    padding: 24px 28px;
    display: flex;
    flex-direction: column;
    transform: translateY(-95px);
  }

  .common-dateTimePickerModal__title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #2f2f2f;
  }

  .common-dateTimePickerModal__message {
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .common-dateTimePickerModal__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .common-dateTimePickerModal__buttons > :not(:last-child) {
    margin-right: 20px;
  }
</style>
