<template>
  <div class="main-form">
    <label
      class="main-form__label main-label--inline"
      for="merchandises-total-value"
    >
      Valor total das mercadorias
    </label>
    <div class="main-form__inline-group main-form__inline-group--center-items">
      <money
        v-model="merchandisesTotalPrice"
        v-bind="moneyConfig"
        class="main-form__input main-form__input--auto-with"
      />
    </div>
    <div class="warning">
      <warning>
        Este valor será usado para calcular o seguro de carga (Ad Valorem), o qual estará
        sujeito a correções realizadas pela Box Delivry após o recebimento das Notas Fiscais.
      </warning>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, localize, extend } from 'vee-validate';
import { required, integer } from 'vee-validate/dist/rules';
import { Money } from 'v-money'
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';
import Warning from '@/spot/common/Warning.vue';

extend('required', required);
extend('integer', integer);

localize('pt_BR', pt_BR);

export default {
  name: 'MerchandisesTotalPrice',

  components: {
    ValidationProvider,
    Money,
    Warning,
  },

  data() {
    return {
      moneyConfig: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    };
  },

  computed: {
    ...mapState('spotRequest', {
      merchandisesTotalPriceFromStore: (state) => state.merchandisesTotalPrice,
    }),

    merchandisesTotalPrice: {
      get() {
        return this.merchandisesTotalPriceFromStore || 0;
      },
      set(value) {
        this.$store.commit('spotRequest/updateMerchandisesTotalPrice', value || null);
      },
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@vuxx/hounds/scss/abstracts/variables';

  .main-form__label {
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .main-form__input {
    padding: 12px 16px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
  }

  .main-form__inline-group {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .warning {
    width: fit-content;
    background-color: $color-gray--50;
    top: 87px;
    padding: 24px 24px 40px 24px;
    border-radius: 4px;
  }

</style>
