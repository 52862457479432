<template>
  <div class="billingStatement-orderOverview-charges">

    <div class="billingStatement-orderOverview-charges__content">
      <div class="billingStatement-orderOverview-charges__content__freight">
        <label class="billingStatement-orderOverview-charges__content__freight__label"> Frete </label>
        <div class="billingStatement-orderOverview-charges__content__freight__price">
          R$ {{ order.freight_price | moneyFormat }}
        </div>
      </div>

      <charge
        v-for="(charge, index) in this.order.charges"
        :key="index"
        :charge="charge | formatCharge">
      </charge>

      <div class="taxValuesSummary billingStatement-orderOverview-charges__content__freight">
        <label class="billingStatement-orderOverview-charges__content__freight__label">
          Total líquido
        </label>
        <div class="billingStatement-orderOverview-charges__content__freight__price">
          R$ {{ order.net_price | moneyFormat }}
        </div>
      </div>

      <div class="taxValuesSummary billingStatement-orderOverview-charges__content__freight">
        <label class="billingStatement-orderOverview-charges__content__freight__label">
          Impostos
        </label>
        <div class="billingStatement-orderOverview-charges__content__freight__price">
          R$ {{ order.total_taxes | moneyFormat }}
        </div>
      </div>

      <div class="taxValues">
        <table class="taxValuesDetail">
          <tbody>
            <tr
              v-for="tax of taxes"
              :key="tax.id"
            >
              <th scope="row">
                {{ tax.name }}:
              </th>
              <td>
                R$ {{ tax.value | moneyFormat }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="separator" />

      <div class="billingStatement-orderOverview-charges__content__totalPrice">
        <label class="billingStatement-orderOverview-charges__content__totalPrice__label"> Total </label>
        <div class="billingStatement-orderOverview-charges__content__totalPrice__price">
          R$ {{ order.gross_price | moneyFormat }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Charge from './Charge.vue';
import { moneyFormat } from '../../common/filters/numberFormatters';
import { chargeTypes, chargeTypeLabels } from './chargeTypes';
import TaxType from '../../common/TaxType';

export default {
  name: 'Charges',

  components: {Charge},

  props: {
    order: Object,
  },

  computed: {
    taxes() {
      return Object.entries(this.order.taxes).map(([key, value]) => ({
        key,
        value,
        name: TaxType.nameFor(key),
      }));
    },
  },

  filters: {
    moneyFormat,

    formatCharge(charge) {
      const chargeTypeLabel = chargeTypeLabels[charge.type];
      const isOtherCharge = charge.type === chargeTypes.OTHER;

      return {
        ...charge,
        typeLabel: isOtherCharge ? chargeTypeLabel : null,
        description: isOtherCharge ? charge.description : chargeTypeLabel,
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@vuxx/hounds/scss/abstracts/variables' as *;

  .billingStatement-orderOverview-charges {
    overflow: auto;
    font-family: "Source Sans Pro", sans-serif;
  }

  .billingStatement-orderOverview-charges__content {
    padding: 28px 0 24px 80px;
  }

  .billingStatement-orderOverview-charges .separator {
    margin-bottom: 4px;
    height: 1px;
    background-color: #dcdcdc;
  }

  .billingStatement-orderOverview-charges__content__freight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-right: 20%;
    padding-bottom: 4px;
    margin-bottom: 60px;
  }

  .billingStatement-orderOverview-charges__content__freight__label {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #212121;
  }

  .billingStatement-orderOverview-charges__content__freight__price {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: $color-text;
    white-space: nowrap;
  }

  .billingStatement-orderOverview-charges__content__totalPrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20%;
    margin-bottom: 60px;
  }

  .billingStatement-orderOverview-charges__content__totalPrice__label {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #212121;
    text-transform: uppercase;
  }

  .billingStatement-orderOverview-charges__content__totalPrice__price {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: $color-text;
    white-space: nowrap;
  }

  .taxValuesSummary {
    margin-bottom: 4px;
  }

  .taxValues {
    padding-right: 20%;
  }

  .taxValuesDetail {
    width: 100%;
    border-collapse: collapse;
  }

  .taxValuesDetail th {
    text-align: left;
    font-weight: 400;
    padding: 2px 0;
  }

  .taxValuesDetail td {
    text-align: right;
    padding: 2px 0;
  }

</style>
