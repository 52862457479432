<template>
  <div class="content__pod--titlecontainer">
    <h3 class="content__pod--title">Pedido {{this.podData.barcode}} </h3>
    <span class="badge badge-pill" :class="badgeCssClass">{{this.podData.statusMessage}}</span>
  </div>
</template>

<script>
  export default {
    name: "PodHeader",
    props: {
      podData: Object
    },
    computed: {
      badgeCssClass(){
        switch (this.podData.statusType) {
          case 'partial success':
            return 'badge-warning';
          case 'failure':
            return 'badge-fail';
          default:
            return 'badge-success'
        }
      },

    }
  }
</script>

<style scoped>
  .content__pod--titlecontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-right: 30px;
    width: 100%;
  }

  .content__pod--title{
    display: block;
    font-size: 20px;
    margin-left: 20px;
  }

  .badge-success {
    color: #fff;
    background-color: #28a745;
  }

  .badge-warning {
    color: #fff;
    background-color: #e5dd50;
  }

  .badge-fail {
    color: #fff;
    background-color: #E55957;
  }

  .badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
  }

  .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    margin-left: 16px;
    height: 17px;
  }
</style>
