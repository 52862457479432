import axios from 'axios';

export async function getSpotConfig() {
  try {
    const response = await axios.get('/jade/api/spots/config');
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Erro ao buscar configurações do spot:', error);
    throw error;
  }
}
