import moment from 'moment';
import dayjs from "dayjs";

export const formatDate = (date, format = 'DD/MM/YYYY hh:mm:ss') => {
  if (!date) return null;
  return moment(date).format(format);
};

export const formatScheduledDatetimeBadge = (datetime, displayTime = false) => {
  const scheduledDateTime = dayjs(datetime);
  const now = dayjs();

  let dateFormat = 'DD/MM/YYYY';
  if (scheduledDateTime.isSame(now, 'day')) {
    dateFormat = '[hoje]';
  } else if (scheduledDateTime.isSame(now.add(1, 'day'), 'day')) {
    dateFormat = '[amanhã]';
  } else if (scheduledDateTime.isSame(now, 'year')) {
    dateFormat = 'DD/MM';
  }

  if(displayTime) {
    dateFormat += ' [•] HH:mm';
  }

  return scheduledDateTime.format(dateFormat);
};
