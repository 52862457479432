import Intercom from './intercom';
import { intercomAppId } from './runtimeConfig';

export default function intercomInit({
  customerName, personName, personEmail, customerId, personId,
}) {
  Intercom.boot({
    app_id: intercomAppId,
    alignment: 'left',
    name: `${customerName} ${personName}`,
    email: personEmail,
    user_id: `C${customerId}:U${personId}`,
  });
}
