<template>
  <div class="billingStatement-orderOverview-charge">
    <div class="header">
      <div class="charge-title">
        <div class="charge-type" v-if="charge.typeLabel"> {{ charge.typeLabel }} </div>
        <div class="charge-description"> {{ charge.description }} </div>
      </div>
      <div class="price">
        <div class="price-positive" v-if="charge.price > 0"> {{ formattedPrice }} </div>
        <div class="price-discount" v-else-if="charge.type === chargeTypes.DISCOUNT"> {{ formattedPrice }}</div>
        <div class="price-negative" v-else> {{ formattedPrice }} </div>
      </div>
    </div>
    <div class="separator" />
    <div class="notes" v-if="charge.notes != null && charge.notes !== ''">
      <div class="notes-header"> Observações </div>
      <div class="notes-value"> {{ charge.notes }} </div>
    </div>
  </div>
</template>

<script>
import { moneyFormat } from '../../common/filters/numberFormatters';
import { chargeTypes } from './chargeTypes';

export default {
  name: 'Charge',

  props: [
    'charge'
  ],

  data: function(){
    return {
      chargeTypes: chargeTypes
    }
  },

  computed: {
    formattedPrice() {
      return 'R$ ' + moneyFormat(this.charge.price);
    }
  }
}
</script>

<style scoped>
  .billingStatement-orderOverview-charge {
    margin-bottom: 60px;
  }

  .billingStatement-orderOverview-charge .header {
    padding-right: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .billingStatement-orderOverview-charge .charge-type {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #b7b7b7;
  }

  .billingStatement-orderOverview-charge .charge-description {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #212121;
  }

  .billingStatement-orderOverview-charge .price {
    align-self: flex-end;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.3px;
    white-space: nowrap;
    text-align: right;
  }

  .billingStatement-orderOverview-charge .price .price-positive {
    color: #000000;
  }

  .billingStatement-orderOverview-charge .price .price-negative {
    color: #f59925;
  }

  .billingStatement-orderOverview-charge .price .price-discount {
    color: #0ecf00;
  }

  .billingStatement-orderOverview-charge .separator {
    margin-top: 4px;
    height: 1px;
    background-color: #dcdcdc;
  }

  .billingStatement-orderOverview-charge .notes {
    margin-top: 12px;
    margin-left: 40px;
    padding-right: calc(100px + 20%);
  }

  .billingStatement-orderOverview-charge .notes-header {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #b7b7b7;
    text-transform: uppercase;
  }

  .billingStatement-orderOverview-charge .notes-value {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.2px;
    color: #212121;
    text-transform: none;
  }
</style>
