const merchandiseErrorTypes = {
  FiscalDocumentNotFound: 'FiscalDocumentNotFound',
  FiscalDocumentProblem: 'FiscalDocumentProblem',
  InvalidKey: 'InvalidKey',
  NetworkError: 'NetworkError',
  NoGeolocationFound: 'NoGeolocationFound',
  XmlProcessingError: 'XmlProcessingError',
  InvalidFileTypeToUpload: 'InvalidFileTypeToUpload',
  UploadedFileDuplicated: 'UploadedFileDuplicated',
  AddressNotFound: 'AddressNotFound',
  CTeRetrievalUnavailable: 'CTeRetrievalUnavailable',

};

module.exports = { merchandiseErrorTypes };
