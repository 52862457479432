<template>
    <div class="warning__content warning__font">
      <div class="warning__header">
        <img class="warning__warningIcon" src="/jade/public/ic-exclamation.svg">
        <div class="warning__font--stronger">Aviso</div>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
  name: "Warning"
}
</script>

<style scoped>
  .warning__content {
    width: 300px;
  }

  .warning__font {
    font-size: 14px;
    font-weight: 500;
    color: #858585;
  }

  .warning__font--stronger {
    font-weight: 600;
  }

  .warning__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 12px;
  }
  .warning__warningIcon {
    height: 20px;
    width: 20px;
    margin-right: 12px;
  }
</style>
