export const scheduleTypes = {
  NOW: 'NOW',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  CUSTOM: 'CUSTOM',
};

export const names = {
  NOW: 'Agora',
  TODAY: 'Hoje',
  TOMORROW: 'Amanhã',
  CUSTOM: 'Outro Dia',
};

export function getScheduleTypeNameWithTime(scheduleType, dateTime) {
  switch (scheduleType) {
    case (scheduleTypes.NOW):
      return names[scheduleType];
    case (scheduleTypes.TODAY):
      return `${names[scheduleType]} às ${dateTime.format('HH:mm')}`;
    case (scheduleTypes.TOMORROW):
      return `${names[scheduleType]} às ${dateTime.format('HH:mm')}`;
    case (scheduleTypes.CUSTOM):
      return dateTime.format('DD/MM/YYYY [às] HH:mm');
    default:
      return '';
  }
}
