<template>
    <ValidationProvider
      v-slot="{ errors }"
      :rules="{ max: maxLength }"
      :name="'identificador externo da solicitação'"
      required
    >
      <div class="main-form">
        <label
          class="main-form__label main-label--inline"
          for="spot-external-id"
        >
          Identificador externo da solicitação
        </label>
        <div class="main-form__inline-group main-form__inline-group--center-items">
          <input
            v-model="externalId"
            id="spot-external-id"
            type="text"
            :maxlength="maxLength"
            size="40"
            placeholder="(opcional) código para esta rota"
            class="main-form__input main-form__input--auto-width"
          >
          <span class="externalIdDetailsInput-description">
            Máximo de 80 caracteres.
          </span>
          <span
            v-if="errors[0]"
            class="main-form__info main-form__info--error"
          >
            {{ errors[0] }}
          </span>
        </div>
      </div>
    </ValidationProvider>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, localize, extend } from 'vee-validate';
import { integer, max, required } from 'vee-validate/dist/rules';
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';

extend('integer', integer);
extend('max', max);
extend('required', required);

localize('pt_BR', pt_BR);

export default {
  name: 'ExternalIdInput',

  components: {
    ValidationProvider,
  },

  computed: {
    externalId: {
      get() {
        return this.$store.state.spotRequest.externalId;
      },
      set(value) {
        this.$store.commit('spotRequest/updateExternalId', value);
      },
    },

    maxLength() {
      return 80;
    },
  },
};
</script>

<style>
  .main-form__label {
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .main-form__input {
    padding: 12px 16px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
  }

  .main-form__inline-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .main-form__info {
    font-size: 14px;
  }

  .main-form__info-error {
    color: #e53935;
  }

  .externalIdDetailsInput-description {
    padding-top: 4px;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #858585;
  }
</style>
