<template>
  <div
    class="addFinanceInfo-modal"
    v-show="visible"
  >
    <div class="addFinanceInfo-modal__backdrop">
      <div
        class="addFinanceInfo-modal__content"
        role="dialog"
      >
        <finance-info-form
          :close-handler="closeHandler"
          :show-message-handler="showMessageHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>

import FinanceInfoForm from './FinanceInfoForm.vue';

export default {
  name: "FinanceInfoModal",

  components: { FinanceInfoForm, },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    closeHandler: {
      type: Function,
      required: true,
    },

    showMessageHandler: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style scoped>
  .addFinanceInfo-modal {
    position: fixed;
    z-index: 1000;
  }

  .addFinanceInfo-modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .addFinanceInfo-modal__content {
    width: 560px;

    background-color: #ffffff;
    max-height: 80%;
    overflow: auto;

    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e7e7e7;
  }
</style>
