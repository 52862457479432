<template>
  <Modal
    @close="close"
  >
    <template #content>
      <div class="feedback">
        <img :src="successIcon">
        <p :class="textStyle.heading5">Avaliação enviada</p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/hounDS/components/Modal";
import textStyle from "@/hounDS/styles/text.module.scss"
import successIcon from "./success.svg";

export default {
  name: "ShipmentRatingResponse",

  components: { Modal },

  computed: {
    successIcon: () => Object.freeze(successIcon),
    textStyle: () => Object.freeze(textStyle)
  },

  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.feedback > * {
  margin: 16px;
}
</style>
