import groupBy from 'lodash.groupby';

function buildTransportationFromShipment(shipments, placesById) {
  const firstShipment = shipments[0]; // any place id will be the same
  return {
    origin: placesById[firstShipment.originPlaceId],
    destination: placesById[firstShipment.destinationPlaceId],
    shipments: shipments,
  };
}

function buildTransportationsFromShipmentsAndPlaces(shipments, placesById) {
  const groupedShipments = groupShipmentsBySameOriginAndDestination(shipments);
  return groupedShipments.map(shipments => buildTransportationFromShipment(shipments, placesById));
}

function groupShipmentsBySameOriginAndDestination(shipments) {
  const groupedShipments = groupBy(
    shipments,
    (shipment) => `${shipment.originPlaceId}${shipment.destinationPlaceId}`
  );
  return Object.values(groupedShipments);
}

export { buildTransportationsFromShipmentsAndPlaces };
