<template>
  <base-layout>
    <div class="bulma-is-flex bulma-is-flex-direction-column gap-32">
      <h1 class="title padding-top-24">
        Extrato de solicitações
      </h1>

      <nav-bar
        :months="months"
        :default-month="defaultMonth"
        @month-selected="onMonthSelect"
        @showCalendarModal="showModal"
      />

      <skeletonLoadingPage v-if="isLoadingData" />

      <template v-else>
        <div
          v-if="error"
          class="card"
        >
          <div class="empty-state">
            <div>
              <h3>Ops! Ocorreu um erro 😿</h3>
              <p>Tivemos um problema no carregamento do seu extrato!</p>
              <p>
                <a
                  href="#"
                  @click.prevent="reloadPage"
                >
                  Tente novamente
                </a>
                ou entre em contato com a nossa equipe.
              </p>
            </div>
          </div>
        </div>
        <div
          v-else-if="noEntries"
          class="card"
        >
          <div class="empty-state">
            <img
              alt=""
              src="/jade/public/empty-boxes.svg"
            >
            <h3>Não houve solicitações nesse período</h3>
          </div>
        </div>
        <template v-else>
          <div class="card">
            <form
              class="main-form"
              @submit.prevent="onDownloadSheetsButtonClicked()"
            >
              <div class="card__header-actions">
              <select class="main-form__select--auto-width" id="downloadOption" name="downloadOptions">
                <option value="all">Todas as solicitações</option>
                <option value="finished">Apenas finalizadas</option>
              </select>
                <button
                  type="button"
                  class="button button--text-button-primary"
                >
                  <button
                    class="button button--secondary button--with-icon"
                    type="submit"
                    :class="!summaryOfSelectedPeriod ? 'button--disabled' : ''"
                    :disabled="!summaryOfSelectedPeriod"
                  >
                    <svg
                      aria-hidden="true"
                      class="icon"
                    >
                      <use
                        aria-hidden="true"
                        href="@vuxx/hounds/icons/general-icons-defs.svg#icon-download"
                      />
                    </svg>
                    Download
                  </button>
                </button>
              </div>
            </form>

            <billing-summary
              v-if="summaryOfSelectedPeriod"
              :show-summary-taxes="showSummaryTaxes"
              :summary="summaryOfSelectedPeriod"
            />

            <button
              class="card__toggle-button"
              @click="toggleSummaryTaxesDetails"
            >
              {{ showSummaryTaxes ? 'Ocultar impostos' : 'Exibir impostos' }}
            </button>
          </div>

          <div
            v-if="summaryOfSelectedPeriod"
            class="card"
          >
            <orders-list
              :orders="orders"
              :month-period-to-show="monthPeriodToShow"
              @order-clicked="onOrderClicked"
            />
          </div>
        </template>
      </template>
    </div>

    <div
      id="js-custom-date-calendar"
      class="modal micromodal-slide"
      aria-hidden="true"
    >
      <div
        data-micromodal-close
        class="modal__overlay"
        tabindex="-1"
      >
        <div
          class="modal__container modal__container--overflow-y-visible"
          role="dialog"
          aria-modal="true"
          aria-labelledby="transportation-dialog"
        >
          <form
            class="main-form"
            @submit.prevent="handleCalendarSubmit"
          >
            <header class="modal__header">
              <h2
                id="custom-date-dialog"
                class="modal__title"
              >
                Selecione o período
              </h2>
            </header>
            <main
              id="custom-date-dialog-content"
              class="modal__content"
            >
              <div class="double-calendar">
                <label class="double-calendar__calendar-label">
                  De:
                  <input
                    ref="dateFrom"
                    class="main-form__input"
                    type="text"
                    required
                  >
                </label>
                <label class="double-calendar__calendar-label">
                  Até:
                  <input
                    ref="dateTo"
                    class="main-form__input"
                    type="text"
                    required
                  >
                </label>
              </div>
            </main>
            <footer class="modal__footer modal__footer--radius">
              <button
                type="submit"
                class="button button--primary button--full-width"
              >
                Aplicar
              </button>
            </footer>
          </form>
        </div>
      </div>
    </div>

    <order-overview-modal
      v-if="orderOverviewVisible"
      :order-id="orderOverviewOrderId"
      @close="onOrderOverviewClose"
    />

    <loading-modal
      title="Aguarde enquanto buscamos suas informações"
      message=""
      :visible="isDownloadingSpreadsheet"
    />
  </base-layout>
</template>

<script>
import axios from 'axios';
import fileDownload from 'js-file-download';
import dayjs from 'dayjs';
import MicroModal from 'micromodal';
import Datepicker from 'vanillajs-datepicker/Datepicker';
import ptBR from 'vanillajs-datepicker/locales/pt-BR';
import BaseLayout from '../common/BaseLayout.vue';
import NavBar from './NavBar.vue';
import BillingSummary from './BillingSummary.vue';
import OrdersList from './OrdersList.vue';
import OrderOverviewModal from './orderOverview/OrderOverviewModal.vue';
import LoadingModal from '../common/LoadingModal.vue';
import skeletonLoadingPage from './SkeletonLoadingPage.vue';

Object.assign(Datepicker.locales, ptBR);

function zeroPad(number, width) {
  const asString = String(number);
  const remaining = Math.max(0, width - asString.length);
  return '0'.repeat(remaining) + asString;
}

function parseMonthData(data) {
  return {
    month: `${data.year}-${zeroPad(data.month, 2)}`,
    totalValue: data.totalPrice,
  };
}

export default {
  name: 'BillingStatementPage',

  components: {
    BaseLayout,
    NavBar,
    BillingSummary,
    OrdersList,
    OrderOverviewModal,
    LoadingModal,
    skeletonLoadingPage,
  },

  data() {
    return {
      isLoadingData: true,
      showSummaryTaxes: false,
      error: false,
      months: [],
      dateFrom: null,
      dateTo: null,
      defaultMonth: 'NONE',
      selectedMonth: 'NONE',
      monthPeriodToShow: null,
      summary: null,
      orderOverviewVisible: false,
      orderOverviewOrderId: null,
      selectedOrder: null,
      orders: [],
      isDownloadingSpreadsheet: false,
    };
  },

  computed: {
    noEntries() {
      return this.summary?.byVehicleType.length === 0
      && this.orders.length === 0;
    },

    summaryOfSelectedPeriod() {
      return this.summary;
    },

    now() {
      return new Date();
    },

    formattedToRequestDateFrom() {
      return dayjs(this.dateFrom).format('YYYY-MM-DD');
    },

    formattedToRequestDateTo() {
      return dayjs(this.dateTo).format('YYYY-MM-DD');
    },

    firstDayOfCurrentMonth() {
      return dayjs().startOf('month').format('YYYY-MM-DD');
    },

    lastDayOfCurrenMonth() {
      return dayjs().endOf('month').format('YYYY-MM-DD');
    },

    halfOfCurrentMonth() {
      return dayjs(this.firstDayOfCurrentMonth).add(13, 'day').format('YYYY-MM-DD');
    },
  },

  async mounted() {
    MicroModal.init();

    const { dateFrom, dateTo } = this.$refs;

    const datepickerOptions = {
      language: 'pt-BR',
      weekStart: 1,
      autohide: true,
      maxDate: new Date(),
    };

    const datepickerFrom = new Datepicker(dateFrom, datepickerOptions);
    const datepickerTo = new Datepicker(dateTo, datepickerOptions);

    this.dateFrom = dayjs(this.firstDayOfCurrentMonth).format('YYYY-MM-DD');
    this.dateTo = dayjs(this.lastDayOfCurrenMonth).format('YYYY-MM-DD');

    dateFrom.addEventListener('changeDate', () => {
      this.dateFrom = datepickerFrom.getDate();
      datepickerTo.setOptions({
        minDate: datepickerFrom.getDate(),
      });
    });

    dateTo.addEventListener('changeDate', () => {
      this.dateTo = datepickerTo.getDate();
      datepickerFrom.setOptions({
        maxDate: datepickerTo.getDate(),
      });
    });

    const { months, lastMonth } = await this.getMonths();
    this.months = months;
    this.defaultMonth = lastMonth;
    this.selectedMonth = lastMonth;
  },

  methods: {
    async getData() {
      this.isLoadingData = true;
      this.orders = [];
      this.summary = await this.getSummary();
      this.monthPeriodToShow = 'month';
      this.orders = await this.getDetails();
    },

    async getSummary() {
      try {
        const response = await axios.get('/jade/api/billing-statement/summary', {
          params: {
            'from-date': this.formattedToRequestDateFrom,
            'to-date': this.formattedToRequestDateTo,
          },
        });
        return response.data;
      } catch {
        this.error = true;
        return false;
      } finally {
        this.isLoadingData = false;
      }
    },

    onMonthSelect(month) {
      this.dateFrom = dayjs(month);
      this.dateTo = dayjs(month).endOf('month');
      this.selectedMonth = month;
      this.getData();
    },

    toggleSummaryTaxesDetails() {
      this.showSummaryTaxes = !this.showSummaryTaxes;
    },

    showModal() {
      MicroModal.show('js-custom-date-calendar');
    },

    handleCalendarSubmit() {
      MicroModal.close('js-custom-date-calendar');
      this.getData();
    },

    setDateFromAndDateTo(dateFrom, dateTo) {
      this.dateFrom = dayjs(dateFrom).format('YYYY-MM-DD');
      this.dateTo = dayjs(dateTo).format('YYYY-MM-DD');
    },

    async getDetails() {
      try {
        const response = await axios
          .get(`/jade/api/billing-statement/details?from-date=${this.formattedToRequestDateFrom}&to-date=${this.formattedToRequestDateTo}`);
        return response.data;
      } catch {
        this.error = true;
        return false;
      } finally {
        this.isLoadingData = false;
      }
    },

    onOrderClicked(order) {
      this.showOrderOverview(order.orderId);
    },

    showOrderOverview(orderId) {
      this.orderOverviewOrderId = orderId;
      this.orderOverviewVisible = true;
    },

    onOrderOverviewClose() {
      this.orderOverviewVisible = false;
    },

    async downloadBillingStatementSheets() {
      const downloadOption = document.getElementById('downloadOption').value;
      const response = await axios.get('/jade/api/billing-statement/download', {
        params: {
          'from-date': this.formattedToRequestDateFrom,
          'to-date': this.formattedToRequestDateTo,
          'order-status': downloadOption,
        },
        responseType: 'blob',
      });
      const fileName = response.headers['content-disposition'].replace('attachment; filename=', '');
      fileDownload(response.data, fileName);
    },

    async onDownloadSheetsButtonClicked() {
      this.isDownloadingSpreadsheet = true;
      try {
        await this.downloadBillingStatementSheets();
      } finally {
        this.isDownloadingSpreadsheet = false;
      }
    },

    async getMonths() {
      try {
        // Total period interval in months
        const totalMonthsPeriod = 6;

        // end of request period
        const firstDayOfNextMonth = dayjs().add(1, 'month').startOf('month');

        const firstDayOfPeriod = firstDayOfNextMonth.subtract(totalMonthsPeriod, 'months');

        const params = {
          'from-date': firstDayOfPeriod.format('YYYY-MM-DD'),
          'to-date': firstDayOfNextMonth.format('YYYY-MM-DD'),
        };

        const response = await axios.get('/jade/api/billing-statement/monthly-totals', { params });
        const months = response.data.map(parseMonthData);
        const lastMonth = months[months.length - 1].month;
        return { months, lastMonth };
      } catch {
        this.error = true;
        return false;
      } finally {
        this.isLoadingData = false;
      }
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
<style scoped lang="scss">
  .double-calendar {
    display: flex;
    flex-direction: row;
    // border: 1px solid #dee2e6;
    padding: 1.5rem;
    border-radius: 16px;
    gap: 1.5rem;

    /*
    &::before {
      content: "";
      border: 0.5px solid #dee2e6;
      align-self: stretch;
    }
    */
  }

  @media (max-width: 675px) {
    .double-calendar {
      flex-direction: column;
    }
  }

  .double-calendar__calendar-label:first-child {
    order: -1;
  }

  .pageBody {
    padding: 0 30px 30px 30px;
    margin: 30px 30px 30px 30px;
    background-color: #fff;
  }

  .pageBody__verticalSeparator {
    height: 34px;
  }

  .pageBody__top {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
