<template>
  <div class="spotRequest-merchandiseList-content">
    <div
      class="spotRequest-merchandiseList__collapsibleBlock"
      :class="{
        'spotRequest-merchandiseList__collapsibleBlock--collapsibleModeNotAllowed': collapsibleModeNotAllowed,
        'spotRequest-merchandiseList__collapsibleBlock--collapsed': isBlockCollapsed,
        'spotRequest-merchandiseList__collapsibleBlock--expanded': isBlockExpanded,
      }"
    >
      <h3 v-if="title">{{ title }}</h3>
      <table class="spotRequest-merchandiseList">
        <thead class="spotRequest-merchandiseList__header">
        <tr>
          <th class="spotRequest-merchandiseList__headerCell spotRequest-merchandiseListColumn-listIndex">#</th>
          <th class="spotRequest-merchandiseList__headerCell spotRequest-merchandiseListColumn-trackingCode">Código</th>
          <th class="spotRequest-merchandiseList__headerCell spotRequest-merchandiseListColumn-serviceType">Serviço</th>
          <th class="spotRequest-merchandiseList__headerCell spotRequest-merchandiseListColumn-address">Endereço</th>
          <th class="spotRequest-merchandiseList__headerCell spotRequest-merchandiseListColumn-consignor">Remetente</th>
          <th class="spotRequest-merchandiseList__headerCell spotRequest-merchandiseListColumn-timeWindow">Horários</th>

          <th v-if="!readOnly" class="spotRequest-merchandiseList__headerCell spotRequest-merchandiseListColumn-actions">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(merchandise, index) in merchandises"
          :key="merchandise.id"
          class="spotRequest-merchandiseList__row"
          v-bind:class="{'spotRequest-merchandiseList__row--danger': Boolean(merchandise.status.error)}"
        >
          <td class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-listIndex">{{ index + 1 }}</td>
          <td class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-trackingCode">
            <span :title="merchandise.trackingCode">{{ merchandise.trackingCode | abbreviateLongCode }}</span>
            <span v-if="merchandise.trackingCodeTooLong" class="fa fa-warning">
              Código excede o tamanho máximo de 44 caracteres!
            </span>
          </td>
          <td class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-serviceType">
            <img
              width="16"
              height="16"
              :src="imageForServiceType(merchandise.serviceType)">
          </td>
          <template v-if="merchandise.status.ok">
            <td class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-address">
              {{ merchandise.address.thoroughfare }}, {{ merchandise.address.number }}<br>
              {{ merchandise.address.neighborhood }},
              {{ merchandise.address.locality }},
              {{ merchandise.address.state }}
            </td>
            <td class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-consignor">{{ merchandise.sender | firstWord }}</td>
            <td class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-timeWindow">
              <merchandise-list-time-window
                :merchandise="merchandise"
                :allow-add-time-window=allowAddTimeWindow
                :service-type="serviceType"
                @on-add-time-window="onAddTimeWindow"
              />
            </td>
          </template>
          <template v-else>
            <td v-if="merchandise.status.loading"
                class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-message"
                colspan="2">
              <minimal-spinner size="16px" hex-color="#000000" class="spotRequest-merchandiseList__spinner" />
              Consultando CT-e no SEFAZ
            </td>
            <td v-else
                class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-message"
                colspan="2">
              <i class="fa fa-warning"></i>
              {{ merchandise.status.error | formatMerchandiseError }}
            </td>
            <td></td>
            <td
              class="spotRequest-merchandiseList__itemCell"
              v-if="!readOnly"
            >
              <merchandise-list-fiscal-document-upload
                :merchandise="merchandise"
                :all-merchandises="merchandises"
              ></merchandise-list-fiscal-document-upload>
            </td>
          </template>
          <td
            v-if="!readOnly"
            class="spotRequest-merchandiseList__itemCell spotRequest-merchandiseListColumn-actions"
            colspan="2">
            <div class="spotRequest-merchandiseListItem__actions">
              <button
                class="spotRequest-merchandiseListItem__action spotRequest-merchandiseListItem__action-refresh"
                @click="onMerchandiseRefreshClicked(merchandise)"
                v-if="!cannotRefresh(merchandise)"
                v-tooltip="'Atualizar'"
              ></button>
              <button
                class="spotRequest-merchandiseListItem__action spotRequest-merchandiseListItem__action-edit"
                @click="onMerchandiseEditClicked(merchandise)"
                v-if="!cannotEdit(merchandise)"
                v-tooltip="'Editar'"
              ></button>
              <button
                class="spotRequest-merchandiseListItem__action spotRequest-merchandiseListItem__action-delete"
                @click="onMerchandiseRemoveClicked(merchandise)"
                v-tooltip="'Remover'"
              ></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>


    </div>

    <div class="spotRequest-merchandiseList__captionsTitle">Legenda</div>

    <div class="spotRequest-merchandiseList__captions">
      <div class="spotRequest-merchandiseList__captionsItem">
        <img
          class="spotRequest-merchandiseList__captionsItemImage"
          src="/jade/public/ic-spot-request-type-delivery.svg"
        >
        <div class="spotRequest-merchandiseList__captionsItemLabel">Entrega</div>
      </div>
      <div class="spotRequest-merchandiseList__captionsItem">
        <img
          class="spotRequest-merchandiseList__captionsItemImage"
          src="/jade/public/ic-spot-request-type-pickup.svg"
        >
        <div class="spotRequest-merchandiseList__captionsItemLabel">Coleta</div>
      </div>
    </div>

    <div
      v-if="distance"
      class="horizontalSeparator spotRequest-merchandiseList__horizontalSeparator"
    ></div>

    <div
      v-if="distance"
      class="spotRequest-distance-display"
    >
      Distância:&nbsp;
      <span class="spotRequest-merchandiseList__fontBolder">
        {{ distance | distanceFormat }} km
      </span>
    </div>

    <div
      v-if="showExpandCollapseButton"
      class="horizontalSeparator spotRequest-merchandiseList__horizontalSeparator"
    ></div>

    <button
      v-if="showExpandCollapseButton"
      class="spotRequest-merchandiseList__expandCollapseButton"
      :class="{
        'spotRequest-merchandiseList__expandCollapseButton--collapsed': isBlockCollapsed,
        'spotRequest-merchandiseList__expandCollapseButton--has-error': hasError
      }"
      :disabled="hasError"
      @click="collapseWantedByUser=!collapseWantedByUser"
    >
      <minimal-spinner size=16px v-if="isButtonSpinnerVisible" hex-color="#198bfd" />
      <icon-base
        :color="hasError ? '#aab6b9' : '#198BFD'"
        width="12px"
        height="12px"
      >
        <icon-arrow-right v-if="isBlockCollapsed" />
        <icon-arrow-up v-else/>
      </icon-base>

      {{ collapseWantedByUser ? 'Ver mais' : 'Ver menos' }}
    </button>
  </div>
</template>

<script>
import { VTooltip } from 'v-tooltip';
import {merchandiseErrorTypes} from './merchandiseErrors';
import MerchandiseListTimeWindow from './MerchandiseListTimeWindow.vue';
import MerchandiseListFiscalDocumentUpload from './MerchandiseListFiscalDocumentUpload.vue';
import { distanceFormat, moneyFormat } from '../../common/filters/numberFormatters';
import MinimalSpinner from '../../common/MinimalSpinner.vue';
import { serviceTypeImages } from "../common/serviceTypes";
import IconBase from '../../icons/IconBase.vue';
import IconArrowUp from '../../icons/IconArrowUp.vue';
import IconArrowRight from '../../icons/IconArrowRight.vue';

const merchandiseErrorMessages = {
  [merchandiseErrorTypes.FiscalDocumentProblem]: 'Não foi possível usar os dados do SEFAZ',
  [merchandiseErrorTypes.FiscalDocumentNotFound]: 'Documento fiscal não encontrado',
  [merchandiseErrorTypes.InvalidKey]: 'Chave de acesso inválida',
  [merchandiseErrorTypes.NetworkError]: 'Não foi possível consultar o SEFAZ',
  [merchandiseErrorTypes.NoGeolocationFound]: 'Não foi encontrado um endereço para o CEP/número',
  [merchandiseErrorTypes.XmlProcessingError]: 'Arquivo XML inválido',
  [merchandiseErrorTypes.InvalidFileTypeToUpload]: 'Não é um arquivo XML',
  [merchandiseErrorTypes.UploadedFileDuplicated]: 'Arquivo já está na lista',
  [merchandiseErrorTypes.AddressNotFound]: 'Endereço não encontrado',
  [merchandiseErrorTypes.CTeRetrievalUnavailable]: 'Para solicitações com CTe, deve ser feito o upload do arquivo XML'
};

export default {
  name: 'MerchandiseList',

  directives: { tooltip: VTooltip },

  components: {
    MerchandiseListTimeWindow,
    MerchandiseListFiscalDocumentUpload,
    MinimalSpinner,
    IconBase,
    IconArrowUp,
    IconArrowRight
  },

  props: {
    title: String,
    merchandises: Array,
    readOnly: Boolean,
    allowAddTimeWindow: Boolean,
    serviceType: String,
    collapsibleModeNotAllowed: {
      type: Boolean,
      default: false
    },
    distance: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      collapseWantedByUser: true,
    }
  },

  computed: {
    hasError() {
      return this.merchandises.some(m => m.status.error);
    },

    hasMoreThanTwoMerchandises() {
      return this.merchandises.length > 2;
    },

    collapsibleModeAllowed() {
      return !this.collapsibleModeNotAllowed;
    },

    showExpandCollapseButton() {
      return this.collapsibleModeAllowed && this.hasMoreThanTwoMerchandises;
    },

    isButtonSpinnerVisible() {
      return this.isBlockCollapsed && this.merchandises.some(m => m.status.loading);
    },

    isBlockCollapsed() {
      return this.collapsibleModeAllowed && this.hasMoreThanTwoMerchandises && this.collapseWantedByUser;
    },

    isBlockExpanded() {
      return (this.collapsibleModeAllowed && this.hasMoreThanTwoMerchandises && !this.collapseWantedByUser) || this.hasError;
    },
  },

  filters: {
    abbreviateLongCode(code) {
      if (code.length > 16) {
        const head = code.slice(0, 8);
        const tail = code.slice(code.length - 8);
        return head + '…' + tail;
      } else {
        return code;
      }
    },

    decorateOptional(data) {
      return (data === '' || data === 0 || data === '0') ? '-' : data;
    },

    firstWord(text) {
      return text ? text.split(' ')[0] : '';
    },

    formatMerchandiseError(error) {
      return merchandiseErrorMessages[error.type];
    },

    distanceFormat,
  },

  methods: {
    cannotEdit(merchandise) {
      return merchandise.status.loading
             || (merchandise.status.error && merchandise.status.error.type !== 'NoGeolocationFound') ;
    },

    cannotRefresh(merchandise) {
      return merchandise.status.loading
             || !merchandise.status.error
             || (merchandise.status.error && merchandise.status.error.type === 'NoGeolocationFound');
    },

    onMerchandiseEditClicked(merchandise) {
      this.$emit('merchandise-edit-requested', merchandise);
    },

    onMerchandiseRefreshClicked(merchandise) {
      merchandise.queryMerchandise();
    },

    onMerchandiseRemoveClicked(merchandise) {
      this.$emit('merchandise-remove-requested', merchandise);
    },

    imageForServiceType(serviceType) {
      return serviceTypeImages[serviceType];
    },

    onAddTimeWindow(merchandise, start, end) {
      this.$emit('on-merchandise-add-time-window', merchandise, start, end);
    }
  }

};
</script>

<style scoped>

.spotRequest-merchandiseList-content {
  display: flex;
  flex-direction: column;
}

.spotRequest-merchandiseList__fontBolder {
  font-weight: 600;
}

.spotRequest-merchandiseList {
  width: 100%;
  border-collapse: collapse;
  letter-spacing: 0.3px;
}

.spotRequest-merchandiseList__header {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  border-bottom: 1px solid #e7e7e7;
}

.spotRequest-merchandiseList__headerCell {
  text-align: center;
  padding: 4px 10px;
}

.spotRequest-merchandiseList__row {
  border-bottom: 1px solid #e7e7e7;
  font-size: 16px;
  height: 60px;
}

.spotRequest-merchandiseList__row--danger {
  background-color:#FFEEEF;
}

.spotRequest-merchandiseList__itemCell {
  text-align: center;
  padding: 12px 10px;
}

.spotRequest-merchandiseList__spinner {
  margin-right: 6px;
}

.spotRequest-merchandiseListColumn-trackingCode,
.spotRequest-merchandiseListColumn-address,
.spotRequest-merchandiseListColumn-consignor,
.spotRequest-merchandiseListColumn-message {
  text-align: left;
}


.spotRequest-merchandiseListColumn-addressNumber {
  text-align: right;
}

.spotRequest-merchandiseListColumn-timeWindow {
  display: flex;
  justify-content: center;
}

.spotRequest-merchandiseListColumn-actions {
  width: 83px;
}

.spotRequest-merchandiseListItem__actions {
  display: flex;
  justify-content: center;
}

.spotRequest-distance-display {
  display: flex;
  justify-content: center;
  padding-top: 16px;
}

.spotRequest-merchandiseListItem__action {
  width: 34px;
  height: 34px;
  border: none;
  padding: 0;
  background: transparent no-repeat center center;
  text-indent: -9999em;
  overflow: hidden;
  cursor: pointer;
}

.spotRequest-merchandiseListItem__action:disabled {
  opacity: 0.4;
  cursor: auto;
}

.spotRequest-merchandiseListItem__action:not(:last-child) {
  margin-right: 15px;
}

.spotRequest-merchandiseListItem__action-refresh {
  background-image: url(/jade/public/icon-refresh.svg);
}

.spotRequest-merchandiseListItem__action-edit {
  background-image: url(/jade/public/icon-edit.svg);
}

.spotRequest-merchandiseListItem__action-delete {
  background-image: url(/jade/public/icon-remove.svg);
}

.fa-warning {
  color: #ed293c;
  font-size: 12px;
  margin-bottom: 8px;
}

.spotRequest-merchandiseList__horizontalSeparator {
  margin-top: 16px;
}

.spotRequest-merchandiseList__collapsibleBlock {
  overflow: hidden;
}

.spotRequest-merchandiseList__collapsibleBlock--collapsibleModeNotAllowed {
  max-height: unset;
  overflow: inherit;
}

.spotRequest-merchandiseList__collapsibleBlock--collapsed {
  max-height: 146px;
  transition: max-height .4s ease-out;
}

.spotRequest-merchandiseList__collapsibleBlock--expanded {
  max-height: max-content; /* css does not support animation with height: auto, only with max-height */
  transition: max-height 1s ease-in;
}

.spotRequest-merchandiseList__captionsTitle {
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #2f2f2f;
}

.spotRequest-merchandiseList__captions {
  display: flex;
}

.spotRequest-merchandiseList__captionsItem {
  display: flex;
  align-items: center;
  margin-right: 28px;
}

.spotRequest-merchandiseList__captionsItemImage {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.spotRequest-merchandiseList__captionsItemLabel {
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #2f2f2f;
}

.spotRequest-merchandiseList__expandCollapseButton {
  width: 160px;
  height: 36px;
  margin-top: 16px;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #198bfd;
  border-radius: 4px;
  border: solid 1px #198bfd;
  align-self: center;
  cursor: pointer;
  text-align: center;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spotRequest-merchandiseList__expandCollapseButton > * {
  margin: 0 4px;

}

.spotRequest-merchandiseList__expandCollapseButton--has-error {
  color: #aab6b9;
  border-color: #aab6b9;
}

</style>
