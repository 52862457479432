<template>
  <div class="warehouse" @click="onWarehouseClicked">
    <div class="warehouse-info">
      <h1 class="warehouse__title"> {{ name }} </h1>
      <div class="warehouse__address"> {{ formattedAddress }} </div>
    </div>
  </div>
</template>

<script>
import { formattedAddress } from './warehouse';

export default {
  name: 'WarehouseItem',

  props: {
    warehouse: {
      type: Object,
      default: null,
    }
  },

  computed: {
    name() {
      return this.warehouse ? this.warehouse.name : '';
    },

    formattedAddress() {
      return formattedAddress(this.warehouse);
    },
  },

  methods: {
    onWarehouseClicked() {
      this.$emit('on-warehouse-clicked', this.warehouse);
    },
  },

};
</script>

<style scoped>
  .warehouse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    cursor: pointer;
    background: white;
    border-radius: 4px;
  }

  .warehouse-info {
    flex-grow: 1;
    overflow: hidden;
  }

  .warehouse__title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    margin: 0 0 2px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .warehouse__address {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #858585;
    max-lines: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
