export const XMLUploadEvent = (detail) => ({
  category: 'XML',
  action: 'Upload de XML',
  label: detail && `pela origem ${detail}`,
});

export const XMLClickUploadEvent = (detail) => ({
  category: 'XML',
  action: 'Clique em upload de XML',
  label: detail && `pela origem ${detail}`,
});

export const XMLPageAccessEvent = (detail) => ({
  category: 'XML',
  action: 'Acesso página de XML',
  label: detail && `por ${detail}`,
});

/**
 * @param {{action, label, category}} eventFactory factory remember to call it.
 * */
export function gaSendEvent({ action, category, label }) {
  if (typeof ga === 'undefined') {
    console.warn('GA Events is undefined');
    return;
  }

  ga('send', 'event',
    category,
    action,
    label);
}
