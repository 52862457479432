<template>
  <div
    class="fiscalDocumentsUploadModal-backdrop"
    @click="close"
    @keyup.esc="close"
  >
    <div
      class="fiscalDocumentsUploadModal-content"
      @click.stop
    >
      <div class="fiscalDocumentsUploadModal-closeButton">
        <img
          src="/jade/public/ic-inputhelp-close.svg"
          @click="close"
        />
      </div>

      <div class="fiscalDocumentsUploadModal-dropBox">
        <img
          src="/jade/public/ic-inputhelp-xml.svg"
          class="fiscalDocumentsUploadModal-dropBox__image"
        >
        <div class="fiscalDocumentsUploadModal-dropBox__header">
          <div class="fiscalDocumentsUploadModal-dropBox__header__title">
            Enviar arquivo XML
          </div>
          <div class="fiscalDocumentsUploadModal-dropBox__header__subTitle">
            Arraste e solte os arquivos aqui, ou clique em “Enviar arquivo XML” e informe o horário de entrega.
          </div>

          <input
            class="fiscalDocumentsUploadModal-fileInput"
            accept="text/xml"
            type="file"
            ref="fiscalDocuments"
            multiple
            @change="fiscalDocumentsInputChanged()"
          />
          <button
            class="fiscalDocumentsUploadModal-uploadButton"
            @click="openFileDialog()"
          >
            Enviar arquivo XML
          </button>
        </div>
      </div>

      <merchandise-list
        class="fiscalDocumentsUploadModal-merchandiseList"
        :merchandises="merchandises"
        :read-only=false
        :collapsible-mode-not-allowed="true"
        :service-type="serviceType"
        @merchandise-remove-requested="onMerchandiseRemoveRequested"
        @merchandise-edit-requested="onMerchandiseEditRequested"
      />

      <div class="horizontalSeparator fiscalDocumentsUploadModal-horizontalSeparator"></div>
      <button
        class="baseButton submitButton fiscalDocumentsUploadModal-confirmButton"
        @click="close"
      >Confirmar</button>
    </div>
  </div>
</template>

<script>
import MerchandiseList from './MerchandiseList.vue';
import { newMerchandiseFromFiscalDocumentUpload } from './merchandiseFiscalDocumentUpload';
import { XMLUploadEvent, XMLClickUploadEvent, XMLPageAccessEvent, gaSendEvent } from '../../client/gaEvents';

  export default {
    name: 'FiscalDocumentsUploadModal',

    components: {
      MerchandiseList
    },

    props: {
      merchandises: Array,
      serviceType: String,
    },

    methods: {
      onMerchandiseRemoveRequested(merchandise) {
        this.$emit('merchandise-remove-requested', merchandise);
      },

      onMerchandiseEditRequested(merchandise) {
        this.$emit('merchandise-edit-requested', merchandise);
      },

      openFileDialog() {
        gaSendEvent(XMLClickUploadEvent('fiscalDocumentsUploadModal'));

        this.$refs.fiscalDocuments.click();
      },

      fiscalDocumentsInputChanged() {
        this.uploadFiscalDocuments(this.$refs.fiscalDocuments.files);
      },

      uploadFiscalDocuments(files) {
        // `files` does not have forEach function
        if (files.length > 0) {
          for (var i = 0; i < files.length; i++) {
            const file = files[i];
            const merchandiseFromFile = newMerchandiseFromFiscalDocumentUpload(file, this.serviceType, this.merchandises);

            this.$emit('merchandise-from-fiscal-document-created', merchandiseFromFile);

            gaSendEvent(XMLUploadEvent('fiscalDocumentsUploadModal'));
          }
        }
      },

      preventEventDefault(e) {
        e.preventDefault();
      },

      uploadFromDroppedFiles(e) {
        e.preventDefault();
        this.uploadFiscalDocuments(e.dataTransfer.files);
      },

      close() {
        this.removeEvents();
        this.$emit('close');
      },

      addEvents() {
        window.addEventListener("dragleave", this.preventEventDefault);
        window.addEventListener("dragover", this.preventEventDefault);
        window.addEventListener("drop", this.uploadFromDroppedFiles);
      },

      removeEvents() {
        window.removeEventListener("dragleave", this.preventEventDefault);
        window.removeEventListener("dragover", this.preventEventDefault);
        window.removeEventListener("drop", this.uploadFromDroppedFiles);
      },
    },

    mounted() {
      this.addEvents();

      gaSendEvent(XMLPageAccessEvent('fiscalDocumentsUploadModal'));
    },
  }
</script>

<style scoped>

  .fiscalDocumentsUploadModal-backdrop {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fiscalDocumentsUploadModal-content {
    width: 800px;
    height: 620px;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .fiscalDocumentsUploadModal-closeButton {
    display: flex;
    flex-direction: row-reverse;
    margin: 20px 20px 0 0;
    cursor: pointer;
  }

  .fiscalDocumentsUploadModal-dropBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 20px;
  }

  .fiscalDocumentsUploadModal-dropBox__image {
    width: 160px;
  }

  .fiscalDocumentsUploadModal-dropBox__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
  }

  .fiscalDocumentsUploadModal-dropBox__header__title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #0e0e0e;
  }

  .fiscalDocumentsUploadModal-dropBox__header__subTitle {
    width: 532px;
    margin-top: 4px;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #636363;
  }

  .fiscalDocumentsUploadModal-fileInput {
    display: none;
  }

  .fiscalDocumentsUploadModal-uploadButton {
    margin: 16px 0 24px 0;
    border-radius: 4px;
    border: solid 1px #6a6a6a;
    padding: 8px 10px 8px 32px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    background-color: #ffffff;
    background: url('/jade/public/icon-upload.svg') no-repeat 11px 10px;
    cursor: pointer;
  }

  .fiscalDocumentsUploadModal-merchandiseList {
    padding: 0 24px 16px;
    height: 342px;
    overflow: auto;
  }

  .fiscalDocumentsUploadModal-horizontalSeparator {
    margin: 0 24px;
  }

  .fiscalDocumentsUploadModal-confirmButton {
    margin: 16px 24px 28px 0;
    width: 120px;
    align-self: flex-end;
  }

  @media only screen and (min-width: 1366px) {
    .fiscalDocumentsUploadModal-content {
      width: 960px;
      height: 620px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .fiscalDocumentsUploadModal-content {
      width: 1200px;
      height: 860px;
    }

    .fiscalDocumentsUploadModal-merchandiseList {
      height: 582px;
    }
  }

</style>
