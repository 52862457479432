/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import { getActiveExperiments } from '@/common/activeExperiments';

const state = () => ({
  activeExperiments: null,
});

// getters
const getters = {
  isExperimentActive: (state) => (experiment) => (state.activeExperiments || []).includes(experiment),
};

// actions
const actions = {
  async fetchActiveExperiments({ commit, dispatch }) {
    const activeExperiments = await getActiveExperiments();
    commit('updateActiveExperiments', activeExperiments);
    dispatch('onActiveExperimentsUpdated', activeExperiments, { root: true });
  },
};

// mutations
const mutations = {
  updateActiveExperiments(state, activeExperiments) {
    state.activeExperiments = activeExperiments;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
