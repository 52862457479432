<template>
  <div>
    <template v-if="!orders || orders.length === 0">
      <p>Não há ordens de serviço</p>
    </template>
    <table
      v-else
      class="table"
    >
      <caption class="sr-only">
        Tabela de monitoramento de ordens de serviço
      </caption>
      <thead>
        <tr>
          <th scope="col">
            OS
          </th>
          <th
            v-if="ordersHasExternalId"
            scope="col"
          >
            Código externo
          </th>
          <th scope="col">
            Solicitada
          </th>
          <th scope="col">
            Agendada
          </th>
          <th scope="col">
            Solicitante
          </th>
          <th scope="col">
            Veículo
          </th>
          <th scope="col">
            Progresso
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(order, key) in orders">
          <tr
            :key="key"
            class="table__tr-as-link"
            @click="handleClick(order.id, $event)"
          >
            <td data-label="OS">
              <router-link
                :to="`/pedidos/acompanhe/${order.id}`"
              >
                <span class="sr-only">Detalhes da OS</span> {{ order.id }}
              </router-link>
            </td>
            <td
              v-if="ordersHasExternalId"
              data-label="Código Externo"
            >
              {{ order.externalId ? order.externalId : '-' }}
            </td>

            <td data-label="Solicitada">
              <div class="table__multiline-text">
                <span>
                  {{ $date(order.created_at).format('DD/MM/YYYY') }}
                </span>
                <span>
                  {{ $date(order.created_at).format('HH:mm') }}
                </span>
              </div>
            </td>
            <td data-label="Agendada">
              <template v-if="order.scheduled_for">
                <div class="table__multiline-text">
                  <span>
                    {{ $date(order.scheduled_for).format('DD/MM/YYYY') }}
                  </span>
                  <span>
                    {{ $date(order.scheduled_for).format('HH:mm') }}
                  </span>
                </div>
              </template>
              <span
                v-else
                class="destak-text"
              >
                imediata
              </span>
            </td>
            <td data-label="Solicitante">
              {{ order.requesterAbbr }}
            </td>
            <td data-label="Veículo">
              <span class="table__multiline-text">
                <span v-if="order.driver && order.driver.name">
                  {{ order.driver.nameAbbr }}
                </span>
                <span
                  v-if="order.vehicle_type"
                  class="destak-text"
                >
                  {{ formatVehicleName(order.vehicle_type) }}
                  <template
                    v-if="order.driver && order.driver.vehicle_plate"
                  >
                    &middot;
                    {{ order.driver.vehicle_plate }}
                  </template>
                </span>
              </span>
            </td>
            <td data-label="Progresso">
              {{ order.progress }}/{{ order.total_stops }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { vehicleNames } from '@/common/vehicleTypes';

export default {
  name: 'OrdersTable',

  props: {
    ordersHasExternalId: {
      type: Boolean,
      required: true,
    },
    orders: {
      type: Array|undefined,
      required: true,
    },
  },

  methods: {
    handleClick(orderId, event) {
      if (event.target instanceof HTMLAnchorElement) {
        return;
      }
      this.$router.push({ name: 'orderTrackingPage', params: { id: orderId } });
    },
    formatVehicleName(vehicleName) {
      return vehicleNames[vehicleName];
    },
  },
};
</script>
