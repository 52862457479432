<template>
  <div class="billingStatement-orderOverview-stop">
    <div
      class="billingStatement-orderOverview-stop__leftMarker"
      :class="{ 'billingStatement-orderOverview-stop__leftMarker--danger': isNotFinished }"
    ></div>
    <div class="billingStatement-orderOverview-stop__content">
      <div class="billingStatement-orderOverview-stop__content__header">
        <div
          class="billingStatement-orderOverview-stop__content__header__stopIndex"
          :class="{ 'billingStatement-orderOverview-stop__content__header__stopIndex--danger': isNotFinished }"
        >
          {{ index + 1 }}
        </div>
        <div class="billingStatement-orderOverview-stop__content__header__stopType">{{ taskType }}</div>
        <div
          class="billingStatement-orderOverview-stop__content__header__exceededWaitingTime"
          title="Tempo de Espera Excedido"
          v-if="stop.exceeded_waiting_time_in_minutes"
        >{{ stop.exceeded_waiting_time_in_minutes }} min</div>
      </div>
      <div class="billingStatement-orderOverview-stop__content__row">
        <div class="billingStatement-orderOverview-stop__content__row__info">
          <div class="billingStatement-orderOverview-stop__content__row__info__title">Endereço</div>
          <div class="billingStatement-orderOverview-stop__content__row__info__body">{{ address }}</div>
        </div>
      </div>
      <div class="billingStatement-orderOverview-stop__content__row">
        <div class="billingStatement-orderOverview-stop__content__row__info">
          <div class="billingStatement-orderOverview-stop__content__row__info__title">COD. NF/CT-E</div>
          <div class="billingStatement-orderOverview-stop__content__row__info__body">{{ stop.document_code }}</div>
        </div>
        <div class="billingStatement-orderOverview-stop__content__row__info">
          <div class="billingStatement-orderOverview-stop__content__row__info__title">Status</div>
          <div class="billingStatement-orderOverview-stop__content__row__info__body">{{ status }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import { stopStatus, stopStatusNames } from './stopStatus';
  import { stopTaskTypeNames } from "./stopTaskTypes";

  export default {
    name: 'Stop',

    props: {
      index: Number,
      stop: Object
    },

    computed: {
      isNotFinished() {
        return this.stop.status !== stopStatus.FINISHED;
      },

      taskType() {
        return stopTaskTypeNames[this.stop.task_type];
      },

      address() {
        return this.stop.address.thoroughfare + ', ' + this.stop.address.number;
      },

      status() {
        let status = stopStatusNames[this.stop.status];
        if (this.stop.status_updated_at) {
          const statusUpdatedAt = moment(this.stop.status_updated_at).format('DD/MM HH:mm');
          status += ' (' + statusUpdatedAt + ')'
        }

        return status;
      }
    },
  }
</script>

<style lang="scss" scoped>
@use '@vuxx/hounds/scss/abstracts/variables' as *;

  .billingStatement-orderOverview-stop {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .billingStatement-orderOverview-stop__leftMarker {
    width: 4px;
    box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.12);
    background-color: $color-primary;
  }

  .billingStatement-orderOverview-stop__leftMarker--danger {
    background-color: #ea3856;
  }

  .billingStatement-orderOverview-stop__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }

  .billingStatement-orderOverview-stop__content__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .billingStatement-orderOverview-stop__content__header__stopIndex {
    width: 29px;
    height: 27px;
    background-image: linear-gradient(to bottom, $color-primary, $color-primary);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: $color-text;
    text-align: center;
    padding: 3px 0 0 1px;
  }

  .billingStatement-orderOverview-stop__content__header__stopIndex--danger {
    background-image: linear-gradient(to bottom, #ea3856, #d92e4e);
  }

  .billingStatement-orderOverview-stop__content__header__stopType {
    flex-grow: 1;
    padding-left: 8px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #212121;
  }

  .billingStatement-orderOverview-stop__content__header__exceededWaitingTime {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #212121;
    margin-right: 4px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #212121;
    padding: 3px 8px 3px 27px;
    background: url('/jade/public/ic-clock.svg') no-repeat 8px 5px;
  }

  .billingStatement-orderOverview-stop__content__row {
    padding-top: 12px;
    display: flex;
    flex-direction: row;
  }

  .billingStatement-orderOverview-stop__content__row__info {
    display: flex;
    flex-direction: column;
    padding-right: 32px;
  }

  .billingStatement-orderOverview-stop__content__row__info:last-child {
    padding-right: 0;
  }

  .billingStatement-orderOverview-stop__content__row__info__title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #000000;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .billingStatement-orderOverview-stop__content__row__info__body {
    padding-top: 2px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #212121;
  }

</style>
