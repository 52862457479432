<template>
  <div class="question">

    <p class="ratingLabel">
      {{ question.questionText }}
    </p>

    <star-rating
      v-if="question.type === 'star_rating'"
      class="ratingStar"
      @rating-selected="(rating) => setAnswer(question, rating)"
      :rating="question.answer"
      :show-rating="false"
    />

    <textarea
      v-else-if="question.type === 'text_long'"
      v-model="question.answer"
      class="main-form__input"
    ></textarea>

    <nps-rating
      v-else-if="question.type === 'nps'"
      v-model="question.answer"
    />

    <!-- else: handle error (TODO) -->

  </div>
</template>

<script>
import NpsRating from '@/common/NpsRating.vue';
import StarRating from 'vue-star-rating';

export default {
  name: 'RatingQuestion',

  components: {
    NpsRating,
    StarRating,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  methods: {
    setAnswer(question, answer) {
      question.answer = answer;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@vuxx/hounds/scss/abstracts/mixins' as *;
@use '@vuxx/hounds/scss/abstracts/variables' as *;

.ratingLabel {
  margin-bottom: 16px;
}

.ratingStar {
  margin-bottom: 16px;

  @include breakpoint('medium') {
    justify-content: center;
  }
}

</style>
