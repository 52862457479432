<template>
  <div class="panel spotRequest-summaryHeader">
    <div class="spotRequest-summaryHeader__serviceInfo">
      <div>
        <label class="spotRequest-summaryHeader__serviceInfo__serviceType__label">
          Horário do Serviço
        </label>
        <label class="spotRequest-summaryHeader__serviceInfo__serviceType__value">
          {{ scheduleLabel }}
        </label>
      </div>

      <div class="verticalSeparator spotRequest-summaryHeader__verticalSeparator" />

      <div>
        <label class="spotRequest-summaryHeader__serviceInfo__serviceType__label">
          Serviço
        </label>
        <label class="spotRequest-summaryHeader__serviceInfo__serviceType__value">
          {{ serviceType }}
        </label>
      </div>

      <div class="verticalSeparator spotRequest-summaryHeader__verticalSeparator" />

      <div>
        <label class="spotRequest-summaryHeader__serviceInfo__vehicleType__label">
          Veículo
        </label>
        <label class="spotRequest-summaryHeader__serviceInfo__vehicleType__value">
          {{ vehicleTypeName }}
        </label>
      </div>

      <div class="verticalSeparator spotRequest-summaryHeader__verticalSeparator" />

      <div v-if="totalWeightInKilograms">
        <span class="spotRequest-summaryHeader__serviceInfo__vehicleType__label">
          Peso total da carga
        </span>
        <span class="spotRequest-summaryHeader__serviceInfo__vehicleType__value">
          {{ Number(totalWeightInKilograms).toLocaleString('pt-BR') }} kg
        </span>
      </div>

      <div
        v-if="selectedVehicleBodyTypes.length > 0"
        class="spotRequest-summaryHeader__full-width"
      >
        <span class="spotRequest-summaryHeader__serviceInfo__vehicleType__label">
          Carroceria
        </span>
        <span class="spotRequest-summaryHeader__tags-list">
          <template v-for="(bodyType, index) in selectedVehicleBodyTypes">
            <span
              :key="index"
              class="spotRequest-summaryHeader__tags-list-item"
            >
              {{ bodyType.name }}
            </span>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getScheduleTypeNameWithTime } from '../common/ScheduleTypes';

export default {
  name: 'SummaryHeader',

  props: [
    'vehicleTypeName',
    'serviceType',
    'scheduling',
  ],

  computed: {
    ...mapState('spotRequest', ['totalWeightInKilograms', 'selectedVehicleBodyTypes']),

    scheduleLabel() {
      return getScheduleTypeNameWithTime(this.scheduling.type, this.scheduling.dateTime);
    },
  },
};
</script>

<style lang="scss" scoped>
  .spotRequest-summaryHeader {
    margin: 20px 20px 20px 20px;
    padding: 24px 0 24px 20px;
  }

  .spotRequest-summaryHeader__serviceInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.25rem;
  }

  .spotRequest-summaryHeader__full-width {
    width: 100%;
  }

  .spotRequest-summaryHeader__tags-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .spotRequest-summaryHeader__tags-list-item {
    padding: 0.5rem 0.75rem;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    font-weight: 400;
  }

  .spotRequest-summaryHeader__verticalSeparator {
    height: 45px;
  }

  .spotRequest-summaryHeader__serviceInfo__serviceType__label {
    display: block;
    font-size: 14px;
    letter-spacing: 0px;
    color: #2f2f2f;
  }

  .spotRequest-summaryHeader__serviceInfo__serviceType__value {
    display: block;
    margin-top: 2px;
    font-size: 20px;
    font-weight: 0;
    letter-spacing: 0.6px;
    color: #2f2f2f;
  }

  .spotRequest-summaryHeader__serviceInfo__vehicleType__label {
    display: block;
    font-size: 14px;
    letter-spacing: 0px;
    color: #2f2f2f;
  }

  .spotRequest-summaryHeader__serviceInfo__vehicleType__value {
    display: block;
    margin-top: 2px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #2f2f2f;
  }
</style>
