const stopStatus = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
};

const stopStatusTranslations = {
  PENDING: '',
  FAILED: 'Insucesso',
  FINISHED: 'Concluído',
};

export { stopStatus, stopStatusTranslations };
