import escape from 'escape-html';

export default function addNpsWidget(userInfo) {
    if (userInfo.features['nps_survey_binds.co'] !== true) {
        return;
    }

    const metadata = {canal: "widget", userId: userInfo.personId, customerId: userInfo.customerId};
    const user = {name: userInfo.personName, email: userInfo.personEmail};

    const div = document.createElement('div');
    div.innerHTML = `
        <link href="https://widget.binds.co/css/app.css" rel=stylesheet>
        <div>
            <vue-widget widget_position="lowerRight" widget_title="feedback" buttons="#010101" width="500"
                text_buttons="#ffffff" background="#673C84" texts="#ffffff" timeout="0" width_metric="px"
                survey_id="60da1a33744ec327558ce49b" from='${escape(JSON.stringify(user))}'
                is_to_post="true" metadata='${escape(JSON.stringify(metadata))}' />
        </div>
    `;

    const widgetScript = document.createElement('script');
    widgetScript.src = 'https://widget.binds.co/js/app.js';
    div.appendChild(widgetScript);

    document.body.appendChild(div);
}