function createPlaceIdFromMerchandise({address, fiscalDocument}) {
  const {postalCode, number, complement} = address;
  const {latitude, longitude} = address.geolocation;
  const contact = findAddresseeContactFromMerchandise(fiscalDocument);

  return `(${latitude},${longitude})${postalCode}-${number}-${complement}-${contact}`;
}

function findAddresseeContactFromMerchandise(fiscalDocument) {
  if (!fiscalDocument) {
    return null;
  }

  const addressee = fiscalDocument.addressee
  if (!addressee) {
    return null;
  }

  return addressee.name || null;
}

const defaultPlaceIds = {
  CUSTOMER_WAREHOUSE: 'CUSTOMER_WAREHOUSE',
  ROUND_TRIP: 'ROUND_TRIP',
};

export { createPlaceIdFromMerchandise, defaultPlaceIds }
