<template>
  <div v-if="fiscalDocumentWasNotFound">
    <button
      class="merchandiseListFiscalDocumentUpload-uploadButton"
      @click="openFileDialog()"
    >
      Enviar XML
    </button>

    <input
      class="merchandiseListFiscalDocumentUpload-fileInput"
      accept="text/xml"
      type="file"
      ref="fiscalDocumentInput"
      @change="uploadFiscalDocuments"
    />
  </div>
</template>

<script>
import { merchandiseErrorTypes } from './merchandiseErrors';
import { updateMerchandiseWithFiscalDocumentUpload } from './merchandiseFiscalDocumentUpload'

  export default {
    name: 'MerchandiseListFiscalDocumentUpload',

    props: {
      merchandise: Object,
      allMerchandises: Array,
    },

    computed: {
      fiscalDocumentWasNotFound() {
        return this.merchandise.status.error &&
          this.merchandise.status.error.type === merchandiseErrorTypes.FiscalDocumentNotFound;
      }
    },

    methods: {
      openFileDialog() {
        this.$refs.fiscalDocumentInput.click();
      },

      uploadFiscalDocuments() {
        updateMerchandiseWithFiscalDocumentUpload(this.merchandise, this.$refs.fiscalDocumentInput.files[0], this.allMerchandises);
      },
    },
  }
</script>

<style scoped>

  .merchandiseListFiscalDocumentUpload-uploadButton {
    width: 154px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #f22134;
    display: table-cell;
    vertical-align: middle;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 0.3px;
    text-align:center;
    color: #ffffff;
    cursor:pointer;
    padding: 4px 4px 4px 30px;
    background: #f22134 url('/jade/public/icon-upload-white.svg') no-repeat 25px 10px;
  }

  @media only screen and (min-width: 1366px) {
    .merchandiseListFiscalDocumentUpload-uploadButton {
      font-size: 16px;
      -ms-background-position-x: 22px;
      background-position-x: 22px;
      padding-top: 5px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .merchandiseListFiscalDocumentUpload-uploadButton {
      font-size: 14px;
    }
  }

  .merchandiseListFiscalDocumentUpload-fileInput {
    display: none;
  }

</style>
