<template>
  <div>
    <div
      @click="() => $emit('click')"
      class="preview"
      :class="[buttons.imagePreview]">
      <div class="thumb">
        <img :src="imageUrl" />
      </div>
      <div class="label">
        {{ label }}
      </div>
      <div class="action">
        Ver foto
      </div>
    </div>
  </div>
</template>

<script>
import buttons from '../styles/buttons.module.scss'

export default {
  name: "ButtonWithImage",

  props: {
    imageUrl: String,
    label: String
  },

  computed: {
    buttons: () => Object.freeze(buttons)
  }
}
</script>

<style scoped>
.preview {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thumb {
  height: 32px;
  width: 32px;
}

.thumb > img {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 4px;
}

.label {
  padding: 0 12px;
}

.action {
  /* Blue 500 */
  color: #198BFD;
  font-weight: bold;
  margin-left: auto;
}
</style>
