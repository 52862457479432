<template>
  <div class="container">
    <header>
      <h1 class="headerText">Complete suas informações para cobrança</h1>
      <div class="horizontalSeparator horizontalSeparator--darker"></div>
    </header>

    <main class="mainContent">
      <p class="instructions">Para completar esta solicitação, precisamos que você complete seu cadastro com as informações da sua empresa para cobrança.</p>

      <form>
        <p class="fieldRow field">
          <label for="financeInfo_cnpj" class="fieldLabel">CNPJ</label>
          <the-mask
            id="financeInfo_cnpj"
            type="tel"
            class="fieldInput"
            :class="{'fieldInput--danger': cnpjIsInvalid}"
            mask="##.###.###/####-##"
            masked
            size="18"
            v-model="customer.cnpj"
          />
        </p>

        <p class="fieldRow field">
          <label for="financeInfo_razao_social" class="fieldLabel">Razão social</label>
          <input id="financeInfo_razao_social" type="text" v-model="customer.razao_social" size="50" class="fieldInput">
        </p>

        <h2 class="subheader">Responsável financeiro</h2>

        <p class="fieldRow field">
          <label for="financeInfo_responsible_name" class="fieldLabel">Nome</label>
          <input id="financeInfo_responsible_name" type="text" v-model="customer.finances_responsible.name" size="50" class="fieldInput">
        </p>

        <div class="fieldRow fieldRowGroup">
          <div class="field field--phone">
            <label for="financeInfo_responsible_phone" class="fieldLabel">Telefone</label>
            <the-mask
              id="financeInfo_responsible_phone"
              type="text"
              class="fieldInput"
              :class="{'fieldInput--danger': phoneIsInvalid}"
              :mask="['(##) ####-####', '(##) #####-####']"
              masked
              size="20"
              placeholder="Ex: (11) 3333-3333"
              v-model="customer.finances_responsible.phone"
            />
          </div>

          <div class="field field--phoneBranch">
            <label for="financeInfo_responsible_phone_branch" class="fieldLabel">Ramal</label>
            <input
              id="financeInfo_responsible_phone_branch"
              type="text"
              class="fieldInput"
              size="6"
              v-model="customer.finances_responsible.phone_branch"
            />
          </div>
        </div>

        <p class="fieldRow field">
          <label for="financeInfo_billing_email" class="fieldLabel">E-mail</label>
          <input
            id="financeInfo_billing_email"
            type="email"
            v-model="customer.finances_responsible.email"
            size="50"
            class="fieldInput"
            :class="{'fieldInput--danger': emailIsInvalid}"
          >
        </p>

        <h2 class="subheader">Endereço de faturamento</h2>

        <div class="fieldRow fieldRowGroup">
          <div class="field field--addressPostalCode">
            <label for="financeInfo_billing_address_postalCode" class="fieldLabel">CEP</label>
            <the-mask
              id="financeInfo_billing_address_postalCode"
              type="text"
              class="fieldInput"
              :class="{'fieldInput--danger': postalCodeIsInvalid}"
              mask="#####-###"
              size="10"
              placeholder="Ex: 01310-200"
              v-model="customer.billing_address.postal_code"
            />
          </div>

          <div class="field field--addressNumber">
            <label for="financeInfo_billing_address_number" class="fieldLabel">Número</label>
            <input id="financeInfo_billing_address_number" type="text" v-model="customer.billing_address.number" size="10" class="fieldInput"
                   placeholder="Ex: 1578">
          </div>
        </div>

        <p class="fieldRow field">
          <label for="financeInfo_billing_address_complement" class="fieldLabel">Complemento</label>
          <input id="financeInfo_billing_address_complement" type="text" v-model="customer.billing_address.complement" size="20" class="fieldInput"
            placeholder="Ex: Sala 91">
        </p>

        <div class="horizontalSeparator horizontalSeparator--darker actionsTopSeparator"></div>

        <div class="actions">
          <button
            class="actionButton baseButton cancelButton"
            type="button"
            @click="closeHandler"
            >Voltar</button>
          <ButtonWithSpinner
            type="button"
            label="Confirmar solicitação"
            class="actionButton submitButton"
            :spinning="waitingForResponse"
            :disabled="cannotSubmit"
            @click="submit"
          />
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import ButtonWithSpinner from '../../common/ButtonWithSpinner.vue';
import { TheMask } from 'vue-the-mask';
import axios from 'axios';
import { getErrorResponseBody } from '../../client/errorUtils';
import { validate as validateCnpj } from 'cnpj';

function validData(customer) {
  return (
    customer.cnpj
    && customer.razao_social
    && customer.billing_address.postal_code
    && customer.billing_address.number
    && customer.finances_responsible.name
    && customer.finances_responsible.email
    && customer.finances_responsible.phone
  );
}

function submitFinanceInfo({ customer: { cnpj, razao_social, billing_address, finances_responsible } }) {
  let customer = {
    cnpj,
    razao_social,
    billing_address: {
      cep: billing_address.postal_code,
      number: billing_address.number,
      complement: billing_address.complement,
    },
    contacts: [
      {
        responsible: 'Financeiro',
        name: finances_responsible.name,
        email: finances_responsible.email,
        phone: finances_responsible.phone,
        phone_branch: finances_responsible.phone_branch,
        department: ''
      },
    ],
    billing_email: finances_responsible.email,
  };
  return axios.patch('/jade/api/customers', customer);
}

export default {
  name: "FinanceInfoForm",

  components: {
    ButtonWithSpinner,
    TheMask,
  },

  props: {
    closeHandler: {
      type: Function,
      required: true,
    },

    showMessageHandler: {
      type: Function,
      required: true,
    },
  },

  data: function () {
    return {
      customer: {
        cnpj: '',
        razao_social: '',
        billing_address: {
          postal_code: '',
          number: '',
          complement: '',
        },
        finances_responsible: {
          name: '',
          email: '',
          phone: '',
          phone_branch: '',
        }
      },
      waitingForResponse: false,
    }
  },

  computed: {
    cannotSubmit() {
      return this.waitingForResponse ||
        !validData(this.customer) ||
        this.cnpjIsInvalid ||
        this.phoneIsInvalid ||
        this.emailIsInvalid ||
        this.postalCodeIsInvalid;
    },

    cnpjIsInvalid() {
      return this.customer.cnpj && !validateCnpj(this.customer.cnpj);
    },

    phoneIsInvalid() {
      return this.customer.finances_responsible.phone && this.customer.finances_responsible.phone.length < 14;
    },

    emailIsInvalid() {
      return this.customer.finances_responsible.email &&
        !this.customer.finances_responsible.email.match(/.+@.+\..+/i);
    },

    postalCodeIsInvalid() {
      return this.customer.billing_address.postal_code && this.customer.billing_address.postal_code.length < 8;
    },
  },

  methods: {
    submit() {
      this.waitingForResponse = true;

      submitFinanceInfo({ customer: this.customer })
        .then(() => {
          this.waitingForResponse = false;
          this.closeHandler({ completed: true });
        })
        .catch(err => {
          const errorInfo = getErrorResponseBody(err);
          if (errorInfo.code === 'validation_error') {
            this.showMessageHandler({
              title: 'Erro de validação',
              message: 'Houve um problema no preenchimento dos campos. Por favor verifique seus dados e tente novamente.',
            });
          }
          else {
            this.showMessageHandler({
              title: 'Erro na atualização dos dados',
              message: 'Houve um problema ao atualizar seus dados. Por favor tente novamente dentro de alguns instantes.',
            });
          }
          this.waitingForResponse = false;
        });
    },
  },

}
</script>

<style scoped>
  .headerText {
    padding: 24px 32px 10px;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #1f1f1f;
  }

  .mainContent {
    padding: 24px 32px 28px;
  }

  .field {
    margin: 0;
  }

  .fieldRow {
    margin: 0 0 20px;
  }

  .fieldRowGroup {
    display: flex;
  }

  .fieldRowGroup > .field:not(:first-child) {
    margin-left: 12px;
  }

  .field--phone {
    flex-grow: 1;
  }

  .field--phoneBranch {
    flex-grow: 0.5;
  }

  .field--addressPostalCode,
  .field--addressNumber {
    flex-grow: 1;
  }

  .fieldInput {
    max-width: 100%;
    box-sizing : border-box;
    width: 100%;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 9px 13px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .fieldInput--danger {
    border-color: #e8425a;
  }

  .fieldInput--warning {
    border-color: #fba30f;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .fieldInput::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .fieldInput::-moz-placeholder {
    color: #c6ced0;
  }

  .fieldInput:-ms-input-placeholder {
    color: #c6ced0;
  }

  .fieldInput:focus {
    border-color: #28c0f1;
    outline: none;
  }

  .fieldLabel {
    display: block;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #858585;
  }

  .subheader {
    margin: 28px 0 12px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #858585;
  }

  .instructions {
    margin: 0 0 28px;
  }

  .actionsTopSeparator {
    margin-top: 24px;
  }

  .actions {
    margin: 16px 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .actionButton:not(:last-child) {
    margin-right: 16px;
  }

  .submitButton {
    width: auto;
  }

</style>
