<template>
  <span> {{ content }} </span>
</template>

<script>
  export default {
    name: "DriverInfoWindow",

    props: {
      content: String
    }
  }
</script>
