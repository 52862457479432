<template>
  <div class="stopInfoWindow">
    <b v-if="trackingCode"> {{ trackingCode }}</b>
    <span> {{ address }} </span>
    <span v-if="isDriverOnTheWay">Chegada estimada: {{ formattedArrivalDate }} </span>
  </div>
</template>

<script>
  import { formatDate } from '../../common/filters/dateFormatter';

  export default {
    name: "StopInfoWindow",

    props: {
      driverEta: Object,
      trackingCode: String,
      address: String,
      stopId: Number
    },

    computed: {
      isDriverOnTheWay() {
        return this.driverEta && this.stopId === this.driverEta.nextStopId;
      },

      formattedArrivalDate() {
        return formatDate(this.driverEta.estimatedArrival, 'DD/MM HH:mm');
      }
    }
  }
</script>

<style scoped>
.stopInfoWindow {
  display: flex;
  flex-direction: column;
}
</style>
