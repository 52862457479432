function getTitle(vm) {
  const { htmlDocumentTitle } = vm.$options;
  if (htmlDocumentTitle) {
    return typeof htmlDocumentTitle === 'function'
      ? htmlDocumentTitle.call(vm)
      : htmlDocumentTitle;
  }
}

export default {
  mounted() {
    const title = getTitle(this);
    if (title) {
      document.title = title;
    }
  },
};
