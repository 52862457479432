import axios from 'axios';

export async function getPodById(id) {
  return axios.get(`/jade/api/pods/${id}`);
}

export async function getPodByToken(token) {
  return axios.get(`/jade/api/pods/public/${token}`);
}

export function populatePodData(res) {
  const podData = {
    hashId: '',
    address: '',
    barcode: '',
    orderId: '',
    timeFinish: '',
    itemsCount: '',
    driverName: '',
    driverVehiclePlate: '',
    recipientSignature: '',
    recipientName: '',
    recipientIdentifier: '',
    recipientSignatureTime: '',
    statusMessage: '',
    notes: '',
    type: '',
    statusType: '',
    pictures: [],
  };

  podData.barcode = res.data.barcode;
  podData.orderId = res.data.brainOrderId;
  podData.type = res.data.type;
  podData.hashId = res.data.hashid;
  podData.address = res.data.address;
  podData.timeFinish = res.data.timeFinish;
  podData.itemsCount = res.data.itemsCount ? res.data.itemsCount : '—';
  podData.driverName = res.data.driverName;
  podData.driverVehiclePlate = res.data.driverVehiclePlate;
  podData.recipientSignature = res.data.recipientSignature;
  podData.recipientName = res.data.recipientName;
  podData.recipientIdentifier = res.data.recipientIdentifier;
  podData.recipientSignatureTime = res.data.recipientSignatureTime;
  podData.pictures = res.data.pictures;
  podData.statusMessage = res.data.statusMessage;
  podData.notes = res.data.notes;
  podData.type = res.data.type;
  podData.statusType = res.data.statusType;
  podData.pictures = res.data.pictures;

  return podData;
}
