<template>
  <div>
    <h2>Detalhamento</h2>
    <form class="main-form">
      <div class="bulma-columns bulma-my-0">
        <div class="bulma-column bulma-is-5">
          <label for="search">
            <input
              v-model="orderIdFilter"
              type="search"
              placeholder="Procure por OS"
              class="input-search"
            >
          </label>
        </div>
        <div class="bulma-column bulma-is-7">
          <div class="main-form__inline-group gap-32">
            <label class="main-form__label main-form__label--checkbox">
              <input
                v-model="statusFilter"
                type="radio"
                name="statusFilter"
                value=""
                class="main-form__checkbox"
              >
              <span
                class="main-form__checkbox-button main-form__checkbox-button--radio"
              >
                Todas
              </span>
            </label>
            <label class="main-form__label main-form__label--checkbox">
              <input
                v-model="statusFilter"
                type="radio"
                name="statusFilter"
                value="FINISHED"
                class="main-form__checkbox"
              >
              <span
                class="main-form__checkbox-button main-form__checkbox-button--radio"
              >
                Finalizadas
              </span>
            </label>
            <label class="main-form__label main-form__label--checkbox">
              <input
                v-model="statusFilter"
                type="radio"
                name="statusFilter"
                value="CANCELED"
                class="main-form__checkbox"
              >
              <span
                class="main-form__checkbox-button main-form__checkbox-button--radio"
              >
                Canceladas
              </span>
            </label>
          </div>
        </div>
      </div>
    </form>
    <table class="table table--zebra">
      <thead>
        <tr>
          <th scope="col">Data da Finalização</th>
          <th scope="col">Número da OS</th>
          <th scope="col">Status</th>
          <th scope="col">Tipo de Veículo</th>
          <th
            class="table__right-text"
            scope="col"
          >
            Total (R$)
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="[date, orders] in ordersByDate">
          <tr
            v-for="(order, index) in orders"
            @click="onOrderClicked(order)"
          >
            <td data-label="Data da Finalização">
              {{ index === 0 ? date : '' | dayOfMonthFormat }}
            </td>
            <td data-label="Número da OS">{{ order.orderId }}</td>
            <td data-label="Status">
              <img v-if="order.status === 'FINISHED'" src="/jade/public/icon-finished.svg">
              <img v-else src="/jade/public/icon-canceled.svg">
            </td>
            <td data-label="Tipo do veículo">{{ order.vehicleType | vehicleTypeLabel }}</td>
            <td data-label="Total (R$)" class="table__right-text">{{ order.grossPrice | moneyFormat }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { moneyFormat } from '../common/filters/numberFormatters'
import { vehicleTypeLabel } from '@/common/vehicleTypes';
import moment from 'moment'

export default {
  name: "OrdersList",

  data() {
    return {
      statusFilter: '',
      orderIdFilter: '',
    };
  },

  props: [
    'orders',
    'monthPeriodToShow'
  ],

  computed: {
    ordersByDate() {
      const dates = [];
      const grouped = {};
      this.orders.filter(this.showOrder).forEach(order => {
        const date = order.invoiceDate;
        if (!grouped[date]) {
          dates.push(date);
          grouped[date] = [];
        }
        grouped[date].push(order)
      });
      return dates.map(date => [date, grouped[date]]);
    }
  },

  methods: {
    showOrder(order) {
      return this.statusFilterMatches(order.status) &&
        this.monthPeriodToShowMatches(order.invoiceDate) &&
        this.orderIdFilterMatches(order.orderId);
    },

    statusFilterMatches(status) {
      return this.statusFilter === '' || status === this.statusFilter;
    },

    monthPeriodToShowMatches(date) {
      const dayOfMonth = Number(date.split('-')[2]);

      return this.monthPeriodToShow === 'month' ||
        (this.monthPeriodToShow === 'firstHalf' && dayOfMonth <= 15) ||
        (this.monthPeriodToShow === 'secondHalf' && dayOfMonth > 15);
    },

    orderIdFilterMatches(orderId) {
      return orderId.toString().includes(this.orderIdFilter);
    },

    onOrderClicked(order) {
      this.$emit('order-clicked', order);
    }
  },

  filters: {
    vehicleTypeLabel,
    moneyFormat,
    dayOfMonthFormat: function (date) {
      return date ? moment(date).format('D MMM.') : '';
    }
  }
}
</script>

<style scoped>
  h2 {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #b7b7b7;
    text-transform: uppercase;
  }

  .filters {
    margin-top: 13px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .statusFilters {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .orderIdSearchBox {
    width: 164px;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    margin-top: 20px;
    width: 100%;
  }

  td {
    height: 40px;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #323232;
    white-space: nowrap;
    padding: 4px 20px;
  }

  .textAlignLeft {
    text-align: left;
  }

  .textAlignCenter {
    text-align: center;
  }

  .textAlignRight {
    text-align: right;
  }

  .noPaddingRight {
    padding-right: 0px;
  }

  tbody > tr {
    cursor: pointer;

    -moz-box-shadow: 0 10px 16px 0 rgba(10, 31, 68, 0), 0 2px 4px 0 rgba(0, 12, 32, 0);
    -webkit-box-shadow: 0 10px 16px 0 rgba(10, 31, 68, 0), 0 2px 4px 0 rgba(0, 12, 32, 0);
    box-shadow: 0 10px 16px 0 rgba(10, 31, 68, 0), 0 2px 4px 0 rgba(0, 12, 32, 0);
    transition: all 0.75s;
  }

  tbody > tr:hover {
    -moz-box-shadow: 0 10px 16px 0 rgba(10, 31, 68, 0.06), 0 2px 4px 0 rgba(0, 12, 32, 0.04);
    -webkit-box-shadow: 0 10px 16px 0 rgba(10, 31, 68, 0.06), 0 2px 4px 0 rgba(0, 12, 32, 0.04);
    box-shadow: 0 10px 16px 0 rgba(10, 31, 68, 0.06), 0 2px 4px 0 rgba(0, 12, 32, 0.04);
    transition: all 0.25s;
  }

  tbody > tr:nth-child(even) {
    /*equivalent to #f9f9f9, this allows box shadow on hover*/
    background: rgba(0, 0, 0, 0.0235);
  }

  thead {
    border-bottom: 1px solid #dcdcdc;
    background: white;
    padding-bottom: 5px;
  }

  th {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 4px 20px;
  }

  .chargesTotal__content {
    /*transform: translateX(28px);*/
    display: inline-block;
    padding-right: 25px;
  }

  tr:hover .chargesTotal--nonempty .chargesTotal__content {
    background: url('/jade/public/icon-charge-details.svg') no-repeat right center;
  }
</style>
