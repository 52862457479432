<template>
  <base-layout
    :add-container="false"
  >
    <page-header title="Contas cadastradas" />
    <div class="pageBody">
      <a :href="customerUrl">
        <button class="baseButton button">
          Cadastrar nova conta
        </button>
      </a>
      <table class="customerList">
        <colgroup>
          <col style="width: 1%;">
          <col style="width: 49%;">
          <col style="width: 49%;">
          <col style="width: 1%;">
        </colgroup>
        <thead>
        <tr>
          <th>#</th>
          <th>Razão Social</th>
          <th colspan=2>Apelido</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="customer in customers">
          <td>{{ customer.id }}</td>
          <td>{{ customer.razao_social }}</td>
          <td>{{ customer.nickname }}</td>
          <td class="icon-column">
            <a :href="`/admin/varejistas/${customer.id}/usuarios`">
              <img src="/jade/public/icon-user.svg" height=20px>
            </a>
            <a :href="`/admin/varejistas/${customer.id}/comprovantes`">
              <img src="/jade/public/icon-documents.svg" height=20px>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </base-layout>
</template>


<script>
import axios from 'axios';
import PageHeader from '../common/PageHeader.vue';
import BaseLayout from '../common/BaseLayout.vue';
import { personRoles } from "../client/personRoleEnum";
import '../common/style.css'
import { customerUrl } from '@/client/runtimeConfig';

export default {
  name: 'CustomerList',

  components: {
    PageHeader,
    BaseLayout,
  },

  data() {
    return {
      customers: [],
    };
  },

  computed: {
    customerUrl() {
      return customerUrl;
    }
  },


  async mounted() {
    this.$root.watchUserInfo(userInfo => {
      if(userInfo.roleId !== personRoles.SUPER_ADMIN){
        window.location.href = '/not-found';
      };
    });

    await axios.get('/jade/api/retailers')
      .then(response => {
        this.customers = response.data;
      });
  }
};
</script>

<style scoped>
  .pageBody {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 300px;
  }

  .button {
    width: 200px;
  }

  table {
    border-collapse: collapse;
    table-layout: auto;
  }

  thead {
    border-bottom: 1px solid #dcdcdc;
  }

  td {
    height: 40px;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #323232;
    text-align: left;
    padding: 4px 20px;
  }

  .icon-column {
    white-space: nowrap;
  }

  .customerList {
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .customerList th {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 12px 20px;
    text-align: left;
  }

  .customerList > tbody > tr:nth-child(even) {
    background: #f9f9f9
  }

  .customerList > tbody > tr > td:first-child,
  .customerList > tbody > tr > td:last-child {
    text-align: right;
  }
</style>
