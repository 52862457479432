import uniqBy from "lodash.uniqby";
import { createPlaceIdFromMerchandise, defaultPlaceIds } from "@/spot/spotRequest/transportation/PlaceIds";
import { serviceTypes } from "@/spot/common/serviceTypes";

// Backend will convert these places to stops

// Merchandise -> Place

function findUniquePlacesFromMerchandises(merchandises) {
  const places = merchandises.map(convertMerchandiseToPlace);
  return uniqBy(places, (place) => place.id);
}

function convertMerchandiseToPlace(merchandise) {
  const {address, timeWindow, instructions, fiscalDocument} = merchandise;

  return {
    id: createPlaceIdFromMerchandise(merchandise),
    isCustomerWarehouse: false,
    address: convertMerchandiseAddressAndFiscalDocumentToAddress(address, fiscalDocument),
    geolocation: address.geolocation,
    timeWindow: buildTimeWindow(timeWindow.start, timeWindow.end),
    instructions: instructions || null,
    addressee: extractContactFromMerchandiseFiscalDocument(fiscalDocument) // TODO: rename to contact?
  };
}

function convertMerchandiseAddressAndFiscalDocumentToAddress(
  {postalCode, thoroughfare, number, neighborhood, city, locality, state, complement},
  fiscalDocument
) {
  const addressee = (fiscalDocument && fiscalDocument.addressee) ? fiscalDocument.addressee : null;

  return {
    postalCode,
    thoroughfare,
    number,
    neighborhood,
    locality: city || locality, // TODO: google returns locality... fiscal document and correios return city
    state,
    complement: complement || null,
    contact: addressee ? addressee.name : null,
  }
}

function extractContactFromMerchandiseFiscalDocument(fiscalDocument) {
  if (!fiscalDocument || !fiscalDocument.addressee) {
    return null;
  }

  const addressee = fiscalDocument.addressee;
  return {
    name: addressee.name,
    phoneNumber: addressee.phoneNumber,
    nationalDocumentNumber: addressee.nationalDocument,
    isLegalPerson: addressee.isLegalPerson,
  }
}

// Warehouse -> place

function convertWarehouseToPlace(warehouse, serviceType) {
  return convertWarehouseModelToPlace(defaultPlaceIds.CUSTOMER_WAREHOUSE, warehouse, serviceType);
}

function convertRoundTripToPlace(roundTrip) {
  if (!roundTrip) {
    return null;
  }

  return convertWarehouseModelToPlace(defaultPlaceIds.ROUND_TRIP, roundTrip, serviceTypes.DELIVERY);
}

function convertWarehouseModelToPlace(id, warehouse, serviceType) {
  const {address, latitude, longitude, opening_time, closing_time, delivery_instructions, pickup_instructions} = warehouse;
  return {
    id,
    isCustomerWarehouse: true,
    address: convertWarehouseAddressToAddress(address),
    geolocation: { latitude, longitude },
    timeWindow: buildTimeWindow(opening_time, closing_time),
    instructions: findWarehouseInstructionFromServiceType(delivery_instructions, pickup_instructions, serviceType),
    addressee: null, // TODO: find the warehouse contact/addressee
  };
}

function findWarehouseInstructionFromServiceType(delivery_instructions, pickup_instructions, serviceType)
{
  switch (serviceType) {
    case serviceTypes.DELIVERY:
      return delivery_instructions || null;
    case serviceTypes.PICKUP:
      return pickup_instructions || null;
    default:
      throw Error(`Could not find warehouse instructions. Service of type ${serviceType} is invalid.`);
  }
}

function convertWarehouseAddressToAddress({cep, address, number, neighborhood, city, state, complement}) {
  return {
    postalCode: cep,
    thoroughfare: address,
    number,
    neighborhood,
    locality: city,
    state,
    complement: complement || null,
    contact: null,
  };
}

function buildTimeWindow(start, end)
{
  if (!start || !end) {
    return null;
  }

  return { start, end };
}

export { findUniquePlacesFromMerchandises, convertWarehouseToPlace, convertRoundTripToPlace };
