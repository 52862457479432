import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VTooltip from 'v-tooltip';
import '../common/style.css';
import moment from 'moment';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import * as VueGoogleMaps from 'vue2-google-maps';
import store from '@/store';
import dayjs from 'dayjs';
import Sidebar from '../sidebar/Sidebar.vue';
import routes from './routes';
import intercomInit from './intercomInit';
import tmsInteropInit, { closeModal, isEmbedded } from '../tmsInterop/init';
import googleAnalyticsInit from './googleAnalyticsInit';
import heapAnalyticsInit from './heapAnalyticsInit';
import initializeSentry from './sentry';
import redirectToLoginWhenSessionIsExpired from "@/client/axiosInterceptor";
import redirectToLogin from "@/common/redirectToLogin";
import LoggerPlugin from './LoggerPlugin';
import { auth, logger } from './app';
import npsWidget from './npsWidget';
import { googleMapsKey } from './runtimeConfig';
import '@/plugins/Dayjs';

import 'dayjs/locale/pt-br';

moment.locale('pt-br');
dayjs.locale('pt-br');

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.use(VueGoogleMaps, {
  load: {
    libraries: 'geometry,places',
    key: googleMapsKey,
    v: '3.41',
  },
});

Vue.use(VueRouter);
Vue.use(VTooltip);
Vue.use(Vuex);

const router = new VueRouter({
  routes,
  mode: 'history',
});

initializeSentry(router, auth, logger);

redirectToLoginWhenSessionIsExpired()

router.beforeResolve(beforeRoute);

async function beforeRoute(to, from, next) {
  const isPublic = to.meta && to.meta.public;
  if (!isPublic) {
    const { loggedIn } = await auth.getUser();
    if (!loggedIn) {
      next(false);
      redirectToLogin(to.fullPath);
      return;
    }
  }

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | Box`;
  }

  next();
}

const vm = new Vue({
  router,
  store,
  components: {
    sidebar: Sidebar,
  },

  data: {
    embedded: isEmbedded(),
    userInfo: {},
    alerts: {
      type: null,
      message: null,
    },
  },

  computed: {
    hasUserIdentity() {
      return Object.keys(this.userInfo).length !== 0;
    },
  },

  methods: {
    closeEmbeddedModal() {
      closeModal();
    },

    toggleGlobalAlert(payload) {
      this.alerts = { ...this.alerts, ...payload };
    },

    watchUserInfo(listener) {
      const { userInfo } = this.$data;
      if (Object.keys(userInfo).length !== 0) {
        return listener(userInfo);
      }
      return this.$watch('userInfo', listener);
    },

    watchAlerts(listener) {
      return this.$watch('alerts', listener);
    },

    loggedIn({ identity, config }) {
      Vue.set(this, 'userInfo', identity);
      this.$emit('loggedIn', { identity });
    },

    waitForLogin() {
      this.$auth.getUser().then((res) => {
        if (res.loggedIn) {
          this.loggedIn(res);
        }
      });
    },
  },
});

tmsInteropInit({ router });

vm.$once('loggedIn', ({ identity }) => {
  intercomInit(identity);
});

vm.$on('loggedIn', ({ identity }) => {
  googleAnalyticsInit(identity);
});

vm.$on('loggedIn', ({ identity }) => {
  heapAnalyticsInit(identity);
});

vm.$on('loggedIn', ({ identity }) => {
  npsWidget(identity);
});

Vue.use(auth);
vm.waitForLogin();

Vue.use(LoggerPlugin, { logger });

vm.$mount('#app');
