<template>
  <img :src="icons[name].path" alt="" />
</template>

<script>
import icons from '../icons/iconBase'

export default {
  name: "Icon",

  props: {
    name: String
  },

  data() {
    return {
      icons: Object.freeze(icons),
    }
  }
}
</script>

<style scoped>

</style>
