<template>
  <tabbed-panel
    :tabs="[
        {
          name: 'Status dos transportes',
          component: tabs.transportationsStatus,
          props: { driverEta, stops }
        },
        {
          name: 'Informações da rota',
          component: tabs.orderDetails,
          props: { orderDetails }
        }
      ]"
    :sticky-header="true"
  />
</template>

<script>
import TransportationsStatusTab from "./TransportationsStatusTab.vue";
import OrderDetailsTab from "./OrderDetailsTab.vue";
import TabbedPanel from "../../../hounDS/components/TabbedPanel";

export default {
  name: "DetailsPanel",

  components: { TabbedPanel, TransportationsStatusTab, OrderDetailsTab },

  props: {
    stops: Array,
    orderDetails: Object,
    driverEta: Object
  },

  data: function () {
    return {
      tabs: {
        transportationsStatus: TransportationsStatusTab,
        orderDetails: OrderDetailsTab,
      },
    };
  },
}
</script>
