<template>
  <div class="orderPanel">
    <ShipmentRatingModal
      v-if="showShipmentRatingModal"
      @close="closeShipmentRatingModal"
      :tracking-code="shipmentDetails.trackingCode"
      :driver="shipmentDetails.driver"
      :shipment-rating.sync="shipmentDetails.rating"
      :questions="ratingQuestions"
    />

    <div class="orderPanel__header">
      <div class="trackingCode">{{ shipmentDetails.trackingCode }}</div>
    </div>

    <div class="orderPanel__body">
      <div v-if="shipmentDetails.status === 'CANCELED'" class="canceledOrder">
        <div class="canceledOrder__title">Entrega cancelada</div>
        <div class="canceledOrder__time">{{ formattedCanceledAt }}</div>
      </div>
      <div v-else>
        <timeline
          class="timeline"
          :elements="timelineElements"
        />
      </div>
    </div>

    <div class="updateInfoContainer">
      <Icon name="information" />
      <div class="updateInfo">Dados atualizados automaticamente</div>
    </div>

    <div class="inline-buttons-container margin-bottom-16">
      <button
        v-if="showShipmentRatingButton"
        class="button button--tertiary"
        @click="openShipmentRatingModal"
      >
        Avaliação da entrega
      </button>
      <a
        v-if="!hasUserIdentity"
        class="button button--tertiary"
        href="https://api.whatsapp.com/send?l=pt_br&phone=5511986024396"
      >
        Fale com a Box Delivery
      </a>
    </div>
  </div>
</template>

<script>
import Timeline from "@/hounDS/components/timeline/Timeline.vue";
import CenterScreenNotification from "@/hounDS/components/CenterScreenNotification.vue";
import { buildShipmentTimelineElements } from "./shipmentTimelineElementsFactory";
import Icon from "@/hounDS/components/Icon";
import Modal from "@/hounDS/components/Modal";
import { formatDate } from "@/common/filters/dateFormatter";
import ShipmentRatingModal from "./shipmentRating/ShipmentRatingModal";

export default {
  name: 'ShipmentSummaryPanel',

  components: { ShipmentRatingModal, Icon, CenterScreenNotification, Timeline, Modal },

  props: {
    shipmentDetails: Object,
  },

  data() {
    return {
      userOpenShipmentRatingModal: false,
    }
  },

  computed: {
    timelineElements() {
      const driverEta = this.shipmentDetails && this.shipmentDetails.deliveryStop ? this.shipmentDetails.deliveryStop.driverEta : null;

      return buildShipmentTimelineElements(this.shipmentDetails.timeline, driverEta);
    },

    formattedCanceledAt() {
      return formatDate(this.shipmentDetails.statusUpdatedAt, 'DD/MM [às] HH:mm');
    },

    showShipmentRatingModal() {
      return this.userOpenShipmentRatingModal;
    },

    showShipmentRatingButton() {
      return true;
      return this.shipmentDetails.rating || this.shipmentDetails.requestRating;
    },

    ratingQuestions() {
      return this.shipmentDetails?.ratingQuestions ?? [];
    },

    hasUserIdentity() {
      return this.$root.hasUserIdentity;
    },
  },

  methods: {
    closeShipmentRatingModal() {
      this.userOpenShipmentRatingModal = false;
    },

    openShipmentRatingModal() {
      this.userOpenShipmentRatingModal = true;
    }
  },

  mounted() {
    if (this.shipmentDetails.requestRating) {
      this.openShipmentRatingModal();
    } else {
      this.closeShipmentRatingModal();
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../hounDS/styles/global/colors';
@import '../../hounDS/styles/global/mediaQueries';
@import '@vuxx/hounds/scss/index';

.orderPanel {
  width: 100%;
}

.orderPanel__header {
  display: flex;
  flex-direction: row;
  margin-top: -4px;
  margin-right: -4px;
  margin-left: -4px;
  margin-bottom: 16px;

  @include for-large-devices {
    flex-wrap: wrap;
  }
}

.orderPanel__header > * {
  margin-top: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
}

.driverRating {
  padding: 10px 16px;
  font-size: 12px;
}

.trackingCode {
  font-size: 20px;
  font-weight: 500;
  margin-right: auto;

  @include for-large-devices {
    width: 100%;
  }
}

.whatsapp {
  text-align: center;
  padding: 8px 16px;
}

.canceledOrder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.canceledOrder__title {
  font-size: 16px;
  font-weight: 600;
}

.canceledOrder__time {
  font-size: 12px;
  letter-spacing: 0.02em;
  color: $gray800;
}

.timeline {
  width: 100%;
  height: 100%;
}

.updateInfoContainer {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

.updateInfo {
  font-style: italic;
  font-size: 12px;
  margin-left: 8px;
}

@media (max-width: 768px) {
  .orderPanel__body {
    background-color: $purple100;
    padding: 20px;
    border-radius: 8px;
  }

  .trackingCode {
    font-size: 20px;
    font-weight: 500;
    margin-right: 8px;
  }

  .canceledOrder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .canceledOrder__title {
    font-size: 16px;
    font-weight: 600;
  }

  .canceledOrder__time {
    font-size: 12px;
    letter-spacing: 0.02em;
    color: $gray800;
  }

  .updateInfoContainer {
    margin-bottom: 16px;
  }
}
</style>
