const chargeTypes = {
  OTHER: 99,
  WAITING_TIME: 101,
  DISTANCE: 102,
  CO_DRIVER: 103,
  TOLL: 104,
  RETURN: 105,
  PERNOITE: 107,
  REENTREGA: 108,
  AD_VALOREM: 110,
  CANCELAMENTO: 111,
  REEMBOLSO: 112,
  EXCEDENTE_DE_PESO: 113,
  ESTORNO_CANCELAMENTO: 114,
  ROTA_CIRCULAR: 115,
  DISCOUNT: 501,
  DESCARGA: 550,
};

const chargeTypeLabels = {
  [chargeTypes.OTHER]: 'Outros',
  [chargeTypes.WAITING_TIME]: 'Tempo de Espera',
  [chargeTypes.DISTANCE]: 'Distância',
  [chargeTypes.CO_DRIVER]: 'Ajudante(s)',
  [chargeTypes.TOLL]: 'Pedágio(s)',
  [chargeTypes.RETURN]: 'Devoluções',
  [chargeTypes.PERNOITE]: 'Pernoite',
  [chargeTypes.REENTREGA]: 'Reentrega',
  [chargeTypes.AD_VALOREM]: 'Ad valorem',
  [chargeTypes.CANCELAMENTO]: 'Taxa de cancelamento',
  [chargeTypes.REEMBOLSO]: 'Reembolso',
  [chargeTypes.EXCEDENTE_DE_PESO]: 'Excedente de peso',
  [chargeTypes.ESTORNO_CANCELAMENTO]: 'Estorno – Rota cancelada',
  [chargeTypes.ROTA_CIRCULAR]: 'Rota circular',
  [chargeTypes.DISCOUNT]: 'Desconto(s)',
  [chargeTypes.DESCARGA]: 'Descarga',

};

module.exports = { chargeTypeLabels, chargeTypes };
