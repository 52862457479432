<template>
  <div :class="tabs.section">
    <RichListItemWithIcon
      icon="locationPin"
      label="Endereço"
      :value="fullAddress"
    />

    <RichListItemWithIcon
      icon="barcodeRounded"
      label="Código do Pedido"
      :value="trackingCode"
    />

    <h4 v-if="driver" :class="[text.infoTab, text.heading]">Motorista</h4>

    <RichListItemWithIcon
      v-if="driver"
      icon="person"
      label="Motorista"
      :value="driverFullName"
    />

    <RichListItemWithIcon
      v-if="driver"
      icon="vehiclePlate"
      label="Placa"
      :value="driver.vehiclePlate"
    />
  </div>
</template>

<script>
import RichListItemWithIcon from "../../../hounDS/components/RichListItemWithIcon";
import tabs from "../../../hounDS/styles/tabs.module.scss"
import text from "../../../hounDS/styles/text.module.scss"
export default {
  name: "StopInfoTab",
  components: {RichListItemWithIcon},
  props: {
    trackingCode: String,
    stop: Object,
    driver: Object
  },

  computed: {
    tabs: () => Object.freeze(tabs),
    text: () => Object.freeze(text),

    fullAddress() {
      if (!this.stop) {
        return ''
      }

      return `${this.stop.addressThoroughfare}, ${this.stop.addressNumber}`
    },

    driverFullName() {
      if (!this.driver) {
        return ''
      }

      return `${this.driver.firstName} ${this.driver.lastName}`
    }
  }
}
</script>

