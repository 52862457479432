<template>
  <div class="spinnerWrapperContainer" :style="{ width: size, height: size }">
    <div class="spinnerWrapper">
      <div
        class="spinner"
        :style="{ width: size, height: size, backgroundImage: cssGradientBackground }"
      >
        <div
          class="spinner-before"
          :style="{ backgroundColor: hexColor }"/>
        <div
          class="spinner-after"
          :style="{ backgroundColor: hexBackgroundColor }"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MinimalSpinner',

  props: {
    size: {
      type: String,
      default: '90px'
    },
    hexColor: {
      type: String,
      default: '#28c0f1'
    },
    hexBackgroundColor: {
      type: String,
      default: '#ffffff'
    }
  },
  computed: {
    cssGradientBackground() {
      return 'linear-gradient(to right, ' + this.hexColor + ' 10%, ' + this.hexColor + '00 42%)';
    }
  }
}
</script>

<style scoped>
  .spinnerWrapperContainer {
    display: inline-block;
    position: relative;
    overflow: hidden;
  }

  .spinnerWrapper {
    position: absolute;
    top: 0;
    left: 0;
  }

  .spinner {
    border-radius: 50%;
    position: relative;
    animation: spinner-animation 1.4s infinite linear;
    transform: translateZ(0);
  }

  .spinner-before {
    width: 50%;
    height: 50%;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  .spinner-after {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @keyframes spinner-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
