import axios from 'axios';
import { scheduleTypes } from '../common/ScheduleTypes';
import * as spotRequestErrors from './spotRequestErrors';
import { errorMessages } from './spotRequestErrorMessages';

export default async function finishRequest(newSpotCommand) {
  try {
    const response = await axios.post('/jade/api/spots', newSpotCommand);
    return { spotId: response.data.id };
  } catch (err) {
    throw parseAPIError(err);
  }
}

function translateAPIErrors(response) {
  const errorKeys = Object.keys(errorMessages);
  const responseErrors = response.data.reasons;
  const translatedErrors = [];

  errorKeys.forEach((error) => {
    if (error in responseErrors) {
      translatedErrors.push(errorMessages[error]);
    }
  });

  return { ...response.data, messages: translatedErrors };
}

function parseAPIError(error) {
  const errors = spotRequestErrors;

  const baseError = {
    req: {
      data: error.config.data,
    },
    status: (error.response && error.response.status) || 500,
  };

  if (!error.response) {
    return { ...baseError, type: errors.NetworkError };
  }
  if (!error.response.data) {
    return { ...baseError, type: errors.UnknownError };
  }

  const errorInfo = error.response.data;

  if (error.response.status === 422) {
    if (errorInfo.errorCode === errors.EstimateNoLongerValid) {
      return {
        ...baseError,
        type: errors.EstimateNoLongerValid,
        maxTimespanBetweenSpotEstimateAndRequestInMinutes: errorInfo.maxTimespanBetweenSpotEstimateAndRequestInMinutes,
      };
    }

    return { ...baseError, type: errors.ValidationError, ...translateAPIErrors(error.response) };
  }

  if (errorInfo.errorCode === errors.MissingFinancialInfo) {
    return { ...baseError, type: errors.MissingFinancialInfo, status: 400 };
  }

  if (errorInfo.errorCode === errors.BlockedCustomer) {
    return { ...baseError, type: errors.BlockedCustomer, status: 403 };
  }


  return { ...baseError, type: errors.UnknownError, status: 500 };
}

export function merchandiseToSpotDocument(merchandise) {
  return {
    ...spotDocumentDefaults(),
    ...((merchandise.fiscalDocument)
      ? hydrateSpotFiscalDocument(merchandise)
      : hydrateSpotDocument(merchandise)
    ),
  };
}

export function formatScheduledFor(scheduling) {
  // This is not time zone aware. Need to be careful when supporting clients
  // outside our time zone.
  return scheduling.type !== scheduleTypes.NOW && scheduling.dateTime
    ? scheduling.dateTime.format('Y-MM-DD HH:mm:ss')
    : null;
}

const spotDocumentDefaults = () => ({
  addressee: null,
});

const hydrateTimeWindow = (merchandise) => {
  let { start, end } = merchandise.timeWindow;
  if (!start || !end) {
    start = '';
    end = '';
  }
  return { start, end };
};

const hydrateSpotDocument = (merchandise) => {
  return {
    code: merchandise.trackingCode,
    address: hydrateAddress(merchandise.address),
    geolocation: merchandise.address.geolocation,
    timeWindow: hydrateTimeWindow(merchandise),
    instructions: merchandise.instructions,
    trackingRecipients: merchandise.trackingRecipients || [],
  };
};

const hydrateAddress = ({
  thoroughfare, number, postalCode, neighborhood, locality, state, complement,
}) => ({
  thoroughfare,
  number,
  postalCode,
  neighborhood,
  locality,
  state,
  complement,
});

const hydrateSpotFiscalDocument = (merchandise) => {
  const { addressee, cargoMeasurements } = merchandise.fiscalDocument;
  return {
    accessKey: merchandise.fiscalDocument.accessKey,
    addressee: {
      name: addressee.name,
      phoneNumber: addressee.phoneNumber,
      isLegalPerson: addressee.isLegalPerson,
      nationalDocumentNumber: addressee.nationalDocument,
    },
    cargoMeasurements: cargoMeasurements,
    ...hydrateSpotDocument(merchandise),
  };
};
