<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="{ required: true }"
    :name="'tipo de carroceria'"
    required
  >
    <div
      v-if="selectedVehicle.possibleBodyTypes && selectedVehicle.possibleBodyTypes.length"
      class="main-form"
    >
      <span
        class="main-form__label"
        for="total-weight"
      >
        Tipos de carroceria *
      </span>
      <div class="main-form__inline-group">
        <template v-for="vehicleBodyType in selectedVehicle.possibleBodyTypes">
          <label
            :key="vehicleBodyType.id"
            :for="vehicleBodyType.id"
            class="main-form__checkbox-container"
          >
            <input
              :id="vehicleBodyType.id"
              v-model="selectedVehicleBodyTypesArray"
              :name="vehicleBodyType.id"
              :value="vehicleBodyType"
              type="checkbox"
              class="main-form__input main-form__input--checkbox"
              @change="updateSelectedVehicleBodyTypes"
            >
            <span class="main-form__checkbox-button">
              {{ vehicleBodyType.name }}
            </span>
          </label>
        </template>
        <span
          v-if="errors[0]"
          class="main-form__info main-form__info--error"
        >
          {{ errors[0] }}
        </span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, localize, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';

extend('required', required);

localize('pt_BR', pt_BR);

export default {
  name: 'VehicleBodyType',

  components: {
    ValidationProvider,
  },

  data() {
    return {
      selectedVehicleBodyTypesArray: this.$store.state.spotRequest.selectedVehicleBodyTypes,
      checkedNames: null,
    };
  },

  computed: {
    ...mapState('spotRequest', ['selectedVehicle']),
  },

  methods: {
    updateSelectedVehicleBodyTypes() {
      this.$store.commit('spotRequest/updateSelectedVehicleBodyTypes', this.selectedVehicleBodyTypesArray);
    },
  },
};
</script>

<style lang="scss">
  .main-form__label {
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .main-form__checkbox-container {
    position: relative;
  }

  .main-form__checkbox-button {
    display: flex;
    align-items: center;
    padding-top: 0.625em;
    padding-right: 0.625em;
    padding-bottom: 0.625em;
    padding-left: 2.2em;
    border: 1px solid #dee2e6;
    transition: border-color 0.3s;
    border-radius: 8px;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 0.5em);
      left: 0.625em;
      width: 1em;
      height: 1em;
      border-radius: 4px;
      border: 1px solid #dee2e6;
      background-image: url('/jade/public/icon-check-alt.svg');
      background-size: 0;
      transition: background-color 0.3s;
    }
  }

  .main-form__input--checkbox:focus + .main-form__checkbox-button {
    border-color: #f6be01;
    box-shadow: 0px 0px 0px 2px rgba(133, 32, 187, 0.16);
  }

  .main-form__input--checkbox:checked + .main-form__checkbox-button {
    border-color: #f6be01;

    &::before {
      color: white;
      background-color: #f6be01;
      background-size: cover;
    }
  }

  .main-form__input--checkbox {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .main-form__inline-group {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .main-form__info {
    font-size: 14px;
  }

  .main-form__info-error {
    color: #e53935;
  }

</style>
