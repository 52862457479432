<template>
  <base-layout
    :add-container="false"
  >
    <page-header title="Perfil do usuário" />
    <div class="pageBody">
      <div class="userPreferences">
        <email-reports></email-reports>
        <change-password></change-password>
      </div>
    </div>
  </base-layout>
</template>

<script>
  import PageHeader from "../common/PageHeader.vue";
  import EmailReports from "./EmailReports.vue";
  import ChangePassword from "./ChangePassword.vue";
  import BaseLayout from "../common/BaseLayout.vue";

  export default {
    name: "ProfilePage",
    components: {
      BaseLayout,
      ChangePassword,
      EmailReports,
      PageHeader,
    },
  }
</script>

<style scoped>
  .content {
    margin-left: 240px;
    background: #f3f3f3;
    height: 100vh;
  }

  .content .pageHeader {
    background: #e8e8e8!important;
  }

  .content h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
  }

  .userPreferences {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
    min-width: 320px;
  }

  .pageBody {
    padding: 20px 20px 30px 0;
    display: flex;
    flex-direction: row;
    flex-direction: column;
    align-items: flex-end;
    background-color: #f3f3f3;
  }

</style>
