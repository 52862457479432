<template>
  <div class="spotRequest-merchandises">
    <merchandise-input
      class="panel spotRequest-merchandises__input"
      :service-type="selectedServiceType"
      :warehouse="warehouse"
      @merchandises-input="onMerchandisesInput"
      @add-merchandise-manually-requested="addMerchandiseManuallyRequested"
      @update:service-type="onServiceTypeChanged"
      @on-warehouse-selected="onWarehouseSelected"
      @show-fiscal-documents-upload-modal="showFiscalDocumentsUploadModal"
    />

    <div v-if="showMerchandiseList" class="panel spotRequest-merchandises__listWrapper">
      <h2 class="spotRequest-sectionTitle spotRequest-merchandises__listSectionTitle">Detalhes do serviço</h2>

      <template v-if="isSuperBatch">
        <merchandise-list
          v-for="(merchandises, externalId) in merchandisesByExternalId "
          v-bind:key="externalId"
          :title="buildTitleForMerchandisesByExternalId(merchandises)"
          :merchandises="merchandises"
          :read-only=false
          :service-type="selectedServiceType"
          allowAddTimeWindow
          @merchandise-remove-requested="onMerchandiseRemoveRequested"
          @merchandise-edit-requested="onMerchandiseEditRequested"
          @on-merchandise-add-time-window="onMerchandiseAddTimeWindow"
        />
      </template>
      <template v-else>
        <merchandise-list
          :merchandises="merchandises"
          :read-only=false
          :service-type="selectedServiceType"
          allowAddTimeWindow
          @merchandise-remove-requested="onMerchandiseRemoveRequested"
          @merchandise-edit-requested="onMerchandiseEditRequested"
          @on-merchandise-add-time-window="onMerchandiseAddTimeWindow"
        />
      </template>

    </div>

    <round-trip
      v-if="canRequestRoundTrip"
      class="panel spotRequest-roundTripOption"
      :round-trip="roundTrip"
      :is-round-trip="isRoundTrip"
      @update:roundTrip="roundTripUpdated"
      @update:isRoundTrip="isRoundTripUpdated"
    ></round-trip>

    <fiscal-documents-upload-modal
      v-if="fiscalDocumentsUploadModalVisible"
      ref="fiscalDocumentsUploadModal"
      :merchandises="merchandises"
      :service-type="serviceType"
      @close="closeFiscalDocumentsUploadModal"
      @merchandise-remove-requested="onMerchandiseRemoveRequested"
      @merchandise-edit-requested="onMerchandiseEditRequested"
      @merchandise-from-fiscal-document-created="onMerchandiseFromFiscalDocumentCreated"
    />

    <merchandise-manual-input-modal
      v-if="manualMerchandiseInputVisible"
      :merchandise-to-edit="merchandiseToEdit"
      :merchandises="merchandises"
      :shipment-tracking-sharing-enabled="shipmentTrackingSharingEnabled"
      @cancel="onMerchandiseDetailsModalClose"
      @submit="onMerchandiseDetailsModalSubmit"
    ></merchandise-manual-input-modal>

    <div class="horizontalSeparator spotRequest-merchandises__horizontalSeparator"></div>

    <spot-batch-request-result
      v-if="isSuperBatch && requestedBatchSpotsCount > 0"
      ref="spotBatchRequestResult"
    />

    <button
      class="baseButton submitButton spotRequest-merchandises__pickVehicleButton"
      v-if="!isSuperBatch"
      :disabled="disableNextStep"
      @click="onNextClicked"
    >
      Escolher veículo
    </button>

    <button
      class="baseButton submitButton spotRequest-merchandises__pickVehicleButton"
      v-if="isSuperBatch"
      @click="onSuperBatchRequested"
    >
      Solicitar em Lote
    </button>
  </div>
</template>

<script>

import './style.css'
import PageHeader from '../../common/PageHeader.vue';
import MerchandiseInput from './MerchandiseInput.vue';
import MerchandiseList from './MerchandiseList.vue';
import MerchandiseManualInputModal from './MerchandiseManualInputModal.vue';
import SpotBatchRequestResult from "@/spot/spotRequest/SpotBatchRequestResult";
import addMerchandises, {
  errorTypes,
  loadedState,
  newMerchandiseFromProductCodeAndServiceType
} from './addMerchandises';
import deepClone from '../../common/deepClone';
import FiscalDocumentsUploadModal from './FiscalDocumentsUploadModal.vue';
import RoundTrip from './RoundTrip.vue';
import { serviceTypes } from "../common/serviceTypes";
import { weightFormat } from "@/common/filters/numberFormatters";
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import groupBy from "lodash.groupby";

export default {
  name: "Merchandises",

  components: {
    RoundTrip,
    MerchandiseInput,
    MerchandiseList,
    PageHeader,
    FiscalDocumentsUploadModal,
    MerchandiseManualInputModal,
    SpotBatchRequestResult,
  },

  props: {
    merchandises: Array,
    serviceType: String,
    warehouse: Object,
    roundTrip: Object,
    isRoundTrip: Boolean,
    shipmentTrackingSharingEnabled: Boolean,
  },

  data() {
    return {
      selectedServiceType: this.serviceType,
      addCustomMerchandiseModalVisible: false,
      manualMerchandiseInputVisible: false,
      fiscalDocumentsUploadModalVisible: false,
      merchandiseToEdit: null,
      existsMerchandiseCodeTooLong: false,
    }
  },

  computed: {
    disableNextStep() {
      return (
        !this.$store.getters['spotRequest/hasValidScheduling']
        || this.merchandises.length === 0 || this.hasAnyMerchandiseWithError
        || this.merchandises.some(m => m.trackingCodeTooLong)
        || !this.warehouse
      );
    },

    hasAnyMerchandiseWithError() {
      return this.merchandises.some(m => !m.status.ok);
    },

    showMerchandiseList() {
      return this.merchandises.length > 0;
    },

    canRequestRoundTrip() {
      return this.showMerchandiseList && this.serviceType === serviceTypes.DELIVERY;
    },

    inativo() {
      const blockDetails = this.$store.state['spotRequest/config']?.block_details;
      return blockDetails?.code === 'Inatividade';
    },

    // SUPER BATCH

    ...mapGetters('spotBatchRequest', [
      'isSuperBatch',
      'batchSpotsCount',
      'requestedBatchSpotsCount',
      'succeededBatchSpots',
      'succeededBatchSpotsCount',
      'failedBatchSpots',
      'failedBatchSpotsCount',
    ]),

    merchandisesByExternalId() {
      return groupBy(this.merchandises, merchandise => merchandise.externalId);
    },

    merchandisesByExternalIdCount() {
      return Object.values(this.merchandisesByExternalId).length;
    },

    canRunSuperBatch() {
      return this.merchandises.length > 0 && ! this.hasAnyMerchandiseWithError;
    },
  },

  methods: {
    batchInputErrorHandler(error, {input}) {
      console.log('Batch mode error:', error, input);
    },

    interactiveInputErrorHandler(error, {input}) {
      if (error.type === errorTypes.NoEnoughData) {
        // TODO: This is not really an error. Think about a better flow and refactor this
        const merchandise = newMerchandiseFromProductCodeAndServiceType(input.productCode, this.selectedServiceType);
        this.addMerchandiseWithManualInput(merchandise);
      } else if (error.type === errorTypes.DuplicateProductCode) {
        this.$emit('show-message', {
          title: 'Código repetido',
          message: `Este código já está na lista, na posição ${error.index + 1}`
        });
      } else if (error.type === errorTypes.InvalidProductCode) {
        this.$emit('show-message', {
          title: 'Código inválido',
          message:
            '<p>O código deve conter apenas números, letras (não acentuadas) e/ou os caracteres:</p>' +
            '<pre>- * @ _</pre>'
        })
      } else {
        console.log('Interactive mode error:', error, input);
      }
    },

    async onMerchandisesInput({merchandises, isSuperBatch = false}) {
      if (isSuperBatch) {
        merchandises = await this.completeSuperBatchMerchandises(merchandises);
      }

      const interactionMode = (merchandises.length > 1) ? 'batch' : 'interactive';

      const onError = (
        ((interactionMode === 'batch') ? this.batchInputErrorHandler : this.interactiveInputErrorHandler)
          .bind(this)
      );

      const addOrUpdateMerchandise = this.addOrUpdateMerchandise.bind(this);
      const updateMerchandise = this.updateMerchandise.bind(this);

      const allMerchandises = this.merchandises;

      addMerchandises(merchandises, { allMerchandises, addMerchandise: addOrUpdateMerchandise, updateMerchandise, onError });
    },

    addMerchandiseManuallyRequested() {
      const newMerchandise = newMerchandiseFromProductCodeAndServiceType('', this.selectedServiceType);
      this.openMerchandiseManualInputModal(newMerchandise);
    },

    addOrUpdateMerchandise(merchandise) {
      this.$emit('merchandise-added-or-updated', merchandise);
    },

    openMerchandiseManualInputModal(merchandise) {
      this.merchandiseToEdit = deepClone(merchandise);
      this.manualMerchandiseInputVisible = true;
    },

    addMerchandiseWithManualInput(merchandise) {
      this.openMerchandiseManualInputModal(merchandise);
    },

    onMerchandiseEditRequested(merchandise) {
      this.openMerchandiseManualInputModal(merchandise);
    },

    onMerchandiseFromFiscalDocumentCreated(merchandise) {
      this.addOrUpdateMerchandise(merchandise);
    },

    closeMerchandiseDetailsModal() {
      this.manualMerchandiseInputVisible = false;
    },

    onMerchandiseDetailsModalClose() {
      this.closeMerchandiseDetailsModal();
    },

    onMerchandiseDetailsModalSubmit(editedMerchandise) {
      this.addOrUpdateMerchandise({
        ...editedMerchandise,
        status: loadedState(),
      });
      this.closeMerchandiseDetailsModal();
    },

    updateMerchandise(merchandise) {
      this.$emit('merchandise-updated', merchandise);
    },

    onMerchandiseRemoveRequested(merchandise) {
      this.$emit('merchandise-removed', merchandise);
    },

    onServiceTypeChanged(serviceType) {
      this.selectedServiceType = serviceType;
      this.$emit('update:service-type', this.selectedServiceType);
    },

    onNextClicked() {
      this.$emit('on-next', 'merchandise', { serviceType: this.selectedServiceType });
    },

    onWarehouseSelected(warehouse) {
      this.$emit('on-warehouse-selected', warehouse);
    },

    showFiscalDocumentsUploadModal() {
      this.fiscalDocumentsUploadModalVisible = true;
      // 1. We can't programmatically open the file dialog at `mounted` life cycle of `fiscalDocumentsUploadModal`
      // component. Browsers allow to open a file dialog only through user interaction (eg. click event)!
      // 2. `setTimeout` guarantees that `fiscalDocumentsUploadModal` component exists.
      setTimeout(() => { this.$refs.fiscalDocumentsUploadModal.openFileDialog(); }, 0);
    },

    closeFiscalDocumentsUploadModal() {
      this.fiscalDocumentsUploadModalVisible = false;
    },

    onMerchandiseAddTimeWindow(merchandise, start, end) {
      this.$emit('on-merchandise-add-time-window', merchandise, start, end);
    },

    roundTripUpdated(roundTrip) {
      this.$emit('update:roundTrip', roundTrip);
    },

    isRoundTripUpdated(isRoundTrip){
      this.$emit('update:isRoundTrip', isRoundTrip);
    },

    // SUPER BATCH

    async completeSuperBatchMerchandises(merchandises) {
      if (this.$root.userInfo.customerId !== 0) {
        alert('Cliente não autorizado a fazer solicitações em lote.');
        throw Error('Spot batch request not authorized for this customer.');
      }

      let completedMerchandises;
      await Swal.fire({
        title: 'Completando os dados...',
        confirmButtonColor: '#d14529',
        allowOutsideClick: false,
        didOpen: async () => {
          Swal.showLoading();

          try {
            completedMerchandises = await this.$store.dispatch('spotBatchRequest/completeMerchandises', merchandises);
          } catch (e) {
            if (! e.hasValidationErrors) {
              throw e;
            }

            await Swal.fire({
              icon: 'error',
              html: `<h2>${e.validationErrors.title}</h2>` +
                `<pre>${e.validationErrors.message}</pre>`,
            });
          }

          Swal.close();
        },
      });

      await Swal.fire({
        icon: 'success',
        title: 'Dados importados com sucesso!',
      });

      this.$store.commit('spotBatchRequest/changeToSuperBatchMode');

      return completedMerchandises;
    },

    buildTitleForMerchandisesByExternalId(merchandises) {
      const {
        customerId,
        externalId,
        scheduledFor,
        vehicleType,
        userEmail,
        driverAssistantsCount,
        warehouseName,
        declaredDistanceInKm,
      } = merchandises[0];

      const totalWeight = merchandises.reduce(
        (total, merchandise) => total + parseFloat(merchandise.weight),
        0
      );

      const declaredDistanceText = declaredDistanceInKm ? `${declaredDistanceInKm} km` : 'não declarada';

      return `${externalId} - ` +
        `Usuário: ${userEmail} - ` +
        `Cliente: ${customerId} - ` +
        `Armazém: ${warehouseName} - ` +
        `Agendamento: ${scheduledFor} - ` +
        `Veículo: ${vehicleType} - ` +
        `Ajudantes: ${driverAssistantsCount} - ` +
        `Peso: ${weightFormat(totalWeight)} kg - ` +
        `Distância: ${declaredDistanceText}`;
    },

    async onSuperBatchRequested() {
      if (! this.canRunSuperBatch) {
        Swal.fire({
          icon: 'warning',
          title: 'Não é possível solicitar!',
          text: 'Alguma remessa está com problema.',
        });
        return;
      }

      const response = await Swal.fire({
        title: 'Solicitação de rotas.',
        text: `${this.merchandisesByExternalIdCount} rotas serão solicitadas. Tem certeza?`,
        showConfirmButton: true,
        confirmButtonText: 'Sim',
        showCancelButton: true,
        cancelButtonText: 'Não',
      });

      if (! response.isConfirmed) {
        return;
      }

      const updateProgress = ({ type }) => {
        if (type !== 'spotBatchRequest/requestSpot') {
          return;
        }

        Swal.update({
          text: `${this.requestedBatchSpotsCount} de ${this.batchSpotsCount} rotas foram solicitadas. ` +
            `${this.failedBatchSpotsCount} falharam.`,
        });
        Swal.showLoading();
      };

      const unsubscribeAction = this.$store.subscribeAction({
        before: updateProgress,
        after: updateProgress,
      });

      await Swal.fire({
        title: 'Solicitando rotas...',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: async () => {
          await this.$store.dispatch('spotBatchRequest/runSuperBatchRequest', this.merchandises);
          Swal.close();
        },
      });

      unsubscribeAction();

      let icon = this.failedBatchSpotsCount > 0 ? 'warning' : 'success';

      const goToMonitorPage = await Swal.fire({
        html: this.$refs.spotBatchRequestResult.$el.innerHTML,
        icon,

        showConfirmButton: true,
        confirmButtonText: 'Ir para a tela de monitoramento',
        showCancelButton: true,
        cancelButtonText: 'Continuar na página',
      });

      if (goToMonitorPage.isConfirmed) {
        window.location.assign(`/pedidos/monitoramento`);
      }
    },
  },
}
</script>

<style scoped>
  .spotRequest-merchandises {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Source Sans Pro", sans-serif;
  }

  .spotRequest-merchandises__input {
    margin: 30px 20px 0 20px;
  }

  .spotRequest-merchandises-modal {
    position: fixed;
    z-index: 1000;
  }

  .spotRequest-merchandises-modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spotRequest-merchandises-modal__content {
    width: 560px;

    background-color: #ffffff;
    max-height: 80%;
    overflow: auto;

    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e7e7e7;
  }

  .spotRequest-merchandises__horizontalSeparator {
    margin: 27px 20px 0 20px;
  }

  .spotRequest-merchandises__pickVehicleButton {
    align-self: flex-end;
    margin: 20px 20px 30px 0;
  }

  .spotRequest-merchandises__listWrapper {
    padding: 24px 20px 16px 20px;
    margin: 24px 20px 0 20px;
    display: flex;
    flex-direction: column;
  }

  .spotRequest-merchandises__listSectionTitle {
    margin-bottom: 12px;
  }

  .spotRequest-roundTripOption {
    margin: 24px 20px 0px 20px;
  }

</style>
