<template>
  <div class="pageHeader">
    <label class="pageHeader__step" v-if="step">{{ step }}</label>
    <label class="pageHeader__title">{{ title }}</label>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',

  props: {
    step: {type: String, default: ''},
    title: {type: String, required: true},
  },
}
</script>

<style scoped>
  .pageHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    height: 80px;
    background-color: #e8e8e8;
  }

  .pageHeader__step {
    display: block;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #717171;
  }

  .pageHeader__title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #2f2f2f;
  }
</style>
