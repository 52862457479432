<template>
    <div class="common-dateTimePicker">
      <vue-ctk-date-time-picker
        class="common-dateTimePicker__input"
        label="Escolha uma data"
        hint="Data"
        locale="pt-br"
        format="YYYY-MM-DD"
        formatted="DD/MM/YYYY"
        v-model="selectedDate"
        :min-date="minDate"
        :disabled="dateDisabled"
        without-header
        disable-time
      ></vue-ctk-date-time-picker>
      <vue-ctk-date-time-picker
        class="common-dateTimePicker__input"
        label="Escolha um horário"
        locale="pt-br"
        format="HH:mm"
        formatted="HH:mm"
        time-format="HH:mm"
        :minute-interval=10
        v-model="selectedTime"
        :disabled="timeDisabled"
        :error-hint="shouldShowTimeInputError"
        :hint="timeHint"
        without-header
        disable-date
      ></vue-ctk-date-time-picker>
    </div>
</template>

<script>
import moment from 'moment';

function getDatetime(date, time) {
  return moment(date + 'T' + time);
}

export default {
  name: 'DateTimePicker',

  data() {
    return {
      selectedDate: '',
      selectedTime: '',
    }
  },

  props: {
    value: '',
    dateDisabled: false,
    timeDisabled: false,
    minFeasibleTimeMinutesIntoFuture: Number,
  },

  computed: {
    minDate() {
      return moment(moment.now()).format('YYYY-MM-DD');
    },

    assertPickupTimeIsFeasible() {
      let dateTime = getDatetime(this.selectedDate, this.selectedTime);
      let nowDate = moment(moment.now()).add(this.minFeasibleTimeMinutesIntoFuture, 'm');

      return dateTime < nowDate;
    },

    hasDateTimeValue() {
      return this.selectedDate && this.selectedTime;
    },

    hasValidDateTimeValue() {
      return this.hasDateTimeValue && !this.assertPickupTimeIsFeasible;
    },

    shouldShowTimeInputError() {
      if (this.hasDateTimeValue) {
        return this.assertPickupTimeIsFeasible;
      }

      return false;
    },

    timeHint() {
      if (this.shouldShowTimeInputError) {
        return 'Escolha um horário no futuro';
      }

      return 'Horário';
    },
  },

  methods: {
    updateBoundDateTimeValue() {
      if (this.hasValidDateTimeValue) {
        this.$emit('input', getDatetime(this.selectedDate, this.selectedTime));
      }
      else {
        this.$emit('input', '');
      }
    },

    updateSelectedDateTimeFromValue() {
      if (!this.value) {
        return;
      }
      this.selectedDate = this.value.format('YYYY-MM-DD');
      this.selectedTime = this.value.format('HH:mm')
    },
  },

  watch: {
    value() {
      this.updateSelectedDateTimeFromValue();
    },

    selectedDate() {
      this.updateBoundDateTimeValue();
    },

    selectedTime() {
      this.updateBoundDateTimeValue();
    },
  },

  mounted() {
    this.updateSelectedDateTimeFromValue();
  }
}
</script>

<style scoped>
  @import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.min.css';

  .ctk-date-time-picker::v-deep .datepicker {
    display: block;
  }

  .common-dateTimePicker {
    display: flex;
  }

  .common-dateTimePicker__input {
    margin-left: 10px;
  }
</style>
