import StopInfoWindow from '../map/StopInfoWindow.vue';
import DriverInfoWindow from '../map/DriverInfoWindow.vue';
import markers from '../map/markers';

function translateStopToMarker(stop, trackingCode, isComplete) {
  const marker = isComplete ? markers.shipment.done : markers.shipment.default;
  return {
    icon: marker,
    position: {
      lat: stop.location.latitude,
      lng: stop.location.longitude,
    },
    infoWindow: {
      component: StopInfoWindow,
      props: {
        // TODO: Maybe review what StopInfoWindow needs
        driverEta: getDriverEta(stop.driverEta),
        stopId: 1,
        address: fullAddress(stop),
        trackingCode,
      },
    },
  };
}

function getDriverEta(driverEta) {
  if (!driverEta) {
    return null;
  }
  return {
    nextStopId: 1,
    estimatedArrival: driverEta,
  };
}

function fullAddress(stop) {
  if (!stop) {
    return '';
  }

  return `${stop.addressThoroughfare}, ${stop.addressNumber}`;
}

function buildDriverMarker(driverLocation) {
  return {
    icon: markers.driver,
    position: {
      lat: driverLocation.latitude,
      lng: driverLocation.longitude,
    },
    infoWindow: {
      component: DriverInfoWindow,
      props: {
        content: 'Motorista',
      },
    },
  };
}

function buildPodMarker(podLocation) {
  return {
    icon: markers.warehouse.default,
    position: {
      lat: podLocation.latitude,
      lng: podLocation.longitude,
    },
    infoWindow: {
      // TODO: add some text on info box
      component: null,
    },
  };
}

function buildShipmentMapMarkers({
  deliveryStop, trackingCode, driver, pod,
}) {
  const markers = [];

  if (deliveryStop) {
    markers.push(translateStopToMarker(deliveryStop, trackingCode, pod !== null));
  }

  if (driver && driver.location) {
    markers.push(buildDriverMarker(driver.location));
  }

  if (pod && pod.location) {
    markers.push(buildPodMarker(pod.location));
  }

  return markers;
}

export { buildShipmentMapMarkers };
