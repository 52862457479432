<template>
  <div
    class="commonModal"
    @keyup.esc="close"
    @keyup.enter="recalculate"
    tabindex="0"
  >
    <div class="commonModal__backdrop">
      <div class="estimateErrorModal__content" role="dialog">
        <div class="estimateErrorModal__title">{{ title }}</div>
        <div class="estimateErrorModal__message" v-html="message">

        </div>
        <div class="horizontalSeparator"></div>
        <div class="estimateErrorModal__buttons">
          <button
            class="baseButton cancelButton estimateErrorModal__cancelButton"
            @click="close"
          >Cancelar</button>
          <button
            class="baseButton submitButton estimateErrorModal__recalculateButton"
            @click="recalculate"
          >Recalcular</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  name: 'EstimateErrorModal',

  components: {Spinner},

  props: {
    title: '',
    message: '',
    visible: false
  },

  methods: {
    close() {
      this.$emit('close');
    },

    recalculate() {
      this.$emit('recalculate');
    },
  },

  mounted() {
    this.$el.focus();
  }
}
</script>

<style scoped>
  .commonModal {
    position: fixed;
    z-index: 1000;
  }

  .commonModal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .estimateErrorModal__content {
    width: 510px;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    padding: 24px 28px;
    display: flex;
    flex-direction: column;
  }

  .estimateErrorModal__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #2f2f2f;
  }

  .estimateErrorModal__message {
    margin-top: 16px;
    margin-bottom: 28px;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .estimateErrorModal__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .estimateErrorModal__buttons > :not(:last-child) {
    margin-right: 20px;
  }
</style>
