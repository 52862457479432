<template>
  <div class="sidebar-left">
    <div class="logo">
      <a href="/inicio">
        <img src="/jade/public/sidebar-logo-vuxx.svg"></a>
    </div>
    <ul class="nav side-navigation">
      <li>
        <router-link
          class="sidebar-item sidebar-spotRequest"
          active-class="sidebar-spotRequest__active"
          to="/spots/solicitar"
        >
          <i class="item-icon fa fa-truck" />
          <span>Solicitar veículo (spot)</span>
        </router-link>
      </li>
      <li>
        <a
          href="/pedidos/monitoramento"
          class="sidebar-item"
        ><i class="item-icon fa icon-speedometer" /><span>Monitoramento</span></a>
      </li>
      <li>
        <a
          href="/spots"
          class="sidebar-item"
        ><i class="item-icon fa fa-truck" /><span>Histórico</span></a>
      </li>
      <li>
        <a
          href="/comprovantes"
          class="sidebar-item"
        ><i class="item-icon fa  fa-file-text-o" /><span>Comprovantes</span></a>
      </li>
      <li>
        <router-link
          class="sidebar-item"
          active-class="sidebar-item__active"
          to="/extrato"
        >
          <i class="item-icon fa icon-login" /><span>Extrato de Solicitações</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { fiscalDocumentsUploadEnabled } from '@/client/runtimeConfig';
export default {
  name: 'Sidebar',

  computed: {
    customerId() {
      return this.$root.userInfo.customerId;
    },
    showDashboard() {
      const allowedCustomerIds = [
        0, // vuxx 🤘
        3154, // pão de acuçar/GPA
      ];
      return allowedCustomerIds.includes(this.customerId);
    },
    fiscalDocumentsUploadMenuItemEnabled() {
      return fiscalDocumentsUploadEnabled;
    },
  },
};
</script>

<style scoped>
  .sidebar-left {
    background-color: #2d2d2d;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    box-sizing: border-box;
    width: 240px;
  }

  .logo {
    height: 60px;
    line-height: 60px;
    padding-left: 20px;
    background: #404040;
  }

  .logo a {
    color: #fff;
    font-size: 22px;
    text-decoration: none;
  }

  .logo img {
    width: 106px;
    height: 100%;
    margin: 2.4px 0 0 2.2px;
  }

  .side-navigation {
    margin-top: 1px;
    padding-left: 0;
    list-style: none;
  }

  .sidebar-item {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 15px 20px;
  }

  .sidebar-item:focus, .sidebar-item:hover, .sidebar-item__active {
    transition: 0.2s linear;
    background-color: #525252;
  }

  .item-icon {
    font-size: 14px;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 3px;
    width: 16px;
    text-align: center;
  }

  .sidebar-spotRequest {
    background-color: #1FABD8;
  }

  .sidebar-spotRequest:focus, .sidebar-spotRequest:hover, .sidebar-spotRequest__active {
    background-color: #019BCD;
  }
  </style>
