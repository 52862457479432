const uiConfig = {
  'MOTO': {
    color: '#328df8',
    watermarkText: 'moto',
  },
  'PASSEIO': {
    color: '#00C853',
  },
  'FIORINO': {
    color: '#e53258',
    shortName: 'Util. Pequeno',
    watermarkText: 'Fiorino',
  },
  'VAN': {
    color: '#ffc634',
    watermarkText: 'Ducato',
  },
  'HR': {
    color: '#8849f8',
    watermarkText: 'HR',
  },
  'IVECO': {
    color: '#cb30dd',
    watermarkText: 'Iveco',
  },
  '3/4': {
    color: '#80dd30',
    watermarkText: '3/4',
  },
  'TOCO': {
    color: '#39D7DC',
    watermarkText: 'Toco',
  },
  'TRUCK': {
    color: '#F0851F',
    watermarkText: 'Truck',
  },
  'BITRUCK': {
    color: '#F0851F',
    watermarkText: 'Bi-Truck',
  },
  'CARRETA': {
    color: '#85F01F',
    watermarkText: 'Carreta',
  },
};

/**
 * @param {VehicleType} vehicle
 * @return UiVehicleType
 */
export function makeUiVehicle(vehicle) {
  const normalized = { ...vehicle, ...uiConfig[vehicle.id] };
  if (!normalized.shortName) {
    normalized.shortName = normalized.name;
  }

  return normalized;
}
