<template>
  <div :class="style.panel">
    <slot></slot>
  </div>
</template>

<script>
  import style from '../styles/panels.module.scss';

  export default {
    name: "Panel",

    computed: {
      style: () => Object.freeze(style)
    },
  }
</script>
