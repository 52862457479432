const config = window._vuxx_env;

export const googleMapsKey = config.googleMapsKey;
export const fiscalDocumentsUploadEnabled = config.fiscalDocumentsUploadEnabled;
export const intercomAppId = config.intercomAppId;
export const customerUrl = config.customerUrl;
export const gaDimension_userAudienceType = config.gaDimension_userAudienceType;

export const releaseName = config.releaseName;
export const sentryEnabled = Boolean(config.sentryEnabled);
export const environment = config.environment;

export default config;
