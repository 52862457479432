import axios from 'axios';
import * as AccessKey from './accessKey';
import { getAddressFromPostalCodeAndNumber } from '../common/address';
import { merchandiseErrorTypes } from './merchandiseErrors';

export const errorTypes = {
  NoEnoughData: 'NoEnoughData',
  InvalidProductCode: 'InvalidProductCode',
  DuplicateProductCode: 'DuplicateProductCode',
};

const invalidProductCodePattern = /[^a-zA-Z0-9\-_*@]/;

export const loadingState = () => ({ loading: true, ok: false, error: null });
export const loadedState = () => ({ loading: false, ok: true, error: null });
export const errorState = (error) => ({ loading: false, ok: false, error });

// TODO Rename
function validateInput(input) {
  const seemsAccessKey = AccessKey.seemsAccessKey(input.productCode);

  const isValidProductCode = isProductCodePatternValid(input.productCode);

  if (!isValidProductCode) {
    throw { type: errorTypes.InvalidProductCode };
  }

  input.productCode = normalizeProductCode(input.productCode);

  const hasEnoughData = seemsAccessKey
    || (input.postalCode && input.number);

  return {
    ...input,
    seemsAccessKey,
    hasEnoughData,
  };
}

export function isProductCodePatternValid(productCode) {
  return !productCode.match(invalidProductCodePattern);
}

function isTrackingCodeTooLong(trackingCode) {
  if (trackingCode.length > 44) {
    return true;
  }

  return false;
}

function prepareNewMerchandise(input) {
  return {
    id: input.productCode,
    trackingCode: input.productCode,
    serviceType: input.serviceType,
    address: {
      postalCode: input.postalCode,
      number: input.number,
      complement: '',
      latitude: input.latitude,
      longitude: input.longitude,
    },
    sender: null,
    volumesCount: 0,
    timeWindow: { start: '', end: '' },
    instructions: input.instructions || '',
    status: loadingState(),
    trackingCodeTooLong: loadingState().loading ? false : isTrackingCodeTooLong(input.productCode),
    trackingRecipients: input.trackingRecipients,

    // SUPER BATCH

    userId: input.userId || null,
    userEmail: input.userEmail || null,
    warehouseName: input.warehouseName || null,
    warehouse: input.warehouse || null,
    customerId: input.customerId || null,
    scheduledFor: input.scheduledFor || null,
    vehicleType: input.vehicleType || null,
    driverAssistantsCount: input.driverAssistantsCount || null,
    externalId: input.externalId || null,
    weight: input.weight || null,
    declaredDistanceInKm: input.declaredDistanceInKm || null,
    merchandisesTotalPrice: input.merchandisesTotalPrice || null,
  };
}

async function fetchFiscalDocument(accessKey) {
  try {
    const { data } = await axios.get(`/jade/api/fiscal-documents/${accessKey}`);
    return data;
  } catch (err) {
    throw parseFiscalDocumentFetchError(err);
  }
}

function parseFiscalDocumentFetchError(error) {
  const errors = merchandiseErrorTypes;


  if (!error.response) {
    return { type: errors.NetworkError };
  }

  const { status } = error.response;

  const type = error.response.data.type


  if (status === 500) {
    return { type: errors.FiscalDocumentProblem, status: 500 };
  } if (status === 502) {
    return { type: errors.NetworkError, status: 500 };
  } if (status === 422 && type === "cte_retrieval_unavailable") {
    return { type: errors.CTeRetrievalUnavailable, status: 422 };
  } if (status === 422) {
    return { type: errors.InvalidKey, status: 422 };

  }
  return { type: errors.FiscalDocumentNotFound, status: 404 };
}

export async function queryAddress({ postalCode, number, latitude, longitude }) {
  try {
    const normalizedAddress = await getAddressFromPostalCodeAndNumber(postalCode, number);
    if (latitude != null && longitude != null) {
      normalizedAddress.geolocation.latitude = latitude;
      normalizedAddress.geolocation.longitude = longitude;
    }
    return {
      ...normalizedAddress,
      number,
    };
  } catch (e) {
    e.query = { postalCode, number };
    e.type = merchandiseErrorTypes.AddressNotFound;
    throw e;
  }
}

async function queryMerchandise({ input, merchandise }, updateMerchandise) {
  const accessKey = input.productCode;
  let query;

  updateMerchandise({ ...merchandise, status: loadingState() });

  if (input.seemsAccessKey) {
    query = fetchFiscalDocument(accessKey).then((fiscalDocument) => {
      const completeMerchandise = {
        ...merchandise,
        fiscalDocument,
        trackingCode: fiscalDocument.code,
        address: fiscalDocument.addressee.address,
        volumesCount: fiscalDocument.shipments.amount,
        sender: fiscalDocument.sender.name,
        status: loadingState(),
        trackingRecipients: [{
          email: fiscalDocument.addressee.email,
          phone: fiscalDocument.addressee.phoneNumber,
        }],
      };

      updateMerchandise(completeMerchandise);
      return completeMerchandise;
    })
      .catch((error) => {
        updateMerchandise({
          ...merchandise,
          status: errorState(error),
        });
        return Promise.reject({ type: 'NoFiscalDocument' });
      });
  } else {
    query = Promise.resolve(merchandise);
  }

  query
    .then(async (completeMerchandise) => {
      try {
        const address = await queryAddress(completeMerchandise.address);
        updateMerchandise({
          ...completeMerchandise,
          address,
          status: loadedState(),
        });
      } catch (err) {
        updateMerchandise({
          ...completeMerchandise,
          status: errorState({ type: merchandiseErrorTypes.NoGeolocationFound }),
        });
      }
    })
    .catch((e) => {
      // TODO
      console.log(e);
    });
}

export function newMerchandiseFromProductCodeAndServiceType(productCode, serviceType) {
  return prepareNewMerchandise({ productCode, serviceType });
}

function normalizeProductCode(code) {
  return code.toUpperCase();
}

export default async function addMerchandises(merchandises,
  {
    allMerchandises, addMerchandise, updateMerchandise, onError,
  }) {
  function findAtExistingIndex(merchandise) {
    return allMerchandises.findIndex((m) => m.id === merchandise.id);
  }

  return Promise.all(
    merchandises.map((input) => {
      try {
        input = validateInput(input);

        const merchandise = prepareNewMerchandise(input);

        const foundAtIndex = findAtExistingIndex(merchandise);
        if (foundAtIndex !== -1) {
          throw { type: errorTypes.DuplicateProductCode, index: foundAtIndex };
        } else if (!input.hasEnoughData) {
          throw { type: errorTypes.NoEnoughData };
        }

        addMerchandise(merchandise);

        merchandise.queryMerchandise = () => queryMerchandise({ input, merchandise }, updateMerchandise);

        return { input, merchandise };
      } catch (e) {
        onError(e, { input });
        return {};
      }
    })
      .filter(({ input = null }) => input != null)
      .map(({ merchandise }) => merchandise.queryMerchandise()),
  );
}
