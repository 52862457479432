import axios from 'axios';

export const experiments = {
  ShipmentTrackingSharing: 'feature:shipment_tracking.sharing',
};

export async function getActiveExperiments() {
  const response = await axios.get('/jade/api/customers/active-experiments');
  return response.data;
}
