import Vue from 'vue';
import Vuex from 'vuex';
import core from './modules/core';
import spotRequest from './modules/spotRequest';
import spotBatchRequest from './modules/spotBatchRequest';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    spotRequest,
    spotBatchRequest,
    core,
  },
  strict: debug,
});
