<template>
  <div class="order-details-tab">
    <div class="details-row">
      <RichListItemWithIcon
        icon="clock"
        label="Solicitada"
        :value="formattedCreatedAt"
      />

      <RichListItemWithIcon
        v-if="orderDetails.scheduledFor"
        icon="calendar"
        label="Agendada"
        :value="formattedScheduledFor"
      />

      <RichListItemWithIcon
        icon="person"
        label="Solicitante"
        :value="orderDetails.requester"
      />

      <RichListItemWithIcon
        icon="vehicle"
        label="Veículo"
        :value="vehicleType"
      />

      <RichListItemWithIcon
        v-if="orderDetails.totalWeightInKilograms"
        icon="weight"
        label="Peso total da carga"
        :value="formattedTotalWeight"
      />
    </div>
    <div v-if="orderDetails.driver" class="details-row" :class="{'details-row--fullDriverInfo': displayFullDriverInfo}">
      <div class="driver-details">
        <div class="detail-block">
          <p class="detail-block--title">Motorista</p>
        </div>

        <div class="details-row">
          <RichListItemWithIcon
            icon="person"
            label="Nome"
            :value="orderDetails.driver.name"
          />

          <RichListItemWithIcon
            v-if="displayFullDriverInfo"
            icon="phone"
            label="Telefone"
            :value="orderDetails.driver.phoneNumber"
          />

          <RichListItemWithIcon
            icon="rg"
            label="RG"
            :value="orderDetails.driver.documentNumber"
          />

          <RichListItemWithIcon
            icon="cpf"
            label="CPF"
            :value="orderDetails.driver.cpf"
          />

          <RichListItemWithIcon
            icon="vehiclePlate"
            label="Placa"
          >
            <span class="vehiclePlate__prefix">{{ vehiclePlatePrefix }}</span>
            <span class="vehiclePlate__suffix">{{ vehiclePlateSuffix }}</span>
          </RichListItemWithIcon>

          <RichListItemWithIcon
            v-if="displayFullDriverInfo"
            icon="cnh"
            label="CNH"
            :value="orderDetails.driver.cnh"
          />
        </div>
      </div>

      <div class="risk-management-details">
        <div class="detail-block">
          <p class="detail-block--title">Gerenciadora de Risco</p>
        </div>
        <div class="details-row">
          <RichListItemWithIcon
            icon="status"
            label="Status"
            :value="riskManagementStatus"
          />

          <RichListItemWithIcon
            icon="barcodeRounded"
            label="Código"
            :value="orderDetails.riskManagement.queryCode"
          />

          <RichListItemWithIcon
            icon="riskManagement"
            label="Gerenciadora"
            :value="orderDetails.riskManagement.riskManager"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDate } from '../../../common/filters/dateFormatter'
  import { riskManagementStatusLabel } from '../riskManagementStatusLabel';
  import { vehicleTypeLabel } from '@/common/vehicleTypes';
  import RichListItemWithIcon from "../../../hounDS/components/RichListItemWithIcon";

  export default {
    name: "OrderDetailsTab",
    components: {RichListItemWithIcon},
    props: {
      orderDetails: Object
    },

    computed: {
      riskManagementStatus() {
        return riskManagementStatusLabel[this.orderDetails.riskManagement.status]
      },

      vehicleType() {
        return vehicleTypeLabel(this.orderDetails.vehicleType);
      },

      rawVehiclePlate() {
        return String(this.orderDetails.driver.vehiclePlate).replace(/-/, '');
      },

      vehiclePlatePrefix() {
        return this.rawVehiclePlate.substr(0, 3)
      },

      vehiclePlateSuffix() {
        return this.rawVehiclePlate.substr(3)
      },
      displayFullDriverInfo() {
        return this.orderDetails.driver.phoneNumber || this.orderDetails.driver.cpf || this.orderDetails.driver.cnh;
      },

      formattedScheduledFor() {
        return formatDate(this.orderDetails.scheduledFor, 'DD/MM/YYYY HH:mm')
      },

      formattedCreatedAt() {
        return formatDate(this.orderDetails.createdAt, 'DD/MM/YYYY HH:mm')
      },

      formattedTotalWeight() {
        return Math.round(this.orderDetails.totalWeightInKilograms) + ' kg'
      },
    }
  }
</script>

<style scoped>
  .order-details-tab {
    padding: 12px 24px;
  }

  .details-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .detail-block {
    flex: 0 50%;
    display: flex;
    margin-bottom: 12px;
    min-width: 200px;
  }

  .details-row--fullDriverInfo {
    flex-direction: column;
  }

  .risk-management-details {
    flex: 0 50%;
    flex-direction: column;
  }

  .driver-details {
    flex: 0 50%;
    flex-direction: column;
  }

  .detail-block--title {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    .details-row {
      flex-direction: column;
    }
  }

  .vehiclePlate__prefix::after {
    content: '-';
  }
</style>
