import { formatDate } from '../../common/filters/dateFormatter';
import timelineStatus from '../../hounDS/components/timeline/timelineStatus';

const timelineSteps = {
  PICKUP: 'PICKUP',
  IN_TRANSIT: 'IN_TRANSIT',
  ON_THE_WAY: 'ON_THE_WAY',
  DELIVERY: 'DELIVERY',
};

const stepTranslations = {
  PICKUP: 'Em separação',
  IN_TRANSIT: 'Em trânsito',
  ON_THE_WAY: 'Indo para seu destino',
  DELIVERY: {
    NOT_DONE: 'Finalizada',
    DOING: 'Entregando',
    DONE: 'Finalizada',
  },
};

function translateStep(step, status) {
  const stepTranslation = stepTranslations[step];
  if (typeof stepTranslation === 'string') {
    return stepTranslation;
  }
  return stepTranslation[status];
}

function buildDoneDescription(time) {
  return formatDate(time, 'DD/MM [às] HH:mm');
}

function buildEtaDoingDescription(estimateArrival) {
  const formattedTime = formatDate(estimateArrival, 'HH:mm');
  return `Próxima chegada estimada: ${formattedTime}`;
}

function buildDescription({
  step, status, startedAt, finishedAt, eta,
}) {
  if (step === timelineSteps.DELIVERY && finishedAt) {
    return buildDoneDescription(finishedAt);
  } if (status === timelineStatus.DONE) {
    return buildDoneDescription(startedAt);
  } if (status === timelineStatus.DOING && eta) {
    return buildEtaDoingDescription(eta);
  }
  return '';
}

function buildTimelineElement({
  step, status, startedAt, finishedAt,
}, eta) {
  return {
    status,
    title: translateStep(step, status),
    description: buildDescription({
      step, status, startedAt, finishedAt, eta,
    }),
  };
}

function buildShipmentTimelineElements(timeline, eta) {
  return timeline.map(
    (element) => buildTimelineElement(element, eta),
  );
}

export { buildShipmentTimelineElements };
