export function formatNumber(
  number,
  decimalPlaces,
  {
    decimalSeparator = ',',
    thousandsSeparator = '.',
  } = {},
) {
  const re = `\\d(?=(\\d{3})+${decimalPlaces > 0 ? '\\D' : '$'})`;
  const asNumber = Number(number);
  if (isNaN(asNumber)) {
    throw new TypeError(`not a number: ${number}`);
  }
  return asNumber
    .toFixed(decimalPlaces)
    .replace('.', decimalSeparator)
    .replace(new RegExp(re, 'g'), `$&${thousandsSeparator || ','}`);
}

export const moneyFormat = (number) => formatNumber(number, 2);

export const percentageFormat = (number, places = 2) => formatNumber(number * 100, places);

export const distanceFormat = (number) => formatNumber(number, 0);

export const weightFormat = (number) => formatNumber(number, 2);
