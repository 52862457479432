<template>
  <div class="buttons">
    <ButtonWithImage
      class="imageButton"
      v-for="(image, index) in images"
      :image-url="image"
      :label="labelForImage(index)"
      @click="openImageView(index)"
    />
    <ImageView
      :images="images"
      :openedImageIndex="openedImage"
      @close="closeImageView()"
    />
  </div>
</template>

<script>
import ImageView from "./ImageView";
import ButtonWithImage from "./ButtonWithImage";

export default {
  name: "ButtonsForImageView",
  components: { ButtonWithImage, ImageView },

  props: {
    images: Object
  },

  data() {
    return {
      openedImage: null
    }
  },

  methods: {
    openImageView(index) {
      this.openedImage = index;
    },
    closeImageView() {
      this.openedImage = null;
    },
    labelForImage(index) {
      return `Imagem ${index + 1}`;
    }
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.imageButton {
  flex: 1;
  margin: 6px 12px;
}
</style>
