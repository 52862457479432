<template>
  <div class="card">
    <table class="table table--zebra">
      <caption>
        Impostos
      </caption>
      <thead>
        <tr>
          <th>Tipo</th>
          <th class="table__right-text">Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="tax in taxes"
          :key="tax.name"
          class="table__right-text"
        >
          <td>{{ tax.name }}</td>
          <td>R$ {{ tax.price | moneyFormat }}</td>
        </tr>
        <tr class="table__right-text">
          <td>
            Total
          </td>
          <td>
            <strong>
              R$ {{ taxTotalPrice | moneyFormat }}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <small>PIS, COFINS e ICMS são passíveis de créditos nos casos previstos por lei.</small>
    </div>
  </div>
</template>

<script>
import { moneyFormat, percentageFormat } from '../common/filters/numberFormatters'
export default {
  name: "TaxesOverview",

  props: {
    taxes: Object,
    taxTotalPrice: String,
  },

  filters: {
    moneyFormat,
    percentageFormat
  }
}
</script>

<style scoped>
  .taxesOverview {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 4px;
    border: solid 1px #dee2e6;
    padding: 1.5rem;
    letter-spacing: 0.2px;
    font-size: 0.8em;
  }

  .taxesOverview__exclamation__icon {
    width: 20px;
    height: 20px;
  }

  ul {
    padding-left: 0;
    list-style-position: inside;
  }
</style>
