// TODO: Generate this file on automatically on build
export default {
  person: {
    path: '/jade/public/tracking/person.svg',
  },
  barcodeRounded: {
    path: '/jade/public/tracking/barcode-rounded.svg',
  },
  calendar: {
    path: '/jade/public/tracking/calendar.svg',
  },
  clock: {
    path: '/jade/public/tracking/clock.svg',
  },
  cnh: {
    path: '/jade/public/tracking/cnh.svg',
  },
  document: {
    path: '/jade/public/tracking/document.svg',
  },
  information: {
    path: '/jade/public/tracking/ic-information.svg',
  },
  phone: {
    path: '/jade/public/tracking/phone.svg',
  },
  riskManagement: {
    path: '/jade/public/tracking/risk-management.svg',
  },
  share: {
    path: '/jade/public/tracking/share.svg',
  },
  status: {
    path: '/jade/public/tracking/status.svg',
  },
  statusComplete: {
    path: '/jade/public/tracking/status-complete.svg',
  },
  vehicle: {
    path: '/jade/public/tracking/vehicle.svg',
  },
  vehiclePlate: {
    path: '/jade/public/tracking/vehicle-plate.svg',
  },
  locationPin: {
    path: '/jade/public/tracking/location-pin.svg',
  },
  weight: {
    path: '/jade/public/tracking/weight.svg',
  },
  rg: {
    path: '/jade/public/tracking/rg.svg',
  },
  cpf: {
    path: '/jade/public/tracking/cpf.svg',
  },
};
