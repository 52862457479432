<template>
  <SkeletonLoadingNavBar v-if="isLoading" />

  <div
    v-else
    class="billingStatement-navBar"
  >
    <nav-bar-item
      v-for="item in months"
      :key="item.month"
      :month="item.month"
      :total-value="item.totalValue"
      :selected="selectedMonth === item.month"
      @click="selectMonth(item.month)"
    />
    <button
      class="button button--secondary button--with-icon"
      @click="$emit('showCalendarModal')"
    >
      <svg
        aria-hidden="true"
        class="icon"
      >
        <use
          aria-hidden="true"
          href="@vuxx/hounds/icons/general-icons-defs.svg#icon-calendar"
        />
      </svg>
      <span class="sr-only">Selecionar Período</span>
    </button>
  </div>
</template>

<script>
import NavBarItem from './NavBarItem.vue';
import SkeletonLoadingNavBar from './SkeletonLoadingNavBar.vue';

export default {
  name: 'NavBar',
  components: { NavBarItem, SkeletonLoadingNavBar },

  props: [
    'months',
    'defaultMonth',
  ],

  data() {
    return {
      selectedMonth: this.defaultMonth,
    };
  },

  computed: {
    isLoading() {
      return this.months.length === 0;
    },
  },

  watch: {
    // Changes when the months list is loaded
    defaultMonth(newDefaultMonth) {
      this.selectMonth(newDefaultMonth);
    },
  },

  methods: {
    selectMonth(month) {
      this.selectedMonth = month;
      this.$emit('month-selected', month);
    },
  },
};
</script>

<style scoped>
  .billingStatement-navBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 16px;
    background-color: #fff;
  }
</style>
