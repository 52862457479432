<template>
  <div class="orderPanel">
    <div class="orderPanel__header">
      <div class="orderId">OS {{ orderDetails.id }}</div>
      <div class="orderScheduledFor">{{ formattedScheduledFor }}</div>
      <Button
        class="shareButton"
        :class="{ 'shareButton--toggleable': !hasUserIdentity }"
        v-if="sharing.token"
        @click="tokenToClipboard"
      >
        <Icon name="share" />
        <span class="shareButton-text">Compartilhar</span>
      </Button>
      <a
        v-if="!hasUserIdentity"
        class="button secondary whatsapp"
        href='https://api.whatsapp.com/send?l=pt_br&phone=5511986024396'
      >
        Fale com a Box Delivery
      </a>
      <center-screen-notification
        v-if="showCopyNotification"
        ref="notification"
        message="Link copiado! Cole (Ctrl-V) onde quiser"
        :delay-to-hide="timeConstants.HIDE_NOTIFICATION_DELAY"
      />
    </div>
    <div class="orderPanel__body">
      <div v-if="orderDetails.status === 'CANCELED'" class="canceledOrder">
        <div class="canceledOrder__title"> OS Cancelada</div>
        <div class="canceledOrder__time">{{ formattedCanceledAt }}</div>
      </div>
      <div v-else>
        <timeline
          class="timeline"
          :elements="timelineElements"
        />
      </div>
    </div>
    <div class="updateInfoContainer">
      <Icon name="information" />
      <div class="updateInfo">Dados atualizados automaticamente</div>
    </div>
  </div>
</template>

<script>
  import Timeline from '../../hounDS/components/timeline/Timeline.vue';
  import CenterScreenNotification from "../../hounDS/components/CenterScreenNotification";
  import { buildOrderTimelineElements } from './orderTimelineElementsFactory';
  import copyToClipboard from "../../hounDS/utils/copyToClipboard";
  import Icon from '@/hounDS/components/Icon';
  import Button from '@/hounDS/components/Button';
  import {formatDate, formatScheduledDatetimeBadge} from '@/common/filters/dateFormatter';
  import moment from "moment";

  const HIDE_NOTIFICATION_DELAY = 2000; // Milliseconds
  const NOTIFICATION_INTERVAL = 3000; // Milliseconds

  export default {
    name: 'OrderSummaryPanel',

    components: { Button, CenterScreenNotification, Icon, Timeline },

    data() {
      return {
        showCopyNotification: false,
        timeConstants: {
          HIDE_NOTIFICATION_DELAY,
          NOTIFICATION_INTERVAL
        }
      }
    },

    props: {
      orderDetails: Object,
      sharing: Object,
      driverEta: Object
    },

    methods: {
      tokenToClipboard() {
        copyToClipboard(this.tokenUrl);
        this.showCopyNotification = true;

        setTimeout(() => {
          this.showCopyNotification = false;
        }, this.timeConstants.NOTIFICATION_INTERVAL);
      },
    },

    computed: {
      timelineElements() {
        return buildOrderTimelineElements(this.orderDetails.timeline, this.driverEta);
      },

      formattedCanceledAt() {
        return formatDate(this.orderDetails.statusUpdatedAt, 'DD/MM [às] HH:mm');
      },

      formattedScheduledFor() {
        if (this.orderDetails.scheduledFor) {
          return formatScheduledDatetimeBadge(this.orderDetails.scheduledFor, true);
        }

        return formatScheduledDatetimeBadge(this.orderDetails.createdAt);
      },

      tokenUrl() {
        // We aren't yet redirecting everyone to the new domain due to authentication issues, but
        // that's not a problem for the public tracking link.
        const canonicalHost = window.location.origin.replace('.gadle.it', '.vuxx.com.br');
        return `${canonicalHost}/r/${this.sharing.token}`
      },

      hasUserIdentity() {
        return this.$root.hasUserIdentity;
      },
    }

  }
</script>

<style scoped lang="scss">
  @import '../../hounDS/styles/global/colors';
  @import '../../hounDS/styles/global/mediaQueries';
  @import '../../hounDS/styles/buttons.module.scss';


  .orderPanel {
    width: 100%;
  }
  .orderPanel__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    @include for-large-devices {
      flex-wrap: wrap;
    }
  }
  .orderId {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-right: 8px;

    @include for-large-devices {
      width: 100%;
    }
  }
  .orderScheduledFor {
    display: flex;
    justify-content: center;
    align-items: center;
    height: available;
    border-radius: 100px;
    padding: 4px 12px;
    background-color: #F1F3F5;
    color: #495057;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.16em;
  }
  .canceledOrder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }
  .canceledOrder__title {
    font-size: 16px;
    font-weight: 600;
  }
  .canceledOrder__time {
    font-size: 12px;
    letter-spacing: 0.02em;
    color: $gray800;
  }
  .timeline {
    width: 100%;
    height: 100%;
  }
  .updateInfoContainer {
    display: flex;
    margin-top: 16px;
  }
  .updateInfo {
    font-style: italic;
    font-size: 12px;
    margin-left: 8px;
  }

  .shareButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    min-width: 40px;
    align-self: self-end;
    cursor: pointer;
    border: 1px solid $gray200;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 0 0 auto;
    padding: 8px 16px;
    font-size: 14px;

    @include for-large-devices {
      flex-grow: 1;
      margin-left: 0;
      align-self: stretch;
    }
  }

  .shareButton:hover {
    background-color: $gray100;
  }

  .shareButton-text {
    margin-left: 10px;
  }

  .shareButton--toggleable {
    @include for-x-small-devices {
      flex-grow: 0;
    }
  }

  .shareButton--toggleable .shareButton-text {
    @include for-x-small-devices {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
  }

  .whatsapp {
    text-align: center;
    flex-grow: 0;
    padding: 8px 16px;
    margin-left: 8px;
    width: auto;

    @include for-large-devices {
      flex-grow: 1;
      margin-left: 8px;
    }
  }

  @media (max-width: 768px) {
    .orderPanel__body {
      background-color: $yellow100;
      padding: 20px;
      border-radius: 8px;
    }
    .orderId {
      font-size: 20px;
      font-weight: 500;
    }
    .orderScheduledFor {
      display: flex;
      justify-content: center;
      align-items: center;
      height: available;
      border-radius: 100px;
      padding-left: 12px;
      padding-right: 12px;
      background-color: $blue100;
      color: $blue700;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.16em;
    }
    .canceledOrder {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;
    }
    .canceledOrder__title {
      font-size: 16px;
      font-weight: 600;
    }
    .canceledOrder__time {
      font-size: 12px;
      letter-spacing: 0.02em;
      color: $gray800;
    }
    .updateInfoContainer {
      margin-bottom: 16px;
    }
  }
</style>
