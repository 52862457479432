/* eslint-disable no-console,max-classes-per-file,class-methods-use-this */

export const LogLevel = {
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
  Debug: 'debug',
};

export class NullLogHandler {
  log() {}
}

export class ConsoleLogHandler {
  log(level, message, { error }) {
    const method = {
      [LogLevel.Error]: console.error,
      [LogLevel.Warning]: console.warn,
      [LogLevel.Info]: console.log,
      [LogLevel.Debug]: console.debug,
    }[level] || console.log;

    const args = [message];
    if (error) {
      args.push(error);
    }

    method(...args);
  }
}

class CompositeLogHandler {
  constructor(...handlers) {
    this.handlers = Array.from(handlers);
  }

  addHandler(handler) {
    this.handlers.push(handler);
  }

  log(level, message, context) {
    this.handlers.forEach((handler) => handler.log(level, message, context));
  }
}

export class Logger {
  constructor({ handler } = {}) {
    this.setHandler(handler);
  }

  setHandler(handler) {
    this.handler = handler || new NullLogHandler();
  }

  addHandler(handler) {
    if (!(this.handler instanceof CompositeLogHandler)) {
      this.handler = new CompositeLogHandler(this.handler);
    }

    this.handler.addHandler(handler);
  }

  log(level, message, context = {}) {
    this.handler.log(level, message, context);
  }

  error(error, context = {}) {
    let errorObj = error;
    if (typeof error === 'string') {
      errorObj = new Error(error);
    } else if (!(error instanceof Error)) {
      const message = error.message || error.detail || error.title || null;
      errorObj = Object.assign(new Error(message), error);
    }

    this.log(LogLevel.Error, errorObj.message, { ...context, error: errorObj });
  }

  warning(message, context = {}) {
    this.log(LogLevel.Warning, message, context);
  }

  info(message, context = {}) {
    this.log(LogLevel.Info, message, context);
  }

  debug(message, context = {}) {
    this.log(LogLevel.Debug, message, context);
  }
}
