<template>
  <div v-if="isShown" class="alert" :class="getClassType">
    {{message}}
    <span class="close" @click="close" >x</span>
  </div>
</template>

<script>
  export default {
    name: "Alerts",

    data: function (){
      return {
        isShown: false,
        message: null,
        type: null,
        timer: null,
      }
    },

    watch: {
      type: function (value) {
        if (value) {
          this.isShown = true;

          if(this.timer){
            clearTimeout(this.timer);
          }

          this.timer = setTimeout(()=>{
            this.close();
          }, 4000)
        }
      },
    },

    methods: {
      close() {
        // This is very important because if
        // the new alert values is equals old
        // values the watch event skips it.
        this.isShown = false;
        this.$root.toggleGlobalAlert({
          type:  null,
          message: null,
        });
      },
    },

    computed: {
      getClassType(){
        if(this.type) {
          return `alert-${this.type}`;
        }
        return;
      }
    },

    mounted(){
      this.$root.watchAlerts(alerts => {
        this.type = alerts.type;
        this.message = alerts.message;
      })
    }
  }
</script>

<style scoped>
  .alert {
    position: absolute;
    z-index: 99999;
    left: 50%;
    transform: translateX(-50%);
    width: 50vw;
    min-width: 320px;
    padding: 15px 35px 15px 14px;
    margin-bottom: 18px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    opacity: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    top: -100px;
    animation: slide 0.5s forwards;
  }

  @-webkit-keyframes slide {
    100% { top: 30px; }
  }

  @keyframes slide {
    100% { top: 30px; }
  }

  .alert-heading {
    color: inherit;
  }

  .alert .close {
    position: relative;
    top: -2px;
    right: -21px;
    line-height: 18px;
    cursor: pointer;
    float: right;
  }

  .alert-success {
    color: #468847;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    float: right;
    opacity: 1;
  }

  .alert-danger,
  .alert-error,
  .alert-fail {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
    opacity: 1;

  }

  .alert-info {
    color: #3a87ad;
    background-color: #d9edf7;
    border-color: #bce8f1;
    opacity: 1;

  }

  .alert-warning {
    color: #c09853;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    opacity: 1;

  }

  .alert-block {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .alert-block > p,
  .alert-block > ul {
    margin-bottom: 0;
  }
  .alert-block p + p {
    margin-top: 5px;
  }
</style>
