import axios from 'axios';

export async function getTrackingForOrder(orderId) {
  return axios.get(`/jade/api/orders/${orderId}/tracking`)
    .then((res) => res.data);
}

export async function getPublicTracking(token) {
  return axios.get(`/jade/api/orders/public/${token}/tracking`)
    .then((res) => res.data);
}

export async function createTrackingRecipients(shipmentId, trackingRecipients) {
  return axios.post('/jade/api/shipments/trackings', { shipmentId, recipients: trackingRecipients });
}
