<template>
  <div class="spotRequest-summary">
    <div class="spotRequest-summary-body">

      <div class="spotRequest-summary-body__content">
        <summary-header
          :service-type="computedServiceType"
          :vehicle-type-name="vehicleTypeName"
          :scheduling="scheduling"
        ></summary-header>

        <div class="panel panel--default">
          <external-id-input />
        </div>

        <div class="panel panel--default spotRequest-summary__merchandiseList">
          <h2 class="spotRequest-sectionTitle">Detalhes do serviço</h2>
          <merchandise-list
            class="spotRequest-merchandises__list"
            :distance="distance"
            :merchandises="merchandises"
            :read-only=true
            @merchandise-remove-requested="onMerchandiseRemoveRequested"
          />
        </div>

        <round-trip
          v-if="showRoundTrip"
          class="panel spotRequest-summary__roundTrip"
          :read-only="true"
          :round-trip="roundTrip"
          :is-round-trip="isRoundTrip"
        ></round-trip>
      </div>

      <div class="spotRequest-summary-body__estimate">
        <div class="panel spotRequest-summary-body__estimatePanel">
          <div>
            <estimate-display
              class="spotRequest-summary-body__estimate__display"
              :price-estimation="priceEstimation"
              :loading="estimatingPriceAndDistance"
            />
          </div>
        </div>
        <div v-if="priceEstimation" class="panel spotRequest-summary-body__estimatePanel">
          <warning
            class="spotRequest-summary-body__estimate__display"
          >
            O valor cotado pode sofrer alterações para mais ou para menos em função de pedágios,
            alteração do itinerário do motorista, seguro de carga, adicionais de tempo de espera, entregas ou coletas não visitadas pelo motorista, dentre outros.
          </warning>
        </div>
      </div>
    </div>

    <div class="horizontalSeparator spotRequest-summary__horizontalSeparator"></div>

    <div class="spotRequest-summary__buttonsContainer">
      <button
        class="baseButton cancelButton spotRequest-summary__goBackButton"
        :disabled="requestSubmitted"
        @click="onPreviousClicked"
      >
        Voltar
      </button>
      <button
        class="baseButton submitButton"
        :disabled="!canFinishRequest"
        @click="onNextClicked"
      >
        Finalizar pedido
      </button>
    </div>
  </div>
</template>

<script>
import './style.css';

import { mapGetters, mapState } from 'vuex';
import PageHeader from '../../common/PageHeader.vue';
import SummaryHeader from './SummaryHeader.vue';
import MerchandiseList from './MerchandiseList.vue';
import EstimateDisplay from '../common/EstimateDisplay.vue';
import ExternalIdInput from '../common/ExternalIdInput.vue';
import { vehicleNames } from '@/common/vehicleTypes';
import Warning from '../common/Warning.vue';
import RoundTrip from './RoundTrip.vue';
import { stopTaskTypes } from "../../billingStatement/orderOverview/stopTaskTypes";

export default {
  name: "Summary",

  components: {
    PageHeader,
    SummaryHeader,
    MerchandiseList,
    EstimateDisplay,
    ExternalIdInput,
    Warning,
    RoundTrip,
  },

  props: {
    serviceType: String,
    priceEstimation: Object,
    merchandises: Array,
    canFinishRequest: Boolean,
    requestSubmitted: Boolean,
    estimatingPriceAndDistance: Boolean,
    roundTrip: Object,
    isRoundTrip: Boolean,
    externalId: String,
  },

  computed: {
    ...mapState('spotRequest', ['scheduling']),
    ...mapGetters('spotRequest', ['vehicleType']),

    computedServiceType() {
      if (this.serviceType == 'PICKUP') {
        return 'Coleta';
      }

      return 'Entrega';
    },

    vehicleTypeName() {
      return vehicleNames[this.vehicleType];
    },

    distance() {
      return this.priceEstimation ? this.priceEstimation.distanceInKm : null;
    },

    showRoundTrip() {
        return this.isRoundTrip && this.serviceType === stopTaskTypes.DELIVERY;
    }
  },

  methods: {
    onPreviousClicked() {
      this.$emit('on-previous', 'summary');
    },

    onNextClicked() {
      this.$emit('finish-request');
    },

    onMerchandiseRemoveRequested() {
      window.alert('Not implemented yet');
    },
  },

  data() {
    return {
      isScheduled: false,
      scheduledDatetime: null,
    }
  },
}
</script>

<style scoped>

  .spotRequest-summary {
    font-family: "Source Sans Pro", sans-serif;
  }

  .spotRequest-summary-body {
    display: flex;
    flex-direction: row;
  }

  .spotRequest-summary-body__content {
    flex-grow: 1;
  }

  .spotRequest-summary-body__estimate {
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
  }

  .spotRequest-summary-body__estimatePanel {
    top: 87px;
    padding: 24px 24px 40px 24px;
    margin: 20px   20px 0px 0px;
  }

  @media only screen and (max-width: 1267px) {
    .spotRequest-summary-body {
      flex-direction: column;
    }
    .spotRequest-summary-body__estimate {
      align-self: flex-end;
    }
    .spotRequest-summary-body__estimate__display {
      position: inherit;
    }
  }

  .panel--default {
    margin: 20px 20px 0px 20px;
    padding: 24px 20px 16px 20px;
  }

  .spotRequest-merchandises__list {
    margin-top: 12px;
    transition-delay: .6s;
  }

  .spotRequest-summary__horizontalSeparator {
    margin: 20px 20px 0 20px;
  }

  .spotRequest-summary__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 16px 20px 30px 20px;
  }

  .spotRequest-summary__goBackButton {
    margin-right: 20px;
  }

  .spotRequest-summary__roundTrip {
    margin: 24px 20px 0px 20px;
  }

</style>
