<template>
  <div v-if="pod">
    <div :class="[tabs.section]">
      <RichListItemWithIcon
        label="Recebido por"
        :value="pod.recipientName"
      />
      <RichListItemWithIcon
        label="Documento de Identidade"
        :value="pod.recipientIdentifier"
      />
      <RichListItemWithIcon
        label="Data e hora"
        :value="formattedTimeFinish"
      />
    </div>
    <div
      v-if="pod.signatureImageUrl"
      :class=[tabs.section]
    >
      <Signature :url="pod.signatureImageUrl"/>
    </div>

    <div class="buttons-section">
      <ButtonsForImageView :images="pod.picturesUrls" />
    </div>
  </div>
  <div v-else>
    <div :class="[tabs.section]">
      <h4 :class="[text.infoTab, text.heading]">Comprovante ainda não disponível.</h4>
    </div>
  </div>
</template>

<script>
import RichListItemWithIcon from "../../../hounDS/components/RichListItemWithIcon";
import { formatDate } from "../../../common/filters/dateFormatter";
import Signature from "../../../hounDS/components/Signature";
import tabs from "../../../hounDS/styles/tabs.module.scss"
import text from "../../../hounDS/styles/text.module.scss"
import ButtonsForImageView from "../../../hounDS/components/ButtonsForImageView";

export default {
  name: "PodInfoTab.vue",
  components: { ButtonsForImageView, Signature, RichListItemWithIcon },

  props: {
    pod: null
  },

  computed: {
    formattedTimeFinish() {
      return formatDate(this.pod.occurredAt, 'DD/MM [às] HH:mm')
    },

    tabs: () => Object.freeze(tabs),
    text: () => Object.freeze(text)
  }
}
</script>
<style scoped>
/**
    Margin trick done with ButtonsForImageView style class imageButton
    half of row padding is from section padding and another half
    comes from button padding, so when the block wrap happens
    still be respecting the full row padding.
*/
.buttons-section {
  padding: 6px 12px;
}
</style>

