<template>
  <div class="content__pod--data">

    <div class="content__pod--column-data">

      <div class="content__pod--name">
        <h1>Comprovante {{ this.podData.type ? `de ${podTitle}` : ''}}
        </h1>
      </div>

      <div>
        <b>OS</b>
        {{ this.podData.orderId }}
      </div>
      <hr>

      <div>
        <b>Data da OS</b>
        {{ formattedDate }}
      </div>
      <hr>

      <div>
        <b>Endereço</b>
        {{ this.podData.address}}
      </div>
      <hr>

      <div>
        <b>Motorista</b>
        {{ this.podData.driverName }}
      </div>
      <div>
        <b>Placa do veículo</b>
        {{ this.podData.driverVehiclePlate }}
      </div>

      <hr v-if="this.podData.notes">
      <div v-if="this.podData.notes">
        <b>Observações fornecidas pelo motorista</b><br>
        {{ this.podData.notes }}
      </div>

    </div>

    <div class="vr"></div>

    <div class="content__pod--columnsignature">
      <div>
        <p>
          Declaro que recebi os volumes em perfeito estado e dou por cumprido o presente contrato de transporte.
        </p>
      </div>

      <hr>
      <div>
        <img  width="200" height="auto" :src="signatureUrl" />
      </div>
      <hr>

      <div>
        <b>Nome</b> {{ this.podData.recipientName }}
      </div>
      <div>
        <b>Documento</b> {{ this.podData.recipientIdentifier}}
      </div>
    </div>
  </div>
</template>

<script>
    import podTypeEnum from "../client/podEnum";
    import moment from 'moment';

    export default {
      name: "Pod",
      props: {
          podData: Object
      },
      computed: {
        podTitle(){
          return podTypeEnum[this.podData.type]
        },
        signatureUrl(){
          return this.podData.recipientSignature ? this.podData.recipientSignature : '';
        },

        formattedDate(){
          if (!this.podData.timeFinish)
            return '';

          let date = moment(this.podData.timeFinish);

          return `${date.format('DD/MM/YYYY', {trim:false})} às ${date.format('HH:mm', {trim:false})}`;
        },
      }
    }
</script>

<style scoped>
  .content__pod--data {
    color: #323232;
    line-height: 20px;
    background: #E8E8E8;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    max-width: 1024px;
    min-height: 400px;
  }

  .content__pod--column-data {
    flex: 0 1 50%;
  }

  .content__pod--columnsignature {
    flex: 0 1 50%;
  }
</style>
