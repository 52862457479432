<template>
  <tracking-page-layout
    title="Rastreamento da Ordem de Serviço"
    :is-unavailable="orderUnavailable"
    :map-markers="mapMarkers"
  >
    <template #top-panel>
      <order-summary-panel
        v-if="trackingData"
        :order-details="trackingData.orderSummary"
        :sharing="trackingData.sharing"
        :driver-eta="trackingData.eta"
      />
    </template>

    <template #bottom-panel>
      <details-panel
        v-if="trackingData"
        :order-details="trackingData.orderDetails"
        :stops="trackingData.stops"
        :driver-eta="driverEta"
      />
    </template>
  </tracking-page-layout>
</template>

<script>
import titleMixin from '../common/mixins/titleMixin';
import DetailsPanel from './order/detailsPanel/DetailsPanel.vue';
import OrderSummaryPanel from './order/OrderSummaryPanel.vue';
import TrackingPageLayout from './TrackingPageLayout.vue';
import { getPublicTracking, getTrackingForOrder } from './order/trackingApi';
import { formatDate } from '../common/filters/dateFormatter';
import { buildOrderMapMarkers } from './order/orderMapMarkersFactory';

export default {
  name: 'OrderTrackingPage',
  components: { TrackingPageLayout, DetailsPanel, OrderSummaryPanel },
  mixins: [titleMixin],

  htmlDocumentTitle() {
    return this.$route.params.id
      ? `OS ${this.$route.params.id} - Acompanhamento de pedido | Vuxx`
      : null;
  },

  data() {
    return {
      trackingData: null,
      mapMarkers: [],
      orderUnavailable: false,
      pollingIntervalId: null,
    };
  },

  computed: {
    driverEta() {
      const driverEta = this.trackingData.eta;

      if (!driverEta) {
        return null;
      }

      // TODO: This should not be done here, it is kinda mutating the driver eta.
      // Refactoring the tracking map was  harder because of this.
      const formattedArrivalDate = formatDate(driverEta.estimatedArrival, 'DD/MM HH:mm');

      return {
        formattedArrivalDate,
        estimatedArrival: driverEta.estimatedArrival,
        nextStopId: driverEta.nextStopId,
      };
    },
  },

  watch: {
    trackingData(newTrackingData) {
      this.mapMarkers = buildOrderMapMarkers(newTrackingData);
    },
  },

  async mounted() {
    try {
      await this.getTrackingData();
    } catch (e) {
      this.orderUnavailable = true;
    }

    this.startPollingData();
  },

  beforeDestroy() {
    clearInterval(this.pollingIntervalId);
  },

  methods: {
    startPollingData() {
      const REFRESH_INTERVAL = 60000;
      this.pollingIntervalId = setInterval(() => this.getTrackingData(), REFRESH_INTERVAL);
    },
    async getTrackingData() {
      const isPublic = this.$route.meta.public;

      if (isPublic) {
        const { token } = this.$route.params;
        this.trackingData = await getPublicTracking(token);
      } else {
        const orderId = this.$route.params.id;
        this.trackingData = await getTrackingForOrder(orderId);
      }
    },
  },
};
</script>
