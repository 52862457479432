import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';
import { environment, releaseName, sentryEnabled } from './runtimeConfig';

export class SentryLogHandler {
  log(level, message, { error, req }) {
    if (error == null) {
      return;
    }

    Sentry.captureException(error, {
      level: Sentry.Severity.fromString(level),
      extra: error,
      contexts: {
        req,
      },
    });
  }
}

/**
 * @param {VueRouter} router
 * @param {Auth} auth
 * @param {Logger} logger
 */
export default function initializeSentry(router, auth, logger) {
  if (!sentryEnabled) {
    return;
  }

  Sentry.init({
    Vue,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // TODO: Understand better how Sentry tracing works. For now we disable it.
        // https://docs.sentry.io/platforms/javascript/performance/connect-services/
        // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracingorigins
        tracingOrigins: [],
      }),
    ],
    dsn: 'https://4d6ef097ba8a4b7890535e3e163c5ccc@o920046.ingest.sentry.io/5943757',
    release: releaseName,
    environment: environment || 'development',
    // Capture X% of transactions for performance monitoring.
    tracesSampleRate: 0.0,
  });

  logger.addHandler(new SentryLogHandler());

  auth.getUser().then((res) => {
    if (res.loggedIn === true && res.identity != null) {
      Sentry.setUser({
        id: String(res.identity.personId),
        email: res.identity.personEmail,
        customerId: String(res.identity.customerId),
        customerName: res.identity.customerName,
      });
    }
  });
}
