import moment from 'moment';
import {
  queryAddress, loadedState, errorState, newMerchandiseFromProductCodeAndServiceType,
} from './addMerchandises';
import { merchandiseErrorTypes } from './merchandiseErrors';
import uploadFiscalDocument from '../../fiscalDocumentsUpload/uploadFiscalDocument';

function checkForMerchandiseDuplicates(productCode, merchandises) {
  const foundAtIndex = merchandises.findIndex((m) => m.trackingCode === productCode);
  if (foundAtIndex >= 0) {
    throw { type: merchandiseErrorTypes.UploadedFileDuplicated };
  }
}

function updateMerchandiseWithQueriedData(merchandiseToUpdate, processedFiscalDocument, queriedAddress) {
  merchandiseToUpdate.fiscalDocument = processedFiscalDocument;
  merchandiseToUpdate.trackingCode = processedFiscalDocument.code;
  merchandiseToUpdate.address = queriedAddress;
  merchandiseToUpdate.volumesCount = processedFiscalDocument.shipments.amount;
  merchandiseToUpdate.sender = processedFiscalDocument.sender.name;
  merchandiseToUpdate.trackingRecipients = [{
    email: processedFiscalDocument.addressee.email,
    phone: processedFiscalDocument.addressee.phoneNumber,
  }];
  merchandiseToUpdate.status = loadedState();
}

async function uploadFiscalDocumentFile(merchandiseToUpdate, fileToUpload, merchandises) {
  try {
    const processedFiscalDocument = await uploadFiscalDocument(fileToUpload);

    checkForMerchandiseDuplicates(processedFiscalDocument.code, merchandises);

    const address = await queryAddress(processedFiscalDocument.addressee.address);

    updateMerchandiseWithQueriedData(merchandiseToUpdate, processedFiscalDocument, address);
  } catch (error) {
    if (error.response) {
      merchandiseToUpdate.status = errorState({ type: merchandiseErrorTypes.XmlProcessingError });
    } else {
      merchandiseToUpdate.status = errorState(error);
    }
  }
}

export function newMerchandiseFromFiscalDocumentUpload(fileToUpload, serviceType, merchandises) {
  const newMerchandise = newMerchandiseFromProductCodeAndServiceType(fileToUpload.name, serviceType);

  // The code bellow makes sure that the id is unique.
  // Using only the file name as id will induce to a bad behavior.
  // Eg. two files with the same name from different folders
  newMerchandise.id = fileToUpload.name + moment().valueOf();

  updateMerchandiseWithFiscalDocumentUpload(newMerchandise, fileToUpload, merchandises);

  return newMerchandise;
}

export function updateMerchandiseWithFiscalDocumentUpload(merchandiseToUpdate, fileToUpload, merchandises) {
  const isXmlFile = fileToUpload.type === 'text/xml';

  if (isXmlFile) {
    uploadFiscalDocumentFile(merchandiseToUpdate, fileToUpload, merchandises);
  } else {
    merchandiseToUpdate.status = errorState({ type: merchandiseErrorTypes.InvalidFileTypeToUpload });
  }
}
