import axios from 'axios';

export async function getShipmentTracking({ shipmentHash = null, accessKey = null, clientCode = null }) {
  let req;
  if (shipmentHash != null) {
    req = axios.get(`/jade/api/shipments/by-hash/${shipmentHash}/tracking`);
  } else if (accessKey != null && clientCode != null) {
    req = axios.get(`/jade/api/shipments/by-dfe/${accessKey}/tracking`, {
      params: { client_code: clientCode },
    });
  } else {
    throw new Error('Invalid request mode');
  }

  return req.then((res) => res.data);
}

export async function submitShipmentRating(shipmentHash, rating) {
  return axios.post(`/jade/api/shipments/by-hash/${shipmentHash}/rating`, rating)
    .then((res) => res.data);
}
