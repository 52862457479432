<template>
  <div>
    <h2>{{ title }}</h2>
    <table>
        <thead>
          <tr>
            <th>Id Externo</th>
            <th>OS</th>
            <th>Peso</th>
          </tr>
        </thead>
      <tbody>
        <tr v-for="createdSpot in succeededBatchSpots">
          <td>{{ createdSpot.externalId }}</td>
          <td>{{ createdSpot.orderId }}</td>
          <td>{{ weightFormat(createdSpot.totalWeightInKilograms) }} kg</td>
        </tr>
        <tr v-for="failedSpot in failedBatchSpots">
          <td>{{ failedSpot.externalId }}</td>
          <td colspan="2">{{ failedSpot.error.title }}: {{ failedSpot.error.message }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { weightFormat } from "@/common/filters/numberFormatters";

export default {
  name: "SpotBatchRequestResult",

  computed: {
    ...mapGetters('spotBatchRequest', [
      'succeededBatchSpots',
      'failedBatchSpots',

      'requestedBatchSpotsCount',
      'succeededBatchSpotsCount',
    ]),

    title() {
      return `${this.succeededBatchSpotsCount} de ${this.requestedBatchSpotsCount} rotas solicitadas com sucesso!`;
    },
  },

  methods: {
    weightFormat,
  },
}
</script>

<style scoped>
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  table {
    width: 100%;
  }

  th, td {
    padding: 0.5rem;
  }
</style>
