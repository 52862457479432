/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import axios from "axios";
import { merchandiseToSpotDocument } from "@/spot/spotRequest/finishRequest";
import { buildTransportationsFromShipmentsAndPlaces } from "@/spot/spotRequest/transportation/TransportationFactory";
import { convertMerchandisesToShipments } from "@/spot/spotRequest/transportation/ShipmentFactory";
import {
  convertWarehouseToPlace,
  findUniquePlacesFromMerchandises
} from "@/spot/spotRequest/transportation/PlaceFactory";
import groupBy from 'lodash.groupby';
import moment from "moment";
import { translateErrorMessage } from "@/spot/spotRequest/spotRequestErrorTranslator";

const state = () => ({
  isSuperBatch: false,
  spotRequests: [],
  succeededRequests: [],
  failedRequests: [],
});

const getters = {
  isSuperBatch(state) {
    return state.isSuperBatch;
  },

  batchSpotsCount(state) {
    return state.spotRequests.length;
  },

  requestedBatchSpotsCount(state) {
    return state.succeededRequests.length + state.failedRequests.length;
  },

  succeededBatchSpots(state) {
    return state.succeededRequests;
  },

  succeededBatchSpotsCount(state) {
    return state.succeededRequests.length;
  },

  failedBatchSpots(state) {
    return state.failedRequests;
  },

  failedBatchSpotsCount(state) {
    return state.failedRequests.length;
  },
};

function formatDatetime(dateTime) {
  return dateTime.format('Y-MM-DD HH:mm:ss');
}

function merchandisesToTransportations(merchandises) {
  const warehouse = merchandises[0].warehouse;
  const warehousePlace = convertWarehouseToPlace(warehouse, 'DELIVERY');
  const merchandisesPlaces = findUniquePlacesFromMerchandises(merchandises);

  let allPlaces = [warehousePlace, ...merchandisesPlaces];
  allPlaces = allPlaces.filter((place) => place); // remove null values (round trip is nullable)

  const placesById = {};
  for (const place of allPlaces) {
    placesById[place.id] = place;
  }

  const shipments = convertMerchandisesToShipments(merchandises, 'DELIVERY');
  return buildTransportationsFromShipmentsAndPlaces(shipments, placesById);
}

function getNewSpotCommand(merchandises) {
  const firstMerchandise = merchandises[0];

  const totalWeight = merchandises.reduce(
    (total, merchandise) => total + parseFloat(merchandise.weight),
    0
  );

  // TODO: Centralize the command creation. The code below is duplicated. See `getNewSpotCommand()` from `Page.vue`.
  return {
    vehicle: {
      type: firstMerchandise.vehicleType,
      bodyTypes: [],
    },
    totalWeightInKilograms: Number(totalWeight),
    notes: 'Solicitado em lote',
    isRoundTrip: false,

    loadingPlace: convertWarehouseToPlace(firstMerchandise.warehouse, 'DELIVERY'),
    unloadingPlace: null,

    documents: merchandises.map(merchandiseToSpotDocument),
    quote: {
      price: "0.00",
      tollPrice: "0.00",
      distance: "0.00",
      quotationId: null
    },
    declaredDistanceInKm: firstMerchandise.declaredDistanceInKm || null,
    merchandisesTotalPrice: firstMerchandise.merchandisesTotalPrice || null,
    serviceType: 'DELIVERY',
    scheduledFor: firstMerchandise.scheduledFor !== null ? formatDatetime(moment(firstMerchandise.scheduledFor)) : null,
    driverAssistantsCount: parseInt(firstMerchandise.driverAssistantsCount),
    priceEstimatedAt: formatDatetime(moment()),

    transportations: merchandisesToTransportations(merchandises),

    // Spot batch request required data

    customerId: parseInt(firstMerchandise.customerId),
    userId: String(firstMerchandise.userId),
    externalId: String(firstMerchandise.externalId),
    integrationId: String(firstMerchandise.integrationId),
  };
}

const actions = {
  async completeMerchandises(context, merchandises) {
    const payload = { shipments: merchandises };

    try {
      const response = await axios.post('/jade/api/spots/batch/shipments/fill-missing-data', payload);
      return response.data;
    } catch (e) {
      throw {
        hasValidationErrors: true,
        validationErrors: translateErrorMessage(e.response.data),
      };
    }
  },

  async requestSpot({ commit }, command) {
    try {
      const response = await axios.post('/jade/api/spots/batch', command);
      const createdSpot = response.data;
      commit('addSucceededRequest', createdSpot);
    } catch (e) {
      commit('addFailedRequest', {
        ...command,
        error: translateErrorMessage(e.response.data),
      });
    }
  },

  async runSuperBatchRequest({ commit, dispatch }, merchandises) {
    const merchandisesByExternalId = groupBy(merchandises, merchandise => merchandise.externalId);
    const newSpotCommands = Object.values(merchandisesByExternalId).map(getNewSpotCommand);

    commit('requestsStarted', newSpotCommands);

    for (const command of newSpotCommands) {
      await dispatch('requestSpot', command);
    }
  }
};

const mutations = {
  changeToSuperBatchMode(state) {
    state.isSuperBatch = true;
  },

  requestsStarted(state, spots) {
    state.spotRequests = spots;
    state.failedRequests = [];
    state.succeededRequests = [];
  },

  addFailedRequest(state, failedSpotRequest) {
    state.failedRequests.push(failedSpotRequest);
  },

  addSucceededRequest(state, createdSpot) {
    state.succeededRequests.push(createdSpot);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
