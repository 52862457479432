import axios from 'axios';

async function getLocationFromPostalCodeAndNumber(postalCode, number, options = {}) {
  const params = { search: `${postalCode}:${number}` };
  if (options.requireGeolocation != null) {
    params.requireGeolocation = String(Boolean(options.requireGeolocation));
  }

  const { data } = await axios.get('/jade/api/locations', {
    params,
  });

  return data;
}

function formatThoroughfare(address) {
  let thoroughfare = '';

  if (address.type != null) {
    thoroughfare = `${address.type} `;
  }

  if (address.thoroughfare != null) {
    thoroughfare += address.thoroughfare;
  }

  if (thoroughfare.trim() === '') {
    thoroughfare = '';
  }

  return thoroughfare;
}

// Strip the 'precision' field that the location service returns
function convertGeolocationFromLocationSearchResult({ latitude, longitude }) {
  return { latitude, longitude };
}

// The output format is the same format returned in the fiscal document address properties.
// The complement is NOT taken into account.
function convertAddressFromLocationSearchResult(result, search) {
  const { address } = result;
  const { geolocation } = result;

  return {
    thoroughfare: formatThoroughfare(address),
    number: search.number,
    neighborhood: address.neighborhood,
    city: address.locality,
    locality: address.locality,
    state: address.state,
    postalCode: address.postalCode,
    geolocation: convertGeolocationFromLocationSearchResult(geolocation),
  };
}

export async function getAddressFromPostalCodeAndNumber(postalCode, number, options = {}) {
  const location = await getLocationFromPostalCodeAndNumber(postalCode, number, options);
  return convertAddressFromLocationSearchResult(location, { postalCode, number });
}
