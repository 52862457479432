<template>
  <div class="innerContent">
    <div class="instructions">Adicione o contato do destinatário para que ele seja informado sobre os status da entrega via e-mail.</div>
    <div class="fieldTitle">E-mail (Adicione mais de um e-mail separando-os por virgulas)</div>
    <!-- Key 13 means Enter -->
    <vue-tags-input
      class="field"
      v-model="emailTag"
      :tags="emailTags"
      :add-on-key="[13, ',']"
      :allow-edit-tags="true"
      placeholder="gabriel.vasconcelos@email.com, lais.lima@email.com, ..."
      @tags-changed="newTags => emailTags = newTags"
      ref="emailInput"
    />
    <div class="fieldTitle">Para remover e-mails clique no ”x” e para editar aperte no retangulo do e-mail</div>
  </div>
</template>

<script>

import {VueTagsInput, createTag, createTags} from "@johmun/vue-tags-input";

export default {
  name: "TrackingRecipientsInput",

  components: {
    VueTagsInput,
  },

  data() {
    return {
      emailTag: '',
      emailTags: createTags(this.getEmails())
    }
  },

  props: {
    trackingRecipients: Array
  },

  methods: {
    getEmails() {
      if (!this.trackingRecipients) {
        return []
      }

      return [...new Set(this.trackingRecipients
        .map((trackingRecipient) => trackingRecipient.email)
        .filter((email) => email)
      )]
    },

    getEmailTagsAsTextWithoutDuplicates () {
      let newTags = this.emailTags

      if (this.emailTag) {
        newTags = [...this.emailTags, createTag(this.emailTag, this.emailTags)]
      }

      return [...new Set(newTags.map((tag) => tag.text))]
    }
  },

  mounted() {
    this.$refs.emailInput.$refs.newTagInput.focus()
  },

  beforeDestroy() {
    const emails = this.getEmailTagsAsTextWithoutDuplicates()

    const trackingRecipients = emails.map((email) => ({email: email}))

    this.$emit('tracking-recipients-changed', trackingRecipients)
  }
}
</script>

<style scoped>
  .innerContent {
    display: flex;
    flex-direction: column;
  }
  .instructions {
    font-size: 16px;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .fieldTitle {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .field {
    margin-bottom: 24px;
  }
</style>
