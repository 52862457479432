/* global ga */

import { gaDimension_userAudienceType } from './runtimeConfig';

export default function googleAnalyticsInit(userInfo) {
  if (typeof ga === 'undefined') {
    console.warn('Google Analytics is not loaded');
    return;
  }

  const fields = {
    userId: userInfo.personId,
  };

  if (gaDimension_userAudienceType && userInfo.audienceType) {
    fields[gaDimension_userAudienceType] = userInfo.audienceType;
  }

  ga('set', fields);
  ga('send', 'event', 'authentication', 'User ID available');
}
