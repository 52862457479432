<template>
  <tab-panel :tabs="
    [
     { name: 'Informações do ponto', component: StopInfoTab, props: { stop: deliveryStop, driver, trackingCode } },
     { name: 'Comprovante do serviço', component: PodInfoTab, props: { pod } },
    ]"
  />
</template>

<script>
import TabPanel from "../../../hounDS/components/TabbedPanel";
import StopInfoTab from "./StopInfoTab";
import PodInfoTab from "./PodInfoTab";

export default {
  name: "DetailsPanel",
  components: {TabPanel},

  props: {
    trackingCode: String,
    pod: Object,
    driver: Object,
    deliveryStop: Object
  },

  computed: {
    StopInfoTab: () => StopInfoTab,
    PodInfoTab: () => PodInfoTab
  }
}
</script>
