<template>
  <gmap-map
    class="billingStatement-orderOverview-itineraryMap"
    ref="map"
    :center="mapCenter"
    map-type-id="roadmap"
  >
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :label="m.label"
      @click="center=m.position"
    ></gmap-marker>

    <gmap-polyline
      v-if="polyline"
      v-bind:path="polyline"
      v-bind:options="polylineOptions"
    ></gmap-polyline>
  </gmap-map>

</template>

<script>
  import { gmapApi } from 'vue2-google-maps';

  export default {
    name: 'ItineraryMap',

    props: {
      geometry: String,
      stops: Array,
    },

    data() {
      return {
        mapCenter: {lat: -23.5505, lng: -46.6333}, // São Paulo
        polylineOptions: {
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2
        }
      };
    },

    computed: {
      google: gmapApi,

      markers() {
        return this.stops.map((stop, index) => ({
          position: {
            lat: stop.address.latitude,
            lng: stop.address.longitude,
          },
          label: (index + 1).toString()
        }));
      },

      polyline() {
        // this.google - Check if Maps API has been loaded
        if (this.geometry && this.google) {
          return this.google.maps.geometry.encoding.decodePath(this.geometry);
        }
        else {
          return this.stops.map((stop) => ({
            lat: stop.address.latitude,
            lng: stop.address.longitude,
          }));
        }
      },

      bounds() {
        // this.google - Check if Maps API has been loaded
        if (!this.google) return null;
        const bounds = new this.google.maps.LatLngBounds();
        this.markers.forEach((marker) => {
          bounds.extend(marker.position);
        });
        return bounds;
      },
    },

    mounted() {
      this.$refs.map.$mapPromise.then(() => {
        this.$refs.map.$mapObject.fitBounds(this.bounds);
      });
    },
  }

</script>

<style scoped>

  .billingStatement-orderOverview-itineraryMap {
    height: 100%;
  }

</style>
