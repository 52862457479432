<template>
  <div class="billingStatement-orderOverview-itinerary">
    <itinerary-map
      class="billingStatement-orderOverview-itinerary__itineraryMap"
      :geometry="order.geometry"
      :stops="order.stops"
    ></itinerary-map>
    <stops
      class="billingStatement-orderOverview-itinerary__stops"
      :stops="order.stops"
    ></stops>
  </div>
</template>

<script>
  import ItineraryMap from './ItineraryMap.vue';
  import Stops from './Stops.vue';

  export default {
    name: 'Itinerary',

    components: { ItineraryMap, Stops },

    props: {
      order: Object,
    },
  }
</script>

<style scoped>

  .billingStatement-orderOverview-itinerary {
    display: flex;
    flex-direction: row;
  }

  .billingStatement-orderOverview-itinerary__itineraryMap {
    flex-grow: 1;
  }

  .billingStatement-orderOverview-itinerary__stops {
    width: 350px;
  }

</style>
