import { Auth } from './user';
import { Logger, ConsoleLogHandler } from './logger';

export const auth = Auth.create();
export const logger = new Logger();

logger.setHandler(new ConsoleLogHandler());

const app = {
  auth,
  logger,
};

export default app;
