<template>
  <label class="spotRequest-vehicleSelectionItem noselect">
    <div class="spotRequest-vehicleSelectionItem__watermark">
      <span class="spotRequest-vehicleSelectionItem__watermark__text">
        {{ vehicle.watermarkText }}
      </span>
    </div>

    <div class="spotRequest-vehicleSelectionItem__content">
      <div class="spotRequest-vehicleSelectionItem__vehicle__container">
        <input
          v-model="selectedVehicle"
          class="spotRequest-vehicleSelectionItem__vehicle__radio"
          type="radio"
          name="spotRequest-vehicleSelectionItem__selectedVehicle"
          :value="vehicle"
        >
        <div class="spotRequest-vehicleSelectionItem__vehicle">
          <span class="spotRequest-vehicleSelectionItem__vehicle__type">
            {{ vehicle.shortName }}
          </span>
          <i
            v-if="vehicle.isBeta"
            class="spotRequest-vehicleSelectionItem__beta"
          >
            beta
          </i>
          <span class="spotRequest-vehicleSelectionItem__vehicle__description">
            {{ vehicle.description }}
          </span>
        </div>
      </div>

      <div class="spotRequest-vehicleSelectionItem__maxCapacity">
        <span class="spotRequest-vehicleSelectionItem__maxCapacity__label">
          Capacidade Max.
        </span>
        <span class="spotRequest-vehicleSelectionItem__maxCapacity__value">
          {{ vehicle.maxCapacityInKg.toLocaleString('pt-BR') }} kg
        </span>
      </div>
    </div>

    <div
      class="spotRequest-vehicleSelectionItem__coloredTab"
      :style="tabStyle"
    />
  </label>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'VehicleSelectionItem',

  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('spotRequest', {
      selectedVehicleFromStore: (state) => state.selectedVehicle,
    }),

    ...mapGetters('spotRequest', {
      selectedVehicleTypeFromStore: 'vehicleType',
      vehicleHasBodyType: 'vehicleHasBodyType',
    }),

    selectedVehicle: {
      get() {
        return this.selectedVehicleFromStore;
      },
      set(value) {
        this.$store.commit('spotRequest/updateSelectedVehicle', value);
        if (!this.vehicleHasBodyType) {
          this.$store.commit('spotRequest/updateSelectedVehicleBodyTypes', []);
        }
      },
    },

    tabStyle() {
      return {
        'background-color': this.vehicle.color,
      };
    },
  },
};
</script>

<style scoped>
.spotRequest-vehicleSelectionItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 160px;
  height: 120px;
  margin: 16px 16px 16px 0;
  border: solid 1px #e7e7e7;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: transform 0.3s ease-out;
  position: relative;
}

.spotRequest-vehicleSelectionItem:hover {
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease-out;
}

.spotRequest-vehicleSelectionItem:hover,
.spotRequest-vehicleSelectionItem:focus,
.spotRequest-vehicleSelectionItem:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.spotRequest-vehicleSelectionItem__watermark {
  display: flex;
  align-items: flex-end;
  position: absolute;
  width: 160px;
  height: 120px;
  z-index: 0;
  overflow: hidden;
}

.spotRequest-vehicleSelectionItem__watermark__text {
  text-transform: uppercase;
  font-size: 56px;
  font-weight: bold;
  word-wrap: break-word;
  color: #f7f7f7;
  line-height: 40px;
  max-width: 88px;
  margin: 0 0 -4px -8px;
}

.spotRequest-vehicleSelectionItem__content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 138px;
  height: 99px;
  margin: 11px 0 10px 16px;
  z-index: 1;
}

.spotRequest-vehicleSelectionItem__coloredTab {
  width: 6px;
  height: 120px;
  z-index: 1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: inset 1px 0 2px 1px rgba(0, 0, 0, 0.12);
}

.spotRequest-vehicleSelectionItem__vehicle__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spotRequest-vehicleSelectionItem__vehicle {
  align-content: center;
  margin-left: 2px;
}

.spotRequest-vehicleSelectionItem__vehicle__type {
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #2f2f2f;
  cursor: pointer;
}

.spotRequest-vehicleSelectionItem__vehicle__description {
  display: block;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.2px;
  color: #848b8d;
  cursor: pointer;
}

.spotRequest-vehicleSelectionItem__maxCapacity {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.spotRequest-vehicleSelectionItem__maxCapacity__label {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: #848b8d;
  cursor: pointer;
}

.spotRequest-vehicleSelectionItem__maxCapacity__value {
  display: block;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #848b8d;
  cursor: pointer;
}

.spotRequest-vehicleSelectionItem__beta {
  background: #198bfd;
  border: 1px solid #198bfd;
  border-radius: 4px;
  padding: 0 5px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 14px;
  position: absolute;
  right: 8px;
  top: 0;
}
</style>
