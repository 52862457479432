<template>
  <div>
    <table class="table table--zebra">
      <caption>
        Resumo
      </caption>
      <thead>
        <tr>
          <th scope="col">
            Tipo de Veículo
          </th>
          <th
            class="table__right-text"
            scope="col"
          >
            Qtde. de solicitações
          </th>
          <th
            class="table__right-text"
            scope="col"
          >
            Frete médio por solicit. (R$)
          </th>
          <th
            class="table__right-text"
            scope="col"
          >
            Valor Total (R$)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(summaryByVehicleType, key) in pricesByVehicleType"
          :key="key"
        >
          <td
            class="table__right-text"
            data-label="Tipo do veículo"
          >
            {{ summaryByVehicleType.vehicleType | vehicleTypeLabel }}
          </td>
          <td
            class="table__right-text"
            data-label="Qtde. de solicitações"
          >
            {{ summaryByVehicleType.ordersCount }}
          </td>
          <td
            class="table__right-text"
            data-label="Frete médio por solicit. (R$)"
          >
            {{ summaryByVehicleType.averagePrice | moneyFormat }}
          </td>
          <td
            class="table__right-text"
            data-label="Valor Total (R$)"
          >
            {{ summaryByVehicleType.totalPrice | moneyFormat }}
          </td>
        </tr>
        <tr>
          <td
            class="table__right-text"
            data-label="Tipo de veículo"
          >
            <strong>
              Todos
            </strong>
          </td>
          <td
            colspan="3"
            class="table__right-text"
            data-label="Total"
          >
            <strong>
              {{ summary.totalPrice | moneyFormat }}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="details collapsible-wrapper"
      :class="showSummaryTaxes ? '' : 'collapsed'"
    >
      <div class="collapsible with-gap margin-top">
        <div class="totalPrices">
          <div class="totalPricesRow">
            <table class="totalsSummary">
              <tbody>
              </tbody>
            </table>
            <!-- Disabled while we don't decide what to do -->
            <taxes-overview
              :taxes="summary.taxes"
              :tax-total-price="summary.taxTotalPrice"
              class="taxes-card"
            ></taxes-overview>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { moneyFormat } from '../common/filters/numberFormatters'
  import { vehicleTypeLabel } from '@/common/vehicleTypes';
  import TaxesOverview from './TaxesOverview.vue';
  import { VPopover } from 'v-tooltip'

  export default {
    name: "BillingSummary",

    components: {TaxesOverview, VPopover},

    props: [
      'summary',
      'showSummaryTaxes'
    ],

    computed: {
      pricesByVehicleType() {
        return this.summary ? this.summary.byVehicleType : [];
      }
    },

    filters: {
      moneyFormat,
      vehicleTypeLabel,
    },
  }
</script>

<style scoped>
  h2 {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #b7b7b7;
    text-transform: uppercase;
    margin: 0 0 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .totalPrices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .totalPricesRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-content: stretch;
    width: 100%;
  }

  /*
  .totalPricesRow > * {
    margin: 15px;
  }
  */

  .emptySummaryMessage {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.8px;
    color: #878787;
    width: 100%;
    text-align: center;
    padding: 38px 0;
    border-bottom: solid 1px #dcdcdc;
  }

  .verticalSeparator {
    width: 100%;
    margin-top: 12px;
    border-bottom: solid 1px #dcdcdc;
  }

  .totalsSummary > tbody > tr {
    height: 48px;
  }

  .totalsSummary > tbody > tr:nth-child(odd) {
    background: #f9f9f9
  }

  .totalsSummary td:first-child {
    text-align: right;
  }

  .totalsSummary__prices {
    width: 100px;
  }

  .totalsSummary__info {
    padding: 0;
  }

  .totalsSummary__info__icon {
    margin: 4px 3px 0 3px;
  }

  .totalsSummary__label {
    padding-right: 7px;
    font-size: 14px;
    width: 162px;
    font-weight: 600;
  }

  .taxes-card {
    width: 100%;
    font-size: 0.9em;
  }
</style>
