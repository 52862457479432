import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');

// extra plugins usage example for future reference
// import relativeTime from 'dayjs/plugin/relativeTime';
// dayjs.extend(relativeTime);

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    },
  },
});
