import { formatDate } from '../../common/filters/dateFormatter';
import timelineStatus from '../../hounDS/components/timeline/timelineStatus';

const stepTranslations = {
  ALLOCATED: 'Alocada',
  ON_THE_WAY: 'A caminho',
  LOADED: 'Carregando',
  IN_PROGRESS: 'Em execução',
  FINISHED: 'Finalizado',
};

function translateStep(step) {
  return stepTranslations[step];
}

function buildDoneDescription(time) {
  return formatDate(time, 'DD/MM [às] HH:mm');
}

function buildEtaDoingDescription(estimateArrival) {
  const formattedTime = formatDate(estimateArrival, 'HH:mm');
  return `Próxima chegada estimada: ${formattedTime}`;
}

function buildDescription(time, status, eta) {
  if (status === timelineStatus.DONE) {
    return buildDoneDescription(time);
  } if (status === timelineStatus.DOING && eta) {
    return buildEtaDoingDescription(eta.estimatedArrival);
  }
  return '';
}

function buildTimelineElement({ step, time, status }, eta) {
  return {
    status,
    title: translateStep(step),
    description: buildDescription(time, status, eta),
  };
}

function buildOrderTimelineElements(timeline, eta) {
  return timeline.map(
    (element) => buildTimelineElement(element, eta),
  );
}

export { buildOrderTimelineElements };
