<template>
  <div>

    <div class="spotRequest-spotScheduling__wrapper">
      <div class="spotRequest-spotScheduling-scheduleTypeSelection">

        <div v-for="scheduleType in scheduleTypes">
          <input
            type="radio"
            name="schedule"
            class="spotRequest-spotScheduling-scheduleTypeSelection__radio"
            :id="'schedule' + scheduleType"
            :value="scheduleType"
            v-model="inputScheduling.type"
            @click="onScheduleTypesItemClicked(scheduleType)"
          >
          <label
            :for="'schedule' + scheduleType"
          >
            {{ getScheduleTypeLabel(scheduleType) }}
          </label>
        </div>

      </div>
    </div>

    <date-time-picker-modal
      v-if="dateTimePickerModalVisible"
      v-model="inputScheduling.dateTime"
      :date-disabled="modalDateInputDisabled"
      :title="dateTimePickerModalTitle"
      :min-feasible-time-minutes-into-future="minAdvanceBookingMinutes"
      @close="onDateTimePickerModalClosed"
      @confirm="onDateTimePickerModalConfirmed"
    ></date-time-picker-modal>
  </div>

</template>

<script>
import DateTimePickerModal from '../../common/DateTimePickerModal.vue';
import { scheduleTypes, names as scheduleTypeNames, getScheduleTypeNameWithTime } from './ScheduleTypes';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: "SpotScheduling",

  components: { DateTimePickerModal },

  data() {
    const { scheduling } = this.$store.state.spotRequest;
    return {
      // analogous to an <input> input/keypress events and change event
      inputScheduling: { ...scheduling }, // before the user confirms their selection via the Confirm button
      confirmedScheduling: { ...scheduling }, // already confirmed by the user

      dateTimePickerModalVisible: false,
    };
  },

  computed: {
    ...mapState('spotRequest', ['config']),

    minAdvanceBookingMinutes() {
      return this.config?.min_advance_booking_time_minutes ?? 45;
    },

    scheduleTypes() {
      const immediateAvailable = !!this.config?.immediate_booking_available;

      const availableTypes = { ...scheduleTypes };
      if (!immediateAvailable) {
        delete availableTypes.NOW;
      }

      return availableTypes;
    },

    modalDateInputDisabled() {
      return this.inputScheduling.type !== scheduleTypes.CUSTOM;
    },

    dateTimePickerModalTitle() {
      return 'Agendar para ' + scheduleTypeNames[this.inputScheduling.type].toLowerCase();
    },
  },

  methods: {
    getScheduleTypeLabel(scheduleType) {
      if (this.inputScheduling.type !== scheduleType || this.confirmedScheduling.type !== this.inputScheduling.type) {
        return scheduleTypeNames[scheduleType];
      }

      return getScheduleTypeNameWithTime(scheduleType, this.confirmedScheduling.dateTime);
    },

    onScheduleTypesItemClicked(scheduleType) {
      this.inputScheduling.type = scheduleType;

      switch (scheduleType) {
        case (scheduleTypes.NOW):
          this.inputScheduling.dateTime = null;
          this.confirmedScheduling = { ...this.inputScheduling };
          this.onConfirmSchedulingNow();
          break;
        case (scheduleTypes.TODAY):
          this.inputScheduling.dateTime = moment().add(1, 'hours');
          this.dateTimePickerModalVisible = true;
          break;
        case (scheduleTypes.TOMORROW):
          const tomorrowRecommendedDateTime = moment().add(1, 'day');
          tomorrowRecommendedDateTime.set({ hour: 6, minute: 0, second: 0 });
          this.inputScheduling.dateTime = tomorrowRecommendedDateTime;
          this.dateTimePickerModalVisible = true;
          break;
        case (scheduleTypes.CUSTOM):
          this.inputScheduling.dateTime = moment().add(2, 'day');
          this.dateTimePickerModalVisible = true;
          break;
      }
    },

    onDateTimePickerModalClosed() {
      this.inputScheduling = { ...this.confirmedScheduling };
      this.dateTimePickerModalVisible = false;
    },

    onDateTimePickerModalConfirmed(selectedDateTime) {
      this.inputScheduling.dateTime = selectedDateTime;
      this.confirmedScheduling = { ...this.inputScheduling };
      this.dateTimePickerModalVisible = false;

      this.$store.commit('spotRequest/updateScheduling', this.confirmedScheduling);
    },

    onConfirmSchedulingNow() {
      this.$store.commit('spotRequest/updateScheduling', this.confirmedScheduling);
    },
  },
}
</script>

<style scoped>

  .spotRequest-spotScheduling-scheduleTypeSelection {
    display: flex;
  }

  .spotRequest-spotScheduling-scheduleTypeSelection__radio {
    position: absolute;
    left: -9999px;
  }

  .spotRequest-spotScheduling-scheduleTypeSelection__radio + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.4px;
    background-color: #C5D1E2;
    color: #000000;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 8px;
    padding: 8px 20px 8px 47px;
    margin-right: 20px;
    opacity: 0.7;
  }

  .spotRequest-spotScheduling-scheduleTypeSelection__radio:checked + label {
    opacity: 1;
    box-shadow: 0 2px 4px 0 rgba(68, 59, 10, 0.121);
    background-color: #FFF5D2;
    border-color: #fff;
    border-radius: 8px;
    color: #000000;
  }

  .spotRequest-spotScheduling-scheduleTypeSelection__radio + label:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 6px;
    width: 24px;
    height: 24px;
    border: 1px solid #333333;
    border-radius: 100%;
  }

  .spotRequest-spotScheduling-scheduleTypeSelection__radio:checked + label:before {
    border: 1px solid #000000;
  }

  .spotRequest-spotScheduling-scheduleTypeSelection__radio + label:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 6px;
    opacity: 0;
    padding: 1px;
    background: url('/jade/public/icon-check.svg') no-repeat center center;
  }

  .spotRequest-spotScheduling-scheduleTypeSelection__radio:checked + label:after {
    opacity: 1;
  }

</style>
