<template>
  <div :class="[text.richListItemWithIcon, text.block]">
    <div v-if="icon" :class="[text.richListItemWithIcon, text.icon]">
      <Icon :name="icon" />
    </div>
    <div :class="[text.richListItemWithIcon, text.details]">
      <div v-if="label" :class="[text.richListItemWithIcon, text.label]">
        {{ label }}
      </div>
      <div :class="[text.richListItemWithIcon, text.value, text.bold]" >
        <!-- Plain text content received as a Prop value -->
        <!-- or HTML content provided in the slot -->
        <slot>{{ value }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import text from '../styles/text.module.scss'
import Icon from "./Icon";

export default {
  name: "RichListItemWithIcon",

  components: { Icon },

  props: {
    icon: String,
    label: String,
    value: String
  },

  computed: {
    text: () => Object.freeze(text)
  }
}
</script>

