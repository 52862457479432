export const requestEnum = {
  INITIAL: '',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAIL: 'fail',
};

export const requestMessages = {
  SUCCESS: 'Solicitação enviada, dados atualizados com sucesso!',
  FAIL: 'Ocorreu uma falha ao processar a solicitação',
  NO_DATA: 'Não temos dados suficientes para concluir esta requisição.',
};

export const emailMessages = {
  SUCCESS: 'Email enviado com sucesso!',
  FAIL: 'Ocorreu uma falha ao processar a solicitação',
  NO_DATA: 'Email inválido, por favor verique se digitou corretamente.',
};
