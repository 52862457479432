import { serviceTypes } from "@/spot/common/serviceTypes";
import { merchandiseToSpotDocument } from "@/spot/spotRequest/finishRequest";
import { createPlaceIdFromMerchandise, defaultPlaceIds } from "@/spot/spotRequest/transportation/PlaceIds";

function convertMerchandiseToShipment(merchandise, serviceType) {
  const merchandisePlaceId = createPlaceIdFromMerchandise(merchandise);
  const customerWarehousePlaceId = defaultPlaceIds.CUSTOMER_WAREHOUSE;

  let originPlaceId = null;
  let destinationPlaceId = null;

  switch (serviceType) {
    case serviceTypes.DELIVERY:
      [originPlaceId, destinationPlaceId] = [customerWarehousePlaceId, merchandisePlaceId];
      break;
    case serviceTypes.PICKUP:
      [originPlaceId, destinationPlaceId] = [merchandisePlaceId, customerWarehousePlaceId];
      break;
    default:
      throw Error(`Could not build shipment. Service of type ${serviceType} is invalid.`);
  }

  return {
    id: merchandise.id,
    originPlaceId,
    destinationPlaceId,
    accessKey: merchandise.fiscalDocument ? merchandise.fiscalDocument.accessKey : null,
    code: merchandise.trackingCode,
    document: merchandiseToSpotDocument(merchandise),
  };
}

// TODO: this conversion should not happen! `merchandises` should be replaced by `shipments`.
function convertMerchandisesToShipments(merchandises, serviceType) {
  return merchandises.map(merchandise => convertMerchandiseToShipment(merchandise, serviceType));
}

export { convertMerchandisesToShipments };
