/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import { makeUiVehicle, } from '@/spot/common/vehicleTypes';
import { getSpotConfig, SpotConfig } from '@/spot/common/spotConfig';
import { scheduleTypes } from '@/spot/common/ScheduleTypes';

const state = () => ({
  externalId: null,
  totalWeightInKilograms: null,
  selectedVehicle: null,
  selectedVehicleBodyTypes: [],
  selectedVehicleExtras: [],

  willCalculateAdValorem: false,
  merchandisesTotalPrice: null,

  vehicleTypesThatRecommendDriverAssistant: ['VAN', 'HR', 'IVECO', '3/4', 'TOCO', 'TRUCK', 'BITRUCK', 'CARRETA'],
  driverAssistants: {
    count: 0,
    requested: false,
  },

  /** @type SpotConfig|null */
  config: null,

  scheduling: {
    dateTime: null,
    type: scheduleTypes.NOW,
  },

  /** @type UiVehicleType[] */
  availableVehicles: [],
});

// getters
const getters = {
  newVehiclePolicy(state) {
    if (state.availableVehicles.length === 0) {
      // Not yet loaded
      return null;
    }

    const available = state.availableVehicles.map((vehicle) => vehicle.id);

    // Se qualquer um destes veículos estiver desativado, entenderemos que o cliente deve receber o aviso da nova política/operação de veículos.
    const checkVehicles = ['VAN', 'HR', 'IVECO'];
    for (const vehicle of checkVehicles) {
      if (! available.includes(vehicle)) {
        return true;
      }
    }

    return false;
  },

  hasValidScheduling(state) {
    if (state.config != null && !state.config.immediate_booking_available) {
      return state.scheduling.type !== scheduleTypes.NOW;
    }

    return true;
  },

  isDriverAssistantRecommended(state) {
    return state.vehicleTypesThatRecommendDriverAssistant.includes(state.selectedVehicle.id);
  },
  driverAssistantsConfig(state) {
    return Object.fromEntries(
      state.availableVehicles.map((vehicle) => [vehicle.id, vehicle.driverAssistants]),
    );
  },
  driverAssistants(state) {
    if (!state.driverAssistants.requested) {
      return 0;
    }
    return state.driverAssistants.count;
  },
  vehicleType(state) {
    if (!state.selectedVehicle) {
      return null;
    }
    return state.selectedVehicle.id;
  },
  vehicleHasBodyType(state) {
    if (!state.selectedVehicle) {
      return null;
    }
    return state.selectedVehicle.possibleBodyTypes?.length > 0;
  },
  selectedVehicleExtraIds(state) {
    return (state.selectedVehicleExtras || []).map(({ id }) => id);
  },
  availableVehicleExtras(state) {
    return state.selectedVehicle?.availableExtras || [];
  },
  vehicleBodyTypeIsRequired(state) {
    if (!state.selectedVehicle) {
      return null;
    }
    return !!state.selectedVehicle.bodyTypeRequired;
  },
  willCalculateAdValorem(state) {
    return state.willCalculateAdValorem;
  },
  merchandisesTotalPrice(state) {
    return state.merchandisesTotalPrice;
  },
};

// actions
const actions = {
  onActiveExperimentsUpdated: {
    root: true,
    handler({ commit }, activeExperiments) {
      commit('updateAdValoremRequirements', activeExperiments.includes('feature:ad_valorem'));
    },
  },

  async fetchVehicles({ commit }) {
    const response = await fetch('/jade/api/spots/vehicles-v2');
    /** @type {{vehicles: VehicleType[]}} */
    const body = await response.json();

    commit('updateVehicles', body.vehicles.map(
      (vehicle) => makeUiVehicle(vehicle),
    ));
  },

  async fetchConfig({ commit }) {
    const config = await getSpotConfig();
    commit('updateConfig', config);
  },
};

// mutations
const mutations = {
  resetSelectedVehicleExtras(state) {

  },

  updateConfig(state, config) {
    state.config = config;
  },

  /** @param {VehicleType[]} vehicles */
  updateVehicles(state, vehicles) {
    state.availableVehicles = vehicles;
  },

  updateSelectedVehicle(state, vehicle) {
    state.selectedVehicle = vehicle;
    state.selectedVehicleExtras = [];
  },

  updateSelectedVehicleBodyTypes(state, selectedVehicleBodyTypes) {
    state.selectedVehicleBodyTypes = selectedVehicleBodyTypes;
  },

  updateSelectedVehicleExtras(state, value) {
    state.selectedVehicleExtras = value;
  },

  updateTotalWeightInKilograms(state, totalWeightInKilograms) {
    state.totalWeightInKilograms = totalWeightInKilograms;
  },

  updateExternalId(state, externalId) {
      state.externalId = externalId || null;
  },

  updateDriverAssistants(state, driverAssistants) {
    state.driverAssistants = driverAssistants;
  },

  updateAdValoremRequirements(state, willCalculateAdValorem) {
    state.willCalculateAdValorem = willCalculateAdValorem;
  },

  updateMerchandisesTotalPrice(state, totalPrice) {
    state.merchandisesTotalPrice = totalPrice;
  },

  updateScheduling(state, scheduling) {
    state.scheduling = scheduling;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
