export const stopTaskTypes = {
  UNKNOWN: 'UNKNOWN',
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
  RETURN: 'RETURN',
};

export const stopTaskTypeNames = {
  UNKNOWN: 'Desconhecido',
  PICKUP: 'Coleta',
  DELIVERY: 'Entrega',
  LOADING: 'Carregamento',
  UNLOADING: 'Descarregamento',
  RETURN: 'Devolução',
};
