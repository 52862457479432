<template>
    <div
      class="spotRequest-merchandiseDetailsInput-content"
    >
      <div class="spotRequest-merchandiseDetailsInput-forms">
        <div style="flex: 1">
          <div class="spotRequest-merchandiseDetailsInput-row">
            <div class="spotRequest-merchandiseDetailsInput-trackingCode">
              <div class="spotRequest-merchandiseDetailsInput-subTitle">
                Código buscável
              </div>
              <div v-if="isEdit">
                {{ merchandiseToEdit.trackingCode }}
              </div>
              <input
                v-if="!isEdit"
                type="text"
                class="spotRequest-merchandiseDetailsInput-textInput"
                v-bind:class="{'spotRequest-merchandiseDetailsInput-textInput--danger': trackingCodeHasErrors}"
                v-model="merchandiseToEdit.trackingCode"
                placeholder="Ex: número da nota fiscal"
                ref="trackingCode"
              >
              <span v-if="documentCodeTooLong" class="fa fa-warning">
                Código excede o tamanho máximo de 44 caracteres!
              </span>
              <div v-if="trackingCodePatternInvalid" class="spotRequest-merchandiseDetailsInput-errorMessage">
                <img class="spotRequest-merchandiseDetailsInput-errorMessage__img" src="/jade/public/icon-error.svg">
                <label class="spotRequest-merchandiseDetailsInput-errorMessage__label">
                  O código deve conter apenas números, letras (não acentuadas) e/ou os caracteres: - * @ _
                </label>
              </div>
              <div v-if="trackingCodeAlreadyExists" class="spotRequest-merchandiseDetailsInput-errorMessage">
                <img class="spotRequest-merchandiseDetailsInput-errorMessage__img" src="/jade/public/icon-error.svg">
                <label class="spotRequest-merchandiseDetailsInput-errorMessage__label">
                  Este código já está em uso.
                </label>
              </div>
              <div
                v-if="showTrackingCodeDescription"
                class="spotRequest-merchandiseDetailsInput-description"
              >
                Use este código para identificar a mercadoria para o motorista ou para acompanhar o andamento da entrega.
              </div>
            </div>
          </div>

          <div class="spotRequest-merchandiseDetailsInput-row">
            <div class="spotRequest-merchandiseDetailsInput-completeAddress">
              <div class="spotRequest-merchandiseDetailsInput-subTitle">
                Endereço completo ou CEP + número
              </div>
              <address-finder
                ref="addressFinder"
                class="spotRequest-merchandiseDetailsInput-container"
                :address="merchandiseToEdit.address"
                @focus-trap-modify="modifyMerchandiseInputFocusTrap"
                @on-querying-address-status-changed="onQueryingAddressStatusChanged"
                @on-address-changed="onAddressChanged"
              />
            </div>
          </div>

          <div class="spotRequest-merchandiseDetailsInput-row">
            <div class="spotRequest-merchandiseDetailsInput-addressComplement">
              <div class="spotRequest-merchandiseDetailsInput-subTitle">
                Complemento
              </div>
              <input
                class="spotRequest-merchandiseDetailsInput-textInput"
                type="text"
                v-model="merchandiseToEdit.address.complement"
                maxlength="100"
              >
            </div>

            <div class="spotRequest-merchandiseDetailsInput-addressComplementSpacer"></div>
          </div>

          <div class="spotRequest-merchandiseDetailsInput-row spotRequest-merchandiseDetailsInput-row--columnFlex">
            <div class="spotRequest-merchandiseDetailsInput-subTitle">
              Horário de entrega
            </div>
            <time-window
              v-model="merchandiseToEdit.timeWindow"
              @on-time-window-validation="onTimeWindowValidation"
            />
          </div>

          <div class="spotRequest-merchandiseDetailsInput-row">
            <div class="spotRequest-merchandiseDetailsInput-instructions">
              <div class="spotRequest-merchandiseDetailsInput-subTitle">
                Observação
              </div>
              <textarea
                class="spotRequest-merchandiseDetailsInput-textAreaInput"
                v-model="merchandiseToEdit.instructions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import AddressFinder from '../common/AddressFinder.vue'
import TimeWindow from '../../common/TimeWindow.vue'
import { isProductCodePatternValid } from './addMerchandises'

export default {
  name: 'MerchandiseDetailsInput',

  components: {
    TheMask,
    TimeWindow,
    AddressFinder,
  },

  props: {
    merchandiseToEdit: Object,
    merchandises: Array,
  },

  data() {
    return {
      validTimeWindow: true,
      trackingCodePatternInvalid: false,
      trackingCodeAlreadyExists: false,
      deliveryPrice: {},
      queryingAddress: false,
      documentCodeTooLong: false,
    };
  },

  computed: {
    incompleteMerchandise() {
      return this.queryingAddress ||
        !this.validTimeWindow ||
        !this.merchandiseToEdit.trackingCode ||
        this.trackingCodePatternInvalid ||
        this.trackingCodeAlreadyExists ||
        !this.addressHasGeolocation ||
        this.documentCodeTooLong;
    },

    trackingCodeHasErrors() {
      return this.trackingCodePatternInvalid || this.trackingCodeAlreadyExists || this.documentCodeTooLong;
    },

    showTrackingCodeDescription() {
      return !this.isEdit && !this.trackingCodeHasErrors;
    },

    isEdit() {
      return Boolean(this.merchandiseToEdit.id);
    },

    addressHasGeolocation() {
      return this.merchandiseToEdit.address.geolocation &&
        this.merchandiseToEdit.address.geolocation.latitude &&
        this.merchandiseToEdit.address.geolocation.longitude;
    },
  },

  methods: {
    onTimeWindowValidation(validTimeWindow) {
      this.validTimeWindow = validTimeWindow;
    },

    onQueryingAddressStatusChanged(isQuerying) {
      this.queryingAddress = isQuerying;
      this.$emit('on-querying-address-status-changed')
    },

    onAddressChanged(newAddress) {
      const originalAddress = this.merchandiseToEdit.address;
      this.merchandiseToEdit.address = {
        ...newAddress,
        complement: originalAddress.complement,
      }
    },

    modifyMerchandiseInputFocusTrap({disabled}) {
      this.$emit('focus-trap-modify', {disabled})
    },
  },

  watch: {
    'merchandiseToEdit.trackingCode': function (newTrackingCode) {
      const upperCasedTrackingCode = newTrackingCode.toUpperCase();
      this.trackingCodePatternInvalid = !isProductCodePatternValid(upperCasedTrackingCode);
      this.trackingCodeAlreadyExists = this.merchandises.some(merchandise => upperCasedTrackingCode === merchandise.id);
      this.documentCodeTooLong = newTrackingCode.length > 44;
    },
    incompleteMerchandise(newValue) {
      this.$emit('incomplete-merchandise', newValue);
    }
  },

  mounted() {
    this.$emit('incomplete-merchandise', this.incompleteMerchandise);

    if (this.$refs.trackingCode) {
      this.$refs.trackingCode.focus();
    }
    else {
      this.$refs.addressFinder.$el.focus();
    }
  },
}
</script>

<style scoped>
  .spotRequest-merchandiseDetailsInput-content {
    display: flex;
    flex-direction: column;
  }

  .spotRequest-merchandiseDetailsInput-forms {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .verticalSeparator {
    margin: 24px 28px 0 28px;
    height: auto;
  }

  .spotRequest-merchandiseDetailsInput-subTitle {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #858585;
  }

  .spotRequest-merchandiseDetailsInput-description {
    padding-top: 4px;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #858585;
  }

  .spotRequest-merchandiseDetailsInput-trackingCode {
    width: 100%;
  }

  .spotRequest-merchandiseDetailsInput-row {
    padding-top: 24px;
    display: flex;
  }

  .spotRequest-merchandiseDetailsInput-row--columnFlex {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .spotRequest-merchandiseDetailsInput-completeAddress {
    flex-grow: 1;
  }

  .spotRequest-merchandiseDetailsInput-addressComplement {
    flex-grow: 1;
  }

  .spotRequest-merchandiseDetailsInput-addressComplementSpacer {
    margin-left: 16px;
    width: 80px;
    flex-grow: 0;
  }

  .spotRequest-merchandiseDetailsInput-instructions {
    width: 100%;
  }

  .spotRequest-merchandiseDetailsInput-textInput {
    box-sizing : border-box;
    width: 100%;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 9px 13px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .spotRequest-merchandiseDetailsInput-textInput--danger {
    border-color: #e8425a !important;
  }

  .spotRequest-merchandiseDetailsInput-textInput--warning {
    border-color: #fba30f;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .spotRequest-merchandiseDetailsInput-textInput::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textInput::-moz-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textInput:-ms-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textInput:focus {
    border-color: #28c0f1;
    outline: none;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput {
    box-sizing : border-box;
    width: 100%;
    height: 72px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 7px 13px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .spotRequest-merchandiseDetailsInput-textAreaInput::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput::-moz-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput:-ms-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput:focus {
    border-color: #28c0f1;
    outline: none;
  }

  .spotRequest-merchandiseDetailsInput-container {
    position: relative;
  }

  .spotRequest-merchandiseDetailsInput-errorMessage {
    display: flex;
    align-items: center;
    padding-top: 4px;
  }

  .spotRequest-merchandiseDetailsInput-errorMessage__img {
    width: 20px;
    height: 20px;
  }

  .spotRequest-merchandiseDetailsInput-errorMessage__label {
    margin-left: 8px;
    color: #ed293c;
  }

  .fa-warning {
    color: #ed293c;
    margin-bottom: 8px;
  }

</style>
