// Check if embedded in a page from the same domain
export function isEmbedded() {
  return window.parent !== window && window.origin === window.parent.origin;
}

export function closeModal() {
  if (!isEmbedded()) {
    return;
  }

  window.parent.history.back();
}

export default function tmsInteropInit({ router }) {
  if (!isEmbedded()) {
    return;
  }

  window.vueRouter = router;
}
