<template>
  <div class="passwordPreferences panel">
    <h2>  <i class="fa fa-lock"></i> Alterar senha</h2>
    <div>
      <label for="password_new">Nova Senha</label>
      <input v-model="password_new" type="password" name="password_new" id="password_new" required/>
    </div>

    <div>
      <label for="password_repeat">Repita</label>
      <input v-model="password_repeat" type="password" name="password_repeat" id="password_repeat" required />
    </div>
    <button @click="updatePassword"> Alterar </button>
  </div>
</template>

<script>
  import { updateUserPassword } from './profileService'
  import { getErrorResponseBody } from '../client/errorUtils'
  import { requestEnum, requestMessages } from '../client/requestStatesEnum'

  export default {
    name: "ChangePassword",

    data() {
      return {
        password_repeat: '',
        password_new: '',
      }
    },

    methods: {
      updatePassword() {
        let validatePasswordObject = {
          password_new: this.password_new,
          password_repeat: this.password_repeat,
        };

        if(!this.validatePassword(validatePasswordObject)){
          return;
        }

        let personId = this.personId;

        if (!personId) {
          this.$root.toggleGlobalAlert({
            type:  requestEnum.FAIL,
            message: requestMessages.NO_DATA
          });

          return;
        }

        let updatePasswordObject = { password: validatePasswordObject.password_new };

        updateUserPassword(personId, updatePasswordObject).then(() => {
          this.$root.toggleGlobalAlert({
            type:requestEnum.SUCCESS,
            message:requestMessages.SUCCESS
          });

        }).catch(err => {
          this.$root.toggleGlobalAlert({
            type:requestEnum.FAIL,
            message: getErrorResponseBody(err).message || requestMessages.FAIL,
          });

        })
      },

      validatePassword(pw) {
        if (pw.password_new !== pw.password_repeat){
          this.$root.toggleGlobalAlert({
            type: requestEnum.FAIL,
            message: 'Falha: as senhas devem ser iguais.'
          });

          return false;
        }

        if(pw.password_new.length <= 7){
          this.$root.toggleGlobalAlert({
            type: requestEnum.FAIL,
            message: 'Falha: a senha deve conter no minímo 8 caracteres.',
          });

          return false;
        }

        return true;
      }
    },

    mounted(){
      this.$root.watchUserInfo(userInfo => {
        this.personId = userInfo.personId;
      });
    }
  }
</script>

<style scoped>

  .passwordPreferences input{
    border: 1px solid #dfdfdf;
    box-shadow: none;
    border-radius: 0;
    display: block;
    width: 60%;
    min-width: 200px;
    height: 34px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
  }

  .passwordPreferences label {
    display: block;
  }

  .passwordPreferences {
    flex: 1 0;
    margin-left: 15px;
    min-width: 320px;
    padding: 0 20px 40px 20px;
    background: white;
  }

  .passwordPreferences button {
    margin-top: 20px;
    background-color: #fff;
    color: #3a3a3a;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #e2e2e2;
    border-radius: 0;
  }

  .passwordPreferences > div {
    margin-bottom: 5px;
  }


  @media only screen and (max-width: 1030px) {
    .passwordPreferences {
      margin-top: 20px;
      margin-left: 20px;
    }

  }
</style>
