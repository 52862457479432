<template>
  <div class="billingStatement-orderOverview-navBarItem"
       :class="{ 'billingStatement-orderOverview-navBarItem--selected': selected }"
       @click="$emit('click')"
  >
    <div class="billingStatement-orderOverview-navBarItem__title">{{ title }}</div>
  </div>
</template>

<script>
  export default {
    name: 'NavBarItem',

    props: {
      title: String,
      selected: Boolean
    },
  }
</script>

<style lang="scss" scoped>
@use '@vuxx/hounds/scss/abstracts/variables' as *;

  .billingStatement-orderOverview-navBarItem {
    margin-right: 60px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #9a9a9a;
    cursor: pointer;
  }

  .billingStatement-orderOverview-navBarItem--selected {
    color: #101010;
    border-bottom: 2px solid $color-primary;
  }

  .billingStatement-orderOverview-navBarItem__title {
    padding-bottom: 8px;
  }

</style>
