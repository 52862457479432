<template>
  <div class="spotRequest-stepperItem__wrapper">
    <div class="spotRequest-stepperItem__content"
         :class="{ 'spotRequest-stepperItem__content--active': thisStepperItemIndex <= currentStepperItemSelected }">
      <div class="spotRequest-stepperItem__itemIcon">
        <img :src="thisStepperItemDetails.iconPath"/>
      </div>
      <div class="spotRequest-stepperItem__titleContainer">
        <div class="spotRequest-stepperItem__title">
          {{ thisStepperItemDetails.title }}
        </div>
      </div>
    </div>
    <transition :name="spotRequestStepperBarTransitionDirection">
      <div v-show="thisStepperItemIndex === currentStepperItemSelected" class="spotRequest-stepperBar">
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'StepperItem',

    props: [
      'thisStepperItemDetails',
      'thisStepperItemIndex',
      'currentStepperItemSelected',
    ],

    data: function () {
      return {
        'currentStepIsFurtherThanMe': false,
      }
    },

    computed: {
      spotRequestStepperBarTransitionDirection() {
        return "spot-stepperBar__transition--" + (this.currentStepIsFurtherThanMe ? "right" : "left");
      },
      spotRequestStepperItemSubtitleTransition() {
        return "spot-stepperItem__subtitle--transition-" + (this.thisStepperItemDetails.done ? "done" : "undone");
      },
    },
    watch: {
      currentStepperItemSelected: function (currentStepperItemIndex) {
        if (currentStepperItemIndex > this.thisStepperItemIndex) {
          this.currentStepIsFurtherThanMe = true;
        } else if (currentStepperItemIndex < this.thisStepperItemIndex) {
          this.currentStepIsFurtherThanMe = false;
        }
      }
    }
  }
</script>

<style scoped>
  .spotRequest-stepperItem__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-right: #e7e7e7 1px solid;

    height: 67px;
    width: 100%;
  }

  .spotRequest-stepperItem__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0.4;
    height: 65px;
    transition: 0.5s;
  }

  .spotRequest-stepperItem__content--active {
    opacity: 1;
  }

  .spotRequest-stepperItem__itemIcon {
    height: 20px;
    width: 20px;
    align-self: center;
  }

  .spotRequest-stepperItem__titleContainer {
    margin-left: 8px;
    font-size: 14px;
  }

  .spotRequest-stepperItem__title {
    font-weight: 600;
    color: #2f2f2f;
  }

  .spotRequest-stepperItem__subtitle {
    letter-spacing: 0.1px;
    color: #858585;
    min-width: 175px;
  }

  .spotRequest-stepperItem__subtitle--done {
    color: #28c0f1;
  }

  .spotRequest-stepperBar {
    height: 2px;
    width: 100%;
    align-self: flex-end;
    background-color: #198bfd;
    transition: transform 0.5s;
  }

  .spotRequest-stepperItem__subtitle--transition-done-leave-active {
    transition: .3s;
  }

  .spotRequest-stepperItem__subtitle--transition-done-enter-active {
    transition: .2s;
  }

  .spotRequest-stepperItem__subtitle--transition-done-leave-to {
    opacity: 0;
    transform: translateX(50%);
  }

  .spotRequest-stepperItem__subtitle--transition-done-enter {
    opacity: 0;
    transform: translateY(50%);
  }

  .spotRequest-stepperItem__subtitle--transition-undone-enter-active {
    transition: .5s;
  }

  .spotRequest-stepperItem__subtitle--transition-undone-enter {
    color: #28c0f1;
  }

  .spotRequest-stepperBar__transition--left-enter-active {
    animation: spotRequest-stepperBar__transition--grow-left .5s ease-out;
  }

  .spotRequest-stepperBar__transition--left-leave-active {
    animation: spotRequest-stepperBar__transition--grow-left .5s ease-in reverse;
  }

  .spotRequest-stepperBar__transition--right-enter-active {
    animation: spotRequest-stepperBar__transition--grow-right .5s ease-out;
  }

  .spotRequest-stepperBar__transition--right-leave-active {
    animation: spotRequest-stepperBar__transition--grow-right .5s ease-in reverse;
  }

  @keyframes spotRequest-stepperBar__transition--grow-right {
    0% {
      transform-origin: right;
      transform: scaleX(0);
    }
    100% {
      transform-origin: right;
      transform: scaleX(1);
    }
  }

  @keyframes spotRequest-stepperBar__transition--grow-left {
    0% {
      transform-origin: left;
      transform: scaleX(0);
    }
    100% {
      transform-origin: left;
      transform: scaleX(1);
    }
  }
</style>
