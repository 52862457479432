<template>
  <tracking-page-layout
    title="Rastreamento da Remessa"
    :is-unavailable="shipmentUnavailable"
    :map-markers="mapMarkers"
  >
    <template #top-panel>
      <shipment-summary-panel
        v-if="trackingData"
        :shipment-details="trackingData"
      ></shipment-summary-panel>
    </template>

    <template #bottom-panel>
      <details-panel
        v-if="trackingData"
        :tracking-code="trackingData.trackingCode"
        :pod="trackingData.pod"
        :driver="trackingData.driver"
        :deliveryStop="trackingData.deliveryStop"
      ></details-panel>
    </template>
  </tracking-page-layout>
</template>

<script>
import titleMixin from "../common/mixins/titleMixin";
import TrackingPageLayout from "./TrackingPageLayout.vue";
import TrackingMap from "./map/TrackingMap.vue";
import {getShipmentTracking} from "./shipment/shipmentTrackingApi";
import ShipmentSummaryPanel from "../tracking/shipment/ShipmentSummaryPanel";
import DetailsPanel from "./shipment/detailsPanel/DetailsPanel";
import {buildShipmentMapMarkers} from './shipment/shipmentMapMarkersFactory';

export default {
    name: "ShipmentTrackingPage",

    mixins: [ titleMixin ],
    components: { ShipmentSummaryPanel, DetailsPanel, TrackingPageLayout, TrackingMap },

    data: function () {
      return {
        trackingData: null,
        shipmentUnavailable: false,
        mapMarkers: [],
      }
    },

    methods: {
      async getTrackingData() {
        const route = this.$route.params;
        try {
          this.trackingData = await getShipmentTracking({
            shipmentHash: route.hash,
            clientCode: route.clientCode,
            accessKey: route.accessKey,
          });
        } catch (e) {
          this.shipmentUnavailable = true;
        }
      },
    },

    watch: {
      trackingData: function (newTrackingData) {
        this.mapMarkers = buildShipmentMapMarkers(newTrackingData);
      },
    },

    async mounted() {
      await this.getTrackingData()

      const REFRESH_INTERVAL = 60000
      window.setInterval(() => this.getTrackingData(), REFRESH_INTERVAL)
    },
  }
</script>
