<!-- TODO: this should be a HounDS component. -->

<template>
  <gmap-map
    class="map"
    map-type-id="roadmap"
    name="map"
    ref="map"
    :center="mapCenter"
    :options="mapOptions"
  >
    <gmap-marker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :label="m.label"
      :icon="m.icon"
      @click="center=m.position"
      @mouseover="openedInfoWindow = index"
      @mouseout="openedInfoWindow = null"
    >
      <gmap-info-window
        :opened="openedInfoWindow === index"
      >
        <component
          v-if="m.infoWindow.component"
          :is="m.infoWindow.component"
          v-bind="m.infoWindow.props"
        />
      </gmap-info-window>
    </gmap-marker>
  </gmap-map>
</template>

<script>
  import { gmapApi } from 'vue2-google-maps';
  import STYLED_MAP_JSON from '../styled_map.json'
  import StopInfoWindow from "./StopInfoWindow.vue";
  import DriverInfoWindow from "./DriverInfoWindow.vue";

  export default {
    name: "TrackingMap",
    components: {StopInfoWindow, DriverInfoWindow},
    props: {
      markers: Array,
    },

    data() {
      return {
        openedInfoWindow: null,
      };
    },

    computed: {
      google: gmapApi,

      mapOptions() {
        return {
          disableDefaultUI: true,
          zoomControl: true,
        };
      },

      bounds() {
        const bounds = new this.google.maps.LatLngBounds();
        this.markers.forEach((marker) => {
          bounds.extend(marker.position);
        });

        return bounds;
      },

      mapCenter() {
        return {
          lat: -23.5506158,
          lng: -46.6334502,
        };
      },

      mapPadding() {
        // according to towel, the left panel uses 56.87% of window width
        return window.innerWidth > 768 ? { left: (window.innerWidth * 0.5687 ) } : { };
      }
    },

    methods: {
      getStyledMap() {
        return new this.google.maps.StyledMapType(STYLED_MAP_JSON, { name: 'styled_map' });
      },

      initMap() {
        const mapPromise = this.$refs.map.$mapPromise;

        mapPromise.then((map) => {
          map.mapTypes.set('styled_map', this.getStyledMap())
          map.setMapTypeId('styled_map');
          map.fitBounds(this.bounds, this.mapPadding);
        });
      }
    },

    mounted() {
      this.initMap();
    }
  }
</script>

<style scoped>
  .map {
    height: 100%;
    width: 100%;
  }
</style>
