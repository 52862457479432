/* global HeapInstance heap */

/**
 * @param {UserIdentity} userInfo
 */
export default function heapAnalyticsInit(userInfo) {
  if (typeof heap === 'undefined') {
    console.warn('Heap Analytics is not loaded');
    return;
  }

  const userId = userInfo.personId;
  heap.identify(userId);

  const fields = {
    email: userInfo.personEmail, // lowercase 'email' is a special field in Heap
    Name: userInfo.personName,
    'Organization ID': userInfo.customerId,
    'Organization Name': userInfo.customerName,
  };

  if (userInfo.audienceType) {
    fields['Audience Type'] = userInfo.audienceType;
  }

  heap.addUserProperties(fields);
}
