<template>
  <div class="spotRequest-vehicleSelection">
    <div class="spotRequest-vehicleSelection__content">
      <div class="panel spotRequest-vehicleSelection__panel">
        <vehicle-picker />

        <div class="horizontalSeparator" />

        <template v-if="selectedVehicleType">
          <TotalWeight :is-weight-required="isWeightRequired" />

          <div class="horizontalSeparator" />
        </template>

        <template v-if="vehicleHasBodyType">
          <VehicleBodyType />

          <div class="horizontalSeparator" />
        </template>

        <vehicle-extras ref="vehicleExtras" />

        <driver-assistant
          @on-driver-assistants-changed="onDriverAssistantsChanged"
        />
      </div>
    </div>

    <div
      class="panel spotRequest-vehicleSelection__panel"
      v-if="willCalculateAdValorem"
    >
      <MerchandisesTotalPrice />
    </div>

    <div class="horizontalSeparator spotRequest-vehicleSelection__horizontalSeparator" />

    <div class="spotRequest-vehicleSelection__buttonsContainer">
      <button
        class="baseButton cancelButton spotRequest-vehicleSelection__goBackButton"
        @click="onPreviousClicked"
      >
        Voltar
      </button>
      <button
        class="baseButton submitButton"
        :disabled="disableNextStep"
        @click="onNextClicked"
      >
        Obter cotação
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import VehiclePicker from '../common/VehiclePicker.vue';
import VehicleExtras from '../common/VehicleExtras.vue';
import DriverAssistant from '../common/DriverAssistant.vue';
import VehicleBodyType from '../common/VehicleBodyType.vue';
import TotalWeight from '../common/totalWeight.vue';
import './style.css';
import MerchandisesTotalPrice from "@/spot/common/merchandisesTotalPrice";

export default {
  name: 'VehicleSelectionStep',

  components: {
    VehiclePicker,
    VehicleBodyType,
    VehicleExtras,
    DriverAssistant,
    TotalWeight,
    MerchandisesTotalPrice,
  },

  computed: {
    ...mapState('spotRequest', [
      'totalWeightInKilograms',
      'selectedVehicleBodyTypes',
      'selectedVehicleExtras',
      'driverAssistants',
    ]),

    ...mapGetters('spotRequest', {
      selectedVehicleType: 'vehicleType',
    }),

    ...mapGetters('spotRequest', [
      'vehicleHasBodyType',
      'vehicleBodyTypeIsRequired',
      'willCalculateAdValorem'
    ]),

    isLoading() {
      return this.$store.state.spotRequest.availableVehicles.length === 0;
    },

    totalWeightIsValid() {
      const onlyDigits = /^\d+$/;
      if (this.totalWeightInKilograms || this.isWeightRequired) {
        return onlyDigits.test(this.totalWeightInKilograms);
      }
      return true;
    },
    disableNextStep() {
      return !this.selectedVehicleType
        || !this.totalWeightIsValid
        || (this.vehicleBodyTypeIsRequired && this.selectedVehicleBodyTypes.length === 0)
        || this.isLoading;
    },
    isWeightRequired() {
      const vehiclesWithWeightRequired = ['3/4', 'TOCO', 'TRUCK', 'BITRUCK', 'CARRETA'];
      return vehiclesWithWeightRequired.includes(this.selectedVehicleType);
    },
  },

  methods: {
    onNextClicked() {
      this.$emit('on-next', 'vehicle', { vehicleType: this.selectedVehicleType });
    },

    onPreviousClicked() {
      this.$emit('on-previous', 'vehicle');
    },

    onDriverAssistantsChanged(count) {
      this.$emit('on-driver-assistants-changed', count);
    },
  },
};
</script>

<style scoped>
  .spotRequest-vehicleSelection {
    display: flex;
    flex-direction: column;
    font-family: "Source Sans Pro", sans-serif;
  }

  .spotRequest-vehicleSelection__content {
    padding-bottom: 8px;
  }

  .spotRequest-vehicleSelection__panel {
    margin: 20px 20px 0px 20px;
    padding: 24px 20px 40px;
  }

  .horizontalSeparator {
    height: 0;
    border-bottom: 1px solid #e7e7e7;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .spotRequest-vehicleSelection__buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0px 20px 30px 20px
  }

  .spotRequest-vehicleSelection__goBackButton {
    margin-right: 20px;
  }

</style>
