<template>
  <div class="skeleton gap-32 bulma-is-flex bulma-is-flex-direction-column">
    <div class="card">
      <span class="skeleton__item skeleton__item--alert" />
      <span class="skeleton__item skeleton__item--alert" />
      <span class="skeleton__item skeleton__item--alert" />
    </div>
    <div class="card">
      <span class="skeleton__item skeleton__item--alert" />
      <span class="skeleton__item skeleton__item--alert" />
      <span class="skeleton__item skeleton__item--alert" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SkeletonLoadingPage',
});
</script>
