<template>
  <div
    class="common-messageModal"
    @keyup.esc="closeMessageModal"
    @keyup.enter="closeMessageModal"
    tabindex="0"
  >
    <div class="common-messageModal__backdrop">
      <div class="common-messageModal__content" role="dialog">
        <div class="common-messageModal__title">{{ title }}</div>
        <div class="common-messageModal__message" v-html="message"></div>
        <div class="horizontalSeparator"></div>
        <div class="common-messageModal__detail" v-if="errors" >
          <p v-for="error in errors">{{error}}</p>
        </div>
        <div class="horizontalSeparator" v-if="errors"></div>
        <button
          class="baseButton submitButton common-messageModal__closeButton"
          @click="closeMessageModal"
        >Ok, entendi</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageModal",

  props: {
    title: '',
    message: '',
    errors: '',
    visible: false
  },

  methods: {
    closeMessageModal() {
      this.$emit('modal-close-requested');
    },
  },

  mounted() {
    this.$el.focus();
  }
}
</script>

<style scoped>
  .common-messageModal {
    position: fixed;
    z-index: 1000;
  }

  .common-messageModal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .common-messageModal__content {
    width: 510px;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    padding: 24px 28px;
    display: flex;
    flex-direction: column;
  }

  .common-messageModal__title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #2f2f2f;
  }

  .common-messageModal__message {
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .common-messageModal__detail {
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .common-messageModal__closeButton {
    margin-top: 16px;
    width: 120px;
    align-self: flex-end;
  }
</style>
