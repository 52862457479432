<template>
  <div :class="style.timeline">
    <timeline-element
      v-for="(element, index) in elements"
      :key="index"
      :status="element.status"
      :title="element.title"
      :description="element.description"
      :isFirst="index === 0"
      :isLast="index === elements.length - 1"
    />
  </div>
</template>

<script>
import TimelineElement from "./TimelineElement.vue";
import style from "../../styles/timeline/timeline.module.scss";

export default {
  name: 'Timeline',

  components: {TimelineElement},

  props: {
    elements: Array,
  },

  computed: {
    style: () => Object.freeze(style)
  },
}
</script>
