<template>
  <div class="signature__content">
    <div class="heading">Assinatura</div>
    <div class="signature">
      <img width="200" height="auto" :src="url" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Signature",

  props: {
    url: String
  }
}
</script>

<style scoped>
/* TODO Refact this to SCSS and import Colors:
   Reason we cant load inline scss in HounDS folder yet. */
.signature__content {
  display: flex;
  flex-direction: column;
  /* Gray 300 */
  border: 1px #C9C9C9 solid;
  border-radius: 8px;
  /* Gray 50 */
  background: #F5F5F5;
  padding: 16px;
  width: 100%;
}

.heading {
  flex: 1;
  font-size: 14px;
  /* Gray 800 */
  color: #424242;
  font-weight: 600;
  margin: 0 0 12px 0;
}

.signature {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
