const accessKeyLength = 44;
const accessKeyPattern = /[0-9]{44}/;
const accessKeyTypeStart = 20;
const accessKeyTypeLength = 2;
const accessKeyTypeNFe = '55';
const accessKeyTypeCTe = '57';
const accessKeyTypeMinuta = '94';

function seemsAccessKey(accessKeyCandidate) {
  return accessKeyCandidate.length === accessKeyLength
    && accessKeyCandidate.match(accessKeyPattern) != null
    && documentTypeOfAccessKey(accessKeyCandidate) != null;
}

function documentTypeOfAccessKey(accessKey) {
  const accessKeyType = accessKey.slice(accessKeyTypeStart, accessKeyTypeStart + accessKeyTypeLength);

  if (accessKeyTypeNFe === accessKeyType) {
    return 'NF-e';
  } if (accessKeyTypeCTe === accessKeyType) {
    return 'CT-e';
  } if (accessKeyTypeMinuta === accessKeyType) {
    return 'Minuta';
  }

  return null;
}

module.exports = {
  seemsAccessKey,
  documentTypeOfAccessKey,
};
