export const TaxTypes = {
  PIS_COFINS: 'PIS_COFINS',
  ICMS: 'ICMS',
  ISS: 'ISS',
};

const taxTypeNames = {
  [TaxTypes.PIS_COFINS]: 'PIS/COFINS',
  [TaxTypes.ICMS]: 'ICMS',
  [TaxTypes.ISS]: 'ISS',
};

export default class TaxType {
  static nameFor(key) {
    return taxTypeNames[key] || key;
  }
}
