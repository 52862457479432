import axios from "axios";
import Swal from "sweetalert2";
import redirectToLogin from "@/common/redirectToLogin";

const EXPIRED_SESSION_STATUS_CODE = 401; // Unauthorized

export default function redirectToLoginWhenSessionIsExpired() {
  axios.interceptors.response.use(async (response) => {
    return response;
  }, async (error) => {
    const requestUrl = error.response.config.url || '';

    // Bypasses error when it's from /me endpoint
    if (requestUrl.endsWith('/jade/api/me')) {
      throw error;
    }

    if (error.response.status === EXPIRED_SESSION_STATUS_CODE) {
      await Swal.fire({
        icon: 'warning',
        title: 'Sua sessão expirou.',
        text: 'Você será redirecionado para a tela de login.',
        allowOutsideClick: false,
      });

      redirectToLogin(window.location.href);
    }

    throw error;
  });
}
