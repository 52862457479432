<template>
  <div class="spotEstimate spotEstimate__font">
    <div class="spotEstimate__header">
      <label class="spotEstimate__title">
        Cotação
      </label>
    </div>

    <div v-if="loading" class="spotEstimate__spinnerContainer">
      <spinner class="spotEstimate__spinner" size="20px" />
    </div>

    <div v-else-if="priceEstimation" class="spotEstimate__values">
      <div class="spotEstimate__price">
        <div class="spotEstimate__price__description">Frete:</div>
        <div class="spotEstimate__price__value">R$ {{ priceEstimation.freightPrice | moneyFormat }}</div>
      </div>

      <div v-for="discount in priceEstimation.discounts" >
        <div v-if="discount.type === discountTypes.ADVANCE_BOOKING" class="spotEstimate__price">
          <div class="spotEstimate__price__discount_type">
            Desconto por agendamento
            <img
              v-tooltip.bottom-start="{
                content: 'Com 6 horas ou mais entre o horário do pedido e o início do serviço, temos mais tempo para encontrar o motorista ideal para a rota, e assim conseguimos dar um desconto para você',
                classes: ['tooltip-custom']
              }"
              class="spotEstimate__discountInfoIcon" src="/jade/public/ic-question.svg"
            >
          </div>
          <div class="spotEstimate__price__discount_value">-R$ {{ discount.value | moneyFormat }} </div>
        </div>
      </div>

      <div class="spotEstimate__price">
        <div class="spotEstimate__price__description">Ajudante(s):</div>
        <div class="spotEstimate__price__value">R$ {{ priceEstimation.driverAssistantsPrice | moneyFormat }}</div>
      </div>

      <div class="spotEstimate__price" v-if="priceEstimation.tollPrice">
        <div class="spotEstimate__price__description">Pedágio(s):</div>
        <div class="spotEstimate__price__value">R$ {{ priceEstimation.tollPrice | moneyFormat }}</div>
      </div>

      <div class="spotEstimate__price" v-if="hasAdValorem">
        <div class="spotEstimate__price__description">Ad Valorem:</div>
        <div class="spotEstimate__price__value">R$ {{ priceEstimation.adValoremPrice | moneyFormat }}</div>
      </div>

      <div
        v-for="component of priceEstimation.priceComponents"
        :key="component.name"
        class="spotEstimate__price"
      >
        <div class="spotEstimate__price__description">{{ component.name }}:</div>
        <div class="spotEstimate__price__value">R$ {{ component.value | moneyFormat }}</div>
      </div>

      <div class="horizontalSeparator spotEstimate__horizontalSeparator"></div>

      <div class="spotEstimate__price spotEstimate__price--subTotal">
        <div class="spotEstimate__price__description">Valor líquido:</div>
        <div class="spotEstimate__price__value">R$ {{ priceEstimation.netPrice | moneyFormat }}</div>
      </div>

      <div class="spotEstimate__notes">
        <div class="spotEstimate__notesText">
          <h4 class="spotEstimate__notesHeader">Impostos</h4>

          <table class="taxValues">
            <tbody>
              <tr
                v-for="tax of taxes"
                :key="tax.id"
              >
                <th scope="row">
                  {{ tax.name }}:
                </th>
                <td>
                  R$ {{ tax.value | moneyFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="spotEstimate__price spotEstimate__price--grandTotal">
        <div class="spotEstimate__price__description">
          Total com impostos:
        </div>
        <div class="spotEstimate__price__value">
          R$ {{ priceEstimation.grossPrice | moneyFormat }}
        </div>
      </div>

      <div class="spotEstimate__notes">
        <img
          class="spotEstimate__notesIcon"
          :src="icons.report"
          alt=""
        >
        <div class="spotEstimate__notesText">
          <p>ICMS, PIS e COFINS são passíveis de créditos nos casos previstos por lei.</p>
          <p v-if="showMixedTaxesNotice">
            Esta solicitação inclui transportes intra- e intermunicipais, e portanto há incidência
            de ISS e ICMS.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { VPopover } from 'v-tooltip';
  import { discountTypes } from './discountTypes';
  import Spinner from '../../common/Spinner.vue';
  import { moneyFormat, percentageFormat } from '../../common/filters/numberFormatters';
  import TaxType, { TaxTypes } from '../../common/TaxType';
  import reportIcon from '@vuxx/hounds/icons/report.svg';

  export default {
    name: 'EstimateDisplay',

    components: {
      Spinner,
      VPopover,
    },

    filters: {
      moneyFormat,
      percentageFormat,
    },

    props: {
      priceEstimation: {
        type: Object,
      },
      loading: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        discountTypes,
      };
    },

    computed: {
      icons() {
        return {
          report: Object.freeze(reportIcon),
        };
      },

      showMixedTaxesNotice() {
        return this.priceEstimation.taxes[TaxTypes.ISS] && this.priceEstimation.taxes[TaxTypes.ICMS];
      },

      taxes() {
        return Object.entries(this.priceEstimation.taxes).map(([key, value]) => ({
          key,
          value,
          name: TaxType.nameFor(key),
        }));
      },

      hasAdValorem() {
        return this.priceEstimation.adValoremPrice &&
          parseFloat(this.priceEstimation.adValoremPrice) !== 0;
      },
    },
  };
</script>

<style>
  .tooltip-custom {
    z-index: 1000;
  }
  .tooltip-custom .tooltip-inner {
    max-width: 300px;
    transform: translateX(-80px);
  }
</style>
<style scoped lang="scss">
  @import '@vuxx/hounds/scss/abstracts/variables';

  .spotEstimate {
    font-family: 'Open Sans', sans-serif;
    min-width: 300px;
    max-width: 360px;
  }

  .spotEstimate__font {
    font-size: 16px;
    font-weight: 400;
    color: $color-gray--700;
  }

  .spotEstimate__font--semibold {
    font-weight: 600;
  }

  .spotEstimate__header {
    display: flex;
    flex-direction: row;
    margin: 0 0 8px 0;
  }

  .spotEstimate__title {
    font-weight: 600;
    color: $color-gray--900;
    letter-spacing: 0.12em;
    text-transform: uppercase;
  }

  .spotEstimate__spinnerContainer {
    display: flex;
    align-items: center;
    height: 58px;
    overflow: hidden;
  }

  .spotEstimate__spinner {
    margin-left: 20px;
  }

  .spotEstimate__values {
    margin-top: 2px;
    min-width: 184px;
    display: flex;
    flex-direction: column;
  }

  .spotEstimate__price {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .spotEstimate__price--subTotal {
    color: $color-gray--900;
    font-size: 18px;
  }

  .spotEstimate__price--grandTotal {
    color: $color-gray--900;
    font-size: 18px;
    font-weight: 600;
  }

  .spotEstimate__price > .spotEstimate__price__discount_type {
  }

  .spotEstimate__price > .spotEstimate__price__discount_value {
    color: #0ecf00;
    font-weight: 600;
  }

  .spotEstimate__notes {
    display: block;
    margin: 12px 0;
    padding: 12px;
    border-radius: 4px;
    background-color: $color-gray--50;
    color: $color-gray--700;
    font-size: 16px;
  }

  .spotEstimate__notesIcon {
    height: 24px;
    width: 24px;
    margin-bottom: 8px;
  }

  .spotEstimate__discountInfoIcon {
    height: 12px;
    width: 12px;
  }

  .spotEstimate__notesText {
    margin-top: 2px;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }

  .spotEstimate__notesText p {
    margin: 0 0 8px 0;
  }

  .spotEstimate__notesHeader {
    font: inherit;
    margin: 0 0 8px 0;
  }

  .taxValues {
    width: 100%;
    border-collapse: collapse;
  }

  .taxValues th {
    text-align: left;
    font-weight: 400;
    padding: 2px 0;
  }

  .taxValues td {
    text-align: right;
    padding: 2px 0;
  }

  .spotEstimate__horizontalSeparator {
    margin-top: 16px;
  }
</style>
