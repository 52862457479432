<template>
  <div>
    <div
      @click="toggleDropDown"
      class="main-header__dropdown-overlay"
      :class="{ 'main-header__dropdown-overlay--opened': dropdownIsOpened }"
    ></div>
    <header
      v-if="hasUserIdentity"
      class="main-header main-header--top main-header--light"
    >
      <div class="main-header__external-container">
        <div class="main-header__container">
          <a href="/" class="main-header__logo">
            <h1>Cargo</h1>
          </a>

          <button
            :class="{ 'is-active': menuIsOpened }"
            data-test="menu-toggle"
            aria-hidden="true"
            class="hamburger hamburger--squeeze"
            type="button"
            @click="toggleMenu"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner" />
              <span class="hamburger__text"> Menu </span>
            </span>
          </button>
        </div>

        <section
          :class="{ 'main-header__content--is-open': menuIsOpened }"
          class="main-header__content"
        >
          <nav class="main-header__nav">
            <router-link
              to="/spots/solicitar"
              class="main-header__menu-item main-header__menu-item--request"
            >
              Solicitar serviço
            </router-link>
            <router-link
              to="/pedidos/monitoramento"
              class="main-header__menu-item main-header__menu-item--monitoring"
            >
              Monitoramento
            </router-link>
            <router-link
              to="/spots"
              class="main-header__menu-item main-header__menu-item--history"
            >
              Histórico
            </router-link>
            <router-link
              :to="userRoleId === 1 ? '/admin/comprovantes' : '/comprovantes'"
              class="main-header__menu-item main-header__menu--receipt"
            >
              Comprovantes
            </router-link>
            <router-link
              to="/extrato"
              class="
                main-header__menu-item main-header__menu--billing-statement
              "
            >
              Extrato
            </router-link>
          </nav>
          <div
            class="main-header__dropdown js-main-header__dropdown"
            :class="{ 'main-header__dropdown--opened': dropdownIsOpened }"
          >
            <button
              ref="mainHeaderDropdown"
              class="main-header__menu-item main-header__menu-item--dropdown"
              @click="toggleDropDown"
            >
              <span class="main-header__menu-username">
                <span class="sr-only"
                  >Abrir menu de preferências da conta
                </span>
                {{ personFirstName }}
              </span>
            </button>
            <div
              :class="
                dropdownIsOpened ? 'main-header__dropdown-content--opened' : ''
              "
              class="main-header__dropdown-content"
            >
              <router-link
                to="/perfil"
                class="main-header__menu-item main-header__menu-item--config"
              >
                Preferências do usuário
              </router-link>
              <router-link
                v-if="userRoleId === 1"
                to="/contas-cadastradas"
                class="main-header__menu-item main-header__menu-item--admin"
              >
                Admin
              </router-link>
              <a
                href="/sair"
                class="main-header__menu-item main-header__menu-item--logout"
              >
                Sair
              </a>
            </div>
          </div>
        </section>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',

  data() {
    return {
      menuIsOpened: false,
      dropdownIsOpened: false,
    };
  },

  computed: {
    hasUserIdentity() {
      return this.$root.hasUserIdentity;
    },
    personFirstName() {
      return this.$root.userInfo.personName.split(' ')[0];
    },
    userNameFirstLetter() {
      return this.personFirstName.split('')[0];
    },
    userRoleId() {
      return this.$root.userInfo.roleId;
    },
    customerId() {
      return this.$root.userInfo.customerId;
    },
  },

  mounted() {
    this.setUserImageBackground();
  },

  methods: {
    setUserImageBackground() {
      const userButton = this.$refs.mainHeaderDropdown;
      userButton.style.setProperty(
        '--userFirstLetter',
        `"${this.userNameFirstLetter}"`,
      );
    },
    toggleDropDown() {
      this.dropdownIsOpened = !this.dropdownIsOpened;
    },
    toggleMenu() {
      this.menuIsOpened = !this.menuIsOpened;
      document.body.classList.toggle('body--header-submenu-opened');
    },
  },
};
</script>

<style lang="scss" scoped>
.main-header__menu-item--dropdown {
  --userFirstLetter: 'u';
  &::before {
    content: var(--userFirstLetter);
  }
}

.main-header__logo {
  background-image: url('/public/assets/images/logo-cargo.svg') !important;
  width: 4.5rem;
  height: auto;
}

.router-link-exact-active {
  color: #5800dd;
}

</style>
