const baseIconUrl = '/jade/public/tracking';

function stopMarker(type) {
  const color = {
    default: "#ADB5BD",
    success: "#00C853",
    failure: "#E53935",
    active: "#8520BB",
  }

  return {
    path: "M23.1885 30.921C28.6849 28.3566 32.5 22.7295 32.5 16.2001C32.5 7.25305 25.3366 6.10352e-05 16.5 6.10352e-05C7.66344 6.10352e-05 0.5 7.25305 0.5 16.2001C0.5 22.7295 4.31515 28.3566 9.8115 30.921L16.5 36.0001L23.1885 30.921Z",
    fillColor: color[type],
    fillOpacity: 1,
    strokeWeight: 0,
    labelOrigin: {x: 16, y: 16},
  }
}

// TODO: use enums instead of icons.
export default {
  driver: `${baseIconUrl}/driver-truck.svg`,

  stop: {
    default: stopMarker('default'),
    success: stopMarker('success'),
    failure: stopMarker('failure'),
    active: stopMarker('active'),
  },

  shipment: {
    default: `${baseIconUrl}/marker-shipment-default.svg`,
    done: `${baseIconUrl}/marker-shipment-done.svg`,
  },

  warehouse: {
    default: `${baseIconUrl}/marker-warehouse-default.svg`,
    success: `${baseIconUrl}/marker-warehouse-success.svg`,
    failure: `${baseIconUrl}/marker-warehouse-failure.svg`,
  },
};
