import BlankPage from '../tmsInterop/BlankPage';
import Legacy from '../tmsInterop/Legacy';
import BillingStatementPage from '../billingStatement/Page.vue';
import SpotRequestPage from '../spot/spotRequest/Page.vue';
import FiscalDocumentsUploadPage from '../fiscalDocumentsUpload/Page.vue';
import CustomersList from '../customers/Page.vue';
import ProfilePage from '../profile/Page.vue';
import PodsPage from '../pods/Page.vue';
import PublicPodsPage from '../pods/public/Page.vue';
import OrderTrackingPage from '../tracking/OrderTrackingPage.vue';
import Monitoring from '../monitoring/Page.vue';
import ShipmentTrackingPage from '../tracking/ShipmentTrackingPage.vue';
import { fiscalDocumentsUploadEnabled } from './runtimeConfig';
import Notification20230410 from '../pages/Notification20230410.vue';

const ssrRoutes = [
  '/admin*',
  '/entrar',
  '/comprovantes',
  '/comprovantes/pesquisar',
  '/comprovantes/:year([0-9]{4})/*',
  '/comprovantes-v1*',
  '/inicio',
  '/perfil/*',
  '/pods*',
  '/spots',
  '/spots/*/requested/',
];

const routes = [
  {
    path: 'aliasOnly',
    component: Legacy,
    alias: ssrRoutes,
  },
  {
    path: '/jade/blank',
    component: BlankPage,
  },
  {
    path: '/spots/solicitar',
    component: SpotRequestPage,
    meta: {
      title: 'Solicitar veículo',
    },
  },
  {
    path: '/',
    component: Monitoring,
    // this has to be done since alias in this case can't
    // set the active html class to the correct link there is
    // an open issue about it on vue/vue-router, but still opened
    // @see https://github.com/vuejs/vue-router/issues/419
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => next('/pedidos/monitoramento'),
    meta: {
      title: 'Monitoramento',
    },
  },
  {
    path: '/pedidos/monitoramento',
    name: 'monitoring',
    component: Monitoring,
    meta: {
      title: 'Monitoramento',
    },
  },
  {
    path: '/notificacoes/2023-04-10',
    name: 'notificacoes-2023-04-10',
    component: Notification20230410,
    meta: {
      title: 'Anúncio oficial - Box Delivery, 10/04/2023',
    },
  },
  {
    path: '/extrato',
    component: BillingStatementPage,
    meta: {
      title: 'Extrato de solicitações',
    },
  },
  {
    path: '/contas-cadastradas',
    component: CustomersList,
    meta: {
      title: 'Contas Cadastradas',
    },
  },
  {
    path: '/perfil',
    component: ProfilePage,
    meta: {
      title: 'Perfil do usuário',
    },
  },
  {
    path: '/comprovantes/:id',
    component: PodsPage,
    meta: {
      title: 'Comprovantes',
    },
  },
  {
    path: '/comprovantes/publico/:token',
    component: PublicPodsPage,
    meta: {
      title: 'Comprovantes',
      public: true,
    },
  },
  {
    path: '/pedidos/acompanhe/:id',
    component: OrderTrackingPage,
    name: 'orderTrackingPage',
    meta: {
      title: 'Acompanhamento de pedido',
    },
  },
  {
    path: '/r/:token',
    component: OrderTrackingPage,
    meta: {
      title: 'Acompanhamento de pedido',
      public: true,
    },
  },
  {
    path: '/rr/:hash',
    component: ShipmentTrackingPage,
    meta: {
      title: 'Acompanhamento da remessa',
      public: true,
    },
  },
  {
    path: '/rrp/:clientCode/:accessKey',
    component: ShipmentTrackingPage,
    meta: {
      title: 'Acompanhamento da remessa',
      public: true,
    },
  },
];

if (fiscalDocumentsUploadEnabled) {
  routes.push({
    path: '/upload-de-documentos-fiscais',
    component: FiscalDocumentsUploadPage,
    meta: {
      title: 'Upload de documentos fiscais',
    },
  });
} else {
  routes.push({
    path: '/upload-de-documentos-fiscais',
    redirect: '/spots/solicitar',
  });
}

export default routes;
