<template>
  <div
    class="spotRequest-merchandises-modal"

    ref="manualRoundTripPlaceInputModal"
  >
    <div class="spotRequest-merchandises-modal__backdrop">
      <div
        class="spotRequest-merchandises-modal__content"
        role="dialog"
      >
        <div
          @keyup.esc="cancel"
          @keyup.enter="submit"
        >
          <div class="spotRequest-merchandiseDetailsInput-title">Adicionar manualmente</div>

          <div class="horizontalSeparator horizontalSeparator--darker"></div>

          <div class="spotRequest-merchandiseDetailsInput-content">

            <div class="spotRequest-merchandiseDetailsInput-forms">
              <div style="flex: 1">
                <div class="spotRequest-merchandiseDetailsInput-row">
                  <div class="spotRequest-merchandiseDetailsInput-trackingCode">
                    <div class="spotRequest-merchandiseDetailsInput-subTitle">
                      Código buscável
                    </div>
                    <div >
                      {{ roundTrip.trackingCode }}
                    </div>
                  </div>
                </div>
                <div class="spotRequest-merchandiseDetailsInput-row">
                  <div class="spotRequest-merchandiseDetailsInput-completeAddress">
                    <div class="spotRequest-merchandiseDetailsInput-subTitle">
                      Endereço completo ou CEP + número
                    </div>
                    <address-finder
                      ref="addressFinder"
                      class="spotRequest-merchandiseDetailsInput-container"
                      :address="roundTrip.address"
                      @focus-trap-modify="modifyRoundTripInputFocusTrap"
                      @on-querying-address-status-changed="onQueryingAddressStatusChanged"
                      @on-address-changed="onAddressChanged"
                    ></address-finder>
                  </div>
                </div>


                <div class="spotRequest-merchandiseDetailsInput-row">
                  <div class="spotRequest-merchandiseDetailsInput-addressComplement">
                    <div class="spotRequest-merchandiseDetailsInput-subTitle">
                      Complemento
                    </div>
                    <input
                      class="spotRequest-merchandiseDetailsInput-textInput"
                      type="text"
                      v-model="roundTrip.address.complement"
                      maxlength="100"
                    >
                  </div>

                  <div class="spotRequest-merchandiseDetailsInput-addressComplementSpacer"></div>
                </div>

                <div class="spotRequest-merchandiseDetailsInput-row spotRequest-merchandiseDetailsInput-row--columnFlex">
                  <div class="spotRequest-merchandiseDetailsInput-subTitle">
                    Horário de entrega
                  </div>

                  <time-window
                    v-model="roundTrip.timeWindow"
                    @on-time-window-validation="onTimeWindowValidation"
                  >
                  </time-window>
                </div>

                <div class="spotRequest-merchandiseDetailsInput-row">
                  <div class="spotRequest-merchandiseDetailsInput-instructions">
                    <div class="spotRequest-merchandiseDetailsInput-subTitle">
                      Observação
                    </div>
                    <textarea
                      class="spotRequest-merchandiseDetailsInput-textAreaInput"
                      v-model="roundTrip.instructions"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="horizontalSeparator horizontalSeparator--withMarginTop"></div>

            <div class="spotRequest-merchandiseDetailsInput-buttons">
              <button
                class="baseButton cancelButton"
                @click="cancel"
              >Voltar</button>
              <button
                class="baseButton submitButton spotRequest-merchandiseDetailsInput-buttons__submit"
                :class="{'.spotRequest-merchandiseDetailsInput-buttons__submit--loading': queryingAddress}"
                :disabled="cannotSubmit"
                @click="submit"
              >
            <span class="baseButtonWithSpinner__spinnerWrapperContainer" v-if="queryingAddress">
              <span class="baseButtonWithSpinner__spinnerWrapper">
                <minimal-spinner size="20px" class="baseButtonWithSpinner__spinner baseButtonWithSpinner__spinner--disabled" />
              </span>
            </span>
                <span class="spotRequest-merchandiseDetailsInput-buttons__submitLabel">Confirmar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import { TheMask } from 'vue-the-mask'
  import MinimalSpinner from '../../common/MinimalSpinner.vue'
  import TimeWindow from '../../common/TimeWindow.vue'
  import AddressFinder from '../common/AddressFinder.vue'
  import DisposableFocusTrap from '../../common/DisposableFocusTrap';

  export default {
    name: 'RoundTripPlaceInput',

    components: {
      MinimalSpinner,
      TheMask,
      TimeWindow,
      AddressFinder,
    },

    props: {
      roundTripToEdit: Object,
      isVisible: Boolean,
    },

    data() {
      return {
        addressPostalCodeNotFound: false,
        queryingAddress: false,
        validTimeWindow: true,
        fieldAddress: '',
        roundTrip: this.mapRoundTripToMerchandiseAddressFormat(this.roundTripToEdit),
        _manualRoundTripPlaceFocusTrap:  new DisposableFocusTrap(() => this.$refs.manualRoundTripPlaceInputModal),
      };
    },

    computed: {
      validPostalCode() {
        return this.roundTrip
          && this.roundTrip.address
          && this.roundTrip.address.postalCode
          && this.roundTrip.address.postalCode.length === 8;
      },

      cannotSubmit() {
        return this.queryingAddress ||
          !this.addressHasGeolocation
      },

      addressHasGeolocation() {
        return this.roundTrip.address.geolocation &&
          this.roundTrip.address.geolocation.latitude &&
          this.roundTrip.address.geolocation.longitude;
      },

      addressHasThoroughfareAndNumber() {
        return this.roundTrip.address.thoroughfare && this.roundTrip.address.number;
      },

      addressIncomplete() {
        return this.addressHasGeolocation && !this.addressHasThoroughfareAndNumber;
      },
    },

    methods: {
      mapRoundTripToMerchandiseAddressFormat(roundTrip) {
        return {
          trackingCode: 'ROTA-CIRCULAR',

          address: {
            postalCode: roundTrip.address.cep,
            thoroughfare: roundTrip.address.address,
            locality: roundTrip.address.city,
            complement: roundTrip.address.complement,
            neighborhood: roundTrip.address.neighborhood,
            number: roundTrip.address.number,
            state: roundTrip.address.state,
            geolocation: {
              latitude: roundTrip.latitude,
              longitude: roundTrip.longitude
            }
          },
          timeWindow: {
            start: roundTrip.opening_time || "",
            end: roundTrip.closing_time || ""
          },

          instructions: roundTrip.delivery_instructions,
        }
      },

      onQueryingAddressStatusChanged(isQuerying) {
        this.queryingAddress = isQuerying;
      },

      modifyRoundTripInputFocusTrap({disabled}) {
        const focusTrap = this.$data._manualRoundTripPlaceFocusTrap;

        // This waits for vue to render everything before running the pause/unpause. That is, it fixes once and for all
        // the focus trap messing the google maps autocomplete for the first time that it is used.
        // TODO: Understand the vue render cycle and do a better solution.
        setTimeout(() => {
          if (disabled) {
            focusTrap.pause();
          } else {
            focusTrap.unpause();
          }
        }, 0);
      },

      onAddressChanged(newAddress) {
        const originalAddress = this.roundTrip.address;
        this.roundTrip.address = {
          ...newAddress,
          complement: originalAddress.complement,
        }
      },

      cancel() {
        this.$emit('cancel');
      },

      onInput(event) {
        const text = event.target.value;
        const quickTest = /^\s*([0-9-, ]+)$/;
        if (quickTest.test(text)) {
          event.stopImmediatePropagation();

          const fullTest = /^\s*([0-9]{5}-?[0-9]{3})(,\s*|\s+)?(\d+)?/;
          const match = fullTest.exec(text);
          if (match === null) {
            return;
          }

          let [, postalCode, , number] = match;

          postalCode = postalCode.replace('-', '');

          this.queryInput({inputText: text, postalCode, number});
        }
      },

      submit() {
        if (this.cannotSubmit) {
          return;
        }

        this.$emit('submit', this.roundTrip);
      },

      onTimeWindowValidation(validTimeWindow) {
        this.validTimeWindow = validTimeWindow;
      },
    },

    mounted: function(){
      this.$data._manualRoundTripPlaceFocusTrap.activate();
    },
    destroyed:function(){
      this.$data._manualRoundTripPlaceFocusTrap.deactivate();
    }
  }
</script>

<style scoped>
  .spotRequest-merchandiseDetailsInput-content {
    padding: 0 28px 24px 28px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .spotRequest-merchandiseDetailsInput-forms {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .spotRequest-merchandiseDetailsInput-title {
    padding: 27px 0 11px 23px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #1f1f1f;
  }

  .spotRequest-merchandiseDetailsInput-trackingCode {
    width: 100%;
  }

  .spotRequest-merchandiseDetailsInput-subTitle {
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #858585;
  }

  .spotRequest-merchandises-modal {
    position: fixed;
    z-index: 1000;
  }

  .spotRequest-merchandises-modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spotRequest-merchandises-modal__content {
    width: 560px;

    background-color: #ffffff;
    max-height: 80%;
    overflow: auto;

    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e7e7e7;
  }

  .spotRequest-merchandiseDetailsInput-row {
    padding-top: 24px;
    display: flex;
  }

  .spotRequest-merchandiseDetailsInput-row--columnFlex {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .spotRequest-merchandiseDetailsInput-completeAddress {
    flex-grow: 1;
  }

  .spotRequest-merchandiseDetailsInput-addressComplement {
    flex-grow: 1;
  }

  .spotRequest-merchandiseDetailsInput-addressComplementSpacer {
    margin-left: 16px;
    width: 80px;
    flex-grow: 0;
  }

  .spotRequest-merchandiseDetailsInput-instructions {
    width: 100%;
  }


  .horizontalSeparator--withMarginTop {
    margin-top: 24px;
  }

  .spotRequest-merchandiseDetailsInput-buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .spotRequest-merchandiseDetailsInput-textInput {
    box-sizing : border-box;
    width: 100%;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 9px 13px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .spotRequest-merchandiseDetailsInput-textInput--danger {
    border-color: #e8425a;
  }

  .spotRequest-merchandiseDetailsInput-textInput--warning {
    border-color: #fba30f;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .spotRequest-merchandiseDetailsInput-textInput::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textInput::-moz-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textInput:-ms-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textInput:focus {
    border-color: #28c0f1;
    outline: none;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput {
    box-sizing : border-box;
    width: 100%;
    height: 72px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 7px 13px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .spotRequest-merchandiseDetailsInput-textAreaInput::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput::-moz-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput:-ms-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-merchandiseDetailsInput-textAreaInput:focus {
    border-color: #28c0f1;
    outline: none;
  }

  .spotRequest-merchandiseDetailsInput-buttons__submit {
    width: 160px;
    margin-left: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spotRequest-merchandiseDetailsInput-buttons__submit--loading {
    padding-right: 20px; /* fake centering needed because of the spinner */
  }

  .baseButtonWithSpinner__spinnerWrapperContainer {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    padding-right: 8px;
  }

  .baseButtonWithSpinner__spinnerWrapper {
    position: absolute;
    top: 0;
    left: 0;
  }

  .baseButtonWithSpinner__spinner {
    margin: 0;
  }

  .baseButtonWithSpinner__spinner--disabled {
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  }

  .baseButtonWithSpinner__spinner--disabled::before {
    background: #ffffff;
  }
  .baseButtonWithSpinner__spinner--disabled::after {
    background: #aab6b9;
  }

  .spinnerContainer {
    width: 20px;
    height: 20px;
    position: absolute;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }

  .spotRequest-merchandiseDetailsInput-container {
    position: relative;
  }

  .spotRequest-merchandiseDetailsInput-errorMessage,
  .spotRequest-merchandiseDetailsInput-warningMessage {
    display: flex;
    align-items: center;
    padding-top: 4px;
  }

  .spotRequest-merchandiseDetailsInput-errorMessage__img,
  .spotRequest-merchandiseDetailsInput-warningMessage__img {
    width: 20px;
    height: 20px;
  }

  .spotRequest-merchandiseDetailsInput-errorMessage__label {
    margin-left: 8px;
    color: #ed293c;
  }

  .spotRequest-merchandiseDetailsInput-warningMessage__label {
    margin-left: 8px;
    color: #fba30f;
  }

</style>
