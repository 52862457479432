<template>
  <base-layout>

    <div class="fiscalDocumentsUpload-content-panel">
      <page-header title="Upload de documentos fiscais" />

      <div
        v-if="hasFiscalDocuments"
        class="fiscalDocumentsUpload-content-panel-withFiles"
      >
        <div class="fiscalDocumentsUpload-content-panel-withFiles__dropBox">
          <img
            src="/jade/public/icon-xml.svg"
            class="fiscalDocumentsUpload-content-panel-withFiles__dropBox__image"
          >
          <div class="fiscalDocumentsUpload-content-panel-withFiles__dropBox__header">
            <div class="fiscalDocumentsUpload-content-panel-withFiles__dropBox__header__title">
              Enviar arquivo XML
            </div>
            <div class="fiscalDocumentsUpload-content-panel-withFiles__dropBox__header__subTitle">
              Arraste e solte os arquivos aqui, ou clique em “Enviar arquivo XML”
            </div>
            <button
              class="fiscalDocumentsUpload-uploadButton"
              @click="openFileDialog()"
            >
              Enviar arquivo XML
            </button>
          </div>
        </div>

        <div class="horizontalSeparator"></div>

        <div class="fiscalDocumentsUpload-content-panel-withFiles__list">
          <div
            v-for="document in fiscalDocuments"
            class="fiscalDocumentsUpload-content-panel-withFiles__list__item"
          >
            <i
              v-if="document.isUploading"
              class="fa fa-spinner fa-spin fiscalDocumentsUpload-content-panel-withFiles__list__item__status"
            ></i>
            <img
              src="/jade/public/icon-ok.svg"
              v-if="!document.isUploading && document.canUpload && !document.error"
              class="fiscalDocumentsUpload-content-panel-withFiles__list__item__status"
            >
            <img
              v-if="document.error"
              src="/jade/public/icon-alert.svg"
              class="fiscalDocumentsUpload-content-panel-withFiles__list__item__status"
            >
            <img
              src="/jade/public/icon-file.svg"
              class="fiscalDocumentsUpload-content-panel-withFiles__list__item__status"
            >
            <div class="fiscalDocumentsUpload-content-panel-withFiles__list__item__name">{{ document.name }}</div>
            <div
              v-if="document.error"
              class="fiscalDocumentsUpload-content-panel-withFiles__list__item__error"
            >{{ document.error }}</div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="fiscalDocumentsUpload-content-panel-withoutFiles"
      >
        <div class="fiscalDocumentsUpload-content-panel-withoutFiles__dropBox">
          <img
            src="/jade/public/icon-xml.svg"
            class="fiscalDocumentsUpload-content-panel-withoutFiles__dropBox__image"
          >
          <div class="fiscalDocumentsUpload-content-panel-withoutFiles__dropBox__title">
            Enviar arquivo XML
          </div>
          <div class="fiscalDocumentsUpload-content-panel-withoutFiles__dropBox__subTitle">
            Arraste e solte os arquivos aqui, ou clique em “Enviar arquivo XML”
          </div>
          <button
            class="fiscalDocumentsUpload-uploadButton"
            @click="openFileDialog()"
          >
            Enviar arquivo XML
          </button>
        </div>
      </div>

      <input
        class="fiscalDocumentsUpload-content-input"
        accept="text/xml"
        type="file"
        id="fiscalDocuments"
        ref="fiscalDocuments"
        multiple
        @change="fiscalDocumentsInputChanged()"
      />

    </div>
  </base-layout>
</template>

<script>
  import uploadFiscalDocument from './uploadFiscalDocument';
  import BaseLayout from "../common/BaseLayout.vue";
  import PageHeader from "../common/PageHeader.vue";
  import { XMLPageAccessEvent, XMLUploadEvent, XMLClickUploadEvent, gaSendEvent } from "../client/gaEvents";

  export default {
    name: 'FiscalDocumentsUploadPage',

    components: { PageHeader, BaseLayout },

    data() {
      return {
        fiscalDocuments: []
      }
    },

    computed: {
      hasFiscalDocuments() {
        return this.fiscalDocuments.length > 0;
      }
    },

    methods: {
      openFileDialog() {
        this.$refs.fiscalDocuments.click();

        gaSendEvent(XMLClickUploadEvent('fiscalDocumentsUploadPage'));
      },

      fiscalDocumentsInputChanged() {
        this.uploadFiscalDocuments(this.$refs.fiscalDocuments.files);
      },

      uploadFiscalDocuments(files) {
        let fiscalDocuments = [];

        // `files` does not have forEach function
        if (files.length > 0) {
          for (var i = 0; i < files.length; i++) {
            const file = files[i];
            const isXmlFile = file.type === 'text/xml';
            fiscalDocuments.push({
              name: file.name,
              file: file,
              canUpload: isXmlFile,
              isUploading: false,
              error: isXmlFile ? '' : 'Não é um arquivo xml.'
            });
          }
        }

        this.fiscalDocuments = this.fiscalDocuments.concat(fiscalDocuments);

        fiscalDocuments
          .filter(document => document.canUpload)
          .forEach(this.uploadOneFiscalDocument);
      },

      async uploadOneFiscalDocument(fiscalDocument) {
        fiscalDocument.isUploading = true;

        try {
          await uploadFiscalDocument(fiscalDocument.file);

          gaSendEvent(XMLUploadEvent('fiscalDocumentsUploadPage'));
        }
        catch (error) {
          fiscalDocument.error = 'Arquivo xml inválido';
        }
        finally {
          fiscalDocument.isUploading = false;
        }
      },
    },

    mounted() {
      var self = this;

      window.addEventListener("dragleave", function (e) {
        e.preventDefault();
      });

      window.addEventListener("dragover", function (e) {
        e.preventDefault();
      });

      window.addEventListener("drop", function (e) {
        e.preventDefault();
        self.uploadFiscalDocuments(e.dataTransfer.files);
      });

      gaSendEvent(XMLPageAccessEvent('fiscalDocumentsUploadPage'));
    }
  }
</script>

<style scoped>

  .fiscalDocumentsUpload-content {
    margin-left: 240px;
    padding: 20px;
    height: calc(100vh - 40px);
  }

  .fiscalDocumentsUpload-content-panel {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  .fiscalDocumentsUpload-content-panel-withFiles {
    display: flex;
    flex-direction: column;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__dropBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0 0 20px;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__dropBox__image {
    width: 160px;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__dropBox__header {
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__dropBox__header__title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #0e0e0e;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__dropBox__header__subTitle {
    margin-top: 4px;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #636363;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__list {
    max-height: calc(100vh - 244px);
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__list__item {
    display: flex;
    flex-direction: row;
    min-height: 20px;
    padding: 20px 0 20px 4px;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__list__item:not(:last-child) {
    border-bottom: 1px solid #d7d7d7;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__list__item__status {
    font-size: 20px;
    margin-right: 16px;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__list__item__name {
    margin-right: 16px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .fiscalDocumentsUpload-content-panel-withFiles__list__item__error {
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #f22134;
  }

  .fiscalDocumentsUpload-content-panel-withoutFiles {
    position: relative;
    top: 50%;
    transform: translateY(-60%);
  }

  .fiscalDocumentsUpload-content-panel-withoutFiles__dropBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fiscalDocumentsUpload-content-panel-withoutFiles__dropBox__image {
    width: 220px;
  }

  .fiscalDocumentsUpload-content-panel-withoutFiles__dropBox__title {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #0e0e0e;
  }

  .fiscalDocumentsUpload-content-panel-withoutFiles__dropBox__subTitle {
    margin-top: 16px;
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #636363;
  }

  .fiscalDocumentsUpload-content-input {
    display: none;
  }

  .fiscalDocumentsUpload-uploadButton {
    margin: 40px 0;
    border-radius: 4px;
    border: solid 1px #6a6a6a;
    padding: 8px 10px 8px 32px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    background-color: #ffffff;
    background: url('/jade/public/icon-upload.svg') no-repeat 11px 10px;
    cursor: pointer;
  }

</style>
