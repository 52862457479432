<template>
  <div class="skeleton">
    <div class="card bulma-is-flex-direction-row bulma-is-justify-content-center">
      <span class="skeleton__item skeleton__item--square" />
      <span class="skeleton__item skeleton__item--square" />
      <span class="skeleton__item skeleton__item--square" />
      <span class="skeleton__item skeleton__item--square" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SkeletonLoadingNavBar',
});
</script>
