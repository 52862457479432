<template>
  <div :class="[{
      'notification--hiding': state === 'hiding',
      'notification--hidden': state === 'hidden',
    },
    notifications.centerScreenNotification]">
    <p>{{ message }}</p>
  </div>
</template>

<script>
  import notifications from '../styles/notifications.module.scss'
  import sleep from '../utils/sleep';

  /**
   * Milliseconds. Slightly larger than the delay in the 'transition' CSS property for .notification.
   * Theoretically it should match the 'transition' delay but the result was not smooth enough.
   * These times were manually chosen.
   */
  const hidingDuration = 2000;

  export default {
    name: "CenterScreenNotification",

    props: {
      message: String,
      delayToHide: Number // Milliseconds
    },

    data() {
      return {
        state: 'visible',
      }
    },

    computed: {
      notifications: () => Object.freeze(notifications)
    },

    async mounted() {
      this.state = 'visible';
      await sleep(this.delayToHide);
      this.state = 'hiding';
      await sleep(hidingDuration);
      this.state = 'hidden';
    }
  }
</script>

<style scoped>
  .notification--hiding {
    opacity: 0;
  }
  .notification--hidden {
    display: none;
  }
</style>
