<template>
  <button
    :class="[buttons[variation], buttons[size]]"
    :disabled="disabled"
    @click="() => $emit('click')"
  >
    <slot />
  </button>
</template>

<script>
import buttons from '../styles/buttons.module.scss';

export default {
  name: "Button",

  props: {
    variation: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(primary|secondary|default)/)
      },
    },

    size: {
      type: String,
      default: 'small',
      validator: value => {
        return value.match(/(small|large)/)
      },
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttons: () => Object.freeze(buttons)
  }
}
</script>

