import createFocusTrap from 'focus-trap';

/**
 * @callback DisposableFocusTrap~elementProvider
 * @return ?Element
 */

export default class DisposableFocusTrap {
  /**
   * @param {DisposableFocusTrap~elementProvider} elementProvider
   */
  constructor(elementProvider) {
    this.getElement = elementProvider;
    this.focusTrap = null;
  }

  activate() {
    const el = this.getElement();
    if (el == null) {
      console.warn('Focus trap not activated');
      return;
    }
    if (this.focusTrap != null) {
      this.focusTrap.deactivate();
    }
    this.focusTrap = createFocusTrap(el);
    this.focusTrap.activate();
  }

  deactivate() {
    if (this.focusTrap != null) {
      this.focusTrap.deactivate();
      this.focusTrap = null;
    }
  }

  pause() {
    if (this.focusTrap != null) {
      this.focusTrap.pause();
    }
  }

  unpause() {
    if (this.focusTrap != null) {
      this.focusTrap.unpause();
    }
  }
}
