export const errorCodes = {
  NetworkError: 'NetworkError',
};

/**
 * Format a request error from Axios as a request body: extract its body in case
 * there is a JSON response, or return a default body in case there is no response
 * (e.g. a network error).
 *
 * @param err
 * @returns {object}
 *
 * @see apiClient.forwardError
 */
export function getErrorResponseBody(err) {
  return err.response && err.response.data
    || { code: errorCodes.NetworkError }; // A fake response body in case we didn't get a response
}
