<template>
  <base-layout>
    <div class="app-content bg-color-gray--50">
      <div class="main-content">
        <div class="post card card--no-gap">

        <h1 class="title">
          Anúncio Oficial Box Delivery
        </h1>

        <p class="subtitle">
          10/04/2023
        </p>

        <div class="post-content">

        <p>Hoje a Box Delivery inicia uma nova e importante etapa em sua história. <strong>Fechamos um acordo de venda para a Rappi, o maior Super App da América Latina.</strong></p>

        <p>Desde a fundação da Box, há quase sete anos, nos consolidamos como referência no mercado de last mile de 2 rodas, passando por diversas fases, contando com aportes institucionais e aquisições recentes, como a da Vuxx, que foram essenciais para o nosso posicionamento no segmento de 4 rodas.</p>

        <p>Temos modelos de negócio complementares, e nossas operações serão importantes na construção de um novo ecossistema conjunto com a Rappi. Nossa integração ocorrerá ao longo do tempo, então não se preocupem, nada muda agora.</p>

        <p>A Rappi é uma empresa colombiana e, desde que chegou ao Brasil, tem investido no país, nos parceiros, nos talentos e no desenvolvimento de uma plataforma que possibilita que milhões de pessoas realizem suas compras online por meio da intermediação entre consumidores, fornecedores e entregadores independentes.</p>

        <p>O acordo de venda foi firmado e submetido ao CADE. Agora, o órgão fará a avaliação da aquisição até a conclusão completa do negócio. <q>Estamos felizes em fazer parte desse momento de mercado da Rappi. Nosso foco sempre foi e continuará sendo atender nossos clientes, entregadores e o food service da melhor forma possível, de modo que todos os envolvidos sejam beneficiados. Meu objetivo é continuar beneficiando todo o ecossistema, dos entregadores aos restaurantes. Unir nossa expertise com a multiverticalidade da Rappi é a chave para garantir que nosso cliente não espere um minuto a mais do que o necessário. Estou muito feliz com a oportunidade</q>, garante nosso CEO, Felipe Criniti.</p>

        <p><strong>O “NÃO É SÓ UMA ENTREGA” segue mais forte do que nunca.</strong></p>

        <p>Temos paixão pelo que somos, paixão pelo que fazemos, paixão pelo que significamos aos nossos entregadores e parceiros.</p>

        <p>Celebramos mais essa conquista, com a noção clara dos desafios que estão por vir, dos esforços que empregaremos e com consciência dos acertos e dos erros que fizeram parte dessa história.</p>

        <p><strong>Contamos com você nessa nova jornada!</strong></p>
        </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/common/BaseLayout.vue';

export default {
  name: 'Notification20230410',
  components: {
    BaseLayout,
  },

  data() {
    return {};
  },

  computed: {
  },

  mounted() {
  },

  methods: {
  },
};
</script>

<style scoped>
.post {
  max-width: 80ch;
  margin: 0 auto;
}

.card--no-gap {
  gap: 0;
}

.subtitle {
  font-style: italic;
}

q {
  font-style: italic;
}

@media (min-width: 1024px) {
  .post-content {
    font-size: 1.1em;
  }
  .post-content p {
    line-height: 1.6;
  }
}
</style>
