<template>
  <base-layout
    :add-container="false"
  >
    <div
      class="iframe-full-height"
      :class="{ 'iframe-full-height--with-header': hasUserIdentity }"
    >
      <div v-if="loading" class="card-container">
        <p>Carregando…</p>
      </div>
      <iframe @load="handleLoadedIframe" :src="iframeRoute" frameborder="0">
      </iframe>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/common/BaseLayout.vue';
export default {
  name: 'Legacy',

  components: {
    BaseLayout,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    iframeRoute() {
      return `/_ssr${this.$route.fullPath}`;
    },
    hasUserIdentity() {
      return this.$root.hasUserIdentity;
    },
  },

  methods: {
    handleLoadedIframe() {
      this.loading = false;
      this.resetUserInfoOnSessionTimeout();
      this.addTargetTopToIframeLinks();
      this.addTargetTopToIframeForms();
    },
    resetUserInfoOnSessionTimeout() {
      // This is a little hack to handle user logout by inactivity,
      // basically it just update the userInfo on root to an
      // empty object so we hide the useless header
      const iframeFirstPartOfURL = document
        .querySelector('iframe')
        .contentWindow.location.pathname.split('/')[1];

      if (iframeFirstPartOfURL === 'entrar') {
        this.$root.userInfo = {};
      }
    },
    addTargetTopToIframeLinks() {
      const linksInsideIframe = document
        .querySelector('iframe')
        .contentWindow.document.querySelectorAll('a');
      linksInsideIframe.forEach((link) => (link.target = '_top'));
    },
    addTargetTopToIframeForms() {
      const formsInsideIframe = document
        .querySelector('iframe')
        .contentWindow.document.querySelectorAll('form');
      formsInsideIframe.forEach((form) => (form.target = '_top'));
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  overflow: hidden;
}
iframe {
  width: 100%;
  height: 100%;
}

.iframe-full-height {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.iframe-full-height--with-header {
  height: calc(100vh - 96px);
}
</style>
