<template>
  <div
    class="backdrop"
    @keyup.esc="previous"
    @keyup.enter="next"
  >
    <div
      class="card"
      role="dialog"
      ref="manualMerchandiseInputModal"
    >
      <div class="title">Adicionar manualmente</div>

      <div class="horizontalSeparator horizontalSeparator--darker"></div>

      <div class="content">
        <div class="stepper" v-if="shipmentTrackingSharingEnabled">
          <div class="stepItem" :class="{'stepItem--selected': merchandiseDetailsSelected}">Mercadoria</div>
          <img src="/jade/public/ic-arrow-right.svg">
          <div class="stepItem" :class="{'stepItem--selected': trackingRecipientsSelected}">Rastreio da mercadoria</div>
        </div>

        <merchandise-details-input
          v-if="merchandiseDetailsSelected"
          :merchandise-to-edit="merchandiseToEdit"
          :merchandises="merchandises"
          @focus-trap-modify="modifyMerchandiseInputFocusTrap"
          @on-querying-address-status-changed="onQueryingAddressStatusChanged"
          @incomplete-merchandise="onIncompleteMerchandiseChanged"
        />

        <tracking-recipients-input
          v-if="trackingRecipientsSelected"
          :tracking-recipients="merchandiseToEdit.trackingRecipients"
          @tracking-recipients-changed="onTrackingRecipientsChanged"
        />

        <div class="horizontalSeparator horizontalSeparator--withMarginTop"></div>

        <div class="buttons">
          <button
            class="baseButton cancelButton"
            @click="previous"
          >{{ cancelButtonLabel }}</button>
          <button
            class="baseButton submitButton buttons__submit"
            :class="{'.buttons__submit--loading': queryingAddress}"
            :disabled="cannotSubmit"
            @click="next"
          >
            <minimal-spinner
              size="20px"
              hex-color="#ffffff"
              hex-background-color="#aab6b9"
              class="baseButtonWithSpinner__spinner"
              v-if="queryingAddress" />
            <span class="buttons__submitLabel">{{ submitButtonLabel }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MerchandiseDetailsInput from "./MerchandiseDetailsInput";
import TrackingRecipientsInput from "../../common/TrackingRecipientsInput";
import DisposableFocusTrap from "../../common/DisposableFocusTrap";
import MinimalSpinner from '../../common/MinimalSpinner.vue'

export default {
  name: "MerchandiseManualInputModal",

  components: {
    MerchandiseDetailsInput,
    TrackingRecipientsInput,
    MinimalSpinner,
  },

  data() {
    return {
      _manualMerchandiseModalFocusTrap: new DisposableFocusTrap(() => this.$refs.manualMerchandiseInputModal),
      currentStep: 0,
      queryingAddress: false,
      incompleteMerchandise: true,
    }
  },

  props: {
    merchandiseToEdit: Object,
    merchandises: Array,
    shipmentTrackingSharingEnabled: Boolean,
  },

  computed: {
    merchandiseDetailsSelected() {
      return this.currentStep === 0;
    },

    trackingRecipientsSelected() {
      return this.shipmentTrackingSharingEnabled && this.isLastStep;
    },

    isLastStep() {
      return this.shipmentTrackingSharingEnabled ? this.currentStep === 1 : this.currentStep === 0;
    },

    cancelButtonLabel() {
      if (this.currentStep === 0) {
        return 'Cancelar'
      }
      return 'Voltar'
    },

    submitButtonLabel() {
      if (this.isLastStep) {
        return 'Confirmar'
      }
      return 'Próximo'
    },

    cannotSubmit() {
      return this.incompleteMerchandise;
    },
  },

  methods: {
    previous() {
      if (this.currentStep === 0) {
        this.$emit('cancel');
      } else {
        this.currentStep--;
      }
    },

    next() {
      if (this.cannotSubmit) {
        return;
      }

      if (this.isLastStep) {
        this.currentStep++;

        this.$nextTick(() => {
          this.merchandiseToEdit.trackingCode = this.merchandiseToEdit.trackingCode.toUpperCase();
          this.merchandiseToEdit.id = this.merchandiseToEdit.id || this.merchandiseToEdit.trackingCode;

          this.$emit('submit', this.merchandiseToEdit);
        })
      } else {
        this.currentStep++;
      }
    },

    onQueryingAddressStatusChanged(isQuerying) {
      this.queryingAddress = isQuerying;
    },

    onIncompleteMerchandiseChanged(incompleteMerchandise) {
      this.incompleteMerchandise = incompleteMerchandise
    },

    modifyMerchandiseInputFocusTrap({disabled}) {
      const focusTrap = this.$data._manualMerchandiseModalFocusTrap;

      // This waits for vue to render everything before running the pause/unpause. That is, it fixes once and for all
      // the focus trap messing the google maps autocomplete for the first time that it is used.
      // TODO: Understand the vue render cycle and do a better solution.
      setTimeout(() => {
        if (disabled) {
          focusTrap.pause();
        } else {
          focusTrap.unpause();
        }
      }, 0);
    },

    workaround_hideGoogleAutocomplete() {
      document.querySelectorAll('body > .pac-container').forEach(el => {
        el.style.display = 'none';
      });
    },

    onTrackingRecipientsChanged(trackingRecipients) {
      this.merchandiseToEdit.trackingRecipients = trackingRecipients
    }
  },

  mounted() {
    this.$data._manualMerchandiseModalFocusTrap.activate();
  },

  destroyed() {
    this.$data._manualMerchandiseModalFocusTrap.deactivate();
    this.workaround_hideGoogleAutocomplete()
  },
}
</script>

<style scoped>
  .backdrop {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    width: 560px;

    background-color: #ffffff;
    max-height: 85%;
    overflow: auto;

    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e7e7e7;
  }

  .title {
    padding: 27px 0 11px 23px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #1f1f1f;

  }

  .content {
    padding: 0 28px 24px 28px;
    display: flex;
    flex-direction: column;
  }

  .stepper {
    display: inline-flex;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    align-items: center;
    margin-top: 24px;
  }

  .stepItem {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
    padding: 16px 24px;
  }

  .stepItem--selected {
    color: #000000;
    opacity: 1;
  }

  .horizontalSeparator--withMarginTop {
    margin-top: 24px;
  }

  .buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .buttons__submit {
    width: 160px;
    margin-left: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttons__submit--loading {
    padding-right: 20px; /* fake centering needed because of the spinner */
  }

  .baseButtonWithSpinner__spinner {
    padding-right: 8px;
  }
</style>
