<template>
  <div class="spotRequest-vehicleSelection__selection">
    <vehicle-selection-item
      v-for="(vehicle, index) in vehicles"
      :key="index"
      :vehicle="vehicle"
    />

    <div
      v-if="showWarning"
      class="spotRequest-vehicleSelection__warning"
    >
      <b>Toco</b> e
      <b>Truck</b>: consultar restrição de horários das categorias em São Paulo.
      Não poderão circular de segunda a sexta-feira das 5h às 9h e das 17h às 22h;
      aos sábados não podem circular das 10h às 14h; domingos e feriados podem circular livremente.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VehicleSelectionItem from './VehicleSelectionItem.vue';

export default {
  name: 'VehiclePicker',
  components: {
    VehicleSelectionItem,
  },

  computed: {
    ...mapState('spotRequest', {
      vehicleType: (state) => state.vehicleType,
      vehicles: (state) => state.availableVehicles,
    }),
    showWarning() {
      return !!this.$store.state.spotRequest.selectedVehicle?.restrictedCirculation;
    },
  },
};
</script>

<style scoped>
.spotRequest-vehicleSelection__selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.spotRequest-vehicleSelection__warning {
  width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  height: 60px;
  padding: 16px 20px 16px 56px;
  margin: 20px 20px 0 0;
  color: #858585;
  background: url(/jade/public/ic-exclamation.svg) 16px 16px no-repeat;
}
</style>
