<template>
  <div class="container">
    <div class="content">
      <img src="/jade/public/ic-important.svg" class="importantIcon"/>
      <div class="contentTitle">OS indisponível para consulta</div>
      <div class="contentExplanation">Confira o número da OS; Caso esteja com problema verifique com a nossa operação</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnavailablePage",
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.content {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.importantIcon {
  width: 56px;
  height: 56px;
}
.contentTitle {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 12px;
}
.contentExplanation {
  font-size: 16px;
}
@media (max-width: 768px) {
  .contentTitle {
    font-size: 18px;
  }
  .contentExplanation {
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
  }
}
</style>
