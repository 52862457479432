<template>
  <div
    ref="backdrop"
    :class="modals.backdrop"
    @click="closeOnBackdropClick"
  >
    <div :class="[modals.modal, modals[size]]">

      <div v-if="title" :class="modals.header">
        <h5 :class="text.heading5"> {{ title }} </h5>
      </div>

      <slot name="content" />

      <div v-if="$slots.footer" :class="modals.footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import modals from '../styles/modals.module.scss';
import text from '../styles/text.module.scss';

export default {
  name: "Modal",
  props: {
    title: String,
    size: {
      type: String,
      default: 'small',
      validator: value => {
        return value.match(/(small|medium|large)/)
      },
    },
  },

  computed: {
    modals: () => Object.freeze(modals),
    text: () => Object.freeze(text)
  },

  methods: {
    close() {
      this.$emit('close')
    },

    closeOnBackdropClick(ev) {
      const backdropArea = this.$refs.backdrop;

      if (ev.target === backdropArea) {
        this.close();
      }
    }
  }
}
</script>
