import axios from 'axios';

export function formattedAddress(warehouse) {
  if (!warehouse) {
    return '';
  }

  const addressElements = [];

  if (warehouse.address.address) {
    addressElements.push(warehouse.address.address);
    if (warehouse.address.number) {
      addressElements.push(warehouse.address.number);
    }
  } else {
    if (warehouse.address.neighborhood) {
      addressElements.push(warehouse.address.neighborhood);
    }
    if (warehouse.address.city) {
      addressElements.push(warehouse.address.city);
    }
    if (warehouse.address.state) {
      addressElements.push(warehouse.address.state);
    }
  }

  if (warehouse.address.complement) {
    addressElements.push(warehouse.address.complement);
  }

  return addressElements.join(', ');
}

export function getCustomerWarehouses() {
  return axios.get('/jade/api/customers/warehouses')
    .then((response) => response.data);
}

export async function addWarehouse(warehouse) {
  const response = await axios.post('/jade/api/customers/warehouses', warehouse);
  const { warehouse_id } = response.data;

  // TODO: parsing to string is needed because we have id as string in customer registration
  // TODO: remove this when we fix that issue
  return { ...warehouse, id: warehouse_id.toString() };
}
