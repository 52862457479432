<template>
  <div>
    <table>
      <thead>
      <tr>
        <th class="index sticky">#</th>
        <th class="address sticky">Endereço</th>
        <th class="trackingCode sticky">Código de pedido</th>
        <th class="status sticky">Status</th>
        <th class="pods sticky">Comprov.</th>
        <th v-if="isSharingEnabled" class="share sticky"></th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="stop in stops"
        class="stopList"
      >
        <td class="index sticky">
          <div class="index-content">
            {{ stop.index + 1 }}
          </div>
        </td>
        <td class="address sticky">
          <div class="address-content">
            {{ stop.address }}
          </div>
        </td>
        <td class="trackingCode">
          <div class="trackingCode-content">
            {{ stop.trackingCode }}
          </div>
        </td>
        <td class="status">
          <div
            class="status-content"
          >
            <template v-if="showDriverEta(stop.id)">
              <div
                class="eta-notification"
              >
                Estimada: {{ driverEta.formattedArrivalDate}}
              </div>
            </template>
            <template v-else-if="showStopStatus(stop.status)">
              <div>{{ translateStopStatus(stop.status) }}</div>
              <div>({{ formatDateTime(stop.statusUpdatedAt) }})</div>
            </template>
          </div>
        </td>
        <td class="pods">
          <div class="pods-content">
            <div
              v-if="!stop.pods.length"
              class="pod pod--not-available"
            ></div>
            <a
              v-for="pod in stop.pods"
              class="pod"
              target="_blank"
              :class="getPodStyleModifier(pod.status)"
              :href="pod.link"
            ></a>
          </div>
        <td
          v-if="isSharingEnabled"
          class="share"
        >
          <span
            v-if="stop.sharing"
            title="Rastreio de remessa individual"
          >
            <Button
              class="share-button"
              variation="default"
              @click="doShowTrackingRecipientInputModal(stop)"
            >
              <i class="fa fa-share-alt" />
            </Button>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
    <tracking-recipients-input-modal
      v-if="showTrackingRecipientInputModal"
      :address="editedStop.address"
      :tracking-code="editedStop.trackingCode"
      :shipment-id="editedStop.sharing.shipmentId"
      :anonymous-url="editedStop.sharing.anonymousURL"
      :tracking-recipients-to-edit="trackingRecipients"
      @cancel="cancelTrackingRecipientInputModal"
      @submit="submitTrackingRecipientInputModal"
    />
  </div>
</template>

<script>
  import moment from 'moment';
  import { podStatus } from '../../podStatus';
  import { stopStatus, stopStatusTranslations } from '../../stopStatus';
  import Button from "../../../hounDS/components/Button";
  import TrackingRecipientsInputModal from "@/tracking/order/TrackingRecipientsInputModal";

  export default {
    name: "TransportationsStatusTab",
    components: {Button, TrackingRecipientsInputModal},
    props: {
      stops: Array,
      driverEta: Object
    },

    data() {
      return {
        showTrackingRecipientInputModal: false,
        editedStop: null,
      }
    },

    computed: {
      isSharingEnabled() {
        return this.stops.some(stop => stop.sharing !== null);
      },

      trackingRecipients() {
        if (!this.editedStop) {
          return []
        }

        return this.editedStop.sharing.recipients
      },
    },

    methods: {
      formatDateTime(dateTime) {
        return moment(dateTime).format('DD/MM HH:mm')
      },

      showStopStatus(status) {
        return status !== stopStatus.PENDING;
      },

      showDriverEta(stopId) {
        return this.driverEta && stopId === this.driverEta.nextStopId;
      },

      getPodStyleModifier(status) {
        switch (status) {
          case podStatus.SUCCEEDED:
            return 'pod--succeeded';
          case podStatus.FAILED:
            return 'pod--failed';
          case podStatus.RETURNED:
            return 'pod--returned';
          default:
            console.error(`Pod class modifier not found for "${podStatus}".`);
            return 'pod--not-available';
        }
      },

      translateStopStatus(stopStatus) {
        return stopStatusTranslations[stopStatus];
      },

      doShowTrackingRecipientInputModal(stop) {
        this.showTrackingRecipientInputModal = true
        this.editedStop = stop
      },

      cancelTrackingRecipientInputModal() {
        this.showTrackingRecipientInputModal = false
        this.editedStop = null
      },

      submitTrackingRecipientInputModal(trackingRecipients) {
        this.editedStop.sharing.recipients = trackingRecipients
        this.showTrackingRecipientInputModal = false
        this.editedStop = null
      }
    },
  }
</script>

<style scoped lang="scss">
  @import '../../../hounDS/styles/global/colors';

  table {
    border-spacing: 0;
    margin: auto;
    width: 100%;
  }

  th {
    font-weight: normal;
    font-size: 12px;
    color: $gray800;

    padding: 16px 0 16px 12px;
    background: $gray50;
  }

  td {
    font-size: 14px;
    color: #000000;

    vertical-align: top;
    padding: 16px 0 12px 12px;
    background: white;
    border-bottom: 1px solid $gray100;
  }

  th:first-child, td:first-child {
    padding-left: 24px;
  }

  th:last-child, td:last-child {
    padding-right: 24px;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .index {
    width: 16px;
    text-align: right;
    left: 0;
  }

  th.index.sticky {
    z-index: 3;
    top: 0;
  }

  td.index.sticky {
    z-index: 1;
  }

  .index-content {
    width: 16px;
  }

  .eta-notification {
    background: $yellow500;
    color: black;
    border-radius: 10px;
    padding: 2px 12px;
    font-weight: 600;
    font-size: 12px;
  }

  .address {
    text-align: left;
    left: 40px;
  }

  th.address.sticky {
    z-index: 3;
    top: 0;
  }

  td.address.sticky {
    z-index: 1;
  }

  .address-content {
    min-width: 130px
  }

  .trackingCode {
    width: 100px;
    text-align: right;
  }

  .trackingCode-content {
    min-width: 100px;
  }

  .status {
    text-align: left;
    width: 140px;
  }

  .status-content {
    min-width: 80px;
    max-width: 140px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    white-space: nowrap;
  }

  .status-content > div {
    margin-right: 2px;
  }

  .pods {
    width: 72px;
    text-align: right;
  }

  .share {
    width: 12px;
  }

  .share-button {
    /* Override button component default padding */
    padding: 0 6px !important;
    margin: 0;
  }

  .pods-content {
    width: 72px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .pod {
    width: 20px;
    height: 20px;
    margin: 0 0 4px 4px;
  }

  .pod--not-available {
    background: url('/jade/public/pod-not-available.svg');
  }

  .pod--succeeded {
    background: url('/jade/public/pod-succeeded.svg');
  }

  .pod--failed {
    background: url('/jade/public/pod-failed.svg');
  }

  .pod--returned {
    background: url('/jade/public/pod-returned.svg');
  }

  @media (max-width: 768px) {
    th:first-child, td:first-child {
      padding-left: 16px;
    }

    td:nth-last-child(2), th:nth-last-child(2) {
      padding-right: 16px;
    }

    .pods {
      display: none;
    }

    .address {
      left: 32px;
    }
  }
</style>
