<template>
  <div
    class="timeWindowPickerModal"
    @keyup.esc="close"
    @keyup.enter="confirm"
    tabindex="0"
  >
    <div class="timeWindowPickerModal__backdrop">
      <div class="timeWindowPickerModal__content" role="dialog">
        <div class="timeWindowPickerModal__title">{{ title }}</div>
        <div class="timeWindowPickerModal__pickers">
          <vue-ctk-date-time-picker
            class="timeWindowPickerModal__picker"
            label="Escolha horário inicial"
            locale="pt-br"
            format="HH:mm"
            formatted="HH:mm"
            time-format="HH:mm"
            :minute-interval=10
            v-model="selectedStart"
            without-header
            disable-date
          ></vue-ctk-date-time-picker>
          <vue-ctk-date-time-picker
            class="timeWindowPickerModal__picker"
            label="Escolha horário final"
            locale="pt-br"
            format="HH:mm"
            formatted="HH:mm"
            time-format="HH:mm"
            :minute-interval=10
            :error-hint="shouldShowTimeInputError"
            v-model="selectedEnd"
            without-header
            disable-date
          ></vue-ctk-date-time-picker>
        </div>
        <div class="horizontalSeparator"></div>
        <div class="timeWindowPickerModal__buttons">
          <button
            class="baseButton cancelButton"
            @click="close"
          >Cancelar</button>
          <button
            class="baseButton submitButton"
            @click="confirm"
            :disabled="cannotConfirm"
          >Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

  export default {
    name: 'TimeWindowPickerModal',

    data() {
      return {
        selectedStart: this.start ? this.start : '',
        selectedEnd: this.end ? this.end : ''
      };
    },

    props: {
      title: String,
      start: String,
      end: String,
    },

    computed: {
      cannotConfirm() {
        return this.shouldShowTimeInputError || !this.selectedStart || !this.selectedEnd;
      },

      shouldShowTimeInputError() {
        if (!this.selectedStart || !this.selectedEnd) {
          return false;
        }

        let splittedStart = this.selectedStart.split(":");
        let splittedEnd = this.selectedEnd.split(":");

        let startHour = parseInt(splittedStart[0]);
        let startMinutes = parseInt(splittedStart[1]);

        let endHour = parseInt(splittedEnd[0]);
        let endMinutes = parseInt(splittedEnd[1]);

        return (startHour > endHour) || (startHour == endHour && startMinutes <= endMinutes);
      },
    },

    methods: {
      close() {
        this.$emit('close');
      },

      confirm() {
        this.$emit('confirm', this.selectedStart, this.selectedEnd);
      },
    },

    watch: {
    },
  }
</script>

<style scoped>
  .timeWindowPickerModal {
    position: fixed;
    z-index: 1000;
  }

  .timeWindowPickerModal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timeWindowPickerModal__content {
    width: 510px;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    padding: 24px 28px;
    display: flex;
    flex-direction: column;
  }

  .timeWindowPickerModal__title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #2f2f2f;
  }

  .timeWindowPickerModal__pickers {
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    display: flex;
    flex-direction: row;
  }

  .timeWindowPickerModal__picker:last-child {
    margin-left: 20px;
  }

  .timeWindowPickerModal__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .timeWindowPickerModal__buttons > :not(:last-child) {
    margin-right: 20px;
  }
</style>
