var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tabbed-panel',{attrs:{"tabs":[
      {
        name: 'Status dos transportes',
        component: _vm.tabs.transportationsStatus,
        props: { driverEta: _vm.driverEta, stops: _vm.stops }
      },
      {
        name: 'Informações da rota',
        component: _vm.tabs.orderDetails,
        props: { orderDetails: _vm.orderDetails }
      }
    ],"sticky-header":true}})}
var staticRenderFns = []

export { render, staticRenderFns }