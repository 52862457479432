<template>
  <div>

    <spot-scheduling
      class="spotRequest-merchandiseInput__wrapper"
    />

    <div class="horizontalSeparator"></div>

    <div class="spotRequest-merchandiseInput__wrapper">
      <div class="spotRequest-merchandiseInput-serviceTypeAndWarehouse">
        <div class="spotRequest-merchandiseInput-selectedServiceType">
          <h2 class="spotRequest-sectionTitle">Tipo de Serviço</h2>
          <div class="spotRequest-merchandiseInput-selectedServiceType__radioButtons">
            <label class="defaultRadioButton spotRequest-merchandiseInput-selectedServiceType__pickupRadioButton">
              <input
                type="radio"
                name="selectedServiceType"
                :value="serviceTypes.PICKUP"
                v-model="selectedServiceType"
              >
              <span class="defaultRadioButton__checkmark"></span>
              <span class="defaultRadioButton__text">{{ serviceTypeNames.PICKUP }}</span>
            </label>
            <label class="defaultRadioButton">
              <input
                type="radio"
                name="selectedServiceType"
                :value="serviceTypes.DELIVERY"
                v-model="selectedServiceType"
              >
              <span class="defaultRadioButton__checkmark"></span>
              <span class="defaultRadioButton__text">{{ serviceTypeNames.DELIVERY }}</span>
            </label>
          </div>
        </div>

        <div class="verticalSeparator"></div>

        <div class="spotRequest-merchandiseInput-warehouseList__wrapper">
          <!--
            Note: it seems that the theoretically correct placement should be 'top-start',
            however only 'top-end' seems to produce the desired effect. Maybe this is a
            bug with Popper.js's positioning algorithm.
          -->
          <v-popover
            :open="showCallToAddWarehouseAction"
            trigger="manual"
            placement="top-end"
            :autoHide="false"
            :hideOnTargetClick="false"
            delay="200"
            popoverClass="tooltip-warning"
          >
            <p class="warehouseList__pickerLabel">
              {{ warehousePickerLabel }}
            </p>

            <div
              slot="popover"
              class="spotRequest-merchandiseInput-addWarehouseTooltip"
            >
              <p>Para prosseguir com a solicitação, clique abaixo e cadastre o armazém onde as mercadorias serão carregadas ou descarregadas.</p>
            </div>
          </v-popover>

          <warehouse-selection
            :warehouse="warehouse"
            @on-warehouse-selected="onWarehouseSelected"
            @on-warehouses-loaded="onWarehousesLoaded"
          />

        </div>
      </div>

      <div class="spotRequest-merchandiseInput-inputs">
        <div class="spotRequest-merchandiseInput-inputs__productCode">
          <div class="spotRequest-merchandiseInput-inputs__productCode__title">
            Informe NF-e ou CT-e
          </div>
          <input
            v-if="podeSolicitarRota"
            class="spotRequest-merchandiseInput-inputs__productCode__input"
            :class="{'spotRequest-merchandiseInput-documentCodeTooLong': documentCodeTooLong}"
            type="text"
            v-model="productCode"
            @keyup.enter.prevent="onSingleProductCodeInput"
            @paste="onPasteInput"
            maxlength="255"
            autofocus
          >
          <span v-if="documentCodeTooLong" class="fa fa-warning">
            Código excede o tamanho máximo de 44 caracteres!
          </span>
          <div class="spotRequest-merchandiseInput-inputHelp">
            <label
              class="spotRequest-merchandiseInput-inputHelp__label"
              @click="onInputHelpClicked()"
            >
              Ver opções de como adicionar mercadorias
            </label>
          </div>
        </div>
        <button
          v-if="fiscalDocumentsUploadMenuItemEnabled && podeSolicitarRota"
          class="spotRequest-merchandiseInput-inputs__uploadXmlButton"
          @click="showFiscalDocumentsUploadModal"
        >
          Enviar arquivo XML
        </button>
        <div>
          <button
            class="spotRequest-merchandiseInput-inputs__addManuallyButton"
            :disabled="!podeSolicitarRota"
            @click="addMerchandiseManually"
          >
           Adicionar manualmente
          </button>

        </div>
      </div>

      <merchandise-input-help
        v-if="showInputHelp"
        @cancel="onInputHelpCancel()" />

    </div>

  </div>
</template>

<script>
import Papa from 'papaparse';
import { VPopover } from 'v-tooltip';
import WarehouseSelection from '../common/WarehouseSelection.vue';
import Spinner from '../../common/Spinner.vue';
import SpotScheduling from '../common/SpotScheduling.vue';
import MerchandiseInputHelp from './MerchandiseInputHelp.vue';
import { serviceTypeNames, serviceTypes } from "../common/serviceTypes";
import { fiscalDocumentsUploadEnabled } from '@/client/runtimeConfig';
import { mapState } from 'vuex';

function uniqueDocuments(documents) {
  const documentsByCode = {};

  documents.forEach(function (document) {
    if (!documentsByCode[document.productCode]) {
      documentsByCode[document.productCode] = document;
    }
  });

  const unique = function(document, index, array) {
    return documentsByCode[document.productCode] === document;
  };

  return documents.filter(unique);
}

function readPastedDocumentCodes(pastedText, serviceType) {
  const parsed = Papa.parse(pastedText);
  const parsedLines = parsed.data;

  const columns = {
    ShipmentCode: 0,
    PostalCode: 1,
    AddressNumber: 2,
    Instructions: 3,
    RecipientEmail: 4,
    RecipientPhone: 5,
    Latitude: 6,
    Longitude: 7,
  };

  if (parsedLines.length === 1 && parsedLines[0].length === 1) {
    return null;
  }

  // Split for email addresses or phone numbers
  // (both separated by ',' or ';')
  function splitField(field) {
    return field.split(/[,;]/)
      .map(s => s.trim())
      .filter(s => s)
  }

  function readRecipients(fields) {
    const emails = splitField(fields[columns.RecipientEmail] || '')
    const phones = splitField(fields[columns.RecipientPhone] || '')

    const recipients = [
      ...emails.map(email => ({ email })),
      ...phones.map(phone => ({ phone })),
    ]

    return {trackingRecipients: recipients}
  }

  function readGeolocation(fields) {
    let latitude = Number.parseFloat(fields[columns.Latitude]?.replace(/,/, '.'));
    let longitude = Number.parseFloat(fields[columns.Longitude]?.replace(/,/, '.'));

    if (!(latitude || longitude)) {
      return null;
    }

    return { latitude, longitude };
  }

  function readDocumentFromFields(fields) {
    return {
      productCode: fields[columns.ShipmentCode].toUpperCase(),
      postalCode: fields[columns.PostalCode] ? fields[columns.PostalCode].replace(/\D/g, '') : '',
      number: fields[columns.AddressNumber],
      instructions: fields[columns.Instructions],
      serviceType: serviceType,
      ...readRecipients(fields),
      ...readGeolocation(fields),
    };
  }

  function readSuperBatchDocumentFromFields(fields) {
    return {
      productCode: fields[0].toUpperCase(),
      postalCode: fields[1] ? fields[1].replace(/\D/g, '') : '',
      number: fields[2],
      instructions: fields[3],
      customerId: fields[4],
      userEmail: fields[5],
      vehicleType: fields[6],
      scheduledFor: (fields[7] ?? '').replaceAll(/\u00a0/g, ' '),
      driverAssistantsCount: fields[8],
      externalId: fields[9],
      weight: fields[10],
      warehouseName: fields[11],
      declaredDistanceInKm: fields[12] || null,
      merchandisesTotalPrice: fields[13] || null,

      serviceType: serviceType,
    };
  }

  function nonEmptyCode(document) {
    return document.productCode !== '';
  }

  const isSuperBatch = parsedLines[0].length > 10;
  const documents = isSuperBatch ?
    parsedLines.map(readSuperBatchDocumentFromFields) :
    parsedLines.map(readDocumentFromFields);

  return {
    merchandises: uniqueDocuments(documents.filter(nonEmptyCode)),
    isSuperBatch
  };
}

export default {
  name: "MerchandiseInput",

  components: {
    MerchandiseInputHelp,
    Spinner,
    SpotScheduling,
    VPopover,
    WarehouseSelection,
  },

  props: {
    serviceType: String,
    warehouse: Object,
  },

  computed: {
    ...mapState('spotRequest', ['config']),

    podeSolicitarRota() {
      return this.config?.pode_solicitar_rotas;
    },

    fiscalDocumentsUploadMenuItemEnabled() {
      return fiscalDocumentsUploadEnabled;
    },

    warehousePickerLabel() {
      return this.serviceType === serviceTypes.DELIVERY ?
        'Retirar as mercadorias em:' :
        'Levar as mercadorias para:';
    },

    showCallToAddWarehouseAction() {
      return this.warehousesLoaded && this.warehouse == null;
    },

    serviceTypes() {
      return serviceTypes;
    },

    serviceTypeNames() {
      return serviceTypeNames;
    },
  },

  data() {
    return {
      selectedServiceType: this.serviceType,
      productCode: '',
      showInputHelp: false,
      warehousesLoaded: false,
      documentCodeTooLong: false,
    };
  },

  methods: {
    onSingleProductCodeInput() {
      if (this.documentCodeTooLong) {
        return;
      }
      const productCode = this.productCode.trim();
      if (productCode !== '') {
        const merchandises = [{
          productCode: productCode,
          serviceType: this.selectedServiceType
        }];
        this.$emit('merchandises-input', { merchandises });
        this.productCode = '';
      }
    },

    onPasteInput(clipboardEvent) {
      const rawPastedText = clipboardEvent.clipboardData.getData('text/plain');
      const pastedMerchandises = readPastedDocumentCodes(rawPastedText, this.selectedServiceType);

      if (pastedMerchandises !== null && pastedMerchandises.merchandises.length > 0) {
          clipboardEvent.preventDefault();
          this.$emit('merchandises-input', pastedMerchandises);
          this.productCode = '';
      }
    },

    onWarehouseSelected(warehouse) {
      this.$emit('on-warehouse-selected', warehouse);
    },

    onWarehousesLoaded(loaded) {
      this.warehousesLoaded = loaded;
    },

    onInputHelpClicked() {
      this.showInputHelp = true;
    },

    onInputHelpCancel() {
      this.showInputHelp = false;
    },

    showFiscalDocumentsUploadModal()  {
      this.$emit('show-fiscal-documents-upload-modal');
    },

    addMerchandiseManually() {
      this.$emit('add-merchandise-manually-requested');
    },
  },

  watch: {
    // TODO: This will not be used once we use these radio buttons with their real purpose:
    // changing the service type for the CURRENT merchandise. Currently we are in a temporary
    // solution where this will change the service type for ALL merchandises as our backend
    // cannot yet handle 'mixed' orders.
    selectedServiceType(newServiceType) {
      this.$emit('update:service-type', newServiceType);
    },
    productCode(newProductCode) {
      if (newProductCode.length > 44) {
        this.documentCodeTooLong = true;
      }
      else {
        this.documentCodeTooLong = false;
      }
    },

  },
}
</script>

<style scoped>
  .spotRequest-merchandiseInput__wrapper {
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
  }

  .spotRequest-merchandiseInput-serviceTypeAndWarehouse {
    display: flex;
    height: 90px;
  }

  .spotRequest-merchandiseInput-selectedServiceType {
    margin-bottom: 22px;
    margin-right: 40px;
  }

  .spotRequest-merchandiseInput-selectedServiceType__radioButtons {
    display: flex;
    margin-top: 18px;
  }

  .spotRequest-merchandiseInput-selectedServiceType__pickupRadioButton {
    margin-right: 60px;
  }

  .spotRequest-merchandiseInput-inputs {
    margin-top: 28px;
    display: flex;
    flex-direction: row;
  }

  .spotRequest-merchandiseInput-inputs__productCode {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 12px;
  }

  .spotRequest-merchandiseInput-inputs__productCode__title {
    margin-bottom: 12px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .spotRequest-merchandiseInput-inputs__productCode__input {
    flex-grow: 1;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    width: 100%;
    height: 36px;
    margin-bottom: 4px;
    padding: 10px 11px;

    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #d7d7d7;

    font-size: 12px;
    letter-spacing: 0.2px;
    color: #2f2f2f;
  }

  .spotRequest-merchandiseInput-inputs__uploadXmlButton {
    margin: 32px 12px 0 0;
    max-height: 36px;
    border-radius: 4px;
    border: solid 1px #6a6a6a;
    padding: 6px 10px 8px 32px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    background-color: #ffffff;
    background: url('/jade/public/icon-upload.svg') no-repeat 11px 10px;
    cursor: pointer;
  }

  .spotRequest-merchandiseInput-inputs__addManuallyButton {
    margin-top: 32px;
    max-height: 36px;
    padding: 8px 10px 9px 10px;
    border-radius: 4px;
    border: 1px solid #2a2525;
    background-color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    cursor: pointer;
    white-space: nowrap;
  }

  .spotRequest-merchandiseInput-inputs__addManuallyButton:disabled {
    background: #aab6b9;
    box-shadow: none;
    cursor: default;
    opacity: 0.9;
    border: none;
    color: white;
  }

  .spotRequest-merchandiseInput-warehouseList__wrapper {
    flex-grow: 1;
    margin-left: 40px;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .spotRequest-merchandiseInput-inputs__productCode__input::-webkit-input-placeholder {
    color: #aab6b9;
  }

  .spotRequest-merchandiseInput-inputs__productCode__input::-moz-placeholder {
    color: #aab6b9;
  }

  .spotRequest-merchandiseInput-inputs__productCode__input:-ms-input-placeholder {
    color: #aab6b9;
  }

  .spotRequest-merchandiseInput-inputs__productCode__input:focus {
    border-color: #2ec7f3;
    outline: none;
  }

  .warehouseList__pickerLabel {
    margin: 0 0 8px 0;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  .spotRequest-merchandiseInput-inputHelp {
    margin-top: -4px;
  }

  .spotRequest-merchandiseInput-inputHelp__label {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #909090;
    text-decoration: underline;
    cursor: pointer;
  }

  .spotRequest-merchandiseInput-addWarehouseTooltip {
    width: 320px;
  }

  .spotRequest-merchandiseInput-documentCodeTooLong {
    border-color: #e8425a !important;
  }

  .fa-warning {
    color: #ed293c;
    font-size: 12px;
    margin-bottom: 8px;
  }

</style>
