<template>
  <div
    @keyup.esc="cancel"
    @keyup.enter="submit"
  >
    <div class="spotRequest-addWarehouseInput-title">Adicionar Armazém</div>

    <div class="spotRequest-addWarehouseInput-row">
      <div class="spotRequest-addWarehouseInput-column">
        <div class="spotRequest-addWarehouseInput-subTitle">
          Nome do Armazém:
        </div>
        <input
          ref="warehouseName"
          class="spotRequest-addWarehouseInput-textInput"
          type="text"
          v-model.trim="warehouse.name"
          maxlength="100"
        >
      </div>
    </div>

    <div
      class="spotRequest-addWarehouseInput-row spotRequest-addWarehouseInput-dangerText"
      v-if="nameAlreadyExists"
    >
      Este nome de armazém já existe. Tente outro.
    </div>

    <div class="spotRequest-addWarehouseInput-row">
      <div class="spotRequest-addWarehouseInput-column">
        <div class="spotRequest-addWarehouseInput-subTitle">
          Endereço completo ou CEP + número
        </div>
        <address-finder
          ref="addressFinder"
          class=""
          :address="warehouse.address"
          @on-querying-address-status-changed="onQueryingAddressStatusChanged"
          @on-address-changed="onAddressChanged"
        ></address-finder>
      </div>
    </div>

    <div class="spotRequest-addWarehouseInput-row">
      <div class="spotRequest-addWarehouseInput-column">
        <div class="spotRequest-addWarehouseInput-subTitle">
          Complemento
        </div>
        <input
          class="spotRequest-addWarehouseInput-textInput"
          type="text"
          v-model="warehouse.address.complement"
          maxlength="100"
        >
      </div>
    </div>

    <div class="spotRequest-addWarehouseInput-row">
      <div class="spotRequest-addWarehouseInput-column">
        <div class="spotRequest-addWarehouseInput-subTitle">
          Horário de Funcionamento
        </div>
        <time-window
          custom-input-classes="spotRequest-addWarehouseInput-textInput"
          v-model="warehouse.timeWindow"
          @on-time-window-validation="onTimeWindowValidation"
        >
        </time-window>
      </div>
    </div>

    <div class="spotRequest-addWarehouseInput-row">
      <div class="spotRequest-addWarehouseInput-column">
        <div class="spotRequest-addWarehouseInput-subTitle">
          Nome do Responsável
        </div>
        <input
          class="spotRequest-addWarehouseInput-textInput"
          type="text"
          v-model="warehouse.responsible.name"
          maxlength="100"
        >
      </div>
    </div>

    <div class="spotRequest-addWarehouseInput-row">
      <div class="spotRequest-addWarehouseInput-column spotRequest-addWarehouseInput-responsiblePhone">
        <div class="spotRequest-addWarehouseInput-subTitle">
          Telefone do Responsável
        </div>
        <the-mask
          type="tel"
          class="spotRequest-addWarehouseInput-textInput"
          mask="(##) ####-#####"
          masked
          v-model="warehouse.responsible.phone"
        ></the-mask>
      </div>
      <div class="spotRequest-addWarehouseInput-column">
        <div class="spotRequest-addWarehouseInput-subTitle">
          Email do Responsável
        </div>
        <input
          class="spotRequest-addWarehouseInput-textInput"
          type="text"
          v-model="warehouse.responsible.email"
          placeholder="Digite o E-mail"
          maxlength="100"
        >
      </div>
    </div>

    <div class="spotRequest-addWarehouseInput-buttons">
      <button
        class="baseButton cancelButton"
        @click="cancel"
      >Voltar
      </button>
      <button
        class="baseButton submitButton spotRequest-addWarehouseInput-buttons__submit"
        :class="{'spotRequest-addWarehouseInput-buttons__submit--loading': isSubmittingWarehouse}"
        :disabled="cannotSubmit"
        @click="submit"
      >
        <minimal-spinner
          size="20px"
          hex-color="#ffffff"
          hex-background-color="#aab6b9"
          class="spotRequest-addWarehouseInput-baseButtonWithSpinner__spinner"
          v-if="isSubmittingWarehouse" />
        <span class="spotRequest-addWarehouseInput-buttons__submitLabel">Confirmar</span>
      </button>
    </div>


  </div>
</template>

<script>
  import {TheMask} from 'vue-the-mask';
  import MinimalSpinner from '../../common/MinimalSpinner.vue';
  import TimeWindow from '../../common/TimeWindow.vue';
  import { addWarehouse } from './warehouse';
  import AddressFinder from '../common/AddressFinder.vue';

  function fieldEmpty(value) {
    return value.trim() === '';
  }

  export default {
    name: 'AddWarehouseInput',

    components: {
      MinimalSpinner,
      TheMask,
      TimeWindow,
      AddressFinder,
    },

    data() {
      return {
        queryingAddress: false,
        nameAlreadyExists: false,
        isSubmittingWarehouse: false,
        validTimeWindow: true,
        warehouse: {
          name: '',
          address: {postalCode: '', number: '', complement: ''},
          timeWindow: {start: '', end: ''},
          responsible: {name: '', email: '', phone:''},
        },
      };
    },

    computed: {
      cannotSubmit() {
        return this.queryingAddress ||
          this.isSubmittingWarehouse ||
          !this.validTimeWindow ||
          this.anyRequiredFieldIsEmpty;
      },

      anyRequiredFieldIsEmpty() {
        return (
          fieldEmpty(this.warehouse.name) ||
          fieldEmpty(this.warehouse.responsible.name) ||
          this.warehouse.address.geolocation == null
        );
      },
    },

    methods: {
      cancel() {
        this.$emit('cancel');
      },

      async submit() {
        this.nameAlreadyExists = false;
        this.isSubmittingWarehouse = true;

        await this.submitWarehouse();

        this.isSubmittingWarehouse = false;
      },

      async submitWarehouse() {
        try {
          const formattedWarehouse = this.formatWarehouse(this.warehouse.address);
          const addedWarehouse = await addWarehouse(formattedWarehouse);

          this.$emit('warehouse-added', addedWarehouse);
        }
        catch (error) {
          if (error.response && error.response.data.error_code === 'warehouse_name_in_use') {
            this.nameAlreadyExists = true;
            return;
          }

          // TODO: Other error
          console.log(error, error.response);
        }
      },

      formatWarehouse(address) {
        return {
          name: this.warehouse.name,
          address: {
            address: address.thoroughfare,
            cep: address.postalCode,
            city: address.locality,
            complement: this.warehouse.address.complement,
            neighborhood: address.neighborhood,
            number: address.number,
            state: address.state
          },
          latitude: address.geolocation.latitude,
          longitude: address.geolocation.longitude,
          opening_time: this.formatTime(this.warehouse.timeWindow.start),
          closing_time: this.formatTime(this.warehouse.timeWindow.end),
          responsible_name: this.warehouse.responsible.name,
          phone: this.warehouse.responsible.phone,
          responsible_email: this.warehouse.responsible.email
        };
      },

      formatTime(time) {
        return time === '' ? '' : time + ':00';
      },

      onTimeWindowValidation(validTimeWindow) {
        this.validTimeWindow = validTimeWindow;
      },

      onQueryingAddressStatusChanged(isQuerying) {
        this.queryingAddress = isQuerying;
      },

      onAddressChanged(newAddress) {
        this.warehouse.address = {
          ...newAddress,
        }
      },
    },

    mounted() {
      this.$refs.warehouseName.focus();
    }
  };
</script>

<style>
  .spotRequest-addWarehouseInput-title {
    padding: 27px 0 11px 24px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #2f2f2f;
    border-bottom: 1px solid #c4cccf;
  }

  .spotRequest-addWarehouseInput-row {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
  }

  .spotRequest-addWarehouseInput-column {
    width: 100%;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
  }

  .spotRequest-addWarehouseInput-column:first-child {
    padding-left: 0;
  }

  .spotRequest-addWarehouseInput-dangerText {
    padding-top: 2px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #e8425a;
  }

  .spotRequest-addWarehouseInput-responsiblePhone {
    width: 300px;
  }

  .spotRequest-addWarehouseInput-subTitle {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #7d7d7d;
    margin-bottom: 8px;
  }

  .spotRequest-addWarehouseInput-textInput {
    box-sizing: border-box;
    width: 100%;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 9px 13px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .spotRequest-addWarehouseInput-textInput::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-addWarehouseInput-textInput::-moz-placeholder {
    color: #c6ced0;
  }

  .spotRequest-addWarehouseInput-textInput:-ms-input-placeholder {
    color: #c6ced0;
  }

  .spotRequest-addWarehouseInput-textInput:focus {
    border-color: #28c0f1;
    outline: none;
  }

  .spotRequest-addWarehouseInput-textInput--danger {
    border-color: #e8425a;
  }

  .spotRequest-addWarehouseInput-buttons {
    margin: 32px 24px 28px 24px;
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #c4cccf;
  }

  .spotRequest-addWarehouseInput-buttons__submit {
    width: 160px;
    margin-left: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spotRequest-addWarehouseInput-buttons__submit--loading {
    padding-right: 20px; /* fake centering needed because of the spinner */
  }

  .spotRequest-addWarehouseInput-baseButtonWithSpinner__spinner {
    padding-right: 8px;
  }
</style>
