<template>
  <base-layout>
    <div class="content__panel">

      <page-header :title=pageTitle />

      <div class="content__pod--loading" v-if="this.loading">
        <minimal-spinner size=128px hex-color="#198bfd" />
      </div>

      <div class="content__pod--notfound" v-if="!this.loaded && !this.loading">
        <h1>Oops!</h1>
        <h3>Infelizmente não encontramos o que está buscando</h3>
        <h4>Tente <a href="#" @click="$router.go(-1)">voltar</a> para onde estava.</h4>
      </div>

      <div v-if="this.loaded">
        <div class="content__pod--titlecontainer">
          <h3 class="content__pod--title">Pedido {{this.podData.barcode}} </h3>
          <span class="badge badge-pill" :class="badgeCssClass">{{this.podData.statusMessage}}</span>

          <form
            @submit.prevent="sendEmail"
            class="content__pod--email-form"
          >
            <input class="content__pod--email-input" v-model="email" type="text" name="email" placeholder="enviar para o e-mail...">

            <span class="content__pod--email-span">
              <button class="content__pod--email-button" type="submit" :disabled="sendingEmail">
                 <i class="fa fa-envelope" aria-hidden="true"></i>
              </button>
            </span>
          </form>

        </div>

        <div class="content__pod--main">
          <div class="content__pod--main-pod">
            <Pod v-if="this.loaded" :pod-data="this.podData"></Pod>
          </div>
          <div class="content__pod--main-map">
            <PodMap
              v-if="locationsExist"
              :pod-location="podLocation"
              :stop-location="stopLocation"
            ></PodMap>
          </div>
          <div class="content__pod--main-carousel">
            <PodCarousel v-if="this.podData.pictures" :pictures=this.podData.pictures></PodCarousel>
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
  import BaseLayout from "../common/BaseLayout.vue";
  import PageHeader from "../common/PageHeader.vue";
  import { getPodById, populatePodData } from './podsService';
  import MinimalSpinner from "../common/MinimalSpinner.vue";
  import { isValidEmailAddress} from "../common/validators";
  import { requestEnum, emailMessages } from "../client/requestStatesEnum";
  import PodMap from "./PodMap.vue";
  import PodCarousel from "./PodCarousel.vue";
  import Pod from "./Pod.vue";
  import axios from 'axios';
  import Swal from 'sweetalert2'

  export default {
    name: 'PodsPage',
    components: {
      Pod,
      PodMap,
      MinimalSpinner,
      PageHeader,
      BaseLayout,
      PodCarousel,
    },
    data: function() {
      return {
        podData: {},
        stopLocation: {
          lat: null,
          lng: null,
        },
        podLocation: {
          lat: null,
          lng: null,
        },
        podInput: '',
        email: '',

        loading: false,
        loaded: false,
        sendingEmail: false,
      }
    },
    methods: {
      getPod(){
        this.loading=true;

        getPodById(this.podInput)
          .then(res => {
            if (res.data.hashid)
              this.loaded = true;
            else
              this.loaded = false;

            this.loading=false;

            this.podData = populatePodData(res);

            this.stopLocation = {
              lat: parseFloat(res.data.latAddress),
              lng: parseFloat(res.data.lngAddress),
            }

            this.podLocation = {
              lat: parseFloat(res.data.latSignature),
              lng: parseFloat(res.data.lngSignature),
            }
          })
          .catch(() => {
            this.loaded = false;
            this.loading = false;
          })
      },

      async sendEmail() {
        if (!isValidEmailAddress(this.email)){
          this.$root.toggleGlobalAlert({
            type:  requestEnum.FAIL,
            message: emailMessages.NO_DATA
          });

          return;
        }

        if (!confirm(`Tem certeza que deseja enviar este comprovante para o email ${this.email}?`)) {
          return;
        }

        const genericError = (previousError = undefined) => Object.assign(
          new Error('Erro ao enviar o email :('),
          { previousError },
        )

        let error = null;
        this.sendingEmail = true;

        try {
          const { status } = await axios.post(
            `/jade/api/email/pods/${this.podData.hashId}/notify/user`,
            { to: [this.email] }
          );

          if (status === 400) {
            error = new Error('Erro: email inválido');
          }
          if (status !== 200) {
            error = genericError();
          }
        } catch (err) {
          error = genericError(err);
        } finally {
          this.sendingEmail = false;
        }

        if (error != null) {
          // TODO: Sentry / Error reporting
          await Swal.fire({
            title: error.message,
            icon: 'error',
          });
          return;
        }

        await Swal.fire({
          title: 'E-mail enviado!',
          icon: 'success',
        })
      },
    },

    computed: {
      pageTitle(){
        return `Comprovante ${ this.podData.hashId ? "#"+this.podData.hashId : ''}`;
      },

      badgeCssClass(){
        switch (this.podData.statusType) {
          case 'partial success':
            return 'badge-warning';
          case 'failure':
            return 'badge-fail';
          default:
            return 'badge-success'
        }
      },

      locationsExist() {
        return this.stopLocation.lat && this.stopLocation.lng &&
          this.podLocation.lat && this.podLocation.lng;
      }
    },

    mounted() {
      this.podInput = this.$route.params.id;

      this.getPod();

      if (this.$route.query['alert-status'] == "success"){
        this.$root.toggleGlobalAlert({
          type:  requestEnum.SUCCESS,
          message: emailMessages.SUCCESS
        });
      }
    }
  }
</script>

<style>
  .content__panel {
    padding-bottom: 50px;
  }

  .content__pod--main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .content__pod--column-data > div {
    margin: 20px;
  }

  .content__pod--data hr {
    margin: 0 20px;
    color: #d5d5d5;
    border-top: 1px solid #d5d5d5;
  }

  .content__pod--data .vr {
    border-left:1px solid #d5d5d5;
    height: 92%;
    margin-bottom: 20px;
  }

  .content__pod--name > h1 {
    font-size: 20px;
    line-height: 1.4em;
    font-weight: normal;
    margin-bottom: 40px
  }

  .content__pod--columnsignature > div {
    margin: 20px;
  }

  .content__pod--email-form {
    margin-left:auto;
  }

  .content__pod--email-input {
    height: 22px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dfdfdf;
    box-shadow: none;
    border-radius: 0;
  }

  .content__pod--email-button {
    border: none;
    padding: 10px;
  }

  .content__pod--loading {
    width: 100px;
    margin: 100px auto;
  }

  .content-panel div {
    width: 100%;
    display: block;
  }

  .content__pod--notfound {
    margin: 50px;
  }

  .content__pod--titlecontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-right: 30px;
  }

  .content__pod--title{
    display: block;
    font-size: 20px;
    margin-left: 20px;
  }

  .content__pod--main-pod {
    flex: 2;
    margin: 0 20px 20px;
  }

  .content__pod--main-map {
    flex: 1;
    height: 440px;
    margin-right: 20px;
  }

  .content__pod--main-carousel {
    width: 100%;
    margin: 0 20px;
  }

  @media only screen and (max-width: 1366px) {
    .content__pod--main-pod {
      flex: 1 100%;
      margin: 0 20px 20px;
    }

    .content__pod--main-map {
      flex: 1;
      height: 440px;
      margin: 0 20px;
      width: 50%;
    }

    .content__pod--main-carousel {
      flex: 1;
      margin: 0 20px 0 0;
      width: 50%;
    }
  }

  .badge-success {
    color: #fff;
    background-color: #28a745;
  }

  .badge-warning {
    color: #fff;
    background-color: #e5dd50;
  }

  .badge-fail {
    color: #fff;
    background-color: #E55957;
  }

  .badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
  }

  .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    margin-left: 16px;
    height: 17px;
  }

</style>
