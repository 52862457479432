<template>
  <div class="billingStatement-navBarItem"
       :class="{ 'billingStatement-navBarItem--selected': selected }"
       @click="$emit('click')"
  >
    <div class="billingStatement-navBarItem__month">{{ monthName }}</div>
    <div class="billingStatement-navBarItem__totalValue">{{ formattedTotalValue }}</div>
  </div>
</template>

<script>
import { parseMonth, shortMonthName } from '../common/calendar';
import { moneyFormat } from '../common/filters/numberFormatters';

export default {
  name: 'NavBarItem',
  props: {
    month: String,
    totalValue: String,
    selected: Boolean
  },
  computed: {
    monthName() {
      const { month } = parseMonth(this.month);
      return shortMonthName(month);
    },
    formattedTotalValue() {
      return 'R$ ' + moneyFormat(this.totalValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@use '@vuxx/hounds/scss/abstracts/variables' as *;

  .billingStatement-navBarItem {
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
  }

  .billingStatement-navBarItem--selected {
    padding-bottom: 8px;
    border-bottom: 2px solid $color-primary;
  }

  .billingStatement-navBarItem__month {
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 0.2px;
    color: #1a1a1a;
    opacity: 0.5;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .billingStatement-navBarItem__totalValue {
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #868686;
    opacity: 0.5;
  }

  .billingStatement-navBarItem--selected .billingStatement-navBarItem__month,
  .billingStatement-navBarItem--selected .billingStatement-navBarItem__totalValue {
    opacity: 1.0;
  }
</style>
