import axios from 'axios';

/**
 * @returns {UserIdentity}
 */
async function meInfo() {
  const response = await axios.get('/jade/api/me');
  const me = response.data;

  // TODO: Why are we creating another data type?
  return {
    personId: me.person.id,
    personName: me.person.name,
    personEmail: me.person.email,
    customerId: me.organization.id,
    customerName: me.organization.name,
    emailReports: {
      spotStatusUpdate: me.person.emailReports.spotStatusUpdate,
      podFailure: me.person.emailReports.podFailure,
      podSuccess: me.person.emailReports.podSuccess,
    },
    audienceType: me.person.audienceType,
    roleId: me.person.roleId,
    features: me.features
  };
}

/**
 * @returns {UserInfo}
 */
async function getUserInfo() {
  try {
    const identity = await meInfo();
    return { identity };
  } catch (err) {
    console.error('Could not retrieve user information:', err);
    throw new Error(`Could not retrieve user information: ${err.message}`);
  }
}

/**
 * @returns {Promise<AuthenticationResult>}
 */
export function authenticateUser() {
  const success = ({ identity, config }) => ({ identity, config, loggedIn: true });

  const failure = (err) => ({ loggedIn: false });

  return getUserInfo().then(success, failure);
}

export class Auth {
  /**
   * @return {Promise<AuthenticationResult>}
   */
  getUser() {
    if (this.userPromise == null) {
      this.userPromise = authenticateUser();
    }

    return this.userPromise;
  }

  static create() {
    return new Auth();
  }
}

Auth.prototype.install = function AuthPlugin(Vue) {
  Vue.prototype.$auth = this;
};
