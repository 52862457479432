<template>
  <div class="common-timeWindow">
    <the-mask
      type="text"
      :class= inputClassesStart
      placeholder="Início"
      mask="##:##"
      masked
      v-model="value.start"
      @blur.native="onTimeWindowStartBlur"
    ></the-mask>
    <the-mask
      type="text"
      :class= inputClassesEnd
      placeholder="Fim"
      mask="##:##"
      masked
      v-model="value.end"
      @blur.native="onTimeWindowEndBlur"
    ></the-mask>
    <div v-if="showErrorMessage" class="common-timeWindow-error">
      <img class="common-timeWindow-error__img" src="/jade/public/icon-error.svg">
      <label class="common-timeWindow-error__label">
        {{ errorMessage }}
      </label>
    </div>
  </div>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import moment from 'moment'

export default {
  name: 'TimeWindow',

  components: {TheMask},

  props: {
    value: Object,
    customInputClasses: {
      type: String,
      default: null
    }
  },

  computed: {
    inputClassesStart() {
      return 'common-timeWindow-input' +
        (this.customInputClasses != null ? ' ' + this.customInputClasses : '') +
        (this.showErrorInStartField ? ' common-timeWindow-input--error' : '');
    },
    inputClassesEnd() {
      return 'common-timeWindow-input' +
        (this.customInputClasses != null ? ' ' + this.customInputClasses : '') +
        (this.showErrorInEndField ? ' common-timeWindow-input--error' : '');
    },
    windowStart() {
      return this.value.start;
    },

    windowEnd() {
      return this.value.end;
    },

    areBothFieldsEmpty() {
      return this.windowStart.length === 0 && this.windowEnd.length === 0;
    },

    isStartMissingText() {
      return this.windowStart.length !== 5;
    },

    isEndMissingText() {
      return this.windowEnd.length !== 5;
    },

    isAnyFieldMissingText() {
      return this.isStartMissingText || this.isEndMissingText;
    },

    isStartValid() {
      if (this.isStartMissingText) {
        return false;
      }

      return moment(this.windowStart, 'HH:mm', true).isValid();
    },

    isEndValid() {
      if (this.isEndMissingText) {
        return false;
      }

      return moment(this.windowEnd, 'HH:mm', true).isValid();
    },

    isStartBeforeEnd() {
      const startTime = moment(this.windowStart, 'HH:mm');
      const endTime = moment(this.windowEnd, 'HH:mm');

      if (startTime.isValid() && endTime.isValid()) {
        return startTime.isBefore(endTime);
      } else {
        return true;
      }
    },

    showErrorInStartField() {
      return !this.areBothFieldsEmpty && !this.isStartValid;
    },

    showErrorInEndField() {
      if (!this.areBothFieldsEmpty && !this.isEndValid) {
        return true;
      }

      return !this.isStartBeforeEnd;
    },

    showErrorMessage() {
      return this.windowInvalid && this.errorMessage !== '';
    },
  },

  data() {
    return {
      windowInvalid: false,
      errorMessage: '',
    };
  },

  methods: {
    configureErrorMessageIfAny() {
      if (this.isStartValid && this.isEndValid && !this.isStartBeforeEnd) {
        this.errorMessage = 'O horário final do agendamento deve ser após o inicial';
      } else {
        this.errorMessage = '';
      }
    },

    updateWindowInvalid() {
      const windowInvalid = !this.areBothFieldsEmpty &&
        (!this.isStartValid || !this.isEndValid || !this.isStartBeforeEnd);

      if (windowInvalid !== this.windowInvalid) {
        this.windowInvalid = windowInvalid;
      }
    },

    onTimeWindowStartBlur() {
      this.value.start = this.addZerosToTimeWindow(this.value.start);
    },

    onTimeWindowEndBlur() {
      this.value.end = this.addZerosToTimeWindow(this.value.end);
    },

    addZerosToTimeWindow(value) {
      if (value.length === 1) {
        return `0${value}:00`; // Example: 1+tab = 01:00
      }
      else if (value.length === 2) {
        return `${value}:00`; // Example: 17+tab = 17:00
      }
      else if (value.length === 4) {
        return `${value}0`; // Example: 17:3+tab = 17:30
      }
      else {
        return value; 
      }
    },
  },

  watch: {
    windowStart() {
      this.configureErrorMessageIfAny();
      this.updateWindowInvalid();
    },

    windowEnd() {
      this.configureErrorMessageIfAny();
      this.updateWindowInvalid();
    },

    windowInvalid(newVal) {
      // We use !newVal here because we want to send isValid instead of isInvalid
      this.$emit('on-time-window-validation', !newVal);
    },
  },
}
</script>

<style scoped>

  .common-timeWindow {
    padding-top: 4px;
    display: flex;
  }

  .common-timeWindow-input {
    box-sizing : border-box;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 9px 13px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
    width: 100px;
    margin-right: 20px;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .common-timeWindow-input::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .common-timeWindow-input::-moz-placeholder {
    color: #c6ced0;
  }

  .common-timeWindow-input:-ms-input-placeholder {
    color: #c6ced0;
  }

  .common-timeWindow-input--error, .common-timeWindow-input--error:focus{
    border-color: #e8425a;
  }

  .common-timeWindow-error {
    display: flex;
    align-items: center;
  }

  .common-timeWindow-error__img {
    width: 20px;
    height: 20px;
  }

  .common-timeWindow-error__label {
    margin-left: 8px;
    color: #ed293c;
  }

</style>
