<template>
  <div
    class="spinner"
    :style="{ width: size, height: size }">
    {{ placeholder }}
  </div>
</template>

<script>
export default {
  name: 'Spinner',

  props: {
    size: {
      type: String,
      default: '90px'
    },
    placeholder: {
      type: String,
      default: 'Aguarde…',
    },
  },
}
</script>

<style scoped>
  /* https://projects.lukehaas.me/css-loaders/ */
  .spinner {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #28c0f1;
    background: -moz-linear-gradient(left, #28c0f1 10%, rgba(40,192,241, 0) 42%);
    background: -webkit-linear-gradient(left, #28c0f1 10%, rgba(40,192,241, 0) 42%);
    background: -o-linear-gradient(left, #28c0f1 10%, rgba(40,192,241, 0) 42%);
    background: -ms-linear-gradient(left, #28c0f1 10%, rgba(40,192,241, 0) 42%);
    background: linear-gradient(to right, #28c0f1 10%, rgba(40,192,241, 0) 42%);
    position: relative;
    -webkit-animation: spinner-animation 1.4s infinite linear;
    animation: spinner-animation 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before {
    width: 50%;
    height: 50%;
    background: #28c0f1;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .spinner:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes spinner-animation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner-animation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

</style>
