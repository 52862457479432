<template>
  <button
    type="button"
    class="baseButton submitButton baseButtonWithSpinner"
    :class="{'baseButtonWithSpinner--loading': spinning}"
    :disabled="disabled || spinning"
    @click="click"
  >
    <minimal-spinner
      size="16px"
      hex-color="#ffffff"
      hex-background-color="#aab6b9"
      class="baseButtonWithSpinner__spinner"
      v-if="spinning"
    />
    <span class="baseButtonWithSpinner__label">{{ label }}</span>
  </button>
</template>

<script>
import MinimalSpinner from './MinimalSpinner.vue';

export default {
  name: 'ButtonWithSpinner',

  components: {
    MinimalSpinner,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    spinning: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    click() {
      this.$emit('click');
    },
  },

}
</script>

<style scoped>
  .baseButtonWithSpinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
  }

  .baseButtonWithSpinner--loading {
    padding-left: 8px; /* fake centering needed because of the spinner */
  }

  .baseButtonWithSpinner__spinner {
    padding-right: 8px;
  }
</style>
