<template>
  <Modal
    title="Avalie sua experiência"
    v-if="!this.submitted"
    @close="close"
  >
    <template #content>
      <div class="shipmentInfo">
        <div class="shipmentInfoRow">
          <RichListItemWithIcon
            label="Motorista"
            icon="person"
          >
            {{ driverFullName }}
          </RichListItemWithIcon>

          <RichListItemWithIcon
            label="Placa"
            icon="vehiclePlate">
            {{ driver.vehiclePlate }}
          </RichListItemWithIcon>
        </div>

        <div class="separator" />

        <RichListItemWithIcon
          label="Código do pedido"
          icon="barcodeRounded">
          {{ trackingCode }}
        </RichListItemWithIcon>
      </div>

      <div class="ratingSection">
        <RatingQuestion
          v-for="(question, index) of effectiveQuestions"
          :key="index"
          v-model="answers[question.id]"
          :question="question"
        />
      </div>
    </template>

    <template #footer>
      <div class="inline-buttons-container inline-buttons-container--flex-end">
        <button
          class="button button--tertiary fit-content"
          @click="close"
        >
          Avaliar mais tarde
        </button>
        <button
          :disabled="!canSubmit"
          class="button button--primary"
          @click="submitRating"
        >
          Enviar
        </button>
      </div>
    </template>
  </Modal>

  <ShipmentRatingResponse
    v-else
    @close="close"
  />
</template>

<script>
import Modal from '@/hounDS/components/Modal';
import Button from '@/hounDS/components/Button';
import RichListItemWithIcon from '@/hounDS/components/RichListItemWithIcon';
import { submitShipmentRating } from '../shipmentTrackingApi';
import QuestionId from './QuestionId';
import RatingQuestion from './RatingQuestion.vue';
import ShipmentRatingResponse from './ShipmentRatingResponse';

const defaultQuestions = [
  {
    id: QuestionId.DriverRating,
    type: 'star_rating',
    questionText: 'Como foi o serviço do motorista/entregador?',
    required: true,
  },
  {
    id: QuestionId.Comment,
    type: 'text_long',
    questionText: 'Algum comentário, crítica ou sugestão?',
  },
];

function validate(question, answer) {
  if (!question.required) {
    return { ok: true };
  }

  if (answer != null && answer !== '') {
    return { ok: true };
  }

  return { ok: false };
}

function emptyAnswers(questions) {
  return Object.fromEntries(questions.map((q) => [q.id, null]));
}

export default {
  name: "ShipmentRatingModal",
  components: {
    Button,
    Modal,
    RatingQuestion,
    RichListItemWithIcon,
    ShipmentRatingResponse,
  },

  props: {
    driver: Object,
    trackingCode: String,
    shipmentRating: Object,
    questions: Array,
  },

  data() {
    return {
      rating: this.shipmentRating ? this.shipmentRating.rating : null,
      comment: this.shipmentRating ? this.shipmentRating.comment : '',
      submitted: false,
      answers: emptyAnswers(this.questions || defaultQuestions),
    };
  },

  computed: {
    canSubmit() {
      const validation = this.questionsAndAnswers.map(
        ({ question, answer }) => validate(question, answer)
      );
      return validation.ok === true;
    },

    driverFullName() {
      return `${this.driver.firstName} ${this.driver.lastName}`
    },

    effectiveQuestions() {
      return this.questions.length > 0 ? this.questions : defaultQuestions;
    },

    questionsAndAnswers() {
      return this.effectiveQuestions.map((question) => ({
        question,
        answer: this.answers[question.id],
      }));
    },

    questionsById() {
      return Object.fromEntries(
        this.questionsAndAnswers.map((r) => [r.question.id, r])
      );
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    async submitRating() {
      const shipmentHash = this.$route.params.hash;

      const rating = this.questionsById[QuestionId.DriverRating].answer;
      const comment = this.questionsById[QuestionId.Comment].answer;

      try {
        const shipmentRating = {
          driverId: this.driver.id,
          extraQuestions: this.questionsAndAnswers,
          rating,
          comment,
        };
        await submitShipmentRating(shipmentHash, shipmentRating)
        this.submitted = true;
      } catch (e) {
        console.error(e);
        alert('Falha ao enviar a avaliação');
        this.close();
      }
    }
  },

  watch: {
    questions(newQuestions) {
      this.answers = { ...emptyAnswers(newQuestions), ...this.answers };
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../hounDS/styles/global/colors';
@import '../../../hounDS/styles/global/mediaQueries';
@import '@vuxx/hounds/scss/index';

.button {
  width: auto;
  line-height: 1.4;
}

.shipmentInfo {
  display: flex;
  margin: 16px;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid $gray200;
  padding: 16px 24px 12px;
}

.shipmentInfoRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ratingSection {
  margin: 16px 16px 0;
  display: flex;
  flex-direction: column;
}

.separator {
  border-bottom: solid 1px $gray200;
  margin-bottom: 16px;
}

.submit {
  padding: 12px 48px;

  @include for-medium-devices {
    width: 100%;
  }
}

</style>
