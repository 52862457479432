const months_pt_br = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

function parseMonth(str) {
  const match = str.match(/(\d+)-(\d+)/);
  if (match == null) {
    return null;
  }
  const [, year, month] = match;
  return { year, month };
}

function monthName(number) {
  const index = number - 1;
  if (index < 0 || index >= 12) {
    throw new Error(`invalid month number: ${number}`);
  }
  return months_pt_br[index];
}

function shortMonthName(number) {
  return monthName(number).slice(0, 3);
}

module.exports = {
  monthName,
  parseMonth,
  shortMonthName,
};
