<template>
  <div class="emailReports panel">
    <h2><i class="fa fa-envelope"></i> Notificações por Email</h2>
    <div>
      <input v-model="spotStatusUpdate" type="checkbox" name="spotStatusUpdate" id="spotStatusUpdate" />
      <label for="spotStatusUpdate"> Atualizações de status das solicitações</label>
    </div>
    <div>
      <input v-model="podSuccess" type="checkbox" name="podSuccess" id="podSuccess"/>
      <label for="podSuccess">Comprovantes de entrega com sucesso</label>
    </div>
    <div>
      <input v-model="podFailure" type="checkbox" name="podFailure" id="podFailure"/>
      <label for="podFailure">Comprovantes de entrega que falharam</label>
    </div>

    <button @click="updateEmailReports"> Alterar </button>
  </div>
</template>

<script>
  import { updateUserEmailReports } from './profileService'
  import { getErrorResponseBody } from '../client/errorUtils'
  import { requestEnum, requestMessages } from '../client/requestStatesEnum'

  export default {
    name: "EmailReports",

    methods:{
      updateEmailReports() {
        let userUpdateEmailReportsObject = {
          emailReports: {
            spotStatusUpdate: this.spotStatusUpdate,
            podFailure: this.podFailure,
            podSuccess: this.podSuccess
          }
        };

        let personId = this.personId;

        if(!personId){
          this.$root.toggleGlobalAlert({
            type:  requestEnum.FAIL,
            message: requestMessages.NO_DATA
          });
          return;
        }

        updateUserEmailReports(personId, userUpdateEmailReportsObject).then(res=>{
          this.$root.toggleGlobalAlert({
            type:  requestEnum.SUCCESS,
            message: requestMessages.SUCCESS
          });
        }).catch(err=>{
          this.$root.toggleGlobalAlert({
            type:  requestEnum.FAIL,
            message: getErrorResponseBody(err).message || requestMessages.FAIL
          });
        })
      },
    },

    data: function() {
      return {
        spotStatusUpdate: false,
        podFailure: false,
        podSuccess: false,
        personId: null,
      }
    },

    computed: {
      userInfo() {
        return this.$root.userInfo;
      }
    },

    mounted(){
      this.$root.watchUserInfo(userInfo => {
        this.personId = userInfo.personId;
        this.spotStatusUpdate = userInfo.emailReports.spotStatusUpdate;
        this.podFailure = userInfo.emailReports.podFailure;
        this.podSuccess = userInfo.emailReports.podSuccess;
      });
    }
  }
</script>

<style scoped>
  .emailReports {
    flex: 1 0;
    margin-left: 15px;
    min-width: 320px;
    padding: 0 20px 40px 20px;
    background: white;
  }

  .emailReports button {
    margin-top: 20px;
    background-color: #fff;
    color: #3a3a3a;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #e2e2e2;
    border-radius: 0;
  }

  .emailReports > div {
    margin-bottom: 5px;
  }


  @media only screen and (max-width: 1030px) {
    .emailReports {
      margin-left: 20px;
      padding-left: 20px;
    }

  }

</style>
