<template>
  <div
    class="backdrop"
    @keyup.esc="cancel"
    @keyup.enter="submit"
  >
    <center-screen-notification
      v-if="showCopyNotification"
      message="Link copiado! Cole (Ctrl-V) onde quiser"
      :delay-to-hide="timeConstants.HIDE_NOTIFICATION_DELAY"
    />
    <div
      class="card"
      role="dialog"
    >
      <div class="title">Rastreio da mercadoria</div>

      <div class="horizontalSeparator horizontalSeparator--darker"></div>

      <div class="content">
        <div>
          <div class="merchandiseInfo">
            <div class="merchandiseInfoContainer">
              <div class="merchandiseInfoHeader">Código</div>
              <div class="merchandiseInfoData">{{ trackingCode }}</div>
            </div>
            <div class="merchandiseInfoContainer">
              <div class="merchandiseInfoHeader">Endereço</div>
              <div class="merchandiseInfoData">{{ address }}</div>
            </div>
          </div>
        </div>

        <tracking-recipients-input
          v-if="showShipmentTrackingSharing"
          :tracking-recipients="trackingRecipients"
          @tracking-recipients-changed="onTrackingRecipientsChanged"
        />

        <div class="horizontalSeparator horizontalSeparator--withMarginTop"></div>

        <div class="buttons">
          <Button
            class="share-button"
            variation="default"
            @click="share(anonymousUrl)"
          >
            <img src="/jade/public/ic-link.svg">
            <span class="linkButton">Copiar link</span>
          </Button>
          <button
            class="baseButton cancelButton"
            @click="cancel"
          >Voltar</button>
          <button
            class="baseButton submitButton buttons__submit"
            :class="{'.buttons__submit--loading': submitting}"
            :disabled="submitting"
            @click="submit"
          >
            <minimal-spinner
              size="20px"
              hex-color="#ffffff"
              hex-background-color="#aab6b9"
              class="baseButtonWithSpinner__spinner"
              v-if="submitting" />
            <span>Adicionar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MinimalSpinner from '@/common/MinimalSpinner.vue'
import TrackingRecipientsInput from "@/common/TrackingRecipientsInput";
import copyToClipboard from "@/hounDS/utils/copyToClipboard";
import Button from "@/hounDS/components/Button";
import CenterScreenNotification from "@/hounDS/components/CenterScreenNotification";
import {createTrackingRecipients} from "@/tracking/order/trackingApi";

const HIDE_NOTIFICATION_DELAY = 2000; // Milliseconds
const NOTIFICATION_INTERVAL = 3000; // Milliseconds

export default {
  name: "TrackingRecipientsInputModal",

  components: {
    CenterScreenNotification,
    TrackingRecipientsInput,
    Button,
    MinimalSpinner,
  },

  data() {
    return {
      showShipmentTrackingSharing: true,
      trackingRecipients: this.trackingRecipientsToEdit,
      showCopyNotification: false,
      timeConstants: {
        HIDE_NOTIFICATION_DELAY,
        NOTIFICATION_INTERVAL
      },
      submitting: false,
    }
  },

  props: {
    address: String,
    trackingCode: String,
    trackingRecipientsToEdit: Array,
    anonymousUrl: String,
    shipmentId: Number
  },

  methods: {
    cancel() {
      this.$emit('cancel')
    },

    async submit() {

      await this.refreshTrackingRecipientsInput()

      if (this.trackingRecipientsHaveNotChanged()) {
        this.$emit('cancel')
        return
      }

      this.submitting = true
      try {
        await createTrackingRecipients(this.shipmentId, this.trackingRecipients)
        this.$emit('submit', this.trackingRecipients)
      } catch (e) {
        window.alert("Não foi possível salvar os destinatários.")
        this.$emit('cancel')
      } finally {
        this.submitting = false
      }
    },

    async refreshTrackingRecipientsInput() {
      this.showShipmentTrackingSharing = false

      this.$nextTick(() => {
        this.showShipmentTrackingSharing = true
        return this.$nextTick()
      })
    },

    trackingRecipientsHaveNotChanged() {
      return JSON.stringify(this.trackingRecipientsToEdit) === JSON.stringify(this.trackingRecipients);
    },

    onTrackingRecipientsChanged(trackingRecipients) {
      this.trackingRecipients = trackingRecipients
    },

    share(sharingURL)
    {
      copyToClipboard(sharingURL)

      this.showCopyNotification = true

      setTimeout(() => {
        this.showCopyNotification = false
      }, this.timeConstants.NOTIFICATION_INTERVAL)
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../hounDS/styles/global/colors';
.backdrop {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 560px;

  background-color: white;
  max-height: 85%;
  overflow: auto;

  border-radius: 8px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px $gray100;
}

.title {
  padding: 27px 0 11px 23px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #1f1f1f;

}

.content {
  padding: 0 28px 24px 28px;
  display: flex;
  flex-direction: column;
}

.merchandiseInfo {
  display: inline-flex;
  flex-direction: row;
  padding-left: 24px;
  margin-top: 24px;
  border-radius: 16px;
  border: 1px solid $gray100;
}

.merchandiseInfoContainer {
  padding: 16px 24px 16px 0;
}

.merchandiseInfoHeader {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: $gray500;
}

.merchandiseInfoData {
  font-weight: 600;
  font-size: 16px;
}

.horizontalSeparator--withMarginTop {
  margin-top: 24px;
}

.buttons {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.linkButton {
  padding-left: 8px;
  font-weight: 600;
  font-size: 14px;
  color: $blue500;
}

.share-button {
  /* Override button component default padding */
  padding: 0 6px !important;
  margin: 0 16px 0 0;
  border: none;
}

.buttons__submit {
  width: 160px;
  margin-left: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons__submit--loading {
  padding-right: 20px; /* fake centering needed because of the spinner */
}

.baseButtonWithSpinner__spinner {
  padding-right: 8px;
}
</style>
