<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       :aria-labelledby="iconName"
       role="presentation"
  >
    <!-- iconName should respect DOM's name patterns -->
    <title :id="iconName" lang="en"> {{iconName}} icon</title>
    <g :fill="iconColor" :opacity="iconOpacity">
      <slot />
    </g>
  </svg>
</template>

<script>
  export default {
    name: "IconBase",

    props: {
      iconName: {
        type: String,
        default: 'box'
      },
      width: {
        type: [String],
        default: '18px'
      },
      height: {
        type: [String],
        default: '18px'
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      },
      iconOpacity: {
        type: Number,
        default: 1.0,
      }
    },

    computed: {
      viewBox() {
        //Remove 2 last characters of the dimension, because it is received as 000px, and viewBox accept only numerals
        return `0 0 ${this.width.substr(0,this.width.length-2)} ${this.height.substr(0,this.height.length-2)}`
      }
    }
  }
</script>

<style scoped>
  svg {
    display: inline-block;
    vertical-align: baseline;
  }
</style>
