<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="{ required: isWeightRequired, integer: true }"
    :name="'peso total da carga'"
    required
  >
    <div class="main-form">
      <label
        class="main-form__label main-label--inline"
        for="total-weight"
      >
        Peso total da carga <span class="sr-only">em quilogramas</span>
        <template v-if="isWeightRequired">*</template>
      </label>
      <div class="main-form__inline-group main-form__inline-group--center-items">
        <input
          v-model="totalWeightInKilograms"
          type="text"
          input="numeric"
          placeholder="Ex: 1000"
          class="main-form__input main-form__input--auto-with"
        >
        <span>kg</span>
        <span
          v-if="errors[0]"
          class="main-form__info main-form__info--error"
        >
          {{ errors[0] }}
        </span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, localize, extend } from 'vee-validate';
import { required, integer } from 'vee-validate/dist/rules';
import pt_BR from 'vee-validate/dist/locale/pt_BR.json';

extend('required', required);
extend('integer', integer);

localize('pt_BR', pt_BR);

export default {
  name: 'TotalWeight',

  components: {
    ValidationProvider,
  },

  props: {
    isWeightRequired: Boolean,
  },

  computed: {
    ...mapState('spotRequest', {
      totalWeightInKilogramsFromStore: (state) => state.totalWeightInKilograms,
    }),

    totalWeightInKilograms: {
      get() {
        return this.totalWeightInKilogramsFromStore;
      },
      set(value) {
        this.$store.commit('spotRequest/updateTotalWeightInKilograms', value);
      },
    },
  },
};
</script>

<style>
  .main-form__label {
    display: block;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .main-form__input {
    padding: 12px 16px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
  }

  .main-form__inline-group {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .main-form__info {
    font-size: 14px;
  }

  .main-form__info-error {
    color: #e53935;
  }

</style>
