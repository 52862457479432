<template>
  <div class="commonModal" v-show="visible">
    <div class="commonModal__backdrop">
      <div class="loadingModal__content" role="dialog">
        <div class="loadingModal__title">{{ title }}</div>
        <spinner class="loadingModal__spinner" :size="'90px'" />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  name: 'LoadingModal',

  components: {Spinner},

  props: {
    title: '',
    visible: false
  },
}
</script>

<style scoped>
  .commonModal {
    position: fixed;
    z-index: 1000;
  }

  .commonModal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loadingModal__content {
    width: 200px;
    min-height: 200px;
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .loadingModal__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #2f2f2f;
    text-align: center;
  }
</style>
