<template>
  <div class="bg-color-gray--50 min-height-100vh">
    <Header />
    <div
      :class="addContainer ? 'container container--full-height': ''"
    >
      <alerts></alerts>
      <div class="subcontainer">
        <div class="content">
          <slot></slot>
        </div>
        <!-- TODO: Loading and Message modals (focus trappable modals) should be here -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/common/Header';
import Alerts from './Alerts.vue';

import './style.css';

export default {
  name: 'BaseLayout',

  components: {
    Alerts,
    Header,
  },

  props: {
    addContainer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
  @import '@vuxx/hounds/scss/index';
</style>

<style scoped>
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic);
@import url(//fonts.googleapis.com/css?family=Abel);
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.min.css');

.container--full-height {
  display: grid;
  min-height: calc(100vh - 6rem); /* 6rem is the actual header height */
}

.sidebar {
  flex: 0 0 auto;
}

.subcontainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
}
</style>
