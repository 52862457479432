<template>
  <div>
    <div class="spinnerContainer" v-if="queryingAddress">
      <minimal-spinner size="20px"/>
    </div>
    <gmap-autocomplete
      ref="address"
      placeholder="Ex: 01310200 1578"
      :value="fieldAddress"
      @place_changed="onAddressPlaceChanged"
      @keyup.native.enter.stop.prevent
      @input.native="onInput"
      @blur.native="onAddressBlur"
      @focus.native="onAddressFocus"
      class="addressFinder-textInput"
      :class="{
      'addressFinder-textInput--danger': addressPostalCodeNotFound,
      'addressFinder-textInput--warning': addressIncomplete
    }"
    />
    <div v-if="addressPostalCodeNotFound" class="addressFinder-errorMessage">
      <img class="addressFinder-errorMessage__img" src="/jade/public/icon-error.svg">
      <label class="addressFinder-errorMessage__label">
        O CEP procurado não foi encontrado.
      </label>
    </div>
    <div v-if="addressIncomplete" class="addressFinder-warningMessage">
      <img class="addressFinder-warningMessage__img" src="/jade/public/icon-warning.svg">
      <label class="addressFinder-warningMessage__label">
        Endereço incompleto.<br>Você pode optar por inserir o endereço completo ou CEP + número.
      </label>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import MinimalSpinner from '../../common/MinimalSpinner.vue';
import { getAddressFromPostalCodeAndNumber } from './address';

function formatAddress(address) {
  let text = address.thoroughfare;
  let selection = {};

  if (!text) {
    const nonEmpty = str => Boolean(str);
    text = [address.neighborhood, address.locality, address.state].filter(nonEmpty).join(', ');
    text = [address.neighborhood, address.locality, address.state].filter(t => !!t).join(', ');
    return {text, selection};
  }

  if (address.number) {
    text += ', ' + address.number;
  } else {
    text += ', número';
    selection = {start: text.length - 6, end: text.length};
  }

  return {text, selection};
}

export default {
  name: 'AddressFinder',

  components: {
    MinimalSpinner
  },

  props: {
    address: Object,
  },

  data() {
    return {
      addressToEdit: {...this.address},
      queryingAddress: false,
      fieldAddress: '',
      addressPostalCodeNotFound: false,
    };
  },

  computed: {
    validPostalCode() {
      return this.addressToEdit
        && this.addressToEdit.postalCode
        && this.addressToEdit.postalCode.length === 8;
    },

    addressHasGeolocation() {
      return this.addressToEdit.geolocation &&
        this.addressToEdit.geolocation.latitude &&
        this.addressToEdit.geolocation.longitude;
    },

    addressHasThoroughfareAndNumber() {
      return this.addressToEdit.thoroughfare && this.addressToEdit.number;
    },

    addressIncomplete() {
      return this.addressHasGeolocation && !this.addressHasThoroughfareAndNumber;
    },
  },

  methods: {
    updateAddressInput(address, {fromAutocompleteCallback = false} = {}) {
      let {text, selection} = formatAddress(address);
      this.fieldAddress = text;

      this.$refs.address.$el.value = this.fieldAddress;
      if (!fromAutocompleteCallback) {
        this.$refs.address.$el.blur();
        this.$refs.address.$el.focus();
      }

      if (selection.start != null) {
        this.$refs.address.$el.setSelectionRange(selection.start, selection.end);
      }
    },

    async queryAddressFromPostalCode({requireGeolocation = null} = {}) {
      if (!this.validPostalCode) {
        return;
      }

      this.addressPostalCodeNotFound = false;
      this.setQueryingAddress(true);

      try {
        const inputAddress = this.addressToEdit;
        this.addressToEdit = await getAddressFromPostalCodeAndNumber(inputAddress.postalCode, inputAddress.number, {requireGeolocation});
      }
      catch (error) {
        this.addressPostalCodeNotFound = true;
      }
      finally {
        this.setQueryingAddress(false);
      }
    },

    setQueryingAddress(isQuerying) {
      this.queryingAddress = isQuerying;
      this.$emit('onQueryingAddressStatusChanged', isQuerying);
    },

    queryInput: debounce(function ({inputText, postalCode, number}) {
      this.addressToEdit = {
        postalCode: postalCode,
        number: number ? number : '',
      };

      this.fieldAddress = inputText;

      this.queryAddressFromPostalCode({requireGeolocation: false}).then(() => {
        this.updateAddressInput(this.addressToEdit);
      });
    }, 1000, {}),

    onAddressPlaceChanged(place) {
      function fromGoogleLocation(location) {
        return {
          latitude: location.lat(),
          longitude: location.lng(),
        };
      }

      function addressComponent(componentType, {defaultValue = '', nameField = 'long_name'} = {}) {
        const component = place.address_components.filter(c => c.types.includes(componentType))[0] || null;
        return component != null ? component[nameField] : defaultValue;
      }

      function normalizePostalCode(postalCode) {

        function removeNonDigits(code) {
          return code.replace(/\D+/g, '');
        }

        // Pad 5-digit CEPs with zeros on the right to get full 8-digit CEPs.
        // If the CEP has a different length than 5 or 8, ignore it.
        function padTo8Digits(code) {
          if (code.length === 5) {
            return code + '000';
          }

          if (code.length !== 8) {
            return '';
          }

          return code;
        }

        return padTo8Digits(removeNonDigits(postalCode));
      }

      const geolocation = place.geometry && fromGoogleLocation(place.geometry.location);

      this.addressToEdit = {
        thoroughfare: addressComponent('route'),
        number: addressComponent('street_number'),
        postalCode: normalizePostalCode(addressComponent('postal_code')),
        neighborhood: addressComponent('sublocality'),
        locality: addressComponent('administrative_area_level_2'),
        state: addressComponent('administrative_area_level_1', {nameField: 'short_name'}),
        geolocation,
      };

      this.updateAddressInput(this.addressToEdit, {fromAutocompleteCallback: true});

      this.addressPostalCodeNotFound = false;
    },

    onInput(event) {
      const text = event.target.value;
      const quickTest = /^\s*([0-9-, ]+)$/;
      if (quickTest.test(text)) {
        event.stopImmediatePropagation();

        const fullTest = /^\s*([0-9]{5}-?[0-9]{3})(,\s*|\s+)?(\d+)?/;
        const match = fullTest.exec(text);
        if (match === null) {
          return;
        }

        let [, postalCode, , number] = match;

        postalCode = postalCode.replace('-', '');

        this.queryInput({inputText: text, postalCode, number});
      }
    },

    onAddressFocus() {
      this.$emit('focus-trap-modify', {disabled: true})
    },

    onAddressBlur() {
      this.$emit('focus-trap-modify', {disabled: false})
    },

    updateDisplayedAddress() {
      this.updateAddressInput(this.addressToEdit);
    },
  },

  watch: {
    addressToEdit(newAddress) {
      this.$emit('on-address-changed', newAddress);
    }
  },

  mounted() {
    this.updateDisplayedAddress();
  },
}
</script>

<style scoped>

  .addressFinder-textInput {
    box-sizing : border-box;
    width: 100%;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 9px 13px;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #2f2f2f;
  }

  /* Keep the placeholder styles repeated! Joining them does not work!  */
  .addressFinder-textInput::-webkit-input-placeholder {
    color: #c6ced0;
  }

  .addressFinder-textInput::-moz-placeholder {
    color: #c6ced0;
  }

  .addressFinder-textInput:-ms-input-placeholder {
    color: #c6ced0;
  }

  .addressFinder-textInput--danger {
    border-color: #e8425a;
  }

  .addressFinder-textInput--warning {
    border-color: #fba30f;
  }

  .spinnerContainer {
    width: 20px;
    height: 20px;
    position: absolute;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }

  .addressFinder-errorMessage,
  .addressFinder-warningMessage {
    display: flex;
    align-items: center;
    padding-top: 4px;
  }

  .addressFinder-errorMessage__img,
  .addressFinder-warningMessage__img {
    width: 20px;
    height: 20px;
  }

  .addressFinder-errorMessage__label {
    margin-left: 8px;
    color: #ed293c;
  }

  .addressFinder-warningMessage__label {
    margin-left: 8px;
    color: #fba30f;
  }

</style>
