<template>
  <base-layout>
    <div class="app-content bg-color-gray--50">
      <div class="main-content">
        <h1 class="title">
          Monitoramento
        </h1>
        <template v-if="isLoading">
          <p>Carregando…</p>
        </template>
        <dialog
          v-if="error"
          class="dialog dialog--error"
          open
        >
          Ops! Tivemos um problema ao carregar os dados!
        </dialog>
        <template v-if="allOrders">
          <div
            v-if="allOrders.length === 0"
            class="text-center"
          >
            <p>
              Não há solicitações com informações disponíveis para
              monitoramento.
            </p>
          </div>

          <template v-else>
            <TabWrapper v-model="activeTab">
              <TabList label="Tabelas de monitoramento">
                <TabActivator tab="createdOrders">
                  Em alocação
                  <span class="button__counter button__counter">
                    {{
                      ordersByStatus.CREATED ? ordersByStatus.CREATED.length : 0
                    }}
                  </span>
                </TabActivator>
                <TabActivator tab="allocatedOrders">
                  Alocada
                  <span class="button__counter button__counter">
                    {{
                      ordersByStatus.ALLOCATED
                        ? ordersByStatus.ALLOCATED.length
                        : 0
                    }}
                  </span>
                </TabActivator>
                <TabActivator tab="inProgressOrders">
                  Em execução
                  <span class="button__counter button__counter">
                    {{
                      ordersByStatus.IN_PROGRESS
                        ? ordersByStatus.IN_PROGRESS.length
                        : 0
                    }}
                  </span>
                </TabActivator>
                <TabActivator tab="finishedAndCancelledOrders">
                  Finalizadas/Canceladas
                  <span class="button__counter button__counter">
                    {{
                      ordersByStatus.finishedAndCancelledOrders
                        ? ordersByStatus.finishedAndCancelledOrders.length
                        : 0
                    }}
                  </span>
                </TabActivator>
              </TabList>

              <div class="pods-search">
                <label
                  for="pods-search-input"
                  class="pods-search__input-search-label"
                >
                  {{
                    `Pesquise por OS,
                    ${hasExternalId ? 'código externo,' : ''} solicitante,
                    motorista ou placa do veículo`
                  }}
                </label>
                <input
                  id="pods-search-input"
                  v-model="filters.podsSearchString.value"
                  type="search"
                  class="input-search pods-search__search-input"
                  placeholder="Pesquisar"
                >
                <button
                  class="button button--tertiary pods-search__filters-button"
                  :class="{ 'pods-search__filters-button--with-filters' : filterStatusCount }"
                  type="button"
                  @click="showModal"
                >
                  <transition name="scale">
                    <template v-if="filterStatusCount">
                      <span class="pods-search__filters-number">
                        {{ filterStatusCount }}
                      </span>
                    </template>
                  </transition>
                  <span class="sr-only">Exibir filtros</span>
                </button>
              </div>

              <div class="card-container">
                <TabPanel tab="createdOrders">
                  <OrdersTable
                    :orders-has-external-id="hasExternalId"
                    :orders="ordersByStatus.CREATED"
                  />
                </TabPanel>
                <TabPanel tab="allocatedOrders">
                  <OrdersTable
                    :orders-has-external-id="hasExternalId"
                    :orders="ordersByStatus.ALLOCATED"
                  />
                </TabPanel>
                <TabPanel tab="inProgressOrders">
                  <OrdersTable
                    :orders-has-external-id="hasExternalId"
                    :orders="ordersByStatus.IN_PROGRESS"
                  />
                </TabPanel>
                <TabPanel tab="finishedAndCancelledOrders">
                  <OrdersTable
                    :orders-has-external-id="hasExternalId"
                    :orders="ordersByStatus.finishedAndCancelledOrders"
                  />
                </TabPanel>
              </div>
            </TabWrapper>
          </template>
        </template>
      </div>
    </div>
    <div
      id="js-pods-search-filters"
      class="modal micromodal-slide"
      aria-hidden="true"
    >
      <div
        data-micromodal-close
        class="modal__overlay"
        tabindex="-1"
      >
        <div
          class="modal__container modal__container--small"
          role="dialog"
          aria-modal="true"
          aria-labelledby="transportation-dialog"
        >
          <form class="main-form">
            <header class="modal__header">
              <h2
                id="new-transportation-dialog"
                class="modal__title"
              >
                Filtros
              </h2>
            </header>
            <main
              id="new-transportation-dialog-content"
              class="modal__content"
            >
              <label
                for="filter-is-delayed"
                class="main-form__label main-form__label--checkbox"
              >
                <input
                  id="filter-is-delayed"
                  v-model="filters.isDelayed.value"
                  class="main-form__checkbox"
                  type="checkbox"
                  :value="true"
                  :false-value="null"
                >
                <span class="main-form__checkbox-button">Em atraso</span>
              </label>
              <label
                for="filter-without-failed-stops"
                class="main-form__label main-form__label--checkbox"
              >
                <input
                  id="filter-without-failed-stops"
                  v-model="filters.withFailedStops.value"
                  class="main-form__checkbox"
                  type="checkbox"
                  :disabled="filters.withFailedStops.value === 0"
                  :true-value="1"
                  :false-value="null"
                >
                <span class="main-form__checkbox-button">Com pendência</span>
              </label>
              <label
                for="filter-with-failed-stops"
                class="main-form__label main-form__label--checkbox"
              >
                <input
                  id="filter-with-failed-stops"
                  v-model="filters.withFailedStops.value"
                  class="main-form__checkbox"
                  type="checkbox"
                  :disabled="filters.withFailedStops.value === 1"
                  :true-value="0"
                  :false-value="null"
                >
                <span class="main-form__checkbox-button">Sem pendência</span>
              </label>
            </main>
            <footer class="modal__footer">
              <button
                data-micromodal-close
                type="button"
                class="button button--primary button--full-width"
              >
                Aplicar
              </button>
            </footer>
          </form>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import MicroModal from 'micromodal';
import removeAccents from 'remove-accents'
import BaseLayout from '@/common/BaseLayout.vue';

import {
  TabActivator,
  TabList,
  TabPanel,
  TabWrapper,
} from '@a11y-kit/vue-tabs';
import axios from 'axios';
import groupBy from 'lodash.groupby';
import _get from 'lodash.get';
import _isEmpty from 'lodash.isempty';
import OrdersTable from '@/monitoring/OrdersTable';

export default {
  name: 'Monitoring',
  components: {
    BaseLayout,
    OrdersTable,
    TabActivator,
    TabList,
    TabPanel,
    TabWrapper,
  },

  data() {
    return {
      isLoading: true,
      error: false,
      activeTab: 'createdOrders',
      allOrders: null,

      filters: {
        withFailedStops: {
          type: 'exact_match',
          value: null,
          obj_property: 'failed_services',
          showInCount: true,
        },
        isDelayed: {
          type: 'exact_match',
          value: null,
          obj_property: 'isDelayed',
          showInCount: true,
        },
        podsSearchString: {
          type: 'string_multiple_fields',
          value: null,
          obj_property: [
            'id',
            'requesterAbbr',
            'externalId',
            'driver.nameAbbr',
            'driver.vehicle_plate',
          ],
        },
      },

      podsSearchStringFields: [
        'id',
        'requester',
        'externalId',
        'driver.vehicle_plate',
      ],
    };
  },

  computed: {
    hasExternalId() {
      return this.orders ? this.orders.some((order) => order.externalId) : false;
    },
    hasFilters() {
      return Object.values(this.filters).some((filter) => !_isEmpty(filter));
    },
    activeFilters() {
      return Object.values(this.filters)
        .filter((filterObjects) => (filterObjects.value !== null && filterObjects.value !== ''));
    },
    orders() {
      if (this.activeFilters.length) {
        // there is a bug here duplicating some results but
        // I'm too tired to find it right now using a
        // Set "solves" it but not in a good way
        const filteredOrders = new Set();
        let orders = this.allOrders;

        this.activeFilters.forEach((filter) => {
          if (filter.type === 'exact_match') {
            orders = orders
              .filter((order) => _get(order, filter.obj_property) === filter.value);
          }

          if (filter.type === 'string_multiple_fields') {
            filter.obj_property.forEach((field) => {
              orders.forEach((order) => {
                if (this.searchInObject(order, field, filter.value)) {
                  filteredOrders.add(order);
                }
              });
            });

            orders = Array.from(filteredOrders);
          }
        });

        return orders;
      }
      return this.allOrders;
    },
    ordersByStatus() {
      let ordersByStatus = {};
      ordersByStatus = groupBy(this.orders, 'status2');
      ordersByStatus.finishedAndCancelledOrders = [
        ...(ordersByStatus.FINISHED ? ordersByStatus.FINISHED : []),
        ...(ordersByStatus.CANCELED ? ordersByStatus.CANCELED : []),
      ];
      return ordersByStatus;
    },
    filterStatusCount() {
      return Object.values(this.filters)
        .filter((filter) => filter.showInCount && filter.value !== null).length;
    },
  },

  mounted() {
    this.getData();
    MicroModal.init();
  },

  methods: {
    showModal() {
      MicroModal.show('js-pods-search-filters');
    },
    searchInObject(obj, field, value) {
      return this.normalizeString(_get(obj, field)).includes(this.normalizeString(value));
    },
    containsInOrder(order, field) {
      return this.normalizeString(_get(order, field))
        .includes(this.normalizeString(this.filters.podsSearchString.value));
    },
    async getData() {
      try {
        const response = await axios.get(
          '/jade/api/orders/status?since=72 hours ago',
        );
        this.allOrders = this.handleAllOrders(response.data);

        if (response.status !== 200) {
          throw new Error(`Erro! status: ${response.status}`);
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.error = error.message;
      }
    },
    handleAllOrders(allOrders) {
      allOrders.forEach((order) => {
        const myOrder = order;
        myOrder.isDelayed = this.isOrderDelayed(order);
        myOrder.requesterAbbr = this.formatName(order.requester);
        if (myOrder.driver && myOrder.driver.name) {
          myOrder.driver.nameAbbr = this.formatName(order.driver.name);
        }
      });
      return allOrders;
    },
    formatName(name) {
      const splited = name.split(' ');
      if (splited.length === 1) {
        return splited[0];
      }
      return `${splited[0]} ${splited[splited.length - 1]}`;
    },
    isOrderDelayed(order) {
      if (!order.scheduled_for) {
        return false;
      }
      return this.$date(order.scheduled_for).isBefore(this.$date());
    },
    normalizeString(string) {
      return removeAccents(String(string)).toLowerCase().trim();
    },
  },
};
</script>
