<template>
  <div class="billingStatement-orderOverview-stops">
    <div class="billingStatement-orderOverview-stops-title">Itinerário</div>
    <div class="billingStatement-orderOverview-stops-content">
      <stop
        v-for="(stop, index) in stops"
        :key="index"
        :index="index"
        :stop="stop"
      ></stop>
    </div>
  </div>
</template>

<script>
  import Stop from './Stop.vue';

  export default {
    name: 'Stops',

    components: { Stop },

    props: {
      stops: Array
    }

  }
</script>

<style scoped>

  .billingStatement-orderOverview-stops {
    overflow: auto;
    padding: 16px 0 16px 10px;
  }

  .billingStatement-orderOverview-stops-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #212121;
  }

  .billingStatement-orderOverview-stops-content {
    padding-top: 12px;
  }

</style>
