<template>
  <div>
    <warehouse-list
      v-if="warehousesLoaded"
      class="warehouseSelection-warehouseList"
      :warehouses="warehouses"
      :selected-warehouse-id="selectedWarehouseId"
      @add-warehouse-requested="onAddWarehouseRequested"
      @on-warehouse-selected="onWarehouseSelectedByIndex"
    />
    <div v-else class="warehouseSelection-warehouseList-spinner__container">
      <spinner class="warehouseSelection-warehouseList-spinner" size="20px" />
    </div>

    <div
      class="warehouseSelection-modal"
      v-if="addWarehouseInputVisible"
    >
      <div class="warehouseSelection-modal__backdrop">
        <div
          class="warehouseSelection-modal__content"
          role="dialog"
         >
          <add-warehouse-input
            @cancel="onWarehouseModalClose"
            @warehouse-added="onWarehouseAdded"
          ></add-warehouse-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddWarehouseInput from './AddWarehouseInput.vue';
import Spinner from '../../common/Spinner.vue';
import WarehouseList from './WarehouseList.vue';
import { getCustomerWarehouses } from '../common/warehouse';

  export default {
    name: "WarehouseSelection",

    components: { WarehouseList, Spinner, AddWarehouseInput },

    props: {
      warehouse: Object,
    },

    data() {
      return {
        warehouses: [],
        loadingWarehouses: false,
        selectedWarehouse: this.warehouse,
        addWarehouseInputVisible: false,
      };
    },

    computed: {
      warehousesLoaded() {
        return !this.loadingWarehouses;
      },

      selectedWarehouseId() {
        if (this.selectedWarehouse != null) {
          return this.selectedWarehouse.id;
        }

        return null;
      },
    },

    methods: {
      async getWarehouses() {
        this.loadingWarehouses = true;
        this.warehouses = await getCustomerWarehouses();
        this.selectedWarehouse = this.selectedWarehouse || this.warehouses[0];
        this.loadingWarehouses = false;
        this.$emit('on-warehouses-loaded', true);
      },

      onAddWarehouseRequested() {
        this.addWarehouseInputVisible = true;
      },

      onWarehouseSelectedByIndex(selectedIndex) {
        this.selectedWarehouse = this.warehouses[selectedIndex];
      },

      onWarehouseAdded(warehouse) {
        this.warehouses.push(warehouse);
        this.selectedWarehouse = warehouse;
        this.closeWarehouseInputModal();
      },

      closeWarehouseInputModal() {
        this.addWarehouseInputVisible = false;
      },

      onWarehouseModalClose() {
        this.closeWarehouseInputModal();
      },
    },

    watch: {
      selectedWarehouse(newSelectedWarehouse) {
        this.$emit('on-warehouse-selected', newSelectedWarehouse);
      }
    },

    mounted() {
      this.getWarehouses();
    }
  }
</script>

<style scoped>

  .warehouseSelection-warehouseList {
    width: 386px;
    position: relative;
    z-index: 2;
  }

  .warehouseSelection-warehouseList-spinner__container {
    display: flex;
    align-items: center;
    width: 384px;
    height: 60px;
    overflow: hidden;
  }

  .warehouseSelection-warehouseList-spinner {
    align-self: center;
  }

  .warehouseSelection-modal {
    position: fixed;
    z-index: 1000;
  }

  .warehouseSelection-modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .warehouseSelection-modal__content {
    width: 560px;

    background-color: #ffffff;
    max-height: 80%;
    overflow: auto;

    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e7e7e7;
  }

</style>
