<template>
  <div class="spotRequest-stepper">
    <stepper-item
      class="spotRequest-stepper__item"
      v-for="(step, index) in steps"
      :key="index"
      :thisStepperItemDetails="step"
      :thisStepperItemIndex="index"
      :currentStepperItemSelected="getIndex">
    </stepper-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StepperItem from "./StepperItem.vue";
import { vehicleNames } from '@/common/vehicleTypes';

const merchandiseStepIndex = 0;
const vehicleSelectionStepIndex = 1;
const summaryStepIndex = 2;

const merchandiseStep = 'merchandise';
const vehicleStep = 'vehicle';
const summaryStep = 'summary';

function merchandiseCountText(merchandiseCount) {
  if (merchandiseCount > 1) {
    return `${merchandiseCount} notas cadastradas`;
  } else if (merchandiseCount === 1) {
    return `${merchandiseCount} nota cadastrada`;
  } else {
    return `Nenhuma nota cadastrada`;
  }
}

const defaultMerchandiseStepSubtitle = merchandiseCountText(0);
const defaultVehicleSelectionStepSubtitle = 'Nenhum veículo selecionado';
const defaultSummaryStepSubtitle = 'Nenhuma informação';

export default {
  name: "Stepper",

  components: {StepperItem},

  props: [
    'currentStep',
    'merchandiseCount',
    'quoteValue',
    'quoteValueLoading',
  ],

  computed: {
    ...mapState('spotRequest', {
      vehicleType: (state) => state.vehicleType,
    }),

    getIndex() {
      switch (this.currentStep) {
        case merchandiseStep:
          return merchandiseStepIndex;
        case vehicleStep:
          return vehicleSelectionStepIndex;
        case summaryStep:
          return summaryStepIndex;
      }
    },
  },

  methods: {

    stepFromMerchandiseToVehicleSelection() {
      this.steps[merchandiseStepIndex].done = true;
      this.steps[merchandiseStepIndex].subtitle = merchandiseCountText(this.merchandiseCount);
    },

    stepFromVehicleSelectionToSummary() {
      this.steps[vehicleSelectionStepIndex].done = true;
      this.steps[vehicleSelectionStepIndex].subtitle = vehicleNames[this.vehicleType];
    },

    stepFromSummaryToVehicleSelection() {
      this.steps[summaryStepIndex].done = false;

      this.steps[vehicleSelectionStepIndex].subtitle = this.vehicleType ? vehicleNames[this.vehicleType] : defaultVehicleSelectionStepSubtitle;
      this.steps[vehicleSelectionStepIndex].done = false;
    },

    stepFromVehicleSelectionToMerchandise() {
      this.steps[vehicleSelectionStepIndex].subtitle = this.vehicleType ? vehicleNames[this.vehicleType] : defaultVehicleSelectionStepSubtitle;
      this.steps[vehicleSelectionStepIndex].done = false;

      this.steps[merchandiseStepIndex].subtitle = merchandiseCountText(this.merchandiseCount);
      this.steps[merchandiseStepIndex].done = false;
    },

    handleTransitionFromMerchandiseTo(newState) {
      if (newState === vehicleStep) {
        this.stepFromMerchandiseToVehicleSelection();
      } else {
        console.log('Invalid transition from merchandise to ' + newState);
      }
    },

    handleTransitionFromVehicleTo(newState) {
      switch (newState) {
        case merchandiseStep:
          this.stepFromVehicleSelectionToMerchandise();
          break;
        case summaryStep:
          this.stepFromVehicleSelectionToSummary();
          break;
        default:
          console.log('Invalid transition from vehicle to ' + newState);
      }
    },

    handleTransitionFromSummaryTo(newState) {
      if (newState === vehicleStep) {
        this.stepFromSummaryToVehicleSelection();
      } else {
        console.log('Invalid transition from summary to ' + newState);
      }
    }
  },

  data() {
    return {
      steps: [
        {
          iconPath: '/jade/public/ic-spot-request-step-merchandise.svg',
          title: 'Cadastrar mercadorias',
          subtitle: defaultMerchandiseStepSubtitle,
          done: false,
        },
        {
          iconPath: '/jade/public/ic-spot-request-step-vehicle.svg',
          title: 'Escolher tipo de veículo',
          subtitle: defaultVehicleSelectionStepSubtitle,
          done: false,
        },
        {
          iconPath: '/jade/public/ic-spot-request-step-summary.svg',
          title: 'Resumo do pedido',
          subtitle: defaultSummaryStepSubtitle,
          done: false,
        },
      ]
    }
  },

  watch: {
    currentStep(newValue, oldValue) {
      switch (oldValue) {
        case merchandiseStep:
          this.handleTransitionFromMerchandiseTo(newValue);
          break;
        case vehicleStep:
          this.handleTransitionFromVehicleTo(newValue);
          break;
        case summaryStep:
          this.handleTransitionFromSummaryTo(newValue);
          break;
      }
    },

    quoteValue(newValue, oldValue) {
      this.steps[summaryStepIndex].subtitle = newValue;
    },

    quoteValueLoading(newLoading, oldLoading) {
      const done = !newLoading;
      /* Apply the 'done' transition only if the current step has not been changed */
      if (this.currentStep == summaryStep && done) {
        this.steps[summaryStepIndex].done = true;
      }
    },
  },
}
</script>

<style scoped>
  .spotRequest-stepper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: "Source Sans Pro", sans-serif;
    border-bottom: #e7e7e7 1px solid;
  }
</style>
