<template>
  <div v-if="pictures" class="content__pod--picture">
    <carousel
      :navigationEnabled=true
      ref="carousel"
      :perPageCustom="perPageConfig"
    >
      <slide
        v-for="(picture, index) in pictures"
        :key="index"
      >
        <div class="content__pod--sliderimage--div">
          <img class="content__pod--sliderimage" :src="picture.imageUrl" />
        </div>
      </slide>
    </carousel>
  </div>
</template>
<script>
  import { Carousel, Slide } from 'vue-carousel';

  export default {
    name: "PodCarousel",
    components: {
      Carousel,
      Slide
    },
    props: {
      pictures: Array
    },
    data: function () {
      return {
        perPageConfig: []
      }
    },

    methods: {
      getPerPageConfig() {
        let carouselWidth = this.$refs.carousel.$el.getBoundingClientRect().width;

        if (carouselWidth < 500)
          return [[768, 1], [1024, 1], [1367, 1]]

        else
          return [[768, 1], [1024, 1], [1367, 4]]
      }
    },

    mounted(){
      this.perPageConfig = this.getPerPageConfig();
    }
  }
</script>

<style scoped>
  .content__pod--sliderimage{
    margin: auto;
    display: block;
    height: 100%;
  }

  .content__pod--sliderimage--div {
    min-height: 400px;
    height: 400px;
    width: 400px;
    margin: auto;
  }

  .content__pod--picture {
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    padding: 0 30px;
  }


</style>
