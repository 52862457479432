<template>
  <div
    class="common-DriverAssistant"
  >
    <div v-if="driverAssistantsConfig && vehicleType">
      <div
        v-if="isDriverAssistantDisabled"
        class="common-DriverAssistant__disabled"
      >
        Ajudantes estão disponíveis a partir da categoria Utilitário Pequeno.
      </div>
      <div v-else>
        <div
          class="common-DriverAssistant__control"
        >
          <input
            id="isDriverAssistantRequested"
            v-model="requested"
            class="common-DriverAssistant__checkbox"
            type="checkbox"
          >

          <label
            class="common-DriverAssistant__assistantRequested"
            for="isDriverAssistantRequested"
            :class="{ 'common-DriverAssistant__assistantRequested--enabled': driverAssistants.requested }"
          >
            Adicionar ajudante(s)
          </label>

          <input
            v-model.number="count"
            class="common-DriverAssistant__input"
            min="0"
            :max="driverAssistantsMaxCount"
            :class="{ 'common-DriverAssistant__assistantRequested--enabled': driverAssistants.requested }"
            :disabled="assistantNotRequested"
            type="number"
          >
        </div>
        <div
          v-if="driverAssistants.count === 0"
          class="common-DriverAssistant__description"
        >
          Serão cobrados {{ driverAssistantPrice }} por ajudante adicionado.
        </div>
        <div
          v-else
          class="common-DriverAssistant__priceTable"
        >
          <div class="common-DriverAssistant__priceTable__row common-DriverAssistant__priceTable__row--subtotal">
            <p>Ajudantes </p>
            <p class="common-DriverAssistant__priceTable__row--subtotal--bold">
              {{ driverAssistants.count }} x {{ driverAssistantPrice | moneyFormat }}
            </p>
          </div>
          <div class="common-DriverAssistant__priceTable__row common-DriverAssistant__priceTable__row--total">
            <p>Total ajudantes</p>
            <p>{{ driverAssistantsTotalPrice }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="vehicleType">
      <minimal-spinner size="40px" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { moneyFormat } from '../../common/filters/numberFormatters';
import MinimalSpinner from '../../common/MinimalSpinner.vue';

export default {
  name: 'DriverAssistant',

  components: {
    MinimalSpinner,
  },

  filters: {
    moneyFormat,
  },

  computed: {
    ...mapState('spotRequest', ['driverAssistants']),

    ...mapState('spotRequest', {
      driverAssistantFromStore: (state) => state.driverAssistants,
    }),

    ...mapGetters('spotRequest', [
      'isDriverAssistantRecommended',
      'vehicleType',
      'driverAssistantsConfig',
    ]),

    requested: {
      get() {
        return this.driverAssistants.requested;
      },
      set(value) {
        this.$store.commit('spotRequest/updateDriverAssistants', { ...this.driverAssistantFromStore, ...{ requested: value } });
      },
    },

    count: {
      get() {
        return this.driverAssistants.count;
      },
      set(value) {
        this.$store.commit('spotRequest/updateDriverAssistants', { ...this.driverAssistantFromStore, ...{ count: value } });
      },
    },

    assistantNotRequested() {
      return !this.driverAssistants.requested;
    },

    driverAssistantPrice() {
      if (this.vehicleType
        && this.driverAssistantsConfig
        && this.vehicleType in this.driverAssistantsConfig) {
        return this.driverAssistantsConfig[this.vehicleType].unitPrice;
      }
      return 0;
    },

    driverAssistantsTotalPrice() {
      return this.driverAssistantPrice * this.driverAssistants.count;
    },

    recommendedDriverAssistantsCount() {
      if (this.driverAssistants.count) {
        if (this.driverAssistants.count > this.driverAssistantsMaxCount) {
          return this.driverAssistantsMaxCount;
        }

        return this.driverAssistants.count;
      }
      return this.driverAssistantsDefaultCount;
    },

    driverAssistantsDefaultCount() {
      if (this.vehicleType
        && this.driverAssistantsConfig
        && this.vehicleType in this.driverAssistantsConfig) {
        return this.driverAssistantsConfig[this.vehicleType].defaultCount;
      }
      return 0;
    },

    driverAssistantsMaxCount() {
      if (this.vehicleType
        && this.driverAssistantsConfig
        && this.vehicleType in this.driverAssistantsConfig) {
        return this.driverAssistantsConfig[this.vehicleType].maxCount;
      }
      return 3;
    },

    isDriverAssistantDisabled() {
      return this.driverAssistantsMaxCount === 0;
    },
  },

  watch: {
    vehicleType() {
      this.updateCount();
    },
  },

  methods: {
    updateCount() {
      this.requested = this.isDriverAssistantRecommended;
      this.count = this.recommendedDriverAssistantsCount;
    },
  },
};
</script>

<style scoped>

  .common-DriverAssistant {
    display: flex;
    flex-direction: column;
  }

  .common-DriverAssistant__control {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .common-DriverAssistant__checkbox {
    cursor: pointer;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #ccd4d5;
  }

  .common-DriverAssistant__assistantRequested {
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #858585;
  }

  .common-DriverAssistant__input {
    box-sizing: border-box;
    width: 68px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #c4cccf;
    padding: 8px 11px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #858585;
    margin-left: 16px;
  }

  .common-DriverAssistant__description {
    padding: 12px 46px 0 30px;
    color: #858585;
    font-size: 16px;
    letter-spacing: 0.3px;
  }

  .common-DriverAssistant__disabled {
    padding: 12px 46px 0 0px;
    color: #858585;
    font-size: 16px;
    letter-spacing: 0.3px;
  }

  .common-DriverAssistant__assistantRequested--enabled {
    color: #2f2f2f;
  }

  .common-DriverAssistant__priceTable {
    display: flex;
    flex-direction: column;
    width: 240px;
    margin-left: 30px;
  }

  .common-DriverAssistant__priceTable__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .common-DriverAssistant__priceTable__row p {
    margin: 4px 0;
  }

  .common-DriverAssistant__priceTable__row--subtotal {
    color: #858585;
    margin-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #bbbbbb;
  }

  .common-DriverAssistant__priceTable__row--total {
    font-weight: 600;
  }

  .common-DriverAssistant__priceTable__row--subtotal--bold {
    font-weight: 600;
  }
</style>
