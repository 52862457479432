module.exports = {
  NetworkError: 'NetworkError',
  UnknownError: 'UnknownError',
  ValidationError: 'ValidationError',
  MissingFinancialInfo: 'MissingFinancialInfo',
  EstimateNoLongerValid: 'EstimateNoLongerValid',
  BlockedCustomer: 'BlockedCustomer',
  customerDoesNotHavePricingRule: `customerDoesNotHavePricingRule`,

  usersNotFound: 'usersNotFound',
  warehousesNotFound: 'warehousesNotFound',
  validationFailed: 'validationFailed',
};
