<template>
  <div class="billingStatement-orderOverview-navBar">
    <nav-bar-item
      v-for="page in pages"
      :key="page.title"
      :title="page.title"
      :selected="selectedPage === page"
      @click="selectPage(page)"
    ></nav-bar-item>
  </div>
</template>

<script>
  import NavBarItem from './NavBarItem.vue';

  export default {
    name: 'NavBar',

    components: { NavBarItem },

    props: {
      pages: Array
    },

    data() {
      return {
        selectedPage: this.pages[0]
      };
    },

    methods: {
      selectPage(page) {
        this.selectedPage = page;
        this.$emit('page-selected', page);
      }
    },
  }
</script>

<style scoped>

  .billingStatement-orderOverview-navBar {
    padding-top: 18px;
    display: flex;
    font-family: "Source Sans Pro", sans-serif;
  }

</style>
