export const stopStatus = {
  PENDING: 'PENDING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
};

export const stopStatusNames = {
  PENDING: 'Pendente',
  FINISHED: 'Concluído',
  FAILED: 'Insucesso',
};
