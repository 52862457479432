<template>
  <div :class="[hounDS.panels.tabbedPanel, hounDS.panels.verticalContent]">
    <div :class="headerStyle">
      <div
        v-for="(tab, index) in tabs"
        @click="selectTab(index)"
        :class="selectedStyle(index)"
      >
        <div :class="[hounDS.tabs.title]">
          {{ tab.name }}
        </div>
      </div>
    </div>
    <div :class="[hounDS.tabs.content]">
      <component
        v-for="(tab, index) in tabs"
        v-if="selectedTab === index"
        v-bind="tab.props"
        :is="tab.component"
      />
    </div>
  </div>
</template>

<script>
import tabs from '../styles/tabs.module.scss'
import panels from '../styles/panels.module.scss'

export default {
  name: "TabbedPanel",

  props: {
    tabs: {
      type: Array,
      required: true
    },

    stickyHeader: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  computed: {
    hounDS: () => {
      return {
        tabs: Object.freeze(tabs),
        panels: Object.freeze(panels)
      }
    },

    headerStyle() {
      return this.stickyHeader
        ? [this.hounDS.tabs.tabs, this.hounDS.tabs.stickyHeader]
        : [this.hounDS.tabs.tabs]
    }
  },

  data() {
    return {
      // TODO: Nice to have default tab as prop.
      // Open the first tab by default
      selectedTab: 0
    }
  },

  methods: {
    selectTab(tabIndex) {
      this.selectedTab = tabIndex;
    },

    selectedStyle(tabIndex) {
      return this.selectedTab === tabIndex
        ? [this.hounDS.tabs.tab, this.hounDS.tabs.selected]
        : [this.hounDS.tabs.tab]
    }
  }
}
</script>

