<template>
  <CoolLightBox
    :items="images"
    :fullScreen="true"
    :index="openedImageIndex"
    @close="() => $emit('close')"
  ></CoolLightBox>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "ImageView",

  components: {
    CoolLightBox,
  },

  props: {
    images: Array,
    openedImageIndex: Number
  }
}
</script>
