<template>
  <div class="billingStatement-orderOverview-orderOverviewModal">
    <div
      class="billingStatement-orderOverview-orderOverviewModal__backdrop"
      @click="closeModal"
    >
      <div
        class="billingStatement-orderOverview-orderOverviewModal__content"
        role="dialog"
        @click.stop
      >
        <div class="billingStatement-orderOverview-orderOverviewModal__content__header">
          <nav-bar
            class="billingStatement-orderOverview-orderOverviewModal__content__header__navbar"
            :pages="pages"
            @page-selected="onPageSelected"
          ></nav-bar>
          <div
            class="billingStatement-orderOverview-orderOverviewModal__content__header__closeButton"
            @click="closeModal"
          >
            <img src="/jade/public/close-window-button.svg" />
          </div>
        </div>
        <div class="horizontalSeparator"></div>
        <div class="billingStatement-orderOverview-orderOverviewModal__content__body">
          <spinner
            class="billingStatement-orderOverview-orderOverviewModal__content__body__spinner"
            v-if="loadingOrderDetails"
            :size="'90px'"
          ></spinner>
          <component
            class="billingStatement-orderOverview-orderOverviewModal__content__body__component"
            v-else
            v-bind:is="currentPage.component"
            :order="order"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import Itinerary from './Itinerary.vue';
  import Charges from './Charges.vue';
  import NavBar from './NavBar.vue';
  import Spinner from '../../common/Spinner.vue';

  function getOrderDetails(orderId) {
    return axios.get('/jade/api/billing-statement/order-details', {
      params: { orderId }
    }).then(response => response.data);
  }

  const pages = [
    { component: Itinerary, title: 'Itinerário' },
    { component: Charges, title: 'Valores' },
  ];

  export default {
    name: 'OrderOverviewModal',

    components: { NavBar, Charges, Spinner },


    props: [
      'orderId',
    ],

    data() {
      return {
        currentPage: pages[0],
        order: null,
        loadingOrderDetails: true,
      };
    },

    computed: {
      pages() {
        return pages;
      }
    },

    methods: {
      closeModal() {
        this.$emit('close');
      },

      onPageSelected(item) {
        this.currentPage = item;
      },

      async loadOrderDetails() {
        if (!this.orderId) {
          return;
        }

        this.loadingOrderDetails = true;
        this.order = await getOrderDetails(this.orderId);
        this.loadingOrderDetails = false;
      },
    },

    watch: {
      orderId() {
        this.loadOrderDetails();
      }
    },

    mounted() {
      this.loadOrderDetails();
    }
  }
</script>

<style scoped>

  .billingStatement-orderOverview-orderOverviewModal {
    position: fixed;
    z-index: 1000;
  }

  .billingStatement-orderOverview-orderOverviewModal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .billingStatement-orderOverview-orderOverviewModal__content {
    border-radius: 8px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border: solid 1px #e7e7e7;
    display: flex;
    flex-direction: column;
    max-height: 80%;
    width: 800px;
    height: 620px;
  }

  @media only screen and (min-width: 1366px) {
    .billingStatement-orderOverview-orderOverviewModal__content {
      width: 960px;
      height: 620px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .billingStatement-orderOverview-orderOverviewModal__content {
      width: 1200px;
      height: 860px;
    }
  }

  .billingStatement-orderOverview-orderOverviewModal__content__header {
    display: flex;
    padding: 32px 32px 0 80px;
    min-height: 48px;
  }

  .billingStatement-orderOverview-orderOverviewModal__content__header__navbar {
    flex-grow: 1;
  }

  .billingStatement-orderOverview-orderOverviewModal__content__header__closeButton {
    align-self: flex-start;
    cursor: pointer;
  }

  .billingStatement-orderOverview-orderOverviewModal__content__body {
    height: calc(100% - 80px);
  }

  .billingStatement-orderOverview-orderOverviewModal__content__body__spinner {
    height: 100%;
  }

  .billingStatement-orderOverview-orderOverviewModal__content__body__component {
    height: 100%;
  }

</style>
