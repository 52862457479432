import StopInfoWindow from '../map/StopInfoWindow.vue';
import DriverInfoWindow from '../map/DriverInfoWindow.vue';
import markers from '../map/markers';
import { stopTaskTypes } from '../../billingStatement/orderOverview/stopTaskTypes';
import { stopStatus } from '../../billingStatement/orderOverview/stopStatus';

function getStopMarkerIcon(stop) {
  const isLoadingOrUnloading = [stopTaskTypes.LOADING, stopTaskTypes.UNLOADING].includes(stop.taskType);

  const markerType = isLoadingOrUnloading ? 'warehouse' : 'stop';

  switch (stop.status) {
    case stopStatus.FINISHED:
      return markers[markerType].success;
    case stopStatus.FAILED:
      return markers[markerType].failure;
    case stopStatus.PENDING:
      return markers[markerType].default;
    default:
      return markers[markerType].default;
  }
}

function getStopMarkerLabel(stop) {
  const isLoadingOrUnloading = [stopTaskTypes.LOADING, stopTaskTypes.UNLOADING].includes(stop.taskType);

  if (isLoadingOrUnloading) {
    return null;
  }

  return {
    text: (stop.index + 1).toString(),
    color: "white",
    fontFamily: "open sans",
    fontSize: "0.75em",
    fontWeight: "600",
  }
}

function translateStopToMarker(stop, driverEta) {
  const isIntendedDestination = driverEta && stop.id === driverEta.nextStopId;
  const markerIcon = isIntendedDestination ? markers.stop.active : getStopMarkerIcon(stop);

  return {
    icon: markerIcon,
    position: {
      lat: stop.location.latitude,
      lng: stop.location.longitude,
    },
    label: getStopMarkerLabel(stop),
    infoWindow: {
      component: StopInfoWindow,
      props: {
        driverEta,
        stopId: stop.id,
        address: stop.address,
        trackingCode: stop.trackingCode,
      },
    },
  };
}

function buildDriverMarker(driverLocation) {
  return {
    icon: markers.driver,
    position: {
      lat: driverLocation.latitude,
      lng: driverLocation.longitude,
    },
    infoWindow: {
      component: DriverInfoWindow,
      props: {
        content: 'Motorista',
      },
    },
  };
}

function buildOrderMapMarkers({ stops, eta, driverLocation }) {
  let markers = stops.map((stop) => translateStopToMarker(stop, eta));

  if (driverLocation) {
    markers = [buildDriverMarker(driverLocation), ...markers];
  }

  return markers;
}

export { buildOrderMapMarkers };
