export const serviceTypes = {
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
};

export const serviceTypeNames = {
  PICKUP: 'Coleta',
  DELIVERY: 'Entrega',
};

export const serviceTypeImages = {
  PICKUP: '/jade/public/ic-spot-request-type-pickup.svg',
  DELIVERY: '/jade/public/ic-spot-request-type-delivery.svg',
};
